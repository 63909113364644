import React from "react";
// import { useStyles } from "./Styles";
import "./Styles.css";
const Footer = () => {
  
  const handleMouseDown=(event:React.MouseEvent)=>{
    event?.preventDefault()
  }
  return (
    <div className="footer-layout" onMouseDown={handleMouseDown}>
      <footer className="footer">
        <div className="footerLeft">
          <p>
            &copy; 2024 Byrne Intranet Portal. All Rights Reserved. Internal Use
            Only
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
