import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormLabel,
  RadioGroup,
  Radio,
  Snackbar,
  Alert,
  AlertColor,
  Stack,
} from "@mui/material";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState, useEffect, useRef } from "react";
import fileIcon from "../../Assets/Images/filePdf.svg";
import "./ItRequestStyles.css";
import imageCompression from "browser-image-compression";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useNavigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import {
  useGetItRequestsDataQuery,
  useGetItRequestsByIdQuery,
  useUpdateItRequestsMutation,
  useDeleteItRequestsMutation,
  useCreateItRequestsMutation,
  useGetEmployeeSuggestionsQuery,
  useGetRequestTypesQuery,
  useGetEntitiesQuery,
  useGetLocationsDataQuery,
  useGetEmployeeDetailsByIdQuery,
} from "../../Services/APIs";
import {
  useCreateWorkflowConfigMutation,
  useGetWorkflowConfigDetailsQuery,
} from "../../Services/workFlowAPIs";
import LoaderComponent from "../../hooks/LoaderComponent";
import { isNullOrUndefined } from "util";
import useEmployeeDetailsQuery from "../Hooks/Employee Master/useEmployeeDetailsQuery";
import SearchIcon from "@mui/icons-material/Search";
import IconText from "../IconText/IconText";
import AlertYesNo from "../Hooks/DialogYesNo";
import useSendEmail from "../Hooks/useSendEmail";
import { createEmail } from "../../Constants/Email";
import useLoggedInUser from "../Hooks/useLoggedInUser";
interface Suggestion {
  id: string;
  _id: string;
  name: string;
}

interface FormDataType {
  [x: string]: any;
  firstApprover?: string;
  requestType?: string;
  entity?: string;
  location?: string;
  secondApprover?: string;
  userProfile?: string;
  employeeImage: File | string | null;
  // employeeImage: any;
  fileName: any;
  fileType: any;
  requestForMyself?: boolean;
  userId?: string;
  salutation?: string;
  // firstName?: string;
  // lastName?: string;
  // name?: string;
  employeeId?: string;
  designation?: string;
  department?: string;
  officeDirectNo?: string;
  mobileNo?: string;
  dateOfBirth?: Date | any;
  reportingManager?: string;
  requestorDetail?: string;
  subject?: string;
  requestedBy?: string;
  accessPermissionSimilarTo?: string;
  erpCompanyData?: string;
  preferredEmailAddress?: string;
  name?: string;
  description?: string; // Add this if needed
  requestedId?: string; // Add this if needed
  status?: string; // Add this if needed
  requestedOn?: Date; // Add this if needed
}

interface WorkflowConfigData {
  name: string;
  description: string;
  requestedId: string;
  firstApprover: string;
  requestedOn: Date;
  subject: string;
  status: string;
  requestedBy: string;
}

type FormErrors = {
  [key in keyof FormDataType]?: string;
};
interface ChangeEvent<T = unknown> {
  target: T;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const NewRequestForm = () => {
  const { data, error, isLoading, isSuccess, refetch } =
    useGetItRequestsDataQuery();
  const { data: workFlowConfigData } = useGetWorkflowConfigDetailsQuery();
  console.log(workFlowConfigData, "workFlowConfigData:");
  const [sendItem] = useCreateItRequestsMutation();
  const [updateItem] = useUpdateItRequestsMutation();
  const [deleteItem] = useDeleteItRequestsMutation();
  const [createWorkflowConfig] = useCreateWorkflowConfigMutation();
  const { data: RequestTypeData } = useGetRequestTypesQuery();
  const { data: EntityData } = useGetEntitiesQuery();
  const { data: LocationData } = useGetLocationsDataQuery();
  console.log(RequestTypeData, "RequestTypeData:", EntityData, LocationData);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [phone, setPhone] = useState<string>("");
  // const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  // const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  // const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<Suggestion | null>(null);

  const [searchClicked, setSearchClicked] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [hasChanges, setHasChanges] = useState(false); // State to track if the user made any changes
  const {
    data: fetchedSuggestions,
    isLoading: fetchedSuggestionsLoading,
    isError,
  } = useGetEmployeeSuggestionsQuery(searchValue);

  const {
    data: employeeDetails,
    isLoading: detailsLoading,
    isError: detailsError,
  } = useGetEmployeeDetailsByIdQuery(selectedOption?.id, {
    skip: !selectedOption?.id, // Skip query if no selected option
  });
  const { sendEmailHandler, loading, error: submitError } = useSendEmail();
  console.log(employeeDetails, "checkApproverDetails");
  const { employeeData } = useLoggedInUser();
  // const {
  //   data: employeeDetails,
  //   isLoading: detailsLoading,
  //   isError: detailsError,
  // } = useEmployeeDetailsQuery(selectedOption);
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >("success");

  const [selectedFiles, setSelectedFiles] = useState<File | null>(null);
  // const [fileSelected, setFileSelected] = useState<string | null>(null);
  // const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [fileSelected, setFileSelected] = useState<any>("");
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [file, setFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  // const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [preferredEmailAddress, setPreferredEmailAddress] = useState<any>("");
  const [emailError, setEmailError] = useState<string>("");
  const [firstApprover, setFirstApprover] = useState<string>("");
  const [firstApproverError, setFirstApproverError] = useState<string>("");
  const [requestType, setRequestType] = useState<string>("");
  const [requestTypeError, setRequestTypeError] = useState<string>("");
  const [entity, setEntity] = useState<string>("");
  const [entityError, setEntityError] = useState<string>("");
  const [locationError, setLocationError] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [secondApprover, setSecondApprover] = useState<string>("");
  const [secondApproverError, setSecondApproverError] = useState<string>("");
  const [employeeImageError, setEmployeeImageError] = useState<string | null>(
    null
  );
  const [employeeImage, setEmployeeImage] = useState<any>("");
  const [requestorDetail, setRequestDetail] = useState<string>("");
  const [requestDetailError, setRequestDetailError] = useState<string>("");
  const [userIdError, setUserIdError] = useState<string>("");
  const [userId, setUserId] = useState<any>("");
  const [salutation, setSalutation] = useState<string>("Mr.");
  const [salutationError, setSalutationError] = useState<string>("");
  // const [firstNameError, setFirstNameError] = useState<string>("");
  // const [firstName, setFirstName] = useState<string>("");
  // const [lastName, setLastName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  // const [lastNameError, setLastNameError] = useState<string>("");
  const [employeeIdError, setEmployeeIdError] = useState<string>("");
  const [requestForMyself, setRequestForMyself] = useState<boolean>(false);
  const [requestForMyselfError, setRequestForMyselfError] =
    useState<string>("");
  const [employeeId, setEmployeeId] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [departmentError, setDepartmentError] = useState<string>("");
  const [designationError, setDesignationError] = useState<string>("");
  const [officeDirectNoError, setOfficeDirectNoError] = useState<string>("");
  const [officeDirectNo, setOfficeDirectNo] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [mobileNoError, setMobileNoError] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<any>("");
  const [dateOfBirthError, setDateOfBirthError] = useState<string>("");
  const [reportingManagerError, setReportingManagerError] =
    useState<string>("");
  const [reportingManager, setReportingManager] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [subjectError, setSubjectError] = useState<string>("");
  const [requestedByError, setRequestedByError] = useState<string>("");
  const [requestedBy, setRequestedBy] = useState<string>("");
  const [accessPermissionSimilarTo, setAccessPermission] = useState<string>("");
  const [accessPermissionError, setAccessPermissionError] =
    useState<string>("");
  const [erpCompanyDataError, setErpCompanyDataError] = useState<string>("");
  const [erpCompanyData, setErpCompanyData] = useState<string>("");
  const [checkedYes, setCheckedYes] = useState<boolean>(true);
  const [checkedNo, setCheckedNo] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [firstApproverEmail, setFirstApproverEmail] = useState("");
  let requestorEmail = employeeData?.employeeEmail;
  useEffect(() => {
    if (!fetchedSuggestionsLoading && !isError) {
      const employeeOptions = fetchedSuggestions?.map(
        (employee: { _id: any; employeeName: any }) => ({
          id: employee._id, // Use _id for unique key
          name: employee.employeeName,
        })
      );
      setSuggestions(employeeOptions);
      console.log(
        employeeOptions,
        "employee options",
        fetchedSuggestions,
        "employee name",
        employeeOptions.name
      );
      employeeOptions.forEach((option: { name: any }) => {
        console.log(option.name, "employee name:");
      }); // Set the full object as suggestions
    }
  }, [fetchedSuggestions, fetchedSuggestionsLoading, isError]);

  useEffect(() => {
    if (employeeDetails && !detailsLoading) {
      // Assuming employeeDetails has an email field
      setFirstApproverEmail(employeeDetails.employeeEmail);
      console.log(firstApproverEmail, "employee email", employeeDetails); // Update state with employee email
    }
  }, [employeeDetails, detailsLoading]);

  // const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setSearchValue(event.target.value);
  // };
  // const handleSearchClick = () => {
  //   setSearchClicked(true);
  //   if (searchValue === "" && selectedOption !== "") {
  //     setOpen(true);
  //     setSelectedOption(selectedOption); // Update the selected option state
  //   }
  // };
  // const handleAutocompleteChange = (event: any, newValue: string | null) => {
  //   setSelectedOption(newValue);
  //   if (newValue !== null) {
  //     setOpen(true); // Open the drawer when a dropdown value is selected
  //   }
  // };

  const handleAutocompleteChange = (
    event: any,
    newValue: Suggestion | null
  ) => {
    // Update the selected option state
    setSelectedOption(newValue);
    setHasChanges(true);
    console.log(selectedOption, "selected option:", newValue);
    // Update the reqFormData state with the selected value
    setReqFormData((prevData) => ({
      ...prevData,
      // Ensure firstApprover is always a string
      firstApprover: newValue ? newValue.name : "", // Extract name or set to empty string
    }));
    if (newValue) {
      setSearchValue(newValue.name); // Update searchValue with the name of the selected option
      // setOpen(true); // Open the drawer when a dropdown value is selected
      setFirstApproverError(""); // Clear error message
    } else {
      setFirstApproverError("First Approver is required");
    }

    // Open the drawer if a value is selected
    if (newValue !== null) {
      setOpen(true);
      setSearchValue(""); // Open the drawer when a dropdown value is selected
    }

    // // Set error message based on selection
    // if (newValue) {
    //   setFirstApproverError("");
    // } else {
    //   setFirstApproverError("First Approver is required");
    // }
  };

  const handleInputChange2 = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value; // Get the input value
    setSearchValue(newValue); // Update state
    setHasChanges(true);
    // console.log(searchValue,"search value:")
    // If needed, you could call your query or API function here based on the new value.
  };

  useEffect(() => {
    console.log(searchValue, "searchvalue:");
  }, [searchValue]);

  // const handlePhoneChange = (value: string) => {
  //   setMobileNo(value);
  // };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Remove any non-digit characters
    const sanitizedValue = value.replace(/\D/g, "");

    // Allow only up to 10 digits
    if (sanitizedValue.length <= 10) {
      setReqFormData({ ...reqFormData, mobileNo: sanitizedValue });
      setMobileNoError("");
    }
  };
  // const handlePhoneChange = (
  //   value: string,
  //   data: CountryData, // Properly typed as CountryData
  //   event: ChangeEvent<HTMLInputElement>,
  //   formattedValue: string
  // ) => {
  //   // Clean the input value
  //   const cleanedValue = value.replace(/[^\d+]/g, "");

  //   // Calculate the country code length
  //   const countryCodeLength = data.dialCode.length;

  //   // Ensure that the length after the country code is exactly 10 digits
  //   const maxDigitsAfterCountryCode = 10;

  //   if (cleanedValue.length - countryCodeLength > maxDigitsAfterCountryCode) {
  //     setMobileNoError("Phone number must be 10 digits after the country code");
  //     return;
  //   } else {
  //     setMobileNoError("");
  //   }

  //   setMobileNo(cleanedValue);
  //   setReqFormData({ ...reqFormData, mobileNo: cleanedValue });
  // };
  // const isValidPhoneNumber = (
  //   inputNumber: string,
  //   country: any, // Use any or the correct type that matches the library's expectations
  //   countries: any[],
  //   hiddenAreaCodes: any[]
  // ): boolean => {
  //   const maxLength = 10;
  //   const numberWithoutCountryCode = inputNumber.replace(
  //     new RegExp(`^\\+${country.dialCode}`),
  //     ""
  //   );
  //   return numberWithoutCountryCode.length <= maxLength;
  // };
  const handleOfficeNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;

    // Allow only numeric characters
    const sanitizedValue = value.replace(/\D/g, "");

    // Allow only up to 10 digits
    if (sanitizedValue.length <= 10) {
      setReqFormData({ ...reqFormData, officeDirectNo: sanitizedValue });
      setOfficeDirectNoError(""); // Clear any previous error if valid
    }
  };
  const handleSelectNameChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    // setFirstName(value);
    // setReqFormData({ ...reqFormData, firstName: value });
    setNameError("");
  };

  const resetFormFields = () => {
    setRequestForMyself(Boolean);
    setRequestType("");
    setEntity("");
    setLocation("");
    setUserId("");
    setName("");
    setSelectedFileName("");
    setMobileNo("");
    setFileSelected("");
    setSubject("");
    setReportingManager("");
    setFirstApprover("");
    setAccessPermission("");
    setErpCompanyData("");
    setSecondApprover("");
    setRequestDetail("");
    setSalutation("");
    // setLastName("");
    setRequestedBy("");
    setEmployeeId("");
    setDesignation("");
    setDepartment("");
    setOfficeDirectNo("");
    setDateOfBirth("");
    setPreferredEmailAddress("");
    // setDateOfBirth(null);
    // setIsActives(true);
    setRequestTypeError("");
    setAccessPermissionError("");
    setEntityError("");
    setEmailError("");
    setRequestDetailError("");
    setRequestForMyselfError("");
    // setLastNameError("");
    setLocationError("");
    setSecondApproverError("");
    setFirstApproverError("");
    setErpCompanyDataError("");
    setRequestedByError("");
    setUserIdError("");
    setSubjectError("");
    setSalutationError("");
    setSelectedFiles(null);
    setReportingManagerError("");
    setEmployeeIdError("");
    setMobileNoError("");
    setNameError("");
    setDepartmentError("");
    setDesignationError("");
    setDateOfBirthError("");
    setOfficeDirectNoError("");
    setErpCompanyDataError("");
    // setIsEditMode(false);
  };

  const [reqFormData, setReqFormData] = useState<FormDataType>({
    firstApprover: "",
    requestType: "",
    entity: "",
    location: "",
    // secondApprover: "",
    // userProfile: "",
    requestForMyself: undefined,
    // requestForMyself: false,
    userId: "",
    // salutation: "",
    salutation: "Mr.",
    // firstName: "",
    // lastName: "",
    name: "",
    employeeId: "",
    employeeImage: null,
    fileName: "",
    fileType: "",
    designation: "",
    department: "",
    officeDirectNo: "",
    mobileNo: "",
    dateOfBirth: undefined,
    reportingManager: "",
    requestorDetail: "",
    subject: "",
    requestedBy: "",
    accessPermissionSimilarTo: "",
    erpCompanyData: "",
    preferredEmailAddress: "",
  });

  console.log(reqFormData, "req data");

  const handleDateChange = (date: Dayjs | null) => {
    setHasChanges(true);
    const today = dayjs();

    if (date && date.isValid()) {
      setReqFormData((prevData) => ({
        ...prevData,
        dateOfBirth: date.toDate(),
      }));
      setDateOfBirthError("");
    } else {
      setReqFormData((prevData) => ({
        ...prevData,
        dateOfBirth: null,
      }));
      setDateOfBirthError("Invalid date");
    }
  };

  // const handleDateChange = (date: Dayjs | null) => {
  //   setHasChanges(true);
  //   if (date && date.isValid()) {
  //     setReqFormData((prevData) => ({
  //       ...prevData,
  //       dateOfBirth: date.toDate(),
  //     }));
  //     setDateOfBirthError("");
  //   } else {
  //     setReqFormData((prevData) => ({
  //       ...prevData,
  //       dateOfBirth: null, // Clear the date if it's invalid
  //     }));
  //     setDateOfBirthError("Invalid date");
  //   }
  // };
  const handleChangeRequestType = (event: ChangeEvent<{ value: unknown }>) => {
    setHasChanges(true);
    setReqFormData({
      ...reqFormData,
      requestType: event.target.value as string,
    });
    setRequestTypeError("");
  };
  const handleChangeEntity = (event: ChangeEvent<{ value: unknown }>) => {
    setHasChanges(true);
    setReqFormData({ ...reqFormData, entity: event.target.value as string });
    setEntityError("");
  };
  const handleChangeLocation = (event: ChangeEvent<{ value: unknown }>) => {
    setHasChanges(true);
    setReqFormData({ ...reqFormData, location: event.target.value as string });
    setLocationError("");
  };

  const handleChange =
    (field: keyof FormDataType) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      setHasChanges(true);
      setReqFormData({ ...reqFormData, [field]: event.target.value });

      console.log(field, event, "checkfieldValue");

      // Clear error for the field on change
      // if (field === "firstApprover") setFirstApproverError("");
      // if (field === "requestType") setRequestTypeError("");
      // if (field === "entity") setEntityError("");
      // if (field === "location") setLocationError("");
      // if (field === "employeeImage") setEmployeeImageError("");
      // if (field === "secondApprover") setSecondApproverError("");
      // if (field === "requestForMyself") setRequestForMyselfError("");
      // if (field === "userId") setUserIdError("");
      // if (field === "salutation") setSalutationError("");
      // // if (field === "firstName") setFirstNameError("");
      // // if (field === "lastName") setLastNameError("");
      // if (field === "name") setNameError("");
      // if (field === "employeeId") setEmployeeIdError("");
      // if (field === "designation") setDesignationError("");
      // if (field === "department") setDepartmentError("");
      // if (field === "officeDirectNo") setOfficeDirectNoError("");
      // if (field === "mobileNo") setMobileNoError("");
      // if (field === "dateOfBirth") setDateOfBirthError("");
      // if (field === "reportingManager") setReportingManagerError("");
      // if (field === "requestorDetail") setRequestDetailError("");
      // if (field === "subject") setSubjectError("");
      // if (field === "requestedBy") setRequestedByError("");
      // if (field === "accessPermissionSimilarTo") setAccessPermissionError("");
      // if (field === "erpCompanyData") setErpCompanyDataError("");
      // if (field === "preferredEmailAddress") setEmailError("");
    };

  const handleClick = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const validateForm = () => {
    let formIsValid = true;
    let messageError = "";
    let validationPassed = "";
    console.log(formIsValid, "checkformvalidatrue");
    console.log("Form data at validation:", reqFormData);
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Phone number validation regex (assuming 10 digits)
    const phoneRegex = /^[0-9]{10}$/;
    if (reqFormData.firstApprover === "") {
      setFirstApproverError("First Approver is required");
      messageError += "First Approver is required\n";
      formIsValid = false;
    } else {
      validationPassed += "First Approver passed\n";
      setFirstApproverError("");
    }
    if (reqFormData.requestType === "") {
      setRequestTypeError("Request Type is required");
      messageError = "Request Type is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Request Type passed\n";
      setRequestTypeError("");
    }
    if (reqFormData.entity === "") {
      setEntityError("Entity is required");
      messageError = "Entity is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Entity passed\n";
      setEntityError("");
    }

    if (reqFormData.location === "") {
      setLocationError("Location is required");
      messageError = "Location is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Location passed\n";
      setLocationError("");
    }
    // if (reqFormData.employeeImage === "") {
    //   setEmployeeImageError("Image is required");
    //   formIsValid = false;
    // } else {
    //   setEmployeeImageError("");
    // }
    if (!reqFormData.employeeImage) {
      setEmployeeImageError("Image is required");
      messageError = "Image is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Image passed\n";
      setEmployeeImageError(""); // Clear the error if an image is present
    }
    if (reqFormData.secondApprover === "") {
      setSecondApproverError("Request Type is required");
      messageError = "Request Type is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Request Type passed\n";
      setSecondApproverError("");
    }
    // if (
    //   reqFormData.requestForMyself === undefined ||
    //   reqFormData.requestForMyself === null
    // ) {
    //   setRequestForMyselfError("Please select either 'Yes' or 'No'.");
    //   formIsValid = false;
    // } else {
    //   setRequestForMyselfError("");
    // }

    if (!reqFormData.userId || reqFormData.userId.trim() === "") {
      setUserIdError("User ID is required");
      messageError = "User ID is required\n";
      formIsValid = false;
    } else if (!/^[a-zA-Z0-9\s-]+$/.test(reqFormData.userId.trim())) {
      setUserIdError(
        "User ID can only contain letters, numbers, spaces, or hyphens"
      );
      messageError +=
        "User ID can only contain letters, numbers, spaces, or hyphens\n";
      formIsValid = false;
    } else {
      setUserIdError(""); // Clear error if valid
      validationPassed += "User ID passed\n";
    }

    // if (reqFormData.salutation === "") {
    //   setSalutationError("Salutation is required");
    //   messageError = "Salutation is required\n";
    //   formIsValid = false;
    // } else {
    //   validationPassed += "Salutation passed\n";
    //   setSalutationError("");
    // }
    // if (reqFormData.firstName === "") {
    if (reqFormData.name?.trim() === "") {
      setNameError("Name is required");
      messageError = "Name is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Name passed\n";
      setNameError("");
    }
    // if (reqFormData.lastName === "") {
    //   setLastNameError("Name is required");
    //   messageError = "Last Name is required\n";
    //   formIsValid = false;
    // } else {
    //   validationPassed += "Last Name passed\n"
    //   setLastNameError("");
    // }
    if (!reqFormData.employeeId || reqFormData.employeeId.trim() === "") {
      setEmployeeIdError("Employee ID is required");
      messageError = "Employee ID is required\n";
      formIsValid = false;
    } else if (!/^[a-zA-Z0-9\s-]+$/.test(reqFormData.employeeId.trim())) {
      setEmployeeIdError(
        "Employee ID can only contain letters, numbers, spaces, or hyphens"
      );
      messageError +=
        "Employee ID can only contain letters, numbers, spaces, or hyphens\n";
      formIsValid = false;
    } else {
      setEmployeeIdError(""); // Clear error if valid
      validationPassed += "Employee ID passed\n";
    }

    if (reqFormData.designation?.trim() === "") {
      setDesignationError("Designation is required");
      messageError = "Designation is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Designation ID passed\n";
      setDesignationError("");
    }
    if (reqFormData.department?.trim() === "") {
      setDepartmentError("Department is required");
      messageError = "Department is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Department ID passed\n";
      setDepartmentError("");
    }
    const officeDirectNo = reqFormData.officeDirectNo ?? "";
    if (officeDirectNo?.trim() === "") {
      setOfficeDirectNoError("Office Direct No is required");
      messageError = "Office Direct No is required\n";
    } else if (!phoneRegex.test(officeDirectNo)) {
      setOfficeDirectNoError(
        "Invalid Phone Number. It should be a 10-digit number."
      );
      messageError = "Invalid Phone Number. It should be a 10-digit number\n";
    } else {
      validationPassed += "Office Direct passed\n";
      setOfficeDirectNoError("");
    }

    const mobileNo = reqFormData.mobileNo ?? "";
    if (mobileNo?.trim() === "") {
      setMobileNoError("Mobile No is required");
      messageError = "Mobile No is required\n";
      formIsValid = false;
    } else if (!phoneRegex.test(mobileNo)) {
      setMobileNoError("Invalid Phone Number. It should be a 10-digit number.");
      messageError = "Invalid Phone Number. It should be a 10-digit number.\n";
    } else {
      setMobileNoError("");
      validationPassed += "Mobile No passed\n";
    }
    // if (reqFormData.dateOfBirth === "") {
    //   setDateOfBirthError("Date of Birth is required");
    //   formIsValid = false;
    // } else {
    //   setDateOfBirthError("");
    // }
    if (!reqFormData.dateOfBirth) {
      setDateOfBirthError("Date of Birth is required");
      messageError = "Date of Birth is required\n";
      formIsValid = false;
    } else if (isNaN(new Date(reqFormData.dateOfBirth).getTime())) {
      setDateOfBirthError("Invalid date");
      messageError = "Invalid date\n";
      formIsValid = false;
    } else {
      validationPassed += "Date of Birth passed\n";
      setDateOfBirthError("");
    }
    if (reqFormData.reportingManager?.trim() === "") {
      setReportingManagerError("Reporting Manager is required");
      messageError = "Reporting Manager is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Reporting Manager passed\n";
      setReportingManagerError("");
    }
    if (reqFormData.requestorDetail?.trim() === "") {
      setRequestDetailError("Request Details  is required");
      messageError = "Request Details is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Request Details passed\n";
      setRequestDetailError("");
    }
    if (reqFormData.subject?.trim() === "") {
      setSubjectError("Subject  is required");
      messageError = "Subject is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Subject passed\n";
      setSubjectError("");
    }
    if (reqFormData.requestedBy?.trim() === "") {
      setRequestedByError("Requested By is required");
      messageError = "Requested By is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Requested By passed\n";
      setRequestedByError("");
    }
    if (reqFormData.accessPermissionSimilarTo?.trim() === "") {
      setAccessPermissionError("Access Permission Similar To is required");
      messageError = "Access Permission Similar To is required\n";
      formIsValid = false;
    } else {
      validationPassed += "Access Permission Similar To passed\n";
      setAccessPermissionError("");
    }
    if (reqFormData.erpCompanyData?.trim() === "") {
      setErpCompanyDataError("ERP Company Data is required");
      messageError = "ERP Company Data is required\n";
      formIsValid = false;
    } else {
      validationPassed += "ERP Company Data passed\n";
      setErpCompanyDataError("");
    }
    if ((reqFormData.preferredEmailAddress ?? "")?.trim() === "") {
      setEmailError("Preferred Email Address is required");
      messageError = "Preferred Email Address is required\n";
      formIsValid = false;
    } else if (!emailRegex.test(reqFormData.preferredEmailAddress ?? "")) {
      messageError = "Invalid Email Addres\n";
      setEmailError("Invalid Email Address");
      formIsValid = false;
    } else {
      validationPassed += "Email Address passed\n";
      setEmailError("");
    }
    return {
      formIsValid,
      messageError,
      validationPassed,
    };
  };

  useEffect(() => {
    validateForm();
  }, [
    reqFormData,
    // userId,
    // salutation,
    // // firstName,
    // // lastName,
    // name,
    // employeeId,
    // designation,
    // dateOfBirth,
    // employeeImage,
    // status,
    // mobileNo,
    // subject,
    // requestedBy,
    // reportingManager,
    // requestType,
    // entity,
    // department,
    // firstApprover,
    // location,
    // secondApprover,
    // officeDirectNo,
    // erpCompanyData,
    // preferredEmailAddress,
  ]);

  const handleAlertCancel = () => {
    setOpenAlert(false);
    setAlertMessage("");
  };

  const handleAlertYes = () => {
    setOpenAlert(false);
    navigate(`/itRequests`);
  };

  const handleCancel = () => {
    if (hasChanges) {
      setAlertMessage("Do you wish to cancel the changes?");
      setOpenAlert(true);
    } else {
      navigate(`/itRequests`);
    }

    // setReqFormData(reqFormData);
    // setRequestTypeError("");
    // setAccessPermissionError("");
    // setEntityError("");
    // setEmailError("");
    // setRequestDetailError("");
    // setLastNameError("");
    // setLocationError("");
    // setEmployeeImageError("");
    // setSecondApproverError("");
    // setFirstApproverError("");
    // setErpCompanyDataError("");
    // setRequestedByError("");
    // setUserIdError("");
    // setSubjectError("");
    // setSalutationError("");
    // setSelectedFiles(null);
    // setReportingManagerError("");
    // setEmployeeIdError("");
    // setMobileNoError("");
    // setFirstNameError("");
    // setDepartmentError("");
    // setDesignationError("");
    // setDateOfBirthError("");
    // setOfficeDirectNoError("");
    // setErpCompanyDataError("");
    // setFile(null);
    // Clear any file if you have one
    // Reset other related state variables if necessary
  };

  const handleCheckboxChange =
    (field: keyof FormDataType) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setReqFormData((prevData) => ({
        ...prevData,
        [field]: isChecked,
      }));
      if (field === "requestForMyself") {
        setRequestForMyselfError("");
      }
    };
  // const handleCheckboxChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const isChecked = event.target.checked;
  //   setCheckedYes(isChecked);
  //   setCheckedNo(!isChecked);
  //   setRequestForMyself(isChecked);
  // };

  const resetAllErrorAlert = () => {
    setRequestTypeError("");
    setAccessPermissionError("");
    setEntityError("");
    setEmailError("");
    setRequestDetailError("");
    // setLastNameError("");
    setLocationError("");
    setSecondApproverError("");
    setFirstApproverError("");
    setErpCompanyDataError("");
    setRequestForMyselfError("");
    setRequestedByError("");
    setUserIdError("");
    setSubjectError("");
    setSalutationError("");
    // setSelectedFiles(null);
    setReportingManagerError("");
    setEmployeeIdError("");
    setMobileNoError("");
    setNameError("");
    setDepartmentError("");
    setDesignationError("");
    setDateOfBirthError("");
    setOfficeDirectNoError("");
    setErpCompanyDataError("");
  };

  const createFormData = (data: FormDataType) => {
    const formData = new FormData();

    // Loop through each entry in the form data object
    Object.entries(data).forEach(([key, value]) => {
      if (key === "employeeImage") {
        // If the field is for employeeImage, check if it's a File
        if (value instanceof File) {
          formData.append(key, value); // Append file directly
        } else if (typeof value === "string" && value) {
          formData.append(key, value); // Append string if it's an image URL or base64
        }
      } else {
        // Handle other fields
        if (value !== null && value !== undefined) {
          formData.append(key, String(value)); // Convert non-file values to string
        }
      }
    });

    // If there are additional selected files, append them
    if (selectedFiles) {
      formData.append("file", selectedFiles); // This is additional, based on your usage
    }

    // Debugging logs to see form data structure
    Array.from(formData.entries()).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
    });

    return formData;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission
    // setIsSubmitting(true);
    resetAllErrorAlert();

    // Call validateForm and extract formIsValid
    const { formIsValid, messageError, validationPassed } = validateForm(); // Ensure this returns the actual boolean value
    console.log(
      formIsValid,
      messageError,
      validationPassed,
      salutation,
      "checkFormValidation"
    );
    if (!formIsValid) {
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all fields to submit");
      setSnackbarSeverity("error");
      setIsSubmitting(false);
      return;
    } else {
      setIsSubmitting(true);
      showLoader();
      // Continue with form submission logic if validation passes
      console.log("Form data to be submitted:", reqFormData); // Debugging log

      const formData = createFormData(reqFormData);
      formData.append("firstApproverEmail", firstApproverEmail);
      formData.append("requestorEmail", employeeData?.employeeEmail);
      let runId: string | undefined;
      let nextApproverId: string | undefined;

      try {
        let submitResponse;
        let workflowResponse;

        if (!isEditMode) {
          submitResponse = await sendItem(formData).unwrap();

          const requestedIdFromResponse = submitResponse.result?.requestedId;
          reqFormData.requestedId = requestedIdFromResponse;

          if (!requestedIdFromResponse) {
            throw new Error("requestedId not found in the response.");
          }

          if (
            !submitResponse?.error &&
            requestorEmail &&
            reqFormData?.requestedBy &&
            reqFormData?.firstApprover &&
            requestedIdFromResponse &&
            firstApproverEmail &&
            reqFormData?.requestorDetail
          ) {
            const emailData = createEmail(
              firstApproverEmail, // to :- next approver
              requestorEmail, // cc :- requested user, current approver if any
              reqFormData?.firstApprover, // approverName
              requestedIdFromResponse, // requestId
              reqFormData?.requestedBy, // requestorName
              reqFormData?.requestorDetail // requestDetails
            );
            // If successful, run the other function here
            // sendEmailHandler(emailData)
            console.log(emailData, "checkEmailData");
          }

          console.log("Form data submitted successfully:", submitResponse);

          const workflowFormData = new FormData();
          workflowFormData.append("name", reqFormData.name || "");
          workflowFormData.append("description", reqFormData.description || "");
          workflowFormData.append("requestedId", requestedIdFromResponse);
          workflowFormData.append(
            "firstApprover",
            reqFormData.firstApprover || ""
          );
          workflowFormData.append(
            "requestedOn",
            reqFormData.requestedOn?.toString() || ""
          );
          workflowFormData.append("subject", reqFormData.subject || "");
          workflowFormData.append("status", reqFormData.status || "");
          workflowFormData.append("requestedBy", reqFormData.requestedBy || "");
          workflowFormData.append(
            "firstApproverEmail",
            firstApproverEmail || ""
          );

          workflowResponse = await createWorkflowConfig(
            workflowFormData
          ).unwrap();
          console.log(
            "Workflow configuration created successfully:",
            workflowResponse
          );
        } else {
          formData.append("eventId", userId || "");
          formData.append("previousFile", fileSelected || "");
          formData.append("previousFileName", selectedFileName || "");
          if (selectedFiles) {
            console.log("Appending file:", selectedFiles); // Log the selected file
            formData.append("employeeImage", selectedFiles, selectedFiles.name); // Ensure selectedFiles is not null
          } else {
            console.warn("No file selected"); // Warn if no file is selected
          }
          formData.append("fileType", "image"); // Fi
          // formData.append("firstName", firstName || "");
          // formData.append("lastName", lastName || "");
          formData.append("name", name || "");

          await updateItem(formData); // Adjust according to your actual update function
          console.log("Item updated successfully");
        }

        hideLoader(); // Hide the loader
        resetFormFields(); // Reset the form fields
        setSnackbarOpen(true);

        setSnackbarMessage(
          !isEditMode
            ? "Request Submitted successfully"
            : "Request Submitted Successfully"
        );
        setSnackbarSeverity("success");
        setTimeout(() => {
          navigate("/itRequests");
        }, 2000);
      } catch (error) {
        console.error("Submission or workflow creation error:", error);
        hideLoader(); // Hide the loader
        setSnackbarOpen(true);
        setSnackbarMessage("Failed to Submit the request");
        setSnackbarSeverity("error");
        setIsSubmitting(false);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadMedia = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event?.target?.files?.[0];
    if (!file) return;

    console.log("Selected file:", file); // Log the selected file

    const MAX_SIZE_MB = 16;
    if (file.size / (1024 * 1024) > MAX_SIZE_MB) {
      setEmployeeImageError(`File size should be less than ${MAX_SIZE_MB} MB`);
      return;
    }

    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: MAX_SIZE_MB,
        maxWidthOrHeight: 1920,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setSelectedFiles(compressedFile); // Store compressed file
        setSelectedFileName(file?.name); // Set file name
        setFileSelected(base64String); // Base64 image for preview
        setEmployeeImageError(""); // Clear error if any

        // Update reqFormData with the compressed file
        setReqFormData((prev) => ({
          ...prev,
          employeeImage: compressedFile, // Store the compressed file
          fileName: file?.name, // store the file name
        }));
      };

      // Read the compressed file as Data URL for preview
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Image compression error:", error);
      setEmployeeImageError("Failed to compress image");
    }
  };

  console.log(fileRef.current,'checkdragImage')

  // const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log(event?.target?.files?.[0].name)
  //   const file = event?.target?.files?.[0];
  // //   if (!file) return;
  //   setSelectedFiles(file);
  //   setSelectedFileName(file.name);
  //   setEmployeeImageError("");
  //   let reader = new FileReader();
  //   // @ts-ignore
  //   reader.readAsDataURL(event?.target?.files?.[0]);
  //   reader.onload = (e) => {
  //     console.log(e.target?.result, "kkkkttt");
  //     setFileSelected(e.target?.result);
  //     //   setImageError("");
  //     //@ts-ignore
  //     // var eee4 = window.atob(e.target?.result)
  //     // console.log(eee4,'rrrrrrthds')
  //   };
  // };

  // Prevent file opening in a new tab when dropped outside the upload area
  const handleGlobalDragAndDrop = (event:any) => {
    event.preventDefault();
  };
  
  // Add the global event listeners directly
  window.addEventListener("dragover", handleGlobalDragAndDrop);
  window.addEventListener("drop", handleGlobalDragAndDrop);

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleUploadMediaDragging = async(event: any) => {
    // const files = event.target.files;
    // if (!files || !files[0]) return;

    // setSelectedFiles(files[0]);
    // setSelectedFileName(files[0].name);
    // setEmployeeImageError("");

    // let reader = new FileReader();
    // reader.readAsDataURL(files[0]);
    // reader.onload = (e) => {
    //   setFileSelected(e.target?.result as string);
    // };
    // // Update reqFormData with the compressed file
    // setReqFormData((prev) => ({
    //   ...prev,
    //   employeeImage: files[0], // Store the compressed file
    //   fileName: files[0].name, // store the file name
    // }));

    const file = event?.target?.files?.[0];
    if (!file) return;

    console.log("Selected file:", file); // Log the selected file

    const MAX_SIZE_MB = 16;
    if (file.size / (1024 * 1024) > MAX_SIZE_MB) {
      setEmployeeImageError(`File size should be less than ${MAX_SIZE_MB} MB`);
      return;
    }

    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: MAX_SIZE_MB,
        maxWidthOrHeight: 1920,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setSelectedFiles(compressedFile); // Store compressed file
        setSelectedFileName(file?.name); // Set file name
        setFileSelected(base64String); // Base64 image for preview
        setEmployeeImageError(""); // Clear error if any

        // Update reqFormData with the compressed file
        setReqFormData((prev) => ({
          ...prev,
          employeeImage: compressedFile, // Store the compressed file
          fileName: file?.name, // store the file name
        }));
      };

      // Read the compressed file as Data URL for preview
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error("Image compression error:", error);
      setEmployeeImageError("Failed to compress image");
    }
  };

  const handleDrop = (event: any) => {
    // Mark that changes have been made
    setHasChanges(true);

    // Prevent default browser behavior for file drop
    event.preventDefault();
    event.stopPropagation();



    // Get the dropped files
    const files = event.dataTransfer.files;
    console.log(files,'checkfiles');

    const file = files[0];
    if (file && file.type.startsWith("image/")) {
      handleUploadMediaDragging({ target: { files } });
    } else {
      alert("Please drop an image file.");
    }

    // Call the file upload handler with the dropped files
    // handleUploadMediaDragging({ target: { files } });
  };
  const handleClearFile = () => {
    setHasChanges(true);
    setSelectedFileName("");
    setSelectedFiles(null);
    setFileSelected("");

    setReqFormData((prev) => ({
      ...prev,
      employeeImage: null,
      fileName: "",
    }));

    if (fileRef.current) {
      fileRef.current.value = "";
    }
    setEmployeeImageError("");
  };
  function truncateDescription(description: string, wordLimit: number) {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  }
  return (
    <div>
      <IconText />
      <div
        style={{
          backgroundColor: "#f1f1f1",
          marginTop: "0px",
          // height: "80vh",
          // overflowY: "scroll",
        }}
      >
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          <Grid
            style={{
              backgroundColor: "#D20F3D",
              position: "sticky",
              top: 0,
              zIndex: 1000,
            }}
          >
            <Stack
              paddingRight={{ xs: "10px", md: "40px" }}
              paddingTop="5px"
              paddingBottom="5px"
              display="flex"
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              justifyContent="space-between"
            >
              <div
                className="TableBreadcrumbs"
                // style={{ marginBottom: { xs: "10px", md: "0" } }}
              >
                <Link to="/itRequests" className="TableBreadcrumbs1">
                  Dashboard
                </Link>
                <span className="breadcrumsIconNew1">
                  <NavigateNextIcon />
                </span>
                <span className="Breadcrumbs2">Form</span>
                <span className="breadcrumsIconNew1">
                  <NavigateNextIcon />
                </span>
                <span className="Breadcrumbs2">New Request</span>
              </div>
              <div className="all-buttons">
                <Button className="cancel-req-btn" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  className="submit-req-btn"
                  sx={{ marginLeft: { xs: "0", md: "10px" } }} // Remove margin on small screens
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </Stack>
          </Grid>
          {/* <Grid */}
          {/* // xs={12}
          // style={{ */}
          {/* width: "100%",
             backgroundColor: "#f1f1f1",
             marginBottom: "35px",
             padding: "10px 45px",
           }}
          > */}
          {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                float: "right",
              }}
            > */}
          {/* <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <div>
                <span>
                  <CancelIcon
                    fontSize="small"
                    style={{
                      float: "right",
                      color: "gray",
                      paddingRight: "10px",
                      paddingTop: "5px",
                    }}
                    onClick={handleClose}
                  />
                </span>
              </div>
              <DialogTitle
                style={{
                  color: "#e0001b",
                  fontSize: "20px",
                  padding: "2px 24px",
                }}
              >
                {"Form Submission"}
              </DialogTitle>
              <DialogContent>
                <div style={{ color: "" }}>
                  Are you sure you want to Submit this form?
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} className="all-btn">
                  No
                </Button>
                <Button onClick={handleConfirmSubmit} className="all-btn">
                  Yes Submit
                </Button>
              </DialogActions>
            </Dialog> */}
          {/* </div>
          </Grid> */}
          {isLoadingg && <LoaderComponent />}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <div
            style={{
              // marginBottom: "50px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                padding: "20px",
                margin: "40px",
                // height: "50vh",
                // overflowY: "scroll",
              }}
            >
              <div>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request Type
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Approver</Typography>
                    {/* <TextField
                      id="firstApprover"
                      name="firstApprover"
                      className="span"
                      value={reqFormData.firstApprover}
                      style={{ width: "100%" }}
                      onChange={handleChange("firstApprover")}
                      // onChange={handleApproveChange}
                      placeholder="Enter value here"
                      autoComplete="off"
                    /> */}
                    <Autocomplete<Suggestion>
                      disablePortal
                      id="combo-box-demo"
                      options={suggestions} // Now an array of objects with id and name
                      getOptionLabel={(option) => option.name} // Use name for display
                      loading={isLoading}
                      loadingText="Loading..."
                      noOptionsText="No suggestions available"
                      value={selectedOption} // Ensure this is of type Suggestion | null
                      onChange={handleAutocompleteChange}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{ fontSize: "12px" }}
                          key={option.id}
                        >
                          {" "}
                          {/* Use unique key */}
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          className="span"
                          {...params}
                          label=""
                          onChange={handleInputChange2}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "33px",
                              padding: "4px 4px 4px 5px",
                              color: "gray",
                              "&:hover fieldset": {
                                borderColor: "#e0001b", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#e0001b", // Border color when focused
                              },
                            },
                            "& .MuiAutocomplete-input": {
                              fontSize: "12px !important", // Font size of the input text
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "12px", // Font size for input and placeholder text
                            },
                          }}
                          placeholder="Search First Approver"
                        />
                      )}
                    />

                    <div className="error">{firstApproverError}</div>
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <TextField
                      id="requestType"
                      name="requestType"
                      className="span"
                      value={reqFormData.requestType}
                      style={{ width: "100%" }}
                      onChange={handleChange("requestType")}
                      placeholder="Enter value here"
                      autoComplete="on"
                    />
                    <div className="error">{requestTypeError}</div>
                  </Grid> */}
                  <Grid item xs={11} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <FormControl
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                      }}
                    >
                      {/* <InputLabel
                        id="requestType-label"
                        sx={{
                          textAlign: 'left',
                          width: '100%',
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "#e0001b", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#e0001b", // Border color when focused
                            },
                          },
                          "& .MuiAutocomplete-input": {
                            padding: "0px 4px",
                            fontSize: "12px",
                            height: "0px !important",
                          },
                        }}
                      >
                        Request Type
                      </InputLabel> */}
                      <Select
                        labelId="requestType-label"
                        id="requestType"
                        name="requestType"
                        value={reqFormData.requestType || ""}
                        onChange={handleChangeRequestType}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span
                                style={{
                                  color: "#423E44 !important",
                                  fontSize: "12px",
                                  opacity: "0.7",
                                }}
                              >
                                Select Request Type
                              </span>
                            );
                          }
                          return selected;
                        }}
                        className="span"
                        autoComplete="on"
                        sx={{
                          fontSize: "12px",
                          "& .MuiInputBase-input": {
                            fontSize: "12px",
                            padding: "7px 14px",
                            color: "gray",
                            border: "1px solid #E3E4E5",
                            borderLeft: " 3px solid #d20f3d",
                            borderBottomLeftRadius: "5px",
                            borderTopLeftRadius: "5px",
                          },
                          "& .MuiAutocomplete-input": {
                            fontSize: "12px !important", // Font size of the input text
                          },
                          "& .MuiSelect-select": {
                            fontSize: "12px",
                          },
                          "& .MuiMenuItem-root": {
                            fontSize: "12px",
                          },
                        }}
                        style={{ height: "33px" }}
                      >
                        {/* <MenuItem value="" disabled>
                          Select Request Type
                        </MenuItem> */}
                        {RequestTypeData?.map((type: any) => (
                          <MenuItem
                            key={type._id}
                            value={type.requestType}
                            sx={{ fontSize: "12px", color: "#423e44" }}
                          >
                            {type.requestType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="error">{requestTypeError}</div>
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <TextField
                      id="entity"
                      name="entity"
                      className="span"
                      value={reqFormData.entity}
                      style={{ width: "100%" }}
                      onChange={handleChange("entity")}
                      placeholder="Enter value here"
                      autoComplete="on"
                    />
                    <div className="error">{entityError}</div>
                  </Grid> */}
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        labelId="entity-label"
                        id="entity"
                        name="entity"
                        value={reqFormData.entity}
                        // onChange={handleChange('entity')}
                        onChange={handleChangeEntity}
                        displayEmpty
                        autoComplete="on"
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span
                                style={{
                                  color: "#423E44 !important",
                                  fontSize: "12px",
                                  opacity: "0.7",
                                }}
                              >
                                Select Entity
                              </span>
                            );
                          }
                          return selected;
                        }}
                        sx={{
                          fontSize: "12px", // Font size for the selected value
                          "& .MuiInputBase-input": {
                            fontSize: "12px", // Font size for input and placeholder text
                            padding: "7px 14px",
                            color: "gray",
                            border: "1px solid #E3E4E5",
                            borderLeft: " 3px solid #d20f3d",
                            borderBottomLeftRadius: "5px",
                            borderTopLeftRadius: "5px",
                          },
                          "& .MuiSelect-select": {
                            fontSize: "12px",
                          },
                          "& .MuiAutocomplete-input": {
                            fontSize: "12px !important", // Font size of the input text
                          },
                          "& .MuiMenuItem-root": {
                            fontSize: "12px", // Font size for the dropdown menu items
                          },
                        }}
                        style={{ height: "33px" }}
                      >
                        {/* <MenuItem value="" disabled>
                          Select Entity
                        </MenuItem> */}
                        {EntityData?.map((entity: any) => (
                          <MenuItem key={entity._id} value={entity.entity}>
                            {entity.entity}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="error">{entityError}</div>
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <TextField
                      id="location"
                      name="location"
                      className="span"
                      value={reqFormData.location}
                      style={{ width: "100%" }}
                      onChange={handleChange("location")}
                      placeholder="Enter value here"
                      autoComplete="on"
                    />
                    <div className="error">{locationError}</div>
                  </Grid>
                </Grid> */}
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <FormControl sx={{ width: "100%" }}>
                      {/* <InputLabel id="location-label">Location</InputLabel> */}
                      <Select
                        labelId="location-label"
                        id="location"
                        name="location"
                        value={reqFormData.location}
                        // onChange={handleChange('location')}
                        onChange={handleChangeLocation}
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span
                                style={{
                                  color: "#423E44 !important",
                                  fontSize: "12px",
                                  opacity: "0.7",
                                }}
                              >
                                Select Location
                              </span>
                            );
                          }
                          return selected;
                        }}
                        displayEmpty
                        autoComplete="on"
                        sx={{
                          fontSize: "12px",
                          "& .MuiInputBase-input": {
                            fontSize: "12px", // Font size for input and placeholder text
                            padding: "7px 14px",
                            color: "gray",
                            border: "1px solid #E3E4E5",
                            borderLeft: " 3px solid #d20f3d",
                            borderBottomLeftRadius: "5px",
                            borderTopLeftRadius: "5px",
                          },
                          "& .MuiSelect-select": {
                            fontSize: "12px",
                            color: "gray",
                          },
                          "& .MuiMenuItem-root": {
                            fontSize: "12px", // Font size for the dropdown menu items
                          },
                        }}
                        style={{ height: "33px" }}
                      >
                        {/* <MenuItem value="" disabled>
                          Select Location
                        </MenuItem> */}
                        {LocationData?.map((location: any) => (
                          <MenuItem
                            key={location._id}
                            value={location.location}
                          >
                            {location.location}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="error">{locationError}</div>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    borderBottom: "1px solid #E3E4E5 ",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></Grid>
                {/* <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">First Approver</Typography>
                    <Autocomplete
                      id="firstApprover"
                      options={firstApproverOptions}
                      value={reqFormData.firstApprover}
                      onChange={(event, newValue) =>
                        handleChange("firstApprover", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="off"
                        />
                      )}
                    />
                    <div className="error">{firstApproverError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Request Type</Typography>
                    <Autocomplete
                      id="requestType"
                      options={requestTypeOptions}
                      value={reqFormData.requestType}
                      onChange={(event, newValue) =>
                        handleChange("requestType", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{requestTypeError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Entity</Typography>
                    <Autocomplete
                      id="entity"
                      options={entityOptions}
                      value={reqFormData.entity}
                      onChange={(event, newValue) =>
                        handleChange("entity", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{entityError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Location</Typography>
                    <Autocomplete
                      id="location"
                      options={locationOptions}
                      value={reqFormData.location}
                      onChange={(event, newValue) =>
                        handleChange("location", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter value here"
                          className="span"
                          style={{ width: "100%" }}
                          autoComplete="on"
                        />
                      )}
                    />
                    <div className="error">{locationError}</div>
                  </Grid>
                </Grid> */}
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request For
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    {/* <Typography className="R-Texts">User Profile</Typography> */}
                    <Typography className="R-Texts">Passport Photo</Typography>
                    <Grid>
                      {fileSelected && (
                        <img
                          src={fileSelected}
                          alt="Selected File Preview"
                          style={{
                            width: "100px",
                            height: "100px",
                            marginTop: "10px",
                            marginBottom: "10px",
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          className="upload-div"
                          onDragOver={handleDragOver}
                          onDragEnter={handleDragEnter}
                          onDrop={handleDrop}
                        >
                          {!selectedFileName ? (
                            <>
                              <span className="upload-btn">
                                <input
                                  ref={fileRef}
                                  hidden
                                  type="file"
                                  accept="image/*"
                                  onChange={handleUploadMedia}
                                  id="file-input"
                                />
                                <label
                                  htmlFor="file-input"
                                  style={{ cursor: "pointer" }}
                                >
                                  Upload a file
                                </label>
                              </span>
                              <span
                                className="drag-text"
                                onClick={() => fileRef.current?.click()}
                              >
                                Or drag and drop your file
                              </span>
                            </>
                          ) : (
                            <div style={{ color: "gray" }}>
                              Selected File:{" "}
                              {selectedFileName
                                ? selectedFileName.length > 15
                                  ? `${selectedFileName.substring(0, 15)}...`
                                  : selectedFileName
                                : "No file selected"}
                            </div>
                          )}
                          {selectedFileName && (
                            <span onClick={handleClearFile}>
                              <CancelIcon
                                fontSize="small"
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                      <div>
                        {employeeImageError && (
                          <div className="error">{employeeImageError}</div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  {/*<Grid item xs={12} md={3}>
                   <Typography className="R-Texts">
                      Request For Myself
                    </Typography>
                    <div>
                      {" "}
                      <FormGroup
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          width: "200px",
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Checkbox
                              checked={reqFormData.requestForMyself === true}
                              // onChange={() =>
                              //   setReqFormData((prev) => ({
                              //     ...prev,
                              //     requestForMyself: true,
                              //   }))
                              // }
                              onChange={handleCheckboxChange(
                                "requestForMyself"
                              )}
                              color="default"
                            />
                          }
                          label="yes"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="No"
                          control={
                            <Checkbox
                              checked={reqFormData.requestForMyself === false}
                              // onChange={() =>
                              //   setReqFormData((prev) => ({
                              //     ...prev,
                              //     requestForMyself: false,
                              //   }))
                              // }
                              onChange={handleCheckboxChange(
                                "requestForMyself"
                              )}
                              name="requestForMyself"
                              color="default"
                            />
                          }
                          label="No"
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </div>
                    <div className="error">{requestForMyselfError}</div> 
                  </Grid>*/}
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">User ID</Typography>
                    <TextField
                      id="userId"
                      name="userId"
                      className="span"
                      value={reqFormData.userId}
                      style={{ width: "100%" }}
                      // onChange={handleChange("userId")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z0-9\s-]*)?$/;
                        if (regex.test(value)) {
                          handleChange("userId")(e);
                        }
                      }}
                      placeholder="Enter the User ID"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{userIdError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {/* <Typography className="R-Texts">Salutation</Typography>
                    <TextField
                      id="salutation"
                      name="salutation"
                      className="span"
                      value={reqFormData.salutation}
                      style={{ width: "100%" }}
                      onChange={handleChange("salutation")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    />
                    <div className="error">{salutationError}</div> */}

                    <Typography className="R-Texts">Employee ID</Typography>
                    <TextField
                      id="employeeId"
                      name="employeeId"
                      className="span"
                      value={reqFormData.employeeId}
                      style={{ width: "100%" }}
                      // onChange={handleChange("employeeId")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z0-9\s-]*)?$/;
                        if (regex.test(value)) {
                          handleChange("employeeId")(e);
                        }
                      }}
                      placeholder="Enter the Employee ID"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{employeeIdError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Name</Typography>
                    {/* <TextField
                      id="firstName"
                      name="firstName"
                      className="span"
                      value={reqFormData.firstName}
                      style={{ width: "100%" }}
                      onChange={handleChange("firstName")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    /> */}
                    <div className="input-group">
                      <select
                        name="salutation"
                        value={reqFormData.salutation}
                        onChange={handleChange("salutation")}
                        defaultValue="Mr."
                      >
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Miss">Miss</option>
                      </select>

                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={reqFormData.name}
                        style={{ width: "100%" }}
                        // onChange={handleChange("name")}
                        onChange={(e: any) => {
                          const { value } = e.target;
                          const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                          if (regex.test(value)) {
                            handleChange("name")(e);
                          }
                        }}
                        placeholder="Enter the full name"
                        autoComplete="off"
                        className="input-placeholder"
                      />
                    </div>
                    {/* {firstNameError && (
                      <div className="error">{firstNameError}</div>
                    )} */}
                    {nameError && <div className="error">{nameError}</div>}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Date Of Birth</Typography>
                    <div style={{ width: "200px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          className="span"
                          label=""
                          value={
                            reqFormData.dateOfBirth
                              ? dayjs(reqFormData.dateOfBirth)
                              : null
                          }
                          onChange={handleDateChange}
                          maxDate={dayjs()}
                          slotProps={{
                            textField: {
                              fullWidth: false,
                              autoComplete: "off",
                              inputProps: {
                                name: "dateOfBirth",
                                style: { height: "8px", fontSize: "12px" },
                              },
                              sx: {
                                width: "275px",
                                "& input::placeholder": {
                                  // Placeholder styling
                                  fontSize: "12px",
                                  color: "#a0a096",
                                },
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    {/* <TextField
                      id="dateOfBirth"
                      name="dateOfBirth"
                      className="span"
                      value={formData.dateOfBirth}
                      style={{ width: "100%" }}
                      onChange={handleChange("dateOfBirth")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    /> */}
                    <div className="error">{dateOfBirthError}</div>
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Last Name</Typography>
                    <TextField
                      id="lastName"
                      name="lastName"
                      className="span"
                      value={reqFormData.lastName}
                      style={{ width: "100%" }}
                      onChange={handleChange("lastName")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    />
                    <div className="error">{lastNameError}</div>
                  </Grid> */}
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Designation</Typography>
                    <TextField
                      id="designation"
                      name="designation"
                      className="span"
                      value={reqFormData.designation}
                      style={{ width: "100%" }}
                      // onChange={handleChange("designation")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("designation")(e);
                        }
                      }}
                      placeholder="Enter the job designation"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{designationError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Department</Typography>
                    <TextField
                      id="department"
                      name="department"
                      className="span"
                      value={reqFormData.department}
                      style={{ width: "100%" }}
                      // onChange={handleChange("department")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("department")(e);
                        }
                      }}
                      placeholder="Enter the department name"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{departmentError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Office Direct No
                    </Typography>

                    <TextField
                      id="officeDirectNo"
                      name="officeDirectNo"
                      type="text"
                      className="span"
                      value={reqFormData.officeDirectNo}
                      style={{ width: "100%" }}
                      // onChange={handleChange("officeDirectNo")}
                      onChange={handleOfficeNumberChange}
                      placeholder="Enter the office direct number"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />

                    {/* <PhoneInput
                      country={"in"} 
                      value={reqFormData.officeDirectNo}
                      onChange={handleOfficeNumberChange}
                      placeholder="Enter mobile number"
                      inputStyle={{
                        width: "100%",
                        padding: "10px",
                        fontSize: "12px",
                        paddingLeft: "45px",
                      }}
                      inputProps={{
                        name: "mobileNo",
                        required: true,
                        autoFocus: true,
                      }}
                      isValid={isValidPhoneNumber} 
                    /> */}
                    <div className="error">{officeDirectNoError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">Mobile No</Typography>
                    {/* <PhoneInput
                      country={"in"}
                      value={reqFormData.mobileNo}
                      onChange={handlePhoneChange}
                      placeholder="Enter mobile number"
                      inputStyle={{
                        width: "100%",
                        padding: "10px",
                        fontSize: "12px",
                        paddingLeft: "45px",
                      }}
                      inputProps={{
                        name: "mobileNo",
                        required: true,
                        autoFocus: true,
                      }}
                      isValid={isValidPhoneNumber} // Use the defined function
                    /> */}
                    <TextField
                      id="mobileNo"
                      name="mobileNo"
                      className="span"
                      type="text"
                      value={reqFormData.mobileNo}
                      style={{ width: "100%" }}
                      // onChange={handleChange("mobileNo")}
                      onChange={handlePhoneChange}
                      placeholder="Enter the mobile number"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{mobileNoError}</div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Reporting Manager
                    </Typography>
                    <TextField
                      id="reportingManager"
                      name="reportingManager"
                      className="span"
                      value={reqFormData.reportingManager}
                      style={{ width: "100%" }}
                      // onChange={handleChange("reportingManager")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("reportingManager")(e);
                        }
                      }}
                      placeholder="Enter the reporting manager's name"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                      // sx={{ '& ::placeholder': { fontSize: '14px !important', } }}
                    />
                    <div className="error">{reportingManagerError}</div>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Typography className="R-Texts">Request Details</Typography>
                    <TextField
                      id="requestorDetail"
                      style={{
                        // marginBottom: "10px",
                        width: "100%",
                        color: "gray !important",
                      }}
                      placeholder="Enter detailed information about the request"
                      // multiline
                      name="requestorDetail"
                      // rows={1}
                      value={truncateDescription(
                        reqFormData.requestorDetail || "",
                        10
                      )}
                      // value={reqFormData.requestorDetail}
                      // onChange={handleChange("requestorDetail")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("requestorDetail")(e);
                        }
                      }}
                      InputProps={{
                        style: {
                          fontSize: "12px", // Font size for typing text
                          //color: "gray",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "12px", // Font size for the placeholder
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                          cursor: "pointer",
                        },
                      }}
                      autoComplete="off"
                    />
                    <div className="error">{requestDetailError}</div>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={5.7}>
                    <Typography className="R-Texts">
                      Request Details
                    </Typography>
                    <TextField
                      id="requestorDetail"
                      style={{
                        // marginBottom: "10px",
                        width: "100%",
                        color: "gray !important",
                      }}
                      placeholder="Enter value here"
                      multiline
                      name="requestorDetail"
                      rows={2}
                      value={reqFormData.requestorDetail}
                      onChange={handleChange("requestorDetail")}
                      InputProps={{
                        style: {
                          fontSize: "12px", // Font size for typing text
                          color: "#a0a096", // Color for the typing text
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "12px", // Font size for the placeholder
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                          cursor: "pointer",
                        },
                      }}
                    />
                    <div className="error">{requestDetailError}</div>
                  </Grid>
                </Grid> */}
                <Grid
                  xs={12}
                  style={{
                    borderBottom: "1px solid #E3E4E5 ",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></Grid>
                <Grid>
                  <div
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                      color: "#423e44",
                    }}
                  >
                    Request Subject
                  </div>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={6}>
                    <Typography className="R-Texts">Subject</Typography>
                    <TextField
                      style={{
                        // marginBottom: "10px",
                        width: "100%",
                        color: "gray !important",
                      }}
                      id="subject"
                      placeholder="Enter the subject"
                      multiline
                      rows={2}
                      name="subject"
                      value={reqFormData.subject}
                      // onChange={handleChange("subject")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("subject")(e);
                        }
                      }}
                      InputProps={{
                        style: {
                          fontSize: "12px", // Font size for typing text
                          //color: "#a0a096", // Color for the typing text
                        },
                        sx: { padding: "1px 1px" },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "12px", // Font size for the placeholder
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{subjectError}</div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="R-Texts">Requested By</Typography>
                    <TextField
                      style={{
                        // marginBottom: "10px",
                        width: "100%",
                        color: "#a0a096 !important",
                      }}
                      id="requestedBy"
                      placeholder="Enter the name as the requestor"
                      multiline
                      rows={2}
                      name="requestedBy"
                      value={reqFormData.requestedBy}
                      // onChange={handleChange("requestedBy")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("requestedBy")(e);
                        }
                      }}
                      InputProps={{
                        style: {
                          fontSize: "12px", // Font size for typing text
                         // color: "#a0a096", // Color for the typing text
                        },
                        sx: { padding: "1px 1px" },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "12px", // Font size for the placeholder
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{requestedByError}</div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Access permission similar to
                    </Typography>
                    <TextField
                      id="accessPermissionSimilarTo"
                      name="accessPermissionSimilarTo"
                      className="span"
                      value={reqFormData.accessPermissionSimilarTo}
                      style={{ width: "100%" }}
                      // onChange={handleChange("accessPermissionSimilarTo")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("accessPermissionSimilarTo")(e);
                        }
                      }}
                      placeholder="Enter the access permission "
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{accessPermissionError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      ERP Company data
                    </Typography>
                    <TextField
                      id="erpCompanyData"
                      name="erpCompanyData"
                      className="span"
                      value={reqFormData.erpCompanyData}
                      style={{ width: "100%" }}
                      // onChange={handleChange("erpCompanyData")}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        const regex = /^([a-zA-Z][a-zA-Z\s.]*)?$/;
                        if (regex.test(value)) {
                          handleChange("erpCompanyData")(e);
                        }
                      }}
                      placeholder="Enter the ERP company data required"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{erpCompanyDataError}</div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography className="R-Texts">
                      Preferred Email Address
                    </Typography>
                    <TextField
                      id="preferredEmailAddress"
                      name="preferredEmailAddress"
                      className="span"
                      value={reqFormData.preferredEmailAddress}
                      style={{ width: "100%" }}
                      onChange={handleChange("preferredEmailAddress")}
                      placeholder="Enter the preferred email address"
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "12px", // Font size for the placeholder text
                          color: "#a0a096", // Placeholder color (if needed)
                        },
                      }}
                    />
                    <div className="error">{emailError}</div>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  style={{
                    borderBottom: "1px solid #E3E4E5 ",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></Grid>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "#ffe9e9",
                      padding: "10px",
                      margin: "5px 15px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#e0001b",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Note
                    </Typography>
                    <Grid>
                      <Typography
                        style={{ color: "#423E44", fontSize: "12px" }}
                      >
                        Mention all requirement in detail - like to whom email
                        will be forwarded in case of user deactivation, what all
                        access to be added or removed, if data need to be backed
                        up then who needs that data. If user needs ERP access
                        mention company ids etc. Preferred email id is just for
                        a suggestion to IT, however its subject to
                        consideration, else IT creates as per policy (First name
                        and first character of last name).
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>

        <AlertYesNo
          isAlertOpen={openAlert}
          handleAlertYes={handleAlertYes}
          handleAlertClose={handleAlertCancel}
        >
          {alertMessage}
        </AlertYesNo>
        {/* <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "var(--redprimary)",
              // backgroundColor: "#E0001B0D",
              fontWeight: "600",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              // marginBottom: "73px",
              // marginRight: "45px",
              margin: "auto",
              textAlign: "center",
              fontSize: "14px !important",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar> */}
      </div>
    </div>
  );
};

export default NewRequestForm;
