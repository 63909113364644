import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  Slide,
  DialogActions,
  DialogTitle,
  DialogContent,
  Snackbar,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { createRef, useEffect, useRef, useState } from "react";
import fileIcon from "../../Assets/Images/filePdf.svg";
import "./ItRequestStyles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import defaultImage from "../../Assets/Images/default-image.jpg";
import { TransitionProps } from "@mui/material/transitions";
import "./ItRequestStyles.css";
import {
  useGetItRequestsDataQuery,
  useGetItRequestsByIdQuery,
  useUpdateItRequestsMutation,
  useDeleteItRequestsMutation,
  useCreateItRequestsMutation,
  useGetItRequestByRequestedIdQuery,
} from "../../Services/APIs";
import { CSVLink } from "react-csv";
import ApproveRejectFooterButton from "./ApproveRejectFooterButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
//imports for approve/reject button
// import {
//   Box,
//   Button,
//   Popover,
//   TextField,
//   Typography,
//   Grid,
// } from "@mui/material";
// import React, { useState } from "react";
import { Popover } from "@mui/material";
import "./ItRequestStyles.css";
import { Close } from "@mui/icons-material";
import approveIcon from "../../Assets/it-request/approve-info.png";
import rejectIcon from "../../Assets/it-request/reject-info.png";
import closeIcon from "../../Assets/it-request/closed.png";
import commentsIcon from "../../Assets/it-request/comments-icon.png";
import { useParams } from "react-router-dom";

// import { useLocation } from 'react-router-dom';
import {
  useApproveOrRejectWorkflowRunMutation,
  useGetWorkflowRunByRequestIdQuery,
} from "../../Services/workFlowAPIs";
import OtherDetails from "./OtherDetails";
import LoaderComponent from "../../hooks/LoaderComponent";
import IconText from "../IconText/IconText";
import { PDFExport } from "@progress/kendo-react-pdf";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import WorkflowHistory from "./WorkflowHistory";
import PdfExport from "./PdfExport";
import { createEmail } from "../../Constants/Email";
import useLoggedInUser from "../Hooks/useLoggedInUser";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}
const ITRequestApproveRejectForm = (props: any) => {
  const { Download, isPdfExport } = props;
  const location = useLocation();
  const { formData, runId, nextApproverId, nextApproverName } =
    location.state || {};
  const navigate = useNavigate();
  const { requestedId } = useParams<{ requestedId: string }>();
  const [approveOrRejectWorkflowRun] = useApproveOrRejectWorkflowRunMutation();
  const {
    data: getITRequestFormDetailsUsingRequestId,
    error: getFormDatausingRequestIdError,
    isLoading: getFormDatausingRequestIdisLoading,
    refetch,
  } = useGetItRequestByRequestedIdQuery(requestedId || "", {
    skip: !requestedId,
  });
  console.log(getITRequestFormDetailsUsingRequestId, "upload");
  const { data: workflowHistoryData, refetch: refetchWorkflowHistoryData } =
    useGetWorkflowRunByRequestIdQuery(requestedId || "", {
      skip: !requestedId,
    });
  const { employeeData } = useLoggedInUser();

  useEffect(() => {
    console.log(requestedId, "request id of the form:");
    console.log("refetch function:", refetch);
    if (requestedId) {
      refetch();
      refetchWorkflowHistoryData();
    }
  }, [requestedId, refetch]);

  console.log(
    getITRequestFormDetailsUsingRequestId,
    "getITRequestFormDetailsUsingRequestId:"
  );
  const pdfExportComponent = createRef<PDFExport>();
  const [isProcessing, setIsProcessing] = useState(false);
  const [selected1, setSelected1] = useState("");
  const [open1, setOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >("success");
  const [isEditable, setIsEditable] = useState(false);
  console.log(location.state, "use location data's from form submission:");
  const [approverComments, setApproverComments] = useState("");
  const [updatedApprovalStatus, setUpdatedApprovalStatus] = useState("");
  const [rejectComments, setRejectComments] = useState("");
  const [updatedOverallStatus, setUpdatedOverallStatus] = useState("");
  const [pdfexport, setPdfexport] = useState<any>(false);
  const [approveReject, setApproveReject] = useState<any>(false);
  const [downloadcheck, setdownloadcheck] = useState<any>("");
  const [showAlertForRejectionComments, setShowAlertForRejectionComments] = useState(false);
  // useEffect(() => {
  //   if (!location.state || !location.state.formData) {
  //     console.error("No form data found, redirecting to form page.");
  //     // navigate("/newRequestForm");
  //   }
  // }, [location.state, navigate]);

  // if (!location.state || !location.state.formData) {
  //   return null;
  // }

  // // const { formData } = location.state;
  // console.log(location.state.formData, "form datttttt");

  useEffect(() => {
    if (
      getITRequestFormDetailsUsingRequestId &&
      getITRequestFormDetailsUsingRequestId.itRequest
    ) {
      // setUpdatedApprovalStatus(
      //   getITRequestFormDetailsUsingRequestId.itRequest.approvalStatus || ""
      // ); // Provide a fallback
      setUpdatedOverallStatus(
        getITRequestFormDetailsUsingRequestId.itRequest.overallStatus || ""
      ); // Provide a fallback
    }
  }, [getITRequestFormDetailsUsingRequestId]);

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };
  useEffect(() => {
    // pdfDownload1 = Download
    setdownloadcheck("Download");
  }, [Download]);
  const hideAlertHandler = () => {
    // setPdfexport(true);
    setApproveReject(true);
    setdownloadcheck("check");
  };
  useEffect(() => {
    if (pdfexport) {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
      setTimeout(() => {
        setPdfexport(false);
      }, 1000);
    }
  }, [pdfexport]);
  // const hideAlertHandler = () => {
  //   setPdfexport(true)
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  //   setTimeout(() => {
  //     setPdfexport(false)
  //   }, 200);
  // };
  // const hideAlertHandler = async () => {
  //   setPdfexport(true); // Set pdfexport to true

  //   if (pdfExportComponent.current) {
  //     await new Promise<void>((resolve) => {
  //       if (pdfExportComponent.current) {
  //       pdfExportComponent.current.save(() => {
  //         resolve(); // Call resolve when save is done
  //       });
  //     }
  //     });
  //   }

  //   setPdfexport(false); // Set pdfexport back to false
  // };

  const handleApproveRejectButtonClose = () => {
    setOpen2(false);
    setAnchorEl(null);
  };
  const handleApprove = async () => {
    console.log("Attempting to approve");
    if (isProcessing) {
      console.log("Already processing");
      return; // Prevent multiple clicks
    }
    setIsProcessing(true);
    showLoader();

    try {
      const response = await approveOrRejectWorkflowRun({
        runId: getITRequestFormDetailsUsingRequestId.runId,
        approverId: getITRequestFormDetailsUsingRequestId.approverId,
        approverName: getITRequestFormDetailsUsingRequestId.firstApprover,
        action: "Approved",
        comments: approverComments || "N/A",
        level: 1,
      }).unwrap();

      if (
        !response?.error &&
        getITRequestFormDetailsUsingRequestId?.secondApproverId &&
        getITRequestFormDetailsUsingRequestId?.nextApproverEmail &&
        getITRequestFormDetailsUsingRequestId?.secondApprover &&
        getITRequestFormDetailsUsingRequestId?.requestedId &&
        getITRequestFormDetailsUsingRequestId?.requestedBy &&
        getITRequestFormDetailsUsingRequestId?.requestorDetail &&
        getITRequestFormDetailsUsingRequestId?.requestorEmail
      ) {
        let requestorEmail =
          getITRequestFormDetailsUsingRequestId?.requestorEmail;
        let currentApproverEmailID =
          getITRequestFormDetailsUsingRequestId?.nextApproverEmail;
        let thirdApproverEmailID =
          getITRequestFormDetailsUsingRequestId?.thirdApproverAdminId;
        let mergedCCEmailID = currentApproverEmailID?.concat([requestorEmail]);
        let nextApproverEmailID =
          getITRequestFormDetailsUsingRequestId?.secondApproverId;
        let approverName =
          getITRequestFormDetailsUsingRequestId?.secondApprover;
        const emailData = createEmail(
          nextApproverEmailID, // to :- next approver
          mergedCCEmailID, // cc :- requested user, current approver if any , 3rd IT Admin
          approverName, // approverName
          getITRequestFormDetailsUsingRequestId?.requestedId, // requestId
          getITRequestFormDetailsUsingRequestId?.requestedBy, // requestorName
          getITRequestFormDetailsUsingRequestId?.requestorDetail // requestDetails
        );
        // If successful, run the other function here
        // sendEmailHandler(emailData)
        console.log(emailData, "checkEmailData");
      } else {
        console.log("error in sending email");
      }
      console.log("Approve response:", response, response.comments);
      // Update state with new approval status
      const updatedApprovalStatus = response.itRequest.approvalStatus;
      const updatedOverallStatus = response.itRequest.overallStatus;

      setSnackbarMessage("Request approved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      // setUpdatedApprovalStatus(updatedApprovalStatus); // Store the new approval status
      setUpdatedOverallStatus(updatedOverallStatus); // Store the new overall status
      // Handle additional logic after approval, e.g., navigating to another page or updating UI
      setTimeout(() => {
        navigate("/itRequests");
      }, 1000);
    } catch (error) {
      console.error("Error approving request:", error);
      setSnackbarMessage("Failed to approve the request");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);
      setSelected1("");
      setActionCompleted(true);
      handleApproveRejectButtonClose();
      hideLoader();
    }
  };

  const handleRejectFunction = async () => {
    console.log("Attempting to reject");
    if (isProcessing) {
      console.log("Already processing");
      return; // Prevent multiple clicks
    }
    setIsProcessing(true);
    showLoader();

    try {
      const response = await approveOrRejectWorkflowRun({
        runId: getITRequestFormDetailsUsingRequestId.runId,
        approverId: getITRequestFormDetailsUsingRequestId.approverId,
        approverName: getITRequestFormDetailsUsingRequestId.firstApprover,
        action: "Rejected",
        comments: rejectComments,
        level: 1,
      }).unwrap();

      if (
        !response?.error &&
        getITRequestFormDetailsUsingRequestId?.secondApproverId &&
        getITRequestFormDetailsUsingRequestId?.nextApproverEmail &&
        getITRequestFormDetailsUsingRequestId?.secondApprover &&
        getITRequestFormDetailsUsingRequestId?.requestedId &&
        getITRequestFormDetailsUsingRequestId?.requestedBy &&
        getITRequestFormDetailsUsingRequestId?.requestorDetail &&
        getITRequestFormDetailsUsingRequestId?.requestorEmail
      ) {
        let requestorEmail =
          getITRequestFormDetailsUsingRequestId?.requestorEmail;
        let currentApproverEmailID =
          getITRequestFormDetailsUsingRequestId?.nextApproverEmail;
        let thirdApproverEmailID =
          getITRequestFormDetailsUsingRequestId?.thirdApproverAdminId;
        let mergedCCEmailID = currentApproverEmailID
          ?.concat(thirdApproverEmailID)
          ?.concat([requestorEmail]);
        let nextApproverEmailID =
          getITRequestFormDetailsUsingRequestId?.secondApproverId;
        let approverName =
          getITRequestFormDetailsUsingRequestId?.secondApprover;
        const emailData = createEmail(
          nextApproverEmailID, // to :- next approver
          mergedCCEmailID, // cc :- requested user, current approver if any , 3rd IT Admin
          approverName, // approverName
          getITRequestFormDetailsUsingRequestId?.requestedId, // requestId
          getITRequestFormDetailsUsingRequestId?.requestedBy, // requestorName
          getITRequestFormDetailsUsingRequestId?.requestorDetail // requestDetails
        );
        // If successful, run the other function here
        // sendEmailHandler(emailData)
        console.log(emailData, "checkEmailData");
      } else {
        console.log("error in sending email");
      }

      console.log("Reject response:", response);
      // Update state with new approval status
      const updatedApprovalStatus = response.itRequest.approvalStatus;
      const updatedOverallStatus = response.itRequest.overallStatus;

      setSnackbarMessage("Request rejected successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      // setUpdatedApprovalStatus(updatedApprovalStatus); // Store the new approval status
      setUpdatedOverallStatus(updatedOverallStatus); // Store the new overall status
      // Handle additional logic after rejection, e.g., navigating to another page or updating UI
      setTimeout(() => {
        navigate("/itRequests");
      }, 1000);
    } catch (error) {
      console.error("Error rejecting request:", error);
      setSnackbarMessage("Failed to reject the request");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsProcessing(false);
      setSelected1("");
      // setOpen1(false);
      setOpenPopUp(true);
      setActionCompleted(true);
      handleApproveRejectButtonClose();
      hideLoader();
    }
  };


  const handleReject = async () => {
    if (rejectComments == "" || rejectComments == undefined) {
      setShowAlertForRejectionComments(true);
    } else {
      setShowAlertForRejectionComments(false);
      handleRejectFunction();
    }
  }

  const handleSubmitClick = () => {
    // Submit form logic
    console.log("Form submitted:", formData);
  };
  // const formattedFormData = [formData];
  const formattedFormData = formData
    ? Object.entries(formData).map(([key, value]) => [key, value])
    : [];

  const [formViewData, setFormViewData] = useState(
    location.state?.formData || {}
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormViewData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add a title to the PDF
    doc.text("Form Data", 20, 10);

    doc.autoTable({
      head: [["Field", "Value"]],
      body: formattedFormData.map((row) =>
        Object.entries(row).map(([key, value]) => [
          key.replace(/([A-Z])/g, " $1").trim(), // Add spaces between camelCase words
          value,
        ])
      ),
      styles: {
        cellPadding: 2, // Adjust cell padding to fit content better
        fontSize: 10, // Adjust font size to fit content
        minCellHeight: 10,
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Adjust width of the first column (Field names)
        1: { cellWidth: 150 }, // Adjust width of the second column (Values)
      },
    });

    // Save the PDF
    doc.save("form-data.pdf");
  };

  //states and other details for approve/reject button
  const [selected, setSelected] = useState<string>("approve");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selected2, setSelected2] = useState<string>("Yes Reject");
  const [selected3, setSelected3] = useState<string>("Yes Approve");
  const [showResults, setShowResults] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);

  const onClickResults = (e: any) => {
    setShowResults(e.target.checked);
  };
  const handleToggleAndClick = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected(btn);
    setAnchorEl(event.currentTarget);
  };
  const handleToggleApprove = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected3(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopUp(false);
    setShowAlertForRejectionComments(false);
  };

  const handleToggleReject = (
    btn: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelected2(btn);
    setAnchorEl(null);
    // setAnchorEl(event.currentTarget);
  };
  const [activeTab, setActiveTab] = useState("requestor");
  const [selectedTab, setSelectedTab] = useState<string>("requestor");

  const handleTabClick = (
    tab: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setActiveTab(tab);
    setSelectedTab(tab);
    // setAnchorEl(event.currentTarget); // Uncomment if needed
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const Approve = "first";

  let currentApproverEmail = employeeData?.employeeEmail;
  let currentApproverList =
    getITRequestFormDetailsUsingRequestId?.nextApproverEmail;
  let overallStatus = getITRequestFormDetailsUsingRequestId?.overallStatus;
  let eligibleForAction =
    overallStatus === "Pending" &&
    currentApproverList?.includes(currentApproverEmail);
  console.log(eligibleForAction, "checkeligibleForAction");
  function truncateDescription(description: string, wordLimit: number) {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  }
  return (
    <>
      {approveReject && downloadcheck == "check" ? (
        <PdfExport
          Approve={Approve}
          approveReject={approveReject}
          setApproveReject={setApproveReject}
          requestedId={requestedId}
          activeTab={activeTab}
          getITRequestFormDetailsUsingRequestId={
            getITRequestFormDetailsUsingRequestId
          }
          handleChange={handleChange}
          isEditable={isEditable}
          workflowHistoryData={workflowHistoryData}
        />
      ) : (
        <div>
          <IconText />
          <div style={{ backgroundColor: "#f1f1f1", marginTop: "0px" }}>
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Grid
                style={{
                  // height: "40px",
                  backgroundColor: "#D20F3D",
                  position: "sticky",
                  top: 0,
                  zIndex: 1000,
                  // display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                <div>
                  <div className="TableBreadcrumbs">
                    <Link to="/itRequests" className="TableBreadcrumbs1">
                      Dashboard
                    </Link>
                    <span className="breadcrumsIconNew1">
                      <NavigateNextIcon />
                    </span>
                    <span className="Breadcrumbs2">Form</span>
                    <span className="breadcrumsIconNew1">
                      <NavigateNextIcon />
                    </span>
                    <span className="Breadcrumbs2">{requestedId}</span>
                  </div>
                  <div className="tab-contain">
                    <div>
                      <Button
                        onClick={(event) => handleTabClick("requestor", event)}
                        className={
                          selectedTab === "requestor"
                            ? "selected"
                            : "notSelected"
                        }
                      >
                        Request Details
                      </Button>
                      <Button
                        onClick={(event) => handleTabClick("work", event)}
                        className={
                          selectedTab === "work" ? "selected" : "notSelected"
                        }
                      >
                        Work Flow History
                      </Button>
                      {/* <Button
                  onClick={(event) => handleTabClick("other", event)}
                  className={selectedTab === "other" ? "selected" : "notSelected"}
                >
                  Other Details
                </Button> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {/* {!isPdfExport && (
                        <Tooltip title="Download as Pdf">
                          <IconButton
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "4px !important",
                              padding: "5px 10px !important",
                              fontSize: "12px !important",
                              lineHeight: "1.75 !important",
                              "&:hover": {
                                backgroundColor: "white !important",
                              },
                            }}
                            onClick={hideAlertHandler}
                          >
                            <PrintOutlinedIcon
                              style={{ width: "16px ", height: "16px" }}
                            />
                            <span
                              style={{ fontSize: "12px", marginLeft: "5px" }}
                            >
                              Print
                            </span>
                          </IconButton>
                        </Tooltip>
                          )} */}
                        <Typography
                          className="itformStatus"
                          sx={{
                            backgroundColor:
                              updatedApprovalStatus === "Completed"
                                ? "green"
                                : "inherit",
                            color:
                              updatedApprovalStatus === "Completed"
                                ? "white"
                                : "inherit",
                            padding: "4px",
                            borderRadius: "4px",
                          }}
                        >
                          Status :{" "}
                          <span className="approvalStatus">
                            {/* {updatedApprovalStatus} */}
                            {
                              getITRequestFormDetailsUsingRequestId?.approvalStatus
                            }
                          </span>
                        </Typography>
                      </div>
                      <div>
                        {!actionCompleted && eligibleForAction && (
                          <>
                            <Button
                              sx={{ marginRight: "10px" }}
                              aria-describedby={
                                selected === "reject" ? id : undefined
                              }
                              // className={
                              //   selected === "reject" ? "selected" : "notSelected"
                              // }
                              className="cancel-req-btn"
                              onClick={(event) =>
                                handleToggleAndClick("reject", event)
                              }
                            >
                              Reject
                            </Button>
                            <Popover
                              id={id}
                              open={open && selected === "reject"}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              sx={{
                                padding: "10px",
                                ".MuiPopover-paper": {
                                  borderRadius: "20px",
                                },
                              }}
                            >
                              <Grid style={{ margin: "10px", width: "350px" }}>
                                <div style={{ width: "100%", height: "10px" }}>
                                  <img
                                    src={closeIcon}
                                    alt="sdfsf"
                                    style={{
                                      float: "right",
                                      width: "15px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClose}
                                  />
                                </div>
                                <Grid
                                  style={{
                                    width: "100%",
                                    height: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={rejectIcon}
                                    alt="rejfrt"
                                    style={{ width: "50px" }}
                                  />
                                </Grid>
                                <Typography
                                  sx={{
                                    p: 2,
                                    color: "#3e3e3e",
                                    textAlign: "center",
                                  }}
                                >
                                  Are you sure want to reject this form ?
                                </Typography>
                                <Box style={{ width: "100%" }}>
                                  <TextField
                                    style={{
                                      margin: "10px 17px",
                                      width: "90%",
                                      color: "gray !important",
                                      backgroundColor: "#F4F4F4",
                                    }}
                                    id="Comments"
                                    placeholder="Write Comments here"
                                    multiline
                                    rows={4}
                                    name="Comments"
                                    value={rejectComments}
                                    onChange={(e) => {
                                      console.log("Comments:", e.target.value);
                                      setRejectComments(e.target.value);
                                    }}
                                  />
                                </Box>
                                <Grid sx={{ m: 2, width: "100%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      onClick={(event) =>
                                        handleToggleReject("No", event)
                                      }
                                      className={
                                        selected2 === "No"
                                          ? "selected"
                                          : "notSelected"
                                      }
                                    >
                                      No
                                    </Button>
                                    <Button
                                      onClick={handleReject}
                                      disabled={isProcessing}
                                      className={
                                        selected2 === "Yes Reject"
                                          ? "selected"
                                          : "notSelected"
                                      }
                                      style={{ marginLeft: "30px" }}
                                    >
                                      Yes, Reject
                                    </Button>
                                  </div>
                                </Grid>
                                {showAlertForRejectionComments && <Alert severity="error">Please provide comments to reject the form.</Alert>}
                              </Grid>
                            </Popover>
                            {eligibleForAction && (
                              <Button
                                aria-describedby={
                                  selected === "approve" ? id : undefined
                                }
                                // className={
                                //   selected === "approve" ? "selected" : "notSelected"
                                // }
                                className="submit-req-btn"
                                onClick={(event) =>
                                  handleToggleAndClick("approve", event)
                                }
                              >
                                Approve
                              </Button>
                            )}
                            <Popover
                              id={id}
                              open={open && selected === "approve"}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              sx={{
                                padding: "10px",
                                ".MuiPopover-paper": {
                                  borderRadius: "20px",
                                },
                              }}
                            >
                              <Grid style={{ margin: "10px", width: "350px" }}>
                                <div style={{ width: "100%", height: "10px" }}>
                                  <img
                                    src={closeIcon}
                                    alt="sdfsf"
                                    style={{
                                      float: "right",
                                      width: "15px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleClose}
                                  />
                                </div>
                                <Grid
                                  style={{
                                    width: "100%",
                                    height: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={approveIcon}
                                    alt="rejfrt"
                                    style={{ width: "50px" }}
                                  />
                                </Grid>
                                <Typography
                                  sx={{
                                    p: 2,
                                    color: "#3e3e3e",
                                    textAlign: "left",
                                    marginLeft: "14px !important",
                                  }}
                                >
                                  Are you sure want to approve this form ?
                                </Typography>
                                <Box style={{ width: "100%" }}>
                                  <div
                                    style={{
                                      marginLeft: "25px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "90px",
                                    }}
                                  // onClick={onClickResults}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={showResults}
                                          color="default"
                                          style={{ width: "50px" }}
                                          onChange={onClickResults}
                                          disabled={!!approverComments}
                                        />
                                      }
                                      label={
                                        <span
                                          style={{
                                            fontSize: "16px",
                                            color: "#ababab",
                                          }}
                                        >
                                          Comments
                                        </span>
                                      }
                                      sx={{
                                        marginLeft: "1px",
                                        marginRight: "1px",
                                      }}
                                    />
                                    {/* <img src={commentsIcon} alt="cdfd" />{" "}
                                <span style={{ color: "#ababab" }}>
                                  Comments
                                </span> */}
                                  </div>
                                  {showResults && (
                                    <TextField
                                      style={{
                                        margin: "10px 15px 10px 25px",
                                        width: "90%",
                                        color: "gray !important",
                                        backgroundColor: "#F4F4F4",
                                      }}
                                      id="Comments"
                                      placeholder="Write Comments here"
                                      multiline
                                      rows={4}
                                      name="Comments"
                                      value={approverComments}
                                      onChange={(e) => {
                                        console.log(
                                          "Comments:",
                                          e.target.value
                                        );
                                        setApproverComments(e.target.value);
                                      }}
                                    />
                                  )}
                                </Box>
                                <Grid sx={{ width: "100%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      margin: "auto",
                                    }}
                                  >
                                    {/* <Button
                                      onClick={(event) =>
                                        handleToggleApprove("No", event)
                                      }
                                      className={
                                        selected3 === "No"
                                          ? "selected"
                                          : "notSelected"
                                      }
                                    >
                                      No
                                    </Button> */}
                                    <Button
                                      onClick={handleApprove}
                                      disabled={isProcessing}
                                      className={
                                        selected3 === "Yes Approve"
                                          ? "selected"
                                          : "notSelected"
                                      }
                                    // style={{ marginLeft: "30px" }}
                                    >
                                      Yes, Approve
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Popover>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {isLoadingg && <LoaderComponent />}
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={4000}
                  onClose={() => setSnackbarOpen(false)}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </Grid>

              {getFormDatausingRequestIdisLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {/* <PDFExport
                paperSize="A4"
                scale={0.4}
                keepTogether="p"
                forcePageBreak=".page-break"
                ref={pdfExportComponent}
                fileName={`${requestedId}.pdf`}
              > */}
                  {activeTab === "requestor" && (
                    <>
                      <div
                        style={{
                          // marginBottom: "50px",
                          marginTop: "20px",
                          // height: "80vh",
                          // overflowY: "scroll",
                        }}
                      >
                        <Box
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            margin: "40px",
                            // width: "90%",
                            padding: "20px 20px",
                            // overflowY: "scroll",
                            // height:"50vh"
                            // overflowY: pdfexport ? "auto" : "scroll",
                            // height: pdfexport ? "auto" : "50vh",
                          }}
                        >
                          <div>
                            <Grid>
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                  color: "#423e44",
                                }}
                              >
                                Request Type
                              </div>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  First Approver
                                </Typography>
                                <TextField
                                  id="firstApprover"
                                  name="firstApprover"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.firstApprover ??
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Request Type
                                </Typography>
                                <TextField
                                  id="requestType"
                                  name="requestType"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.requestType ??
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Entity
                                </Typography>
                                <TextField
                                  id="entity"
                                  name="entity"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.entity ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Location
                                </Typography>
                                <TextField
                                  id="location"
                                  name="location"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.location ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              xs={12}
                              style={{
                                borderBottom: "1px solid #E3E4E5 ",
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                            ></Grid>
                            <Grid>
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                  color: "#423e44",
                                }}
                              >
                                Request For
                              </div>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  {/* User Profile */}
                                  Passport Photo
                                </Typography>
                                <Grid>
                                  {/* <div>
                                  <img
                                    src={
                                      typeof getITRequestFormDetailsUsingRequestId?.employeeImage ===
                                      "string"
                                        ? getITRequestFormDetailsUsingRequestId?.employeeImage
                                        : ""
                                    }
                                    alt="selected file"
                                    style={{
                                      maxWidth: "100%",
                                      height: "100px",
                                    }}
                                  />
                                </div> */}
                                  <div>
                                    <img
                                      src={
                                        getITRequestFormDetailsUsingRequestId?.employeeImage
                                      }
                                      alt={`${getITRequestFormDetailsUsingRequestId?.fileName}'s Profile`}
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                        marginBottom: "10px",
                                      }}
                                    />
                                    {/* <div
                                      style={{
                                        backgroundColor: "#fdf3f5",
                                        color: "#d9395f",
                                        padding: "10px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Selected File:{" "}
                                      {getITRequestFormDetailsUsingRequestId?.fileName
                                        ? getITRequestFormDetailsUsingRequestId
                                            .fileName.length > 15
                                          ? `${getITRequestFormDetailsUsingRequestId.fileName.substring(
                                              0,
                                              15
                                            )}...`
                                          : getITRequestFormDetailsUsingRequestId.fileName
                                        : "No file selected"}
                                    </div> */}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              {/*<Grid item xs={12} md={3}>
                         <Typography className="R-Texts">
                          Request For Myself
                        </Typography>
                        <div>
                          <FormGroup
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              width: "200px",
                            }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Checkbox defaultChecked color="default" />}
                              label="yes"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Checkbox defaultChecked color="default" />}
                              label="No"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </div>
                      </Grid> */}
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  User ID
                                </Typography>
                                <TextField
                                  id="userId"
                                  name="userId"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.userId ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                {" "}
                                Salutation
                              </Typography>
                              <TextField
                                id="salutation"
                                name="salutation"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.salutation ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid> */}
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Employee ID
                                </Typography>
                                <TextField
                                  id="employeeId"
                                  name="employeeId"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.employeeId ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Name
                                </Typography>
                                {/* <TextField
                      id="firstName"
                      name="firstName"
                      className="span"
                      value={reqFormData.firstName}
                      style={{ width: "100%" }}
                      onChange={handleChange("firstName")}
                      placeholder="Enter value here"
                      autoComplete="off"
                    /> */}
                                <div className="input-group">
                                  {/* <select
                                  name="firstName"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.firstName ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                  defaultValue="mr"
                                >
                                  <option value="Mr.">Mr.</option>
                                  <option value="Mrs.">Mrs.</option>
                                  <option value="Miss">Miss</option>
                                </select> */}

                                  <TextField
                                    id="name"
                                    name="name"
                                    className="span"
                                    value={
                                      `${getITRequestFormDetailsUsingRequestId?.salutation} ${getITRequestFormDetailsUsingRequestId?.name}`?.trim() ||
                                      ""
                                    }
                                    style={{ width: "100%" }}
                                    placeholder="Enter value here"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    InputProps={{
                                      readOnly: !isEditable,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Date Of Birth
                                </Typography>
                                <TextField
                                  id="dateOfBirth"
                                  name="dateOfBirth"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.dateOfBirth
                                      ? dayjs(
                                        getITRequestFormDetailsUsingRequestId.dateOfBirth
                                      ).format("DD-MM-YYYY")
                                      : ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                First Name
                              </Typography>
                              <TextField
                                id="firstName"
                                name="firstName"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.firstName ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className="R-Texts">
                                Last Name
                              </Typography>
                              <TextField
                                id="lastName"
                                name="lastName"
                                className="span"
                                value={
                                  getITRequestFormDetailsUsingRequestId?.lastName ||
                                  ""
                                }
                                style={{ width: "100%" }}
                                placeholder="Enter value here"
                                autoComplete="off"
                                onChange={handleChange}
                                InputProps={{
                                  readOnly: !isEditable,
                                }}
                              />
                            </Grid> */}
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Designation
                                </Typography>
                                <TextField
                                  id="designation"
                                  name="designation"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.designation ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Department
                                </Typography>
                                <TextField
                                  id="department"
                                  name="department"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.department ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Office Direct No
                                </Typography>
                                <TextField
                                  id="officeDirectNo"
                                  name="officeDirectNo"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.officeDirectNo ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Mobile No
                                </Typography>
                                <TextField
                                  id="mobileNo"
                                  name="mobileNo"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.mobileNo ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Reporting Manager
                                </Typography>
                                <TextField
                                  id="reportingManager"
                                  name="reportingManager"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.reportingManager ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={9}>
                                <Typography className="R-Texts">
                                  Request Details
                                </Typography>
                                <TextField
                                  id="requestDetails"
                                  name="requestDetails"
                                  className="span"
                                  style={{
                                    marginBottom: "10px",
                                    width: "100%",
                                    color: "gray !important",
                                    overflowX: "scroll",
                                  }}
                                  placeholder="Na"
                                  // multiline
                                  // rows={2}
                                  // maxRows={4}
                                  value={truncateDescription(
                                    getITRequestFormDetailsUsingRequestId?.requestorDetail ||
                                    "",
                                    10
                                  )}
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              xs={12}
                              style={{
                                borderBottom: "1px solid #E3E4E5 ",
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                            ></Grid>
                            <Grid>
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                  color: "#423e44",
                                }}
                              >
                                Request Subject
                              </div>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={6}>
                                <Typography className="R-Texts">
                                  Subject
                                </Typography>
                                <TextField
                                  style={{
                                    marginBottom: "10px",
                                    width: "100%",
                                    color: "gray !important",
                                    padding: "1px 2px",
                                  }}
                                  placeholder="Na"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.subject ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                    sx: { padding: "1px 1px" },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography className="R-Texts">
                                  Requested By
                                </Typography>
                                <TextField
                                  style={{
                                    marginBottom: "10px",
                                    width: "100%",
                                    color: "gray !important",
                                  }}
                                  placeholder="Na"
                                  multiline
                                  rows={2}
                                  // maxRows={4}
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.requestedBy ||
                                    ""
                                  }
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                    sx: { padding: "1px 1px" },
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginBottom: "10px" }}
                            >
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Access permission similar to
                                </Typography>
                                <TextField
                                  id="accessPermissionSimilarTo"
                                  name="accessPermissionSimilarTo"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.accessPermissionSimilarTo ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  ERP Company data
                                </Typography>
                                <TextField
                                  id="erpCompanyData"
                                  name="erpCompanyData"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.erpCompanyData ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <Typography className="R-Texts">
                                  Preferred email address
                                </Typography>
                                <TextField
                                  id="preferredEmailAddress"
                                  name="preferredEmailAddress"
                                  className="span"
                                  value={
                                    getITRequestFormDetailsUsingRequestId?.preferredEmailAddress ||
                                    ""
                                  }
                                  style={{ width: "100%" }}
                                  placeholder="Enter value here"
                                  autoComplete="off"
                                  onChange={handleChange}
                                  InputProps={{
                                    readOnly: !isEditable,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </Box>
                      </div>
                    </>
                  )}

                  {/* {activeTab === "other" && (
                  <div>
                    <OtherDetails />{" "}
                  </div>
                )} */}
                  {activeTab === "work" && (
                    <div
                      className="page-break"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      <Box
                        style={{
                          backgroundColor: "#ffffff",
                          borderRadius: "8px",
                          margin: "40px",
                          // width: "90%",
                          padding: "20px 20px",
                          // height: "50vh",
                          // overflowY: "scroll",
                        }}
                      >
                        {/* {workflowHistoryData?.approvalLogs?.length == 0 ? (
                        <div
                          style={{
                            marginBottom: "20px",
                            //   marginTop: "20px",
                            backgroundColor: "#FFF5D4",
                            padding: "10px 10px",
                          }}
                        >
                          <Grid>
                            <div
                              style={{
                                fontSize: "14px",
                                marginBottom: "15px",
                                color: "#423e44",
                                fontWeight: "bold",
                              }}
                            >
                              Request Form Submitted for Approval/Rejection
                            </div>
                          </Grid>
                        </div>
                      ) : ( */}
                        <>
                          <WorkflowHistory
                            workflowHistoryData={workflowHistoryData}
                          />
                        </>
                        {/* )} */}
                      </Box>
                    </div>
                  )}
                  {/* </PDFExport> */}
                </>
              )}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default ITRequestApproveRejectForm;
