import { AppBar, Paper, Typography } from "@mui/material";
import React from "react";
import "./Styles.css";
import LinkedInIcon from "../../Assets/Images/s4.svg";

const ByrneLinkedin = () => {
  return (
    <div>
      <Paper className="linkedinPaper" style={{ borderRadius: "10px" }}>
        <AppBar
          elevation={0}
          position="static"
          className="tabHeaderForSocialMedia"
        >
          {" "}
          <div>
            <Typography className="headersText">
              {" "}
              <span style={{ paddingRight: "10px" }}>
                <img src={LinkedInIcon} alt="" />
              </span>
              Byrne
            </Typography>
          </div>
          <div
            className="customScrollbar"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
              overflowY: "auto", // Ensuring scroll on parent div
            }}
          >
            {/* <iframe
              src="https://www.linkedin.com/embed/feed/update/urn:li:share:7251191032401252355"
              width="100%"
              height="290px" // Force the parent to scroll
              frameBorder="0"
              allowFullScreen={true}
              title="LinkedIn"
            ></iframe> */}

            <iframe 
              src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25475388' 
              frameBorder='0' 
              width='100%' 
              height="290px"
              allowFullScreen={true}
              title="LinkedIn">
            </iframe>
          </div>
        </AppBar>
      </Paper>
    </div>
  );
};

export default ByrneLinkedin;

// import React, { useEffect } from 'react';

// const ByrneLinkedin: React.FC = () => {
//   useEffect(() => {
//     // Load LinkedIn SDK script
//     const script = document.createElement('script');
//     script.src = "https://platform.linkedin.com/in.js";
//     script.async = true;
//     script.innerHTML = `api_key: YOUR_API_KEY`; // Replace with your actual API key
//     document.body.appendChild(script);

//     script.onload = () => {
//       // Initialize LinkedIn plugins if available
//       if (window.IN && window.IN.init) {
//         window.IN.init();
//       }
//     };

//     // Cleanup script on component unmount
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div>
//       <a
//         href="https://www.linkedin.com/company/byrne-equipment-rental-llc/"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         Visit our LinkedIn Page
//       </a>
//       <div style={{ marginTop: '20px' }}>
//         {/* <script
//           type="IN/CompanyProfile"
//           data-id="265564" // This is the Company ID
//           data-format="inline"
//           data-related="false"
//         ></script> */}
        
//       </div>
//     </div>
//   );
// };

// export default ByrneLinkedin;
