
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  Snackbar,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  IconButton,
  DialogActions,
  LinearProgress,
  Pagination,
  Tooltip,
  Stack,
  Menu,
  Alert,
  SelectChangeEvent,
} from "@mui/material";

import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import AddIcon from "@mui/icons-material/Add";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  DataGrid,
  GridRowId,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  useUploadPostDataMutation,
  useCreatePostsFolderMutation,
  useDeletePostItemMutation,
  useGetPostItemsInFolderQuery,
  useGetTopLevelPostItemsQuery,
  useUpdatePostItemMutation,
  useUploadGalleryFoldersMediaMutation,
} from "../../../Services/APIs";
import AlertYesNo from "../../Hooks/DialogYesNo";
import { Box } from "@mui/material";
import uploadblue from "../../../Assets/Images/uploadblue.svg";
import FolderPath from "./PoliciesFolderPath";
import { nanoid } from "nanoid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Styles.css";
// import folder from "../../../Assets/Images/folder.svg";
import fileWord from "../../../Assets/Images/word.svg";
import filePPT from "../../../Assets/Images/ppt.svg";
import fileExcel from "../../../Assets/Images/excel.svg";
import folder from "../../../Assets/Images/folder-red.svg";
import filePdf from "../../../Assets/Images/pdf.svg";
import { ReactComponent as FileIcon } from "../../../Assets/Images/filee.svg";
import filee from "../../../Assets/Images/filee.svg";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import calenderIcon from "../../../Assets/Images/calenderGrey.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LoaderComponent from "../../../hooks/LoaderComponent";
import FilePreviewDialog from "../../DialogPreview/FilePreviewDialog";
import ByrneUpload from "../../../Assets/Images/ByrneUpload.svg";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import filterHoverIcon from "../../../Assets/eventSearch/filter icon - hover.svg";
import filterIcon from "../../../Assets/eventSearch/filter icon.svg";
import FilterMenu from "../../../hooks/FilterMenu";
import CancelIcon from '@mui/icons-material/Cancel';
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchBar from "../../../hooks/SearchBar";
import PublishIcon from '@mui/icons-material/Publish';

interface Posts {
  title: any;
  _id: string;
  fileName: string;
  fileUrl: string;
  quarter: string;
  year: string;
  description: string;
  releaseDate: any;
  thumbnail: string;
  coverPicture: string;
  createdAt: string;
  // newsTitle: string;
  // newsImage: string;
  // newsDescription: string;
  // createdDate: string;
  // isActive: boolean;
}
interface GridValidRowModel {
  id: number;
  documentType: any;
  isActive: boolean;
  // Add other fields as needed
}
const PostsEditorPage = () => {
  // const { departmentsId, selectedDepartmentName } = props;

  const [uploadPoliciesData] = useUploadPostDataMutation();
  const [createPolicyFolder] = useCreatePostsFolderMutation();
  const [deletePolicyItem] = useDeletePostItemMutation();
  const [updatePolicyItem] = useUpdatePostItemMutation();
  const {
    data: topLevelItemsData,
    isLoading: isTopLevelItemsLoading,
    isError: isTopLevelItemsError,
    refetch: refetchTopLevelItems,
  } = useGetTopLevelPostItemsQuery(undefined, {
    selectFromResult: (result: any) => {
      return result;
    },
  });

  const sortedPosts = topLevelItemsData
    ? [...topLevelItemsData].sort(
      (a: Posts, b: Posts) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )
    : [];

  console.log(sortedPosts, "sorted posts");
  interface ImageCellProps {
    value?: string; // URL of the image
    active?: boolean; // Optional prop for indicating active state
  }
  const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
    value && value.trim() !== "---" ? (
      <img src={value} alt="" style={{ width: "30%", height: "auto" }} />
    ) : (
      <span>----</span>
    );

  // const topLevelItems = topLevelItemsData?.filter((i: any) => {
  //   return i?.departmentId == departmentsId
  // })
  const topLevelItems = topLevelItemsData;
  console.log(topLevelItemsData, topLevelItems, "topLevelItem11");

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const { data: policyItems, refetch: refetchPolicyItems } =
    useGetPostItemsInFolderQuery(selectedFolderId || "");

  const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadOption, setUploadOption] = useState<"FILE" | "FOLDER" | null>(
    null
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFilePreview, setSelectedFilePreview] = useState<any>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [folderName, setFolderName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage1, setSnackbarMessage1] = useState("");
  const [alertActivation, setAlertActivation] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [section, setSection] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState<string>("");
  const [publishDate, setpublishDate] = useState<Date | null>(null);
  const [year, setYear] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [quarter, setQuarterType] = useState<string>('');
  const [applicableTo, setApplicableTo] = useState<string[]>([]);
  const [status, setStatus] = useState("");

  const [selectedFolder, setSelectedFolder] = useState<any | null>(null);
  const [folderContents, setFolderContents] = useState<any[]>([]);

  const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
    []
  );

  const [editedItem, setEditedItem] = useState<any | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    "FILE" | "FOLDER" | null
  >(null);
  const [manualDocumentType, setManualDocumentType] = useState("");

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    section: "",
    title: "",
    year: "",
    description: "",
    publishDate: "",
    documentType: "",
    quarter: "",
    applicableTo: "",

    status: "",
  });
  const [isYesButtonDisabled, setIsYesButtonDisabled] = React.useState(false);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadGalleryFoldersMedia, { isLoading, isError }] =
    useUploadGalleryFoldersMediaMutation();
  const [coverPicture, setCoverPicture] = useState<File | null>(null);
  const [coverPictureName, setCoverPictureName] = useState<string>("");
  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const [editName, setEditName] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState<string>("");
  const [editPublishDate, setEditPublishDate] = useState<Date | null>(null);
  console.log(editPublishDate, "editPublishDate");
  const [editYear, setEditYear] = useState("");
  const [editQuaterValue, setEditQuaterValue] = useState("");

  //use states for validation
  const [isFormValid, setIsFormValid] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [quarterError, setQuarterError] = useState<string>('');
  const [yearError, setYearError] = useState("");
  const [publishDateError, setPublishDateError] = useState("");
  const [imageError, setImageError] = useState("");
  const [uploadedFilesError, setUploadedFilesError] = useState<File[]>([]);
  const [coverImageError, setCoverImageError] = useState("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const coverImageInputRef = useRef<HTMLInputElement | null>(null);
  const [editTitleError, setEditTitleError] = useState("");
  const [editDescriptionError, setEditDescriptionError] = useState("");
  const [editNameError, setEditNameError] = useState("");
  const [editYearError, setEditYearError] = useState("");
  const [editPublishDateError, setEditPublishDateError] = useState("");
  const [editQuarterError, setEditQuarterError] = useState<string>('');
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);

  const convertDateFormat = (inputDate: string): string => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  //.....filter functions
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const yearsData = Array.from(
    new Set(
      folderContents.map((item) => item.year).filter((type) => type)
    )
  ).sort();
  console.log(yearsData, "uniqueSortedDocumentTypes");
  const [selectedYears, setSelectedYears] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedLabels, setSelectedLabels] = useState<any[]>([
    yearsData || [],
  ]);
  console.log(selectedLabels, selectedYears, "selectedYears");
  const [isHovered, setIsHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const open = Boolean(anchorEl);
  // Function to filter folderContents based on selectedYears
  const filterBySelectedYears = (contents: any, years: any) => {
    return contents.filter((item: any) => years.includes(item.year));
  };


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (label: string) => {
    setSelectedLabels((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleSelectAll = () => {
    if (isChecked) {
      handleClear();
    } else {
      setIsChecked(true);
      setSelectedLabels(yearsData || []);
    }
  };
  console.log(selectedLabels, "selected labels:");
  const handleClear = () => {
    setSelectedLabels([]);
    setIsChecked(false);
  };
  const handleApplyFilter = () => {
    if (selectedLabels.includes("Select All")) {
      setSelectedYears([]);
    } else {
      setSelectedYears(selectedLabels);
    }
    handleClose();
  };
  //................filter functions
  //................filter for applicable to
  const applicableToData = Array.from(
    new Set(
      folderContents?.flatMap((item) => item?.quarter)?.filter(Boolean)
    )
  )?.sort();
  // const applicableToData = Array.from(
  //   new Set(
  //     folderContents?.flatMap(item => item?.applicableTo ?? [])
  //   )
  // ).filter(Boolean).sort();


  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [filterField, setFilterField] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedApplicableToData, setSelectedApplicableToData] = useState<
    string[]
  >([]);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [selectedLabelsApplicableToData, setSelectedLabelsApplicableToData] =
    useState<any[]>([applicableToData || []]);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const open2 = Boolean(anchorEl2);
  console.log(applicableToData, "applicableToData");
  // Function to filter folderContents based on selectedYears
  //  const filterByApplicableToData = (contents:any, years:any) => {
  //    return contents?.filter((item:any) => years?.includes(item?.ApplicableType));
  //  };

  //@ts-ignore
  // const filterByApplicableToData = (
  //   contents: any,
  //   selectedApplicableToData: any
  // ) => {
  //   return contents?.filter((item: any) =>
  //     item.quarter?.some((quarter: any) =>
  //       selectedApplicableToData.includes(quarter)
  //     )
  //   );
  // };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const newhandleCellClick = (content: any) => {
    setDialogContent(content);
    setDialogOpen(true);
  };
  const handleCloses = () => {
    setDialogOpen(false);
  };

  const filterByApplicableToData = (
    contents: any,
    selectedApplicableToData: any
  ) => {
    return contents?.filter((item: any) => {
      // Ensure that item.quarter is an array before using some()
      if (Array.isArray(item.quarter)) {
        return item.quarter.some((quarter: any) =>
          selectedApplicableToData.includes(quarter)
        );
      }
      return false; // If item.quarter is not an array, exclude it
    });
  };


  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleLabelClick2 = (label: string) => {
    setSelectedLabelsApplicableToData((prevSelectedLabels) =>
      prevSelectedLabels.includes(label)
        ? prevSelectedLabels.filter((item) => item !== label)
        : [...prevSelectedLabels, label]
    );
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleSelectAll2 = () => {
    if (isChecked2) {
      handleClear2();
    } else {
      setIsChecked2(true);
      setSelectedLabelsApplicableToData(applicableToData || []);
    }
  };
  const handleClear2 = () => {
    setSelectedLabelsApplicableToData([]);
    setIsChecked2(false);
  };
  const handleApplyFilter2 = () => {
    if (selectedLabelsApplicableToData.includes("Select All")) {
      setSelectedApplicableToData([]);
    } else {
      setSelectedApplicableToData(selectedLabelsApplicableToData);
    }
    handleClose2();
  };


  const handleFilterIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterField(field);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterField(null);
  };
  const [filterSelectedOptions, setFilterSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const handleFilterChange = (field: string, selectedOptions: string[]) => {
    setFilterSelectedOptions(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    const newFilteredRows = rows.filter((row) => {
      const cellValue = (row as any)[field];
      return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
    });

    setFilteredRows(newFilteredRows);
    // setPage(1);
  };
  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   const newFilteredRows = rows.filter((row: any) => {
  //     return selectedOptions.includes((row as any)[field]);
  //   });
  //   setFilteredRows(newFilteredRows);
  // };

  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };




  const handleClearFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
      setUploadedFiles([]); // Clear the uploaded files state
    }
  };

  const handleClearCoverImage = () => {
    if (coverImageInputRef.current) {
      coverImageInputRef.current.value = "";
      setCoverPicture(null);
      setCoverPictureName("");
    }
  };

  // const handleEditTitle = (event: any) => {
  //   setEditTitle(event.target.value);
  // };
  const validateTitle = (inputValue: any) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (regex.test(inputValue) && inputValue.length <= 170) {
      return { isValid: true, error: "" };
    } else if (inputValue.length > 171) {
      return { isValid: false, error: "Title cannot exceed 170 characters." };
    } else {
      return { isValid: false, error: "Please enter only letters, numbers, and spaces." };
    }
  };
  const handleEditTitle = (event: any) => {
    const { value } = event.target;
    const { isValid, error } = validateTitle(value);
    if (isValid) {
      setEditTitle(value);
      setEditTitleError("");
    } else {
      // Optionally, you can manage an error state for the edit field
      setEditTitleError(error);
    }
  };

  const validateDescription = (inputValue: string) => {
    if (!inputValue) {
      return { isValid: false, error: "Description is required." };
    }
    return { isValid: true, error: "" };
  };

  // const handleEditDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   const { isValid, error } = validateDescription(value);

  //   if (isValid) {
  //     setEditDescription(value);
  //     setEditDescriptionError(""); // Clear error if input is valid
  //   } else {
  //     setEditDescriptionError(error); // Set error message if input is invalid
  //   }
  // };
  const handleEditDescription = (event: any) => {
    setEditDescription(event.target.value);
    setEditDescriptionError("");
  };
  const handleEditName = (event: any) => {
    setEditName(event.target.value);
  };
  // const handleEditPublishedDate = (event: any) => {
  //   const selectedDate = event.target.value;
  //   console.log(selectedDate, "targetss");
  //   setEditPublishDate(selectedDate);

  //   // Extract the year from the selected date and set it as the edit year
  //   const year = new Date(selectedDate).getFullYear();
  //   setEditYear(year.toString());
  // };

  const handleEditPublishedDate = (date: Date | null) => {
    const formattedPublishDate = convertDateFormat(sortedPosts[0]?.publishDate || "");
    const parsedPublishDate = formattedPublishDate ? new Date(formattedPublishDate) : null;
    setEditPublishDate(parsedPublishDate);
    if (date && !isNaN(date.getTime())) {
      try {
        console.log(date.toISOString());
        setEditPublishDate(date);
        setEditPublishDateError('');
        setEditYearError("");
        if (date) {
          const year = new Date(date).getFullYear();
          setEditYear(year.toString());
        } else {
          setEditYear("");
        }
      } catch (error) {
        console.error("Invalid date:", error);
        setEditPublishDateError('Invalid date selected');
      }
    } else {
      setEditPublishDate(null);
      setEditPublishDateError('Please select a valid date');
    }
  };
  const handleEditYear = (event: any) => {
    setEditYear(event.target.value);
  };
  const handleEditQuaterValue = (event: any) => {
    setEditQuaterValue(event.target.value as string);
  };
  const handleEditCoverPicture = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setCoverPicture(file);
      setCoverPictureName(file.name);
    }
  };
  const [openPreviewDialogPreview, setOpenPreviewDialogPreview] =
    useState(false);
  const handleOpenPreview = () => {
    setOpenPreviewDialogPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreviewDialogPreview(false);
  };
  const fileType = selectedFilePreview?.fileName?.split(".")?.pop() || "";
  const filePath = selectedFilePreview?.fileUrl || "";

  useEffect(() => {
    if (selectedFolderId) {
      if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(policyItems);
      }
    } else {
      if (sortedPosts && !isTopLevelItemsLoading && !isTopLevelItemsError) {
        setFolderContents(sortedPosts || []);
      }
    }
  }, [
    selectedFolderId,
    policyItems,
    sortedPosts,
    isTopLevelItemsLoading,
    isTopLevelItemsError,
  ]);

  useEffect(() => {
    if (folderContents) {
      const lowerCaseSearchQuery = searchTerm.toLowerCase();

      // Step 1: Filter by search query
      const filteredBySearchQuery = folderContents.filter((item: any) => {
        const matchesSearchQuery =
          (Array.isArray(item.applicableTo) &&
            item.applicableTo.some(
              (applicable: any) =>
                typeof applicable === "string" &&
                applicable.toLowerCase().includes(lowerCaseSearchQuery)
            )) ||
          (typeof item.year === "string" &&
            item.year.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.quarter === "string" &&
            item.quarter.toLowerCase().includes(lowerCaseSearchQuery)) ||
          (typeof item.documentType === "string" &&
            item.documentType.toLowerCase().includes(lowerCaseSearchQuery));

        return matchesSearchQuery;
      });

      // Step 2: Filter by selected years if they exist
      let finalFilteredContents = filteredBySearchQuery;
      if (selectedYears?.length > 0) {
        finalFilteredContents = filterBySelectedYears(
          finalFilteredContents,
          selectedYears
        );
      }

      // Step 3: Filter by applicableTo data
      if (quarter?.length > 0) {
        finalFilteredContents = filterByApplicableToData(
          finalFilteredContents,
          selectedApplicableToData
        );
      }

      console.log(finalFilteredContents, "finalFilteredContents");

      setFilteredData(finalFilteredContents);
    }
  }, [folderContents, searchTerm, selectedYears, selectedApplicableToData]);



  useEffect(() => {
    if (!openNewFolderDialog && !openUploadDialog && !openEditDialog) {
      resetValues();
    }
  }, [openNewFolderDialog, openUploadDialog, openEditDialog]);

  const handleFolderClick = (folderId: string | null) => {
    if (folderId) {
      const selectedFolder = folderContents.find(
        (item: any) => item._id === folderId
      );
      if (selectedFolder) {
        console.log(
          `Folder clicked: ${selectedFolder.name} (${selectedFolder._id})`
        );
        setSelectedFolderId(selectedFolder._id as string);
        setFolderPath((prevPath) => {
          const folderIndex = prevPath.findIndex(
            (folder) => folder.id === folderId
          );
          if (folderIndex !== -1) {
            return prevPath.slice(0, folderIndex + 1);
          }
          return [
            ...prevPath,
            { id: selectedFolder._id, name: selectedFolder.name },
          ];
        });
        refetchPolicyItems();
        console.log("Refetching folder items");
      }
    } else {
      console.log("Root folder clicked");
      setSelectedFolderId(null);
      setFolderPath([]);
      refetchPolicyItems();
      console.log("Refetching top-level items");
    }
  };

  const handleGoBack = () => {
    if (folderPath.length > 0) {
      const updatedPath = folderPath.slice(0, -1);
      setFolderPath(updatedPath);
      const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
      setSelectedFolderId(previousFolderId);
      refetchPolicyItems();
    }
  };

  const handleFolderSelection = async () => {
    console.log("Attempting to select a folder...");
    try {
      const directoryHandle = await window.showDirectoryPicker();
      console.log("Folder selected:", directoryHandle.name);
      setSelectedFolder(directoryHandle);
    } catch (error) {
      console.error("Error selecting folder:", error);
    }
  };
  // const handleCoverPictureChange = (event: any) => {
  //   const file = event.target.files[0];
  //   setCoverPicture(file);
  //   setCoverPictureName(file.name);
  // };
  const handleCoverPictureChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setCoverPicture(file);
      setCoverPictureName(file.name);
    }
  };
  const handleRemoveCoverPicture = () => {
    setCoverPicture(null);
    setCoverPictureName("");
 
    // Reset file input value to allow selecting the same file again if needed
    if (uploadInputRef.current) {
      uploadInputRef.current.value = "";
    }
  };
  // const processSelectedFolder = async (
  //   directoryHandle: FileSystemDirectoryHandle
  // ) => {
  //   try {
  //     setIsUploading(true);
  //     setUploadProgress(0);

  //     const totalItems = await countFolderItems(directoryHandle);
  //     let processedItems = 0;

  //     await processDirectory(
  //       directoryHandle,
  //       "",
  //       selectedFolderId || "",
  //       (progress) => {
  //         processedItems++;
  //         const percentage = Math.round((processedItems / totalItems) * 100);
  //         setUploadProgress(percentage);
  //       }
  //     );

  //     setIsUploading(false);
  //     console.log("Folder processing completed.");
  //   } catch (error) {
  //     setIsUploading(false);
  //     console.error("Error processing folder:", error);
  //   }
  // };

  // const countFolderItems = async (
  //   directoryHandle: FileSystemDirectoryHandle
  // ): Promise<number> => {
  //   let count = 0;

  //   for await (const entry of directoryHandle.values()) {
  //     count++;
  //     if (entry.kind === "directory") {
  //       count += await countFolderItems(entry);
  //     }
  //   }

  //   return count;
  // };

  // const processDirectory = async (
  //   directoryHandle: FileSystemDirectoryHandle,
  //   basePath: string,
  //   parentFolderId: string,

  //   onProgress: (progress: number) => void
  // ) => {
  //   for await (const entry of directoryHandle.values()) {
  //     const path = `${basePath}/${entry.name}`;
  //     if (entry.kind === "file") {
  //       const file = await entry.getFile();
  //       await uploadFile(path, file, parentFolderId);
  //       onProgress(1);
  //     } else if (entry.kind === "directory") {
  //       const newFolderId = await createDirectory(path, parentFolderId);
  //       await processDirectory(entry, path, newFolderId, onProgress);
  //     }
  //   }
  // };

  // const createDirectory = async (path: string, parentId: string) => {
  //   console.log(`Creating directory: ${path}`);
  //   const pathParts = path.split("/").filter((part) => part !== "");
  //   const folderName = pathParts[pathParts.length - 1];

  //   const existingFolder = folderContents.find(
  //     (item: any) => item.name === folderName && item.parentId === parentId
  //   );

  //   if (existingFolder) {
  //     return existingFolder._id;
  //   } else {
  //     const newFolder = await createPolicyFolder({
  //       name: folderName,
  //       parentId: parentId,
  //       section: section,
  //       title: title,
  //       year: year,
  //       description: description,
  //       publishDate: publishDate,
  //       documentType: documentType,
  //       quarter: quarter,
  //       applicableTo: applicableTo.join(","),
  //       status: status,
  //       //@ts-ignore
  //       departmentId: departmentsId,
  //       departmentName: selectedDepartmentName,
  //     }).unwrap();
  //     return newFolder._id;
  //   }
  // };
  // console.log(departmentsId, "departmentsId", selectedDepartmentName);
  // const uploadFile = async (
  //   path: string,
  //   file: File,
  //   parentFolderId: string
  // ) => {
  //   console.log(`Uploading file: ${path}`);
  //   const formData = new FormData();
  //   formData.append("policiesItem", file);
  //   formData.append("parentId", parentFolderId);
  //   formData.append("section", section);
  //   formData.append("title", title);
  //   formData.append("year", year);
  //   formData.append("description", description);
  //   formData.append("publishDate", publishDate);
  //   formData.append("documentType", documentType);
  //   formData.append("quarter", quarter);
  //   formData.append("applicableTo", applicableTo.join(","));
  //   formData.append("status", status);
  //   formData.append("departmentId", departmentsId);
  //   formData.append("departmentName", selectedDepartmentName);
  //   try {
  //     await uploadPoliciesData(formData);
  //     console.log("File uploaded successfully");
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   }
  // };

  // const handleCreateFolder = async () => {
  //   if (selectedFolder) {
  //     try {
  //       await processSelectedFolder(selectedFolder);
  //       setFolderName("");
  //       setSection("");
  //       setTitle("");
  //       setDescription("");
  //       setpublishDate("");
  //       setDocumentType("");
  //       setStatus("");
  //       setUploadedFiles([]);
  //       setOpenUploadDialog(false);

  //       if (selectedFolderId) {
  //         refetchPolicyItems();
  //       } else {
  //         refetchTopLevelItems();
  //       }

  //       setSnackbarMessage("Folder and its contents created successfully");
  //       setSnackbarOpen(true);
  //     } catch (error) {
  //       console.error("Error creating folder and its contents:", error);
  //       setSnackbarMessage("Error creating folder and its contents");
  //       setSnackbarOpen(true);
  //     }
  //   }
  // };
  const handleChangeTitleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

    if (regex.test(inputValue) && inputValue.length <= 170) {
      // If the input passes the validation and is within the character limit, update the state
      setTitle(inputValue);
      setTitleError("");
    } else if (inputValue.length > 170) {
      // If the input exceeds the character limit, set an error message
      setTitleError("Title cannot exceed 170 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setTitleError("Please enter only letters, numbers, and spaces.");
    }
  };
  const handleChangeDescriptionField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

    if (inputValue) {
      // If the input passes the validation and is within the character limit, update the state
      setDescription(inputValue);
      setDescriptionError("");
    };

    const handleChangeDescriptionField1 = (event: any) => {
      const selectedValue = event.target.value as string;

      if (selectedValue) {
        setQuarterType(selectedValue);
        setQuarterError("");
      }
    };


    // } else if (inputValue.length > 170) {
    //   // If the input exceeds the character limit, set an error message
    //   setDescriptionError("")("Title cannot exceed 170 characters.");
    // } else {
    //   // If the input contains invalid characters, set an error message
    //   setTitleError("Please enter only letters, numbers, and spaces.");
    // }
  };
  // const validateForm = () => {
  //   let errors = {
  //     name: "",
  //     section: "",
  //     title: "",
  //     year: "",
  //     description: "",
  //     publishDate:"",
  //     documentType: "",
  //     quarter: "",
  //     applicableTo: "",
  //     status: "",
  //   };

  //   if (!folderName.trim()) {
  //     errors.name = "Name is required";
  //   }
  //   if (!section.trim()) {
  //     errors.section = "Section is required";
  //   }
  //   if (!title.trim()) {
  //     errors.title = "title is required";
  //   }
  //   if (!year.trim()) {
  //     errors.year = "year is required";
  //   }
  //   if (!description.trim()) {
  //     errors.description = "description is required";
  //   }
  //   if (!publishDate.trim()) {
  //     errors.publishDate = "publishDate is required";
  //   }
  //   if (!documentType.trim()) {
  //     errors.documentType = "Document Type is required";
  //   }
  //   if (!quarter.trim()) {
  //     errors.quarter = "Quarter Type is required";
  //   }
  //   if (applicableTo.length === 0) {
  //     errors.applicableTo = "Applicable To is required";
  //   }
  //   if (!status.trim()) {
  //     errors.status = "Status is required";
  //   }

  //   setValidationErrors(errors);
  //   return Object.values(errors).every((error) => error === "");
  // };
  const resetFormFields = () => {
    setFileUploadError("");
    setTitleError("");
    setQuarterError("");
    setDescriptionError("");
    setYearError("");
    setImageError("");
    setCoverImageError("");
    setPublishDateError("");
  };
  const resetAllErrorAlert = () => {
    setTitleError("");
    setQuarterError("");
    setDescriptionError("");
    setYearError("");
    setImageError("");
    setCoverImageError("");
    setPublishDateError("");
    // setDeginError("");
  };
  // const validateForm = () => {
  //   let formIsValid = true;

  //   if (title === "") {
  //     setTitleError("Title is required");
  //     formIsValid = false;
  //   } else {
  //     setTitleError(""); // Clear error when Title is valid
  //   }

  //   if (
  //     description === "" ||
  //     description.replace(/<[^>]*>/g, "").trim() === ""
  //   ) {
  //     setDescriptionError("Description is required");
  //     formIsValid = false;
  //   } else {
  //     setDescriptionError(""); // Clear error when Description is valid
  //   }
  //   if (quarter === "") {
  //     setQuarteError("Quarter is required");
  //     formIsValid = false;
  //   } else {
  //     setQuarteError(""); // Clear error when Description is valid
  //   }
  //   if (year === "") {
  //     setYearError("Year is required");
  //     formIsValid = false;
  //   } else {
  //     setYearError(""); // Clear error when Description is valid
  //   }

  //   if (selectedFile === null && uploadedFiles.length == 0) {
  //     setImageError("File is required");
  //     formIsValid = false;
  //   } else {
  //     setImageError(""); // Clear error when selectedFiles is valid
  //   }
  //   if (publishDate === "") {
  //     setPublishDateError("Publish date is required");
  //     formIsValid = false;
  //   } else {
  //     setPublishDateError(""); // Clear error when eventDate is valid
  //   }
  //   setIsFormValid(formIsValid); // Update the form validation state
  //   return {
  //     formIsValid,
  //   };
  // };

  // useEffect(() => {
  //   validateForm();
  // }, [
  //   title,
  //   description,
  //   uploadedFiles,
  //   publishDate,
  //   quarter,
  //   year,
  //   selectedFile,
  // ]);
  // console.log(uploadedFiles, "selectedFile", selectedFile);

  // const handleCreateFolderNew = async () => {
  //   // if (!validateForm()) {
  //   //   return;
  //   // }

  //   try {
  //     const folderData = {
  //       name: folderName,
  //       parentId: selectedFolderId,
  //       section: section,
  //       title: title,
  //       year: year,
  //       description: description,
  //       publishDate: publishDate,
  //       documentType:
  //         documentType === "addChoice" && manualDocumentType !== ""
  //           ? manualDocumentType
  //           : documentType,
  //       quarter: quarter,
  //       applicableTo: applicableTo.join(","),
  //       status: status,
  //       departmentId: departmentsId,
  //       departmentName: selectedDepartmentName,
  //     };

  //     const createdFolder = await createPolicyFolder(folderData).unwrap();

  //     setFolderName("");
  //     setSection("");
  //     setTitle("");
  //     setDescription("");
  //     setpublishDate("");
  //     setDocumentType("");
  //     setStatus("");
  //     setUploadedFiles([]);

  //     setOpenNewFolderDialog(false);

  //     if (selectedFolderId) {
  //       refetchPolicyItems();
  //     } else {
  //       refetchTopLevelItems();
  //     }

  //     setSnackbarMessage("Folder created successfully");
  //     setSnackbarOpen(true);
  //   } catch (error) {
  //     console.error("Error creating folder:", error);
  //     setSnackbarMessage("Error creating folder");
  //     setSnackbarOpen(true);
  //   }
  // };

  const handleFileChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   if (files) {

  //     const supportedExtensions = ["pdf", "doc", "docx", "ppt"];

  //     const unsupportedFiles = Array.from(files).filter((file) => {
  //       const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
  //       return !supportedExtensions.includes(fileExtension);
  //     });
  //     if (unsupportedFiles.length > 0) {
  //       window.alert(

  //         "Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported"

  //       );
  //       event.target.value = "";
  //       return;
  //     }
  //     const acceptedFiles = Array.from(files).filter((file) => {
  //       const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
  //       return supportedExtensions.includes(fileExtension);
  //     });
  //     setUploadedFiles(acceptedFiles);
  //   }
  // };
  const handleRemoveFile = (index: number) => {
    setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const supportedExtensions = ["pdf"];
      const unsupportedFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
        return !supportedExtensions.includes(fileExtension);
      });
      if (unsupportedFiles.length > 0) {
        setSnackbarMessage1(
          "Unsupported file type. Only PDF files are supported."
        );
        setOpenSnackbar(true);
        event.target.value = "";
        return;
      }
      const acceptedFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
        return supportedExtensions.includes(fileExtension);
      });
      setUploadedFiles(acceptedFiles);
      setFileUploadError("");
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // const handleCancel = () => {
  //   if (
  //     Title === "" &&
  //     Description === "" &&
  //     designations === "" &&
  //     selectedFiles === null &&
  //     endDate === null &&
  //     eventTime === ""
  //   ) {
  //     handleDrawerClose();
  //   } else {
  //     setAlertContent("Do you want to discard the changes?");
  //     setAlertActivationForCancel(true);
  //   }
  // };

  useEffect(() => {
    const checkFormValidity = () => {
      const isValid =
        uploadedFiles?.length > 0 &&
        title.trim() !== "" &&
        description.trim() !== "" &&
        quarter.trim() !== "" &&
        year.trim() !== "" &&
        publishDate !== null;

      setIsFormValid(isValid);
    };

    checkFormValidity();
  }, [uploadedFiles, title, description, quarter, year, publishDate]);

  const handleUploadFiles = async () => {
    // resetAllErrorAlert();
    // const { formIsValid } = validateForm();
    // if (!formIsValid) {
    //   // There are validation errors, set errors state and don't submit the form
    //   return;
    // }


    let formIsValid = true;
    const currentYear = new Date().getFullYear();

    if (!uploadedFiles || uploadedFiles.length === 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        file: "File is required",
      }));
      setFileUploadError("File is Required");
      formIsValid = false;
      // setSnackbarMessage("File is required");
      // setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      // return;
    }
    if (!title || title.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        title: "title is required",
      }));
      setTitleError("Title is Required");
      formIsValid = false;
      // setSnackbarMessage("title is required");
      // setSnackbarOpen(true);
      // return;
    }
    if (!description || description.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        description: "description is required",
      }));
      setDescriptionError("Description is Required");
      formIsValid = false;
      // setSnackbarMessage("description is required");
      // setSnackbarOpen(true);
      // return;
    }
    if (!quarter || quarter.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        quarter: "quarter is required",
      }));
      setQuarterError("Quarter is Required");
      formIsValid = false;
      // setSnackbarMessage("quarter is required");
      // setSnackbarOpen(true);
      // return;
    }
    if (!year || year.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        year: "year is required",
      }));
      setYearError("Year is Required");
      formIsValid = false;
      // setSnackbarMessage("year is required");
      // setSnackbarOpen(true);
      // return;
    }
    // if (!publishDate || publishDate === null) {
    //   setValidationErrors((prevErrors) => ({
    //     ...prevErrors,
    //     publishDate: "publishDate is required",
    //   }));
    //   setPublishDateError("PublishDate is Required");
    //   formIsValid = false;
    //   // setSnackbarMessage("publishDate is required");
    //   // setSnackbarOpen(true);
    //   // return;
    // }
    if (!publishDate || publishDate === null) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        publishDate: "Publish date is required",
      }));
      setPublishDateError("Publish Date is Required");
      formIsValid = false;
    } else {
      const year = new Date(publishDate).getFullYear();
      if (isNaN(year) || year < currentYear || year > 9999) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          publishDate: `Publish date must be within the current year or later (${currentYear}).`,
        }));
        setPublishDateError(`Enter a valid date (not earlier than the current year)`);
        formIsValid = false;
      } else {
        setPublishDateError(""); // Clear error when publishDate is valid
      }
    }
    setIsFormValid(formIsValid);

    if (!formIsValid) {
      // Return early if any of the fields are invalid
      // setIsUploading(false); // Re-enable the button
      return;
    }
    const formattedPublishDate = convertDateFormat(sortedPosts[0]?.publishDate || "");
    const parsedPublishDate = formattedPublishDate ? new Date(formattedPublishDate) : null;
    setpublishDate(parsedPublishDate);
    // If all validations pass, proceed with the file upload process
    // setIsUploading(true);
    showLoader();
    if (uploadedFiles.length > 0) {
      // setIsUploading(true);
      showLoader();
      // const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
      const supportedExtensions = ["pdf"];
      const unsupportedFiles = uploadedFiles.filter((file) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
        return !supportedExtensions.includes(fileExtension);
      });

      if (unsupportedFiles.length > 0) {
        window.alert("Unsupported file type. Only PDF supported");
        setIsUploading(false);
        return;
      }
      showLoader();
      const formData = new FormData();
      uploadedFiles.forEach((file) => {
        formData.append("policiesItem", file);
      });
      if (coverPicture) {
        formData.append("coverPicture", coverPicture);
      }
      formData.append("parentId", selectedFolderId || "");
      formData.append("section", section);
      formData.append("title", title),
        formData.append("year", year),
        formData.append("description", description),
        formData.append("publishDate", publishDate ? publishDate.toISOString() : ""),
        formData.append(
          "documentType",
          documentType === "addChoice" && manualDocumentType !== ""
            ? manualDocumentType
            : documentType
        );
      formData.append("quarter", quarter);
      formData.append("applicableTo", applicableTo.join(","));
      formData.append("status", status);
      // formData.append("departmentId", departmentsId);
      // formData.append("departmentName", selectedDepartmentName);

      try {
        await uploadPoliciesData(formData);
        setUploadedFiles([]);
        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }
        setOpenUploadDialog(false);
        setSnackbarMessage("Files uploaded successfully");
        setSnackbarOpen(true);
        resetFormFields();
      } catch (error) {
        console.error("Error uploading files:", error);
        setSnackbarMessage("Error uploading files");
        setSnackbarOpen(true);
      } finally {
        // setIsUploading(false);
        hideLoader(); // Re-enable the button after the upload process completes
      }
    }
  };

  // const handleEditIssueNo = (event:any) =>{
  //   setEditIssueNumber(event.target.value)
  // }
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleEditItem = (itemId: string) => {
    const item = folderContents.find((item) => item._id === itemId);

    setCoverPicture(item?.coverPicture || null);

    const url = item?.coverPicture;
    const filename = url ? url.split("/").pop().split("?")[0] : "";

    setCoverPictureName(filename);
    // setCoverPictureName(item?.coverPictureName);
    console.log(
      item,
      "handleEditItem:444444",
      coverPicture,
      coverPictureName,
      filename
    );
    setEditName(item?.fileName);
    setEditTitle(item?.title);
    setEditDescription(item?.description);
    // setEditPublishDate(item?.publishDate)
    // setEditPublishDate(formatDate(item?.publishDate));
    const formattedPublishDate = convertDateFormat(item?.publishDate || "");
    const parsedPublishDate = formattedPublishDate ? new Date(formattedPublishDate) : null;
    setEditPublishDate(parsedPublishDate);
    console.log(parsedPublishDate, "publish date:", formattedPublishDate, (item?.publishDate || ""))
    // Extract the year from the publishDate and set it
    const publishDate = item?.publishDate ? new Date(item?.publishDate) : null;
    const year = publishDate ? publishDate?.getFullYear()?.toString() : "";
    setEditYear(year);
    setEditQuaterValue(item?.quarter);
    // setEditIssueNumber(item?.issueNumber)
    setEditTitle(item?.title);
    if (item) {
      const documentTypes = [
        "CASE STUDIES",
        "PRESENTATION",
        "BROCHURES",
        "Authority Matrix",
        "Policy",
        "Process Map",
        "Supplementry Document",
        "Form",
        "Template",
        "Service Level Agreement",
        "Certificate",
      ];
      const quarterTypes = ["Q1", "Q2", "Q3", "Q4"];
      if (quarterTypes.includes(item.quarter)) {
        setEditedItem(item);
      }
      // } else {
      //   setEditedItem({
      //     ...item,
      //     documentType: "addChoice",
      //     manualDocumentType: item.documentType,
      //   });
      // }
      // refetchTopLevelItems();
      // refetchPolicyItems();
      setOpenEditDialog(true);
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    if (field === "applicableTo") {
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        applicableTo: value,
      }));
    } else {
      setEditedItem((prevItem: any) => ({
        ...prevItem,
        [field]: value,
      }));
    }
  };
  console.log(editedItem, "editedItem");
  // const handleUpdateItem = () => {
  //   console.log(editTitle, coverPicture, editedItem._id, "handleUpdateItem");
  //   const formData = new FormData();
  //   formData.append("title", editTitle);
  //   formData.append("description", editDescription);
  //   formData.append("name", editName);
  //   formData.append("publishDate", editPublishDate);
  //   formData.append("year", editYear);
  //   //@ts-ignore
  //   formData.append("coverPicture", coverPicture);
  //   formData.append("Quarter", editQuaterValue);
  //   formData.append("id", editedItem._id);
  //   console.log(formData, "formData");
  //   //@ts-ignore
  //   updatePolicyItem(formData);

  //   refetchPolicyItems();
  //   setSnackbarMessage("Changes have been published successfully");
  //   setSnackbarOpen(true);
  //   setOpenEditDialog(false);
  //   setEditedItem(null);
  //   refetchTopLevelItems();
  //   refetchPolicyItems();
  // };
  useEffect(() => {
    if (editedItem) {
      const url = editedItem.coverPicture;
      const filename = url ? url.split("/").pop().split("?")[0] : "";
      setCoverPictureName(filename);
    }
  }, [editedItem]);

  const handleUpdateItem = async () => {
    let formIsValid = true;
    if (!editTitle || editTitle.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        file: "File is required",
      }));
      setEditTitleError("Title is Required");
      formIsValid = false;
      // setSnackbarMessage("Title is required");
      // setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      // return;
    }
    if (!editDescription || editDescription.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editDescription: "Description is required",
      }));
      setEditDescriptionError("Description is Required");
      formIsValid = false;
      // setSnackbarMessage("Description is required");
      // setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      // return;
    }
    if (!editName || editName.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editName: "Name is required",
      }));
      setEditNameError("Name is Required");
      formIsValid = false;
      // setSnackbarMessage("Name is required");
      // setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      // return;
    }
    if (!editQuaterValue || editQuaterValue.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editQuaterValue: "Quarter is required",
      }));
      setEditQuarterError("Quarter is Required");
      formIsValid = false;
      // setSnackbarMessage("Quarter is required");
      // setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      // return;
    }
    if (!editYear || editYear.trim() === "") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editYear: "Year is required",
      }));
      setEditYearError("Year is Required");
      formIsValid = false;
      // setSnackbarMessage("Year is required");
      // setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      // return;
    }
    if (!editPublishDate || editPublishDate === null) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        editPublishDate: "Publish Date is required",
      }));
      setEditPublishDateError("Title is Required");
      formIsValid = false;
      // setSnackbarMessage("Publish Date is required");
      // setSnackbarOpen(true);
      setIsUploading(false); // Re-enable the button
      // return;
    }
    if (!formIsValid) {
      // Return early if any of the fields are invalid
      setIsUploading(false); // Re-enable the button
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("title", editTitle);
    formData.append("description", editDescription);
    formData.append("name", editName);
    formData.append("publishDate", editPublishDate ? editPublishDate.toISOString() : "");
    formData.append("year", editYear);

    // if (coverPicture) {
    //   formData.append("coverPicture", coverPicture);
    // } else if (coverPictureName) {
    //   // Include the existing cover picture URL or name if no new picture is selected
    //   formData.append("coverPicture", coverPictureName);
    // }

    if (coverPicture) {
      formData.append("coverPicture", coverPicture);
    } else {
      formData.append("coverPicture", ""); // Set coverPicture as an empty string if cleared
    }

    formData.append("Quarter", editQuaterValue);
    formData.append("id", editedItem._id);

    console.log(formData, "formData:", editYear, editPublishDate);

    try {
      // @ts-ignore
      await updatePolicyItem(formData);
      console.log("Item updated successfully");

      refetchTopLevelItems();

      await setSnackbarMessage("Changes have been published successfully");
      await setSnackbarOpen(true);
      await setOpenEditDialog(false);
      await setEditedItem(null);
    } catch (error) {
      console.error("Error updating item:", error);
      setSnackbarMessage("Failed to update item");
      setSnackbarOpen(true);
    } finally {
      setIsUploading(false); // Re-enable the button after the upload process completes
    }
  };
  console.log(coverPicture, "handleUpdateItem:");
  const handleUpdateItemx = async () => {
    if (editedItem) {
      try {
        const updatedData = {
          name: editedItem.name || editedItem.fileName,
          section: editedItem.section,
          title: editedItem.title,
          year: editedItem.year,
          description: editedItem.description,
          publishDate: editedItem.publishDate,
          quarter: editedItem.quarter,
          documentType:
            editedItem.documentType === "addChoice" &&
              editedItem.manualDocumentType !== ""
              ? editedItem.manualDocumentType
              : editedItem.documentType,
          applicableTo: editedItem.applicableTo,
          status: editedItem.status,
          coverPicture: coverPicture,
        };

        // await updatePolicyItem({
        //   id: editedItem._id,
        //   data: updatedData,
        // }).unwrap();

        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }

        setSnackbarMessage("Item updated successfully");
        setSnackbarOpen(true);
        setOpenEditDialog(false);
        setEditedItem(null);
      } catch (error) {
        console.error("Error updating item:", error);
        setSnackbarMessage("Error updating item");
        setSnackbarOpen(true);
      }
    }
  };

  const handleCloseNewFolderDialog = () => {
    setOpenNewFolderDialog(false);
    setUploadOption(null);
    resetValues();
  };

  const handleCloseEditDialog = () => {
    setValidationErrors({
      name: "",
      section: "",
      title: "",
      year: "",
      description: "",
      publishDate: "",
      documentType: "",
      quarter: "",
      applicableTo: "",
      status: "",
    });



    setOpenEditDialog(false);
    resetValues();
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setValidationErrors({
      name: "",
      section: "",
      title: "",
      year: "",
      description: "",
      publishDate: "",
      documentType: "",
      quarter: "",
      applicableTo: "",
      status: "",
    });
    setUploadOption(null);
    resetValues();
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    // You can handle the dropped files here, for example, upload them
    handleUploadMediaDragging({ target: { files } });
  };
  const handleUploadMediaDragging = (event: any) => {
    // Handle file upload logic here
    const files = event.target.files;
    console.log(files);
    setCoverPicture(event?.target?.files?.[0]);
    setCoverPictureName(event?.target?.files?.[0].name);
    // setImageEditError("");
    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setCoverPicture(event?.target?.files?.[0]);
    };
  };

  const handleUploadMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (!file) return;
    setCoverPicture(file);
    setCoverPictureName(file.name);
  };


  const handleDeleteItem = (itemId: string) => {
    setSelectedItemId(itemId);
    setAlertContent("Are you sure you want to delete this item?");
    setAlertActivation(true);
    setIsYesButtonDisabled(false);
  };

  // const handlePublishDateChange = (event: any) => {
  //   const date = event.target.value;
  //   setpublishDate(date);
  //   setPublishDateError("");
  //   setYearError("");
  //   if (date) {
  //     const year = new Date(date).getFullYear();
  //     setYear(year.toString());
  //   } else {
  //     setYear("");
  //   }
  // };

  const stripHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handlePublishDateChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      try {
        console.log(date.toISOString());
        setpublishDate(date);
        setPublishDateError('');
        setYearError("");
        if (date) {
          const year = new Date(date).getFullYear();
          setYear(year.toString());
        } else {
          setYear("");
        }
      } catch (error) {
        console.error("Invalid date:", error);
        setPublishDateError('Invalid date selected');
      }
    } else {
      setpublishDate(null);
      setPublishDateError('Please select a valid date');
    }
  };
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleConfirmDelete = async () => {
    setIsYesButtonDisabled(true);
    if (selectedItemId) {
      try {
        await deletePolicyItem(selectedItemId).unwrap();
        if (selectedFolderId) {
          refetchPolicyItems();
        } else {
          refetchTopLevelItems();
        }
        setSnackbarMessage("Item deleted successfully");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error deleting item:", error);
        setSnackbarMessage("Error deleting item");
        setSnackbarOpen(true);
      }
      setSelectedItemId(null);
      setAlertActivation(false);
    }
  };

  const handleCancelDelete = () => {
    setSelectedItemId(null);
    setAlertActivation(false);
  };

  const resetValues = () => {
    setFolderName("");
    setSection("");
    setTitle("");
    setDescription("");
    setpublishDate(null);
    setYear("");
    setDocumentType("");
    setQuarterType("");
    setApplicableTo([]);
    setStatus("");
    setUploadedFiles([]);
    setSelectedFolder(null);
    setEditedItem(null);
    setFileUploadError("");
    setTitleError("");
    setDescriptionError("");
    setQuarterError("");
    setPublishDateError("");
    setYearError("");
    setEditTitleError("");
    setEditDescriptionError("");
    setEditQuarterError("");
    setEditYearError("");
    setEditPublishDateError("");


  };
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleUploadClick = () => {
    setOpenConfirmationDialog(true);
    setAnchorEl1(null);
  };

  const handleConfirmationOption = (option: "FILE" | "FOLDER") => {
    setSelectedOption(option);
    setOpenUploadDialog(true);
    setOpenConfirmationDialog(false);
    handleClose1();
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (folderContents.length === 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage(value);
    }
  };
  const totalPages = Math.max(
    1,
    Math.ceil(folderContents.length / itemsPerPage)
  );

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  };

  const handlePageClick = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  //old code
  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (let i = 1; i <= totalPages; i++) {
  //     pageNumbers.push(
  //       <Typography
  //         key={i}
  //         variant="body2"
  //         sx={{
  //           width: 30,
  //           height: 30,
  //           borderRadius: "4px",
  //           backgroundColor: currentPage === i ? "var(--blackprimary)" : "transparent",
  //           color: currentPage === i ? "white" : "inherit",
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           cursor: "pointer",
  //           mx: 0.5,
  //         }}
  //         onClick={() => handlePageClick(i)}
  //       >
  //         {i}
  //       </Typography>
  //     );
  //   }
  //   return pageNumbers;
  // };

  //new code
  const renderPageNumbers = () => {
    const totalItems = filteredData.length > 0 ? filteredData.length : folderContents.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <Typography
          key={i}
          variant="body2"
          sx={{
            width: 30,
            height: 30,
            borderRadius: "4px",
            backgroundColor: currentPage === i ? "var(--blackprimary)" : "transparent",
            color: currentPage === i ? "white" : "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            mx: 0.5,
          }}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </Typography>
      );
    }

    return pageNumbers;
  };


  const columns = [
    // {
    //   field: "index",
    //   headerName: "ID",
    //   width: 80,
    //   headerClassName: "TableHeader",
    //   cellClassName: "ByrnePostTableCell",
    //   valueGetter: (params: any) => {
    //     const index = folderContents.findIndex(item => item._id === params.row._id);
    //     return index + 1;
    //   },
    // },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "ByrnePostTableCell",
    //   field: "name",
    //   headerName: "Name",
    //   width: 250,
    //   sortable: false,
    //   renderCell: (params: GridRenderCellParams) => {
    //     const row = params.row;
    //     const isFolder = row.type === "folder";
    //     const fileName = row.fileName || "";
    //     const folderName = row.name || "";
    //     const itemName = isFolder ? folderName : fileName;

    //     return (
    //       // <Tooltip
    //       //   placement="right"
    //       //   title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
    //       //   arrow
    //       // >
    //       <div
    //         style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
    //         onClick={() => {
    //           if (!isFolder) {
    //             setSelectedFilePreview(row);
    //             handleOpenPreview();
    //           }
    //         }}
    //       >
    //         <div style={{ display: "flex", alignItems: "center" }}>
    //           {isFolder ? (
    //             <FolderIcon style={{ marginRight: "8px" }} />
    //           ) : (
    //             <InsertDriveFileIcon style={{ marginRight: "8px" }} />
    //           )}
    //           <span>{itemName}</span>
    //         </div>
    //         {/* <> */}
    //         {/* <InsertDriveFileIcon style={{ marginRight: "8px" }} /> */}
    //         {/* <div>
    //               {itemName?.includes("pdf") ? (
    //                 <img
    //                   src={filePdf}
    //                   alt="PDF File"
    //                   style={{ marginRight: "8px", height: "20px" }}
    //                 />
    //               ) : itemName?.includes("doc") ? (
    //                 <img
    //                   src={fileWord}
    //                   alt="File"
    //                   style={{ marginRight: "8px" }}
    //                 />
    //               ) : itemName?.includes("ppt") ? (
    //                 <img
    //                   src={filePPT}
    //                   alt="File"
    //                   style={{ marginRight: "8px" }}
    //                 />
    //               ) : itemName?.includes("xlx") ? (
    //                 <img
    //                   src={fileExcel}
    //                   alt="File"
    //                   style={{ marginRight: "8px" }}
    //                 />
    //               ) : (
    //                 <FileIcon
    //                   color="red"
    //                   style={{ marginRight: "8px", height: "20px" }}
    //                 />
    //                 // Replace with your default icon
    //               )}
    //             </div> */}
    //         {/* <Tooltip
    //               placement="right-start"
    //               title={<div dangerouslySetInnerHTML={{ __html: row.fileName }} />}
    //               arrow
    //             >
    //               <div
    //                 className="descriptionceo"
    //                 dangerouslySetInnerHTML={{ __html: fileName }}
    //               />
    //             </Tooltip>
    //           </> */}
    //       </div>
    //       // </Tooltip>
    //     );
    //   },
    // },
    // {
    //   field: "section",
    //   headerName: "Section",
    //   width: 150,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    {
      field: "title",
      headerName: "Title",
      width: 230,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row;
        const isFolder = row.type === "folder";
        const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(
          null
        );

        const handleTooltipOpen = (id: number) => {
          setTooltipOpenCell(id);
        };

        const handleTooltipClose = () => {
          setTooltipOpenCell(null);
        };
        const truncateText = (text: string, maxLength: number) => {
          return text?.length > maxLength ? text?.substring(0, maxLength) + "..." : text;
        };
        const fileName = truncateText(row?.title, 30);
        const itemName = isFolder ? folderName : fileName;
        const isTruncated = row?.title?.length > 30;
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              if (!isFolder) {
                setSelectedFilePreview(row);
                handleOpenPreview();
              }
            }}
          >
            {isFolder ? (
              <>
                <FolderIcon style={{ marginRight: "8px" }} />
                <Tooltip
                  placement="right-start"
                  // title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                  title={isTruncated ? <div dangerouslySetInnerHTML={{ __html: params.value }} /> : ""}
                >
                  <div
                    className="descriptionceo"
                    dangerouslySetInnerHTML={{ __html: folderName }}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                {/* <InsertDriveFileIcon style={{ marginRight: "8px" }} /> */}
                <div>
                  {itemName && (
                    <img
                      src={filePdf}
                      alt="PDF File"
                      style={{ marginRight: "8px", height: "20px" }}
                    />
                  )}
                </div>
                <Tooltip
                  placement="bottom-start"
                  // title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                  title={isTruncated ? <div dangerouslySetInnerHTML={{ __html: params.value }} /> : ""}
                >
                  <div
                    className="descriptionceo"
                    dangerouslySetInnerHTML={{ __html: fileName }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        )
        // return (
        //   <Tooltip
        //     open={tooltipOpenCell === params.row.id}
        //     onOpen={() => handleTooltipOpen(params.row.id)}
        //     onClose={handleTooltipClose}
        //     title={params.value}
        //     placement="right-start"
        //     arrow
        //   >
        //     <div>{params.value}</div>
        //   </Tooltip>
        // );
      },
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: "TableHeader",
    //   cellClassName: "ByrnePostTableCell",
    //   renderCell: (params: GridRenderCellParams) => {
    //     const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(
    //       null
    //     );

    //     const handleTooltipOpen = (id: number) => {
    //       setTooltipOpenCell(id);
    //     };

    //     const handleTooltipClose = () => {
    //       setTooltipOpenCell(null);
    //     };

    //     return (
    //       <div style={{cursor:"pointer"}}>
    //       <Tooltip
    //         open={tooltipOpenCell === params.row.id}
    //         onOpen={() => handleTooltipOpen(params.row.id)}
    //         onClose={handleTooltipClose}
    //         title={params.value || "No description"}
    //         placement="right-start"
    //         // arrow
    //       >
    //         <div className="description-tooltip">
    //           {params.value || "No description"}
    //         </div>
    //       </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: GridRenderCellParams) => {
        // Sanitize the HTML content
        const sanitizedValue = stripHtml(params.value);

        // Truncate the sanitized value if necessary
        const truncatedValue =
          sanitizedValue.length > 30 ? sanitizedValue.substring(0, 20) + "..." : sanitizedValue;

        return (
          <div
            style={{ cursor: "pointer" }}
            className="descriptionceo"
            onClick={() => newhandleCellClick(params.value)} // Ensure this function handles plain text
          >
            {truncatedValue} {/* Render as plain text */}
          </div>
        );
      },
    },
    //     return (
    //       <div 
    //         style={{cursor:"pointer"}}
    //         className="descriptionceo"
    //         onClick={() => newhandleCellClick(params.value)}
    //       >
    //         {params.value || "No description"}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "ByrnePostTableCell",
    //   field: "publishDate",
    //   headerName: "Publish Date",
    //   width: 150,
    //   sortable: true,
    //   valueFormatter: (params: { value: string | number | Date }) => {
    //     const date = new Date(params.value);
    //     return date.toLocaleDateString();
    //   },
    // },
    {
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      field: "publishDate",
      headerName: "Publish Date",
      width: 200,
      sortable: false,
      valueFormatter: (params: { value: string | number | Date }) => {
        const date = new Date(params.value);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
    },

    {
      field: "quarter",
      headerName: "Quarter",
      width: 150,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick2}
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            {/* <img
              src={isHovered || open ? filterHoverIcon : filterIcon}
              alt="filter"
            /> */}
            <FilterListIcon />
          </Button>
        </div>
      ),
    },
    {
      field: "year",
      headerName: "Year",
      width: 150,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderHeader: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>{params.colDef.headerName}</span>

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* <img
              src={isHovered || open ? filterHoverIcon : filterIcon}
              alt="filter"
            /> */}
            <FilterListIcon />
          </Button>
        </div>
      ),
    },
    {
      field: "coverPicture",
      headerName: "Cover Picture",
      width: 150,
      headerClassName: "NewTableHeader",
      cellClassName: "MarketingTableCell",
      sortable: true,
      valueGetter: (params: any) =>
        params.value && params.value.trim() ? params.value : "---",
      renderCell: (params: any) => <ImageCell value={params.value} />,
    },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    //   field: "documentType",
    //   headerName: "Document Type",
    //   width: 250,
    //   sortable: false,
    // },
    // {
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    //   field: "applicableTo",
    //   headerName: "Applicable To",
    //   width: 200,
    //   sortable: false,
    //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
    //     const applicableTo = params.row.applicableTo;
    //     if (Array.isArray(applicableTo)) {
    //       return (
    //         <div
    //           style={{
    //             whiteSpace: "pre-wrap",
    //             textAlign: "center",
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             height: "100%",
    //           }}
    //         >
    //           {applicableTo.map((value) => (
    //             <span key={value}>{value}</span>
    //           ))}
    //         </div>
    //       );
    //     }
    //     return applicableTo;
    //   },
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 180,
    //   sortable: false,
    //   headerClassName: "TableHeader",
    //   cellClassName: "TableHeader",
    // },
    {
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const itemId = params.row._id;

        return (
          <div>
            <Tooltip title="Edit">
              <IconButton
                sx={{ padding: "3px" }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditItem(itemId);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{ padding: "3px" }}
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteItem(itemId);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };

  const getCellClassName = () => {
    return "eve";
  };

  //The React useMemo Hook returns a memoized value.
  //Think of memoization as caching a value so that it does not need to be recalculated.
  const rowsToDisplay = useMemo(() => {
    if (searchTerm && filteredData.length === 0) {
      return []; // Return an empty array if no data matches the search term
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    return filteredData.length > 0
      ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
      : currentItems.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredData, currentItems, currentPage, itemsPerPage, searchTerm]);

  console.log("Current Items:", currentItems, "Filtered Data:", filteredData, "Rows to Display:", rowsToDisplay);


  let content;

  if (isTopLevelItemsLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* <CircularProgress style={{ color: "#009BAD" }} /> */}

        <LoaderComponent />
      </div>
    );
  } else if (isTopLevelItemsError) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Typography>Error loading top-level items.</Typography>
      </div>
    );
  } else {
    content = (
      <div
        style={
          {
            // display: "flex",
            // height: "75%",
            // marginLeft: "15px",
            // marginRight: "15px",
          }
        }
        className="mainTable"
      >
        <div className="ByrnePost-table1">
          <Box sx={{ flexGrow: 1, width: "100%", height: "430px" }}>
            {isLoadingg && <LoaderComponent />}
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#D0D0D0 !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                {
                  outline: "none",
                },
                border: "none !important",
                ".MuiDataGrid-cellContent": {
                  pointerEvents: "none",
                },
              }}
              componentsProps={{
                footer: { style: { display: "none" } },
              }}
              rows={rowsToDisplay || []}
              columns={columns}
              getRowId={(row) => row._id}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              disableDensitySelector
              disableColumnFilter
              disableColumnMenu
              hideFooterPagination={true}
              disableColumnSelector={true}
              getRowClassName={getRowClassName}
              getCellClassName={getCellClassName}
              // rowHeight={73}
              onRowClick={(params) => {
                const row = params.row;
                if (row.type === "folder") {
                  handleFolderClick(row._id);
                }
              }}
              style={{
                color: "rgba(30, 30, 30, 1)!important",
                boxShadow: "20px !important",
                width: "100%",
              }}
            />
          </Box>
        </div>
        <div>
          {/* <DragAndDrop 
onDrop={handleDropFiles}  
handleDragAndDropSubmit={handleDragAndDropSubmit}
/> */}
        </div>
        {/* {filterField && (
          <FilterMenu
            anchorEl={filterAnchorEl}
            onClose={handleFilterClose}
            field={filterField}
            onFilterChange={handleFilterChange}
            options={
              filterField
                ? Array.from(
                  new Set(
                    rows.map((row) => row[filterField as keyof typeof row])
                  )
                )
                : []
            }
          />
        )} */}

        {filterField && (
          <FilterMenu
            anchorEl={filterAnchorEl}
            onClose={handleFilterClose}
            field={filterField}
            onFilterChange={handleFilterChange}
            options={
              filterField
                ? Array.from(
                  new Set(
                    rows
                      .map((row) => row[filterField as keyof typeof row])
                      .filter((value) => value !== null && value !== undefined && value !== "")
                  )
                )
                : []
              //   }
              //   selectedOptions={filterSelectedOptions[filterField] || []} // Pass selectedOptions here
              // />
            }
            selectedOptions={[]}
            hideCheckboxes={searchTerm !== ""} // Hide checkboxes when there's a search term
          />
        )}

        <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleConfirmDelete}
          handleAlertClose={handleCancelDelete}
          isYesButtonDisabled={isYesButtonDisabled}
        >
          {alertContent}
        </AlertYesNo>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#e0001b",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "20px",
              marginRight: "24px",
              fontSize: "14px !important",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
      </div>
    );
  }
  function handleOpenDialog(event: React.MouseEvent<HTMLLIElement>): void {
    // throw new Error("Function not implemented.");
    // console.log("Dialog opened");
    setOpenNewFolderDialog(true);
  }

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Check if selectedFolderId is not null
      if (selectedFolderId) {
        formData.append("parentId", selectedFolderId);
      } else {
        // Handle the case when selectedFolderId is null
        // For example, you can choose a default folder or display an error message to the user
        console.log(
          "Warning: No folder selected. Uploading to default location..."
        );
      }

      // Make the API request to upload the file using the mutation hook
      uploadGalleryFoldersMedia(formData)
        .unwrap()
        .then(() => {
          // Close the upload dialog
          handleCloseUploadDialog();
          // Open the success dialog
          setOpenDialog2(true);
        })
        .catch((error: any) => {
          console.error("Error uploading file:", error);
          // Optionally, show an error message to the user
        });
    } else {
      console.log("No file selected.");
      // Optionally, show a message to the user indicating that no file is selected
    }
  };

  return (
    <div>
      {/* <Grid className="upperPart1">
          <Grid container alignItems="center" style={{ display: "flex" }}>
            <IconButton
              onClick={handleGoBack}
              disabled={folderPath.length === 0}
            >
              <ArrowBackIcon />
            </IconButton>
            <Grid item style={{ display: "flex", alignItems: "left" }}>
              <Typography
                component="span"
                onClick={() => handleFolderClick(null)}
                style={{ 
                  cursor: "pointer", color: "#e0001b", marginLeft: -"13px",
                  position: "relative",
                  right: "11px",
                  fontSize:"18px",
                  fontWeight:600
                 }}
              >
                Byrne Posts
              </Typography>
              <FolderPath
                path={folderPath}
                onFolderClick={handleFolderClick}
                selectedFolderId={selectedFolderId}
              />
            </Grid>
          </Grid>
        </Grid> */}
      <div className="EventsHeaderStack">
        <div className="contenteditorheader">Byrne Posts</div>
        <div className="new-event-btn ">
          {/* <Grid className="new&uploadGrid"> */}
          <Button
            startIcon={<AddCircleOutlineRoundedIcon style={{height:"16px"}}/>}
            // onClick={() => setOpenNewFolderDialog(true)}
            onClick={handleClick1}
            // className="newFolder"
            // sx={{ textTransform: "capitalize", marginRight: "10px" }}
            className="create"
            sx={{ textTransform: "none", backgroundColor: "#e0001b" }}
          >
            New
          </Button>
          <Menu
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={handleClose1}
          >
            {/* <MenuItem
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                //onClick={handleUploadClick}
                onClick={handleOpenDialog}
              >
                <img src={folder} />
                <div className="Menuname">Folder</div>
              </MenuItem> */}

            <MenuItem
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              // onClick={handleFileUpload}
              onClick={() => handleConfirmationOption("FILE")}
            >
              <img src={filee} />
              <label>
                <div className="Menuname">Upload Files </div>
                <input
                  id="fileInput"
                  // type="file"
                  onChange={handleFileChanges}
                  style={{ display: "none" }}
                />
              </label>
            </MenuItem>
            {/* <MenuItem
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                //onClick={handleCloseUploadDialog}
                // onClick = {handleUploadClick}
                onClick={() => handleConfirmationOption("FOLDER")}
              >
                <img src={filee} />
                <div className="Menuname">Upload Folder</div>
              </MenuItem> */}
          </Menu>
          {/* <Button
              startIcon={<img src={uploadblue} alt="Upload Icon" />}
              onClick={handleUploadClick}
              className="upload"
              sx={{ textTransform: "capitalize" }}
            >
              UPLOAD
            </Button> */}
          {/* </Grid> */}
        </div>
      </div>
      <div>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="Tablebreadcrumb">
            <div className="Tablebreadcrumb1">Landing Page</div>
            <span className="Tablebreadcrumbarrow-icon">
              <NavigateNextIcon />
            </span>
            <span className="Tablebreadcrumb2">Byrne Posts</span>
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch} // Add this line
            />
          </div></Stack>
      </div>
      {/* <div className="Tablebreadcrumb">
          <IconButton
            onClick={handleGoBack}
            disabled={folderPath.length === 0}

          >
            <ArrowBackIcon />
          </IconButton>
          <span className="Tablebreadcrumb1">LANDING PAGE</span> */}
      {/* <img src={Breadcrumbs} alt="" className="PolicyArrowIcon" /> */}
      {/* <span className="PolicyArrowIcon">
            <NavigateNextIcon />
          </span>
          <span className="Breadcrumbs2">Byrne Posts</span>
          <FolderPath
            path={folderPath}
            onFolderClick={handleFolderClick}
            selectedFolderId={selectedFolderId}
          />
        </div> */}

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialog",
        }}
        open={openNewFolderDialog}
        onClose={() => {
          setOpenNewFolderDialog(false);
          setValidationErrors({
            name: "",
            section: "",
            title: "",
            year: "",
            description: "",
            publishDate: "",
            documentType: "",
            quarter: "",
            applicableTo: "",
            status: "",
          });
        }}
      >
        {/* <div>
            <Grid className="dialogTitle">
              <Grid>
                <Typography
                  className="newItem"
                  style={{ textAlign: "left", color: "#02354d" }}
                >
                  New Folder
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={() => setOpenNewFolderDialog(false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div> */}
        <div className="plusIcon">
          <div style={{ marginLeft: "10px", marginTop: "15px" }}>
            <img src={plusIcon} alt="Plus Icon" />
          </div>
          <Typography className="addpolicy">Add Documents</Typography>
        </div>
        <div>
          <Divider />
          <div style={{ marginBottom: "10px" }}>
            <div className="label">
              <div className="subHeading">Folder Name</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <TextField
              id="outlined-adornment-weight"
              className="span"
              value={folderName}
              style={{ width: "100%" }}
              onChange={(event) => {
                setFolderName(event.target.value);
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  name: event.target.value.trim() ? "" : "Name is required",
                }));
              }}
              placeholder="Enter folder name"
              autoComplete="off" // Add autoComplete attribute and set it to "off"
            />

            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.name}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Section</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <TextField
              id="outlined-adornment-section"
              className="span"
              value={section}
              style={{ width: "100%" }}
              onChange={(event) => {
                setSection(event.target.value);
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  section: event.target.value.trim()
                    ? ""
                    : "Section is required",
                }));
              }}
              placeholder="Enter section"
            />

            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.section}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Document Type</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <div className="documentTypeWrapper">
              <Select
                id="outlined-adornment-documentType"
                className={`span ${documentType === "addChoice" ? "reduced-width" : ""
                  }`}
                value={documentType}
                style={{ width: "100%", height: "40px" }}
                onChange={(event) => {
                  setDocumentType(event.target.value as string);
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    documentType: event.target.value.trim()
                      ? ""
                      : "Document Type is required",
                  }));
                }}
              >
                <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
                <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
                <MenuItem value="BROCHURES">BROCHURES</MenuItem>
                <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
                <MenuItem value="Policy">Policy</MenuItem>
                <MenuItem value="Process Map">Process Map</MenuItem>
                <MenuItem value="Supplementry Document">
                  Supplementry Document
                </MenuItem>
                <MenuItem value="Form">Form</MenuItem>
                <MenuItem value="Template">Template</MenuItem>
                <MenuItem value="Service Level Agreement">
                  Service Level Agreement
                </MenuItem>
                <MenuItem value="Certificate">Certificate</MenuItem>
                <MenuItem value="addChoice">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AddIcon style={{ marginRight: "8px" }} />
                    Add Choice Manually
                  </div>
                </MenuItem>
              </Select>
              {documentType === "addChoice" && (
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    id="outlined-adornment-manual-document-type"
                    className="span manual-document-type"
                    value={manualDocumentType}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setManualDocumentType(event.target.value)
                    }
                    placeholder="Enter document type"
                  />
                </div>
              )}
            </div>
            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.documentType}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Applicable To</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applicableTo.includes("Sales")}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setApplicableTo((prevApplicableTo) =>
                        checked
                          ? [...prevApplicableTo, "Sales"]
                          : prevApplicableTo.filter(
                            (item: string) => item !== "Sales"
                          )
                      );
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        applicableTo: "",
                      }));
                    }}
                  />
                }
                label="Sales"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applicableTo.includes("Operations")}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setApplicableTo((prevApplicableTo) =>
                        checked
                          ? [...prevApplicableTo, "Operations"]
                          : prevApplicableTo.filter(
                            (item: string) => item !== "Operations"
                          )
                      );
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        applicableTo: "",
                      }));
                    }}
                  />
                }
                label="Operations"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applicableTo.includes("IT")}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setApplicableTo((prevApplicableTo) =>
                        checked
                          ? [...prevApplicableTo, "IT"]
                          : prevApplicableTo.filter(
                            (item: string) => item !== "IT"
                          )
                      );
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        applicableTo: "",
                      }));
                    }}
                  />
                }
                label="IT"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applicableTo.includes("HR & Finance")}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setApplicableTo((prevApplicableTo) =>
                        checked
                          ? [...prevApplicableTo, "HR & Finance"]
                          : prevApplicableTo.filter(
                            (item) => item !== "HR & Finance"
                          )
                      );
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        applicableTo: "",
                      }));
                    }}
                  />
                }
                label="HR & Finance"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applicableTo.includes("Health & Safety")}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      setApplicableTo((prevApplicableTo) =>
                        checked
                          ? [...prevApplicableTo, "Health & Safety"]
                          : prevApplicableTo.filter(
                            (item) => item !== "Health & Safety"
                          )
                      );
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        applicableTo: "",
                      }));
                    }}
                  />
                }
                label="Health & Safety"
              />
            </FormGroup>
            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.applicableTo}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Status</div>
              <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
            </div>
            <TextField
              id="outlined-adornment-status"
              className="span"
              value={status}
              style={{ width: "100%" }}
              onChange={(event) => {
                setStatus(event.target.value);
                setValidationErrors((prevErrors) => ({
                  ...prevErrors,
                  status: event.target.value.trim() ? "" : "Status is required",
                }));
              }}
              placeholder="Enter status"
            />

            {validationErrors.name && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {validationErrors.status}
              </div>
            )}
          </div>
          {/* <div className="actionDivTwo">
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={handleCreateFolderNew}
              className="saveBtn"
            >
              Create Folder
            </Button>
          </div> */}
        </div>
      </Drawer>

      <Dialog open={isUploading}>
        {/* <DialogTitle>Upload Progress</DialogTitle> */}
        <LoaderComponent />
        {/* <DialogContent>
          <Typography variant="body1">{uploadProgress}%</Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsUploading(false)}
            disabled={uploadProgress < 100}
          >
            Close
          </Button>
        </DialogActions> */}
      </Dialog>

      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <DialogTitle>Select Upload Option</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "16px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleConfirmationOption("FILE")}
              style={{ textTransform: "none", backgroundColor: "#e0001b" }}
            >
              File
            </Button>
            <Button
              variant="contained"
              onClick={() => handleConfirmationOption("FOLDER")}
              style={{ textTransform: "none", backgroundColor: "#e0001b" }}
            >
              Folder
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialog",
        }}
        open={openUploadDialog}
        // onClose={handleCloseUploadDialog}
      >
        <div>
          <div className="DrawerHeaderBoxNew">
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <img
                style={{ height: "20px", width: "17px" }}
                src={plusIcon}
                alt="Plus Icon"
              /> */}
              <div>
                <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff",height:"16px" }} />
              </div>
              <div className="DrawerTitle">
                {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
              </div>
            </Stack>
            {/* <div> */}
            <div>
            <Stack direction="row" alignItems="center" spacing={1}>
            <div>
            {selectedOption === "FILE" && isFormValid && (
              <Tooltip placement="top-start" title="Click to Update">
                <PublishIcon
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white !important",
                    height:"17px",
                    marginTop:"3px",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    cursor: "pointer",
                  }}
                  onClick={handleUploadFiles}
                className={"PrimaryBlueButton"}
                />
                </Tooltip>
              )}
              </div>
              <Tooltip placement="top-start" title="Click to Cancel">
            <CloseRoundedIcon
              onClick={() => setOpenUploadDialog(false)}
              style={{
                cursor: "pointer",
                color: "whitesmoke",
                padding: "5px",
                borderRadius: "50%",
                transition: "background-color 0.3s",
                height:"16px",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            />
            </Tooltip>
            </Stack>
            </div>
          </div>
          {/* <Grid className="dialogTitle">
            <Grid>
              <Typography
                className="newItem"
                style={{ textAlign: "left", color: "#02354d" }}
              >
                {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
              </Typography>
            </Grid>
            <Grid>
              <IconButton onClick={() => setOpenUploadDialog(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid> */}
        </div>
        <div>
          {/* <Divider /> */}
          {/* <div>
            {selectedOption === "FILE" && (
              <div style={{ marginBottom: "15px" }}>
                <div className="label"  style={{ marginTop: "45px" }}>
                  <div className="subHeading">Select Files</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>
               
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                  <input
                    type="file"
                    multiple
                    accept=".pdf"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ marginRight: "10px" }} // Adding space between the file input and the cancel button
                  />

                  {uploadedFiles.length > 0 && (
                    <span
                      onClick={handleClearFiles}
                      style={{
                        cursor: "pointer",
                        paddingLeft: "10px",  // Optional, to add space if necessary
                        display: "inline-flex",
                        alignItems: "center",
                        height:"16px !important",
                      }}
                    >
                      <CloseIcon />
                    </span>
                  )}
                </div>

                {fileUploadError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                    {fileUploadError}
                  </div>
                )}
                <div className="error">{imageError}</div>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  // onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    // onClose={handleCloseSnackbar}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {snackbarMessage1}
                  </Alert>
                </Snackbar>
              </div>
            )}
          </div> */}
            <div style={{ marginBottom: "15px",marginTop:"35px" }}>
            <div className="label">
              <div className="subHeading" style={{ display: "flex", position: "relative", top: "2px" }}>
                Select Files
                <div style={{ display: "flex" }}>
                    <div>
                      <img
                        src={Asterisk}
                        alt="..."
                        style={{
                          marginBottom: "5px",
                          position: "relative",
                          left: "5px",
                        }}
                      />
                    </div>
                  </div>
                </div>
            </div>
            <input
              type="file"
              multiple
              accept=".pdf"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <button onClick={() => fileInputRef.current?.click()}>
            Choose Files
          </button>
            {uploadedFilesError && <div className="error">{imageError}</div>}

            {/* {uploadedFiles && uploadedFiles?.length > 0 && (
            <div className="subHeadingnew">No of Selected Files: {uploadedFiles?.length}</div>
          )} */}
           {uploadedFiles && uploadedFiles.length > 0 && (
            <div style={{ marginBottom: "10px" }}>
              <div className="subHeadingnew">Selected Files:</div>
              <ul className="fileList">
                {uploadedFiles.map((folder:any, index:any) => (
                  <li key={index} className="fileItem">
                    <span className="fileName">{folder.name}</span>
                    <button className="deleteButton" onClick={() => handleRemoveFile(index)}>
                      <span role="img" aria-label="delete">
                        <DeleteIcon />
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          </div>
          {selectedOption === "FOLDER" && (
            <div style={{ marginBottom: "10px" }}>
              <div className="label">
                <div className="subHeading">Select Folder</div>
              </div>
              <button onClick={handleFolderSelection}>Select Folder</button>
              {selectedFolder && <p>Selected Folder: {selectedFolder.name}</p>}
            </div>
          )}
          {/* <div style={{ marginBottom: "15px" }}>
            <div className="labelnew">
              <div className="subHeading">Select Cover Image</div>
            </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleCoverPictureChange}
              ref={coverImageInputRef}
            />
            {coverPicture && (
              <span
                onClick={handleClearCoverImage}
                style={{
                  width: "15px",
                  paddingLeft: "20px",
                  cursor: "pointer",
                }}
              >
                <CloseIcon style={{ width: "15px !important" }} />
              </span>
            )}
            </div>
            <div className="error">{coverImageError}</div> */}
               <div style={{ marginBottom: "15px" }}>
            <div className="label">
              <div className="subHeading">Select Cover Image</div>
            </div>
 
            {/* Hidden file input triggered by button */}
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleCoverPictureChange}
              ref={uploadInputRef}
              style={{ display: "none" }}
            />
 
            <button onClick={() => uploadInputRef.current?.click()}>
              Choose Files
            </button>
 
            {coverPicture && (
                <div className="fileItemnew">
                <span>{coverPictureName}</span>
                <IconButton onClick={handleRemoveCoverPicture} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
          {/* </div> */}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Section</div>
              </div>
              <TextField
                id="outlined-adornment-section"
                className="span"
                value={section}
                style={{ width: "100%" }}
                onChange={(event) => setSection(event.target.value)}
                placeholder="Enter section"
              />
              {validationErrors.name && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {validationErrors.section}
                </div>
              )}
            </div> */}
          <div style={{ marginBottom: "15px" }}>
            <div className="labelnew">
              <div className="subHeading">Title</div>
              <img
                src={Asterisk}
                alt="..."
                style={{
                  marginBottom: "5px",
                  position: "relative",
                  left: "5px",
                }}
              />
            </div>
            <TextField
              id="outlined-adornment-section"
              className="span"
              value={title}
              style={{ width: "100%" }}
              // onChange={(event) => setTitle(event.target.value)}
              onChange={handleChangeTitleField}
              placeholder=" Enter the post title"
              autoComplete="off"
            />
            {titleError && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                {titleError}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="labelnew">
              <div className="subHeading">Description</div>
              <img
                src={Asterisk}
                alt="..."
                style={{
                  marginBottom: "5px",
                  position: "relative",
                  left: "5px",
                }}
              />
            </div>
            <TextField
              id="outlined-adornment-section"
              className="span"
              value={description}
              style={{ width: "100%" }}
              // onChange={(event) => setDescription(event.target.value)}
              onChange={handleChangeDescriptionField}
              placeholder=" Enter a brief description of the post"
              autoComplete="off"
            />
            {descriptionError && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                {descriptionError}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="labelnew">
              <div className="subHeading">Quarter</div>
              <img
                src={Asterisk}
                alt="..."
                style={{
                  marginBottom: "5px",
                  position: "relative",
                  left: "5px",
                }}
              />
            </div>
            <div className="documentTypeWrapper">
              <Select

                autoComplete="off"
                id="outlined-adornment-documentType"
                className={`span ${quarter === "addChoice" ? "reduced-width" : ""
                  }`}
                value={quarter}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return (
                      <span style={{ color: "#CFCFCF" }}>Select the quarter</span>
                    );
                  }
                  return selected;
                }}
                style={{ width: "100%", height: "40px" }}
                // onChange={(event) =>
                //   setQuarterType(event.target.value as string)
                // }
                onChange={(event: SelectChangeEvent<string>) => {
                  const selectedValue = event.target.value as string;
                  if (selectedValue) {
                    setQuarterType(selectedValue);
                    setQuarterError("");
                  }
                }}

              >
                <MenuItem value="Q1">Q1</MenuItem>
                <MenuItem value="Q2">Q2</MenuItem>
                <MenuItem value="Q3">Q3</MenuItem>
                <MenuItem value="Q4">Q4</MenuItem>
                {/* <MenuItem value="addChoice">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddIcon style={{ marginRight: "8px" }} />
                      Add Choice Manually
                    </div>
                  </MenuItem> */}
              </Select>
              {/* {documentType === "addChoice" && (
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      id="outlined-adornment-manual-document-type"
                      className="span manual-document-type"
                      value={manualDocumentType}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setManualDocumentType(event.target.value)
                      }
                      placeholder="Enter document type"
                    />
                  </div>
                )} */}
            </div>
            {quarterError && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                {quarterError}
              </div>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <div className="labelnew">
              <div className="subHeading">Year</div>
              <img
                src={Asterisk}
                alt="..."
                style={{
                  marginBottom: "5px",
                  position: "relative",
                  left: "5px",
                }}
              />
            </div>
            <TextField
              id="outlined-adornment-section"
              className="span"
              value={year}
              style={{ width: "100%" }}
              placeholder="Enter the year"
              InputProps={{ readOnly: true }}
              autoComplete="off"
            />
            {yearError && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                {yearError}
              </div>
            )}
          </div>
          {/* <div style={{ marginBottom: "10px", flex: 1 }}>
            <div className="label">
              <div className="subHeading">Publish Date</div>
              <img
                src={Asterisk}
                alt="..."
                style={{
                  marginBottom: "5px",
                  position: "relative",
                  left: "5px",
                }}
              />
            </div>
            <TextField
              fullWidth
              id="outlined-adornment-weight"
              className="span"
              value={publishDate}
              onChange={handlePublishDateChange}
              // inputProps={{ min: getTodayDate() }}
              placeholder="Enter an event date"
              type="date"
              autoComplete="off"
            />
           {publishDateError && (
                <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                  {publishDateError}
                </div>
              )}
          </div> */}
          <div style={{ marginBottom: '10px', flex: 1 }}>
            <div className="labelnew">
              {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
              <div className="subHeading">
                Publish Date
                <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
              </div>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={publishDate}
                onChange={handlePublishDateChange}
                // maxDate={new Date()} // Setting the minimum date to the current date
                slotProps={{
                  textField: {
                    fullWidth: true,
                    autoComplete: "off",
                  },
                }}
              />
            </LocalizationProvider>
            {publishDateError && (
              <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                {publishDateError}
              </div>
            )}
          </div>
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Document Type1</div>
              </div>
              <div className="documentTypeWrapper">
                <Select
                  id="outlined-adornment-documentType"
                  className={`span ${documentType === "addChoice" ? "reduced-width" : ""
                    }`}
                  value={documentType}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(event) =>
                    setDocumentType(event.target.value as string)
                  }
                >
                  <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
                  <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
                  <MenuItem value="BROCHURES">BROCHURES</MenuItem>
                  <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
                  <MenuItem value="Policy">Policy</MenuItem>
                  <MenuItem value="Process Map">Process Map</MenuItem>
                  <MenuItem value="Supplementry Document">
                    Supplementry Document
                  </MenuItem>
                  <MenuItem value="Form">Form</MenuItem>
                  <MenuItem value="Template">Template</MenuItem>
                  <MenuItem value="Service Level Agreement">
                    Service Level Agreement
                  </MenuItem>
                  <MenuItem value="Certificate">Certificate</MenuItem>
                  <MenuItem value="addChoice">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddIcon style={{ marginRight: "8px" }} />
                      Add Choice Manually
                    </div>
                  </MenuItem>
                </Select>
                {documentType === "addChoice" && (
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      id="outlined-adornment-manual-document-type"
                      className="span manual-document-type"
                      value={manualDocumentType}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setManualDocumentType(event.target.value)
                      }
                      placeholder="Enter document type"
                    />
                  </div>
                )}
              </div>
            </div> */}
          {/* <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Applicable To</div>
              </div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Sales")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Sales"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "Sales"
                            )
                        );
                      }}
                    />
                  }
                  label="Sales"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Operations")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Operations"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "Operations"
                            )
                        );
                      }}
                    />
                  }
                  label="Operations"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("IT")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "IT"]
                            : prevApplicableTo.filter(
                              (item: string) => item !== "IT"
                            )
                        );
                      }}
                    />
                  }
                  label="IT"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("HR & Finance")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "HR & Finance"]
                            : prevApplicableTo.filter(
                              (item) => item !== "HR & Finance"
                            )
                        );
                      }}
                    />
                  }
                  label="HR & Finance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={applicableTo.includes("Health & Safety")}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setApplicableTo((prevApplicableTo) =>
                          checked
                            ? [...prevApplicableTo, "Health & Safety"]
                            : prevApplicableTo.filter(
                              (item) => item !== "Health & Safety"
                            )
                        );
                      }}
                    />
                  }
                  label="Health & Safety"
                />
              </FormGroup>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <div className="label">
                <div className="subHeading">Status</div>
              </div>
              <TextField
                id="outlined-adornment-status"
                className="span"
                value={status}
                style={{ width: "100%" }}
                onChange={(event) => setStatus(event.target.value)}
                placeholder="Enter status"
              />
            </div> */}
          <div className="actionDivTwo">
            {/* <Button
              sx={{ textTransform: "capitalize" }}
              onClick={() => setOpenUploadDialog(false)}
              className={"cancelBtnnew"}
            >
              Cancel
            </Button> */}
            {/* {selectedOption === "FILE" && isFormValid && (
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  }
                }
                }
                onClick={handleUploadFiles}
                // disabled={isUploading}
                autoFocus
                // disabled={!isFormValid}
                className="PrimaryBlueButton"
              >
                Upload Files
              </Button>
            )} */}
            {/* {selectedOption === "FOLDER" && (
              <Button
                // sx={{ textTransform: "capitalize" }}
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--redprimary)",
                  color: "white",
                  "&:hover": {
                    backgroundColor:"var(--redprimary)",
                  },
                }}
                onClick={handleCreateFolder}
                className="PrimaryBlueButton"
                disabled={!selectedFolder}
              >
                Upload Folder
              </Button>
            )} */}
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        classes={{
          paper: "newPosOfDialog",
        }}
        open={openEditDialog}
        // onClose={handleCloseEditDialog}
      >
        {/* <div>
            <Grid className="dialogTitle">
              <Grid>
                <Typography
                  className="newItem"
                  style={{ textAlign: "left", color: "#02354d" }}
                >
                  Edit Item
                </Typography>
              </Grid>
              <Grid>
                <IconButton onClick={handleCloseEditDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div> */}

        <div className="DrawerHeaderBoxNew">
          <Stack direction="row" alignItems="center" spacing={1}>
            {/* <img
              style={{ height: "20px", width: "17px" }}
              src={plusIcon}
              alt="Plus Icon"
            /> */}
            <div>
              <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff",height:"16px" }} />
            </div>
            <div className="DrawerTitle">Edit Documents</div>
          </Stack>
          {/* <div> */}
          {/* <CloseRoundedIcon
            onClick={handleCloseEditDialog}
            style={{
              cursor: "pointer",
              color: "whitesmoke",
              padding: "5px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          /> */}
             <div>
            <Stack direction="row" alignItems="center" spacing={1}>
            <div> 
              <Tooltip placement="top-start" title="Click to Update">
                <PublishIcon
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white !important",
                    height:"17px",
                    marginTop:"3px",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                    cursor: "pointer",
                  }}
                onClick={handleUpdateItem}
                className={"PrimaryBlueButton"}
                />
                </Tooltip>
              </div>
              <Tooltip placement="top-start" title="Click to Cancel">
            <CloseRoundedIcon
               onClick={handleCloseEditDialog}
              style={{
                cursor: "pointer",
                color: "whitesmoke",
                padding: "5px",
                borderRadius: "50%",
                transition: "background-color 0.3s",
                height:"16px",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
              }}
            />
            </Tooltip>
            </Stack>
            </div>
          {/* </div> */}
        </div>
        <div>
          <Divider />
          {editedItem && (
            <>
              {/* <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  <div className="subHeading">Name</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>
                <TextField
                  id="outlined-adornment-name"
                  className="span"
                  value={editedItem.name || editedItem.fileName}
                  style={{ width: "100%" }}
                  // onChange={(event) =>
                  //   handleFieldChange("name", event.target.value)
                  // }
                  InputProps={{
                    readOnly: true,
                  }}
                /> */}
              {/* <TextField
                  id="outlined-adornment-name"
                  className="span"
                  value={editName}
                  style={{ width: "100%" }}
                  // onChange={(event) =>
                  //   handleFieldChange("name", event.target.value)
                  // }
                  onChange={(e) => {
                    handleEditName(e);
                  }}
                  placeholder="Enter name"
                  autoComplete="off"
                /> */}
              {/* </div> */}
              {/* <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Section</div>
                  </div>
                  <TextField
                    id="outlined-adornment-section"
                    className="span"
                    value={editedItem.section}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      handleFieldChange("section", event.target.value)
                    }
                    placeholder="Enter section"
                  />
                </div> */}
              <div style={{ marginBottom: "15px",marginTop: "40px" }}>
                <div className="label">
                  <div className="subHeading">Title</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>

                <TextField
                  id="outlined-adornment-section"
                  className="span"
                  value={editTitle}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleEditTitle(e);
                  }}
                  //onChange={(event) => handleFieldChange("title", event.target.value)}
                  placeholder="Enter the post title"
                  autoComplete="off" // Add autoComplete attribute and set it to "off"
                />
                {editTitleError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                    {editTitleError}
                  </div>
                )}
                {/* {validationErrors.name && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {validationErrors.title}
                  </div>
                )} */}
              </div>
              <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  <div className="subHeading">Description</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>
                <TextField
                  id="outlined-adornment-section"
                  className="span"
                  value={editDescription}
                  style={{ width: "100%" }}
                  // onChange={(e) => {
                  //   handleEditDescription(e);
                  // }}
                  onChange={handleEditDescription}
                  //onChange={(event) => handleFieldChange("description", event.target.value)}
                  placeholder="Enter a brief description of the post"
                  autoComplete="off"
                />
                {editDescriptionError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                    {editDescriptionError}
                  </div>
                )}
                {/* {validationErrors.name && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {validationErrors.description}
                  </div>
                )} */}
              </div>
              <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  <div className="subHeading">Quarter</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>
                <div className="documentTypeWrapper">
                  <Select
                    autoComplete="off"
                    id="outlined-adornment-documentType"
                    className={`span ${quarter === "addChoice" ? "reduced-width" : ""
                      }`}
                    value={editQuaterValue}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(e) => {
                      handleEditQuaterValue(e);
                    }}
                  // onChange={(event) =>
                  //   handleFieldChange("quarter", event.target.value as string)
                  // }
                  >
                    <MenuItem value="Q1">Q1</MenuItem>
                    <MenuItem value="Q2">Q2</MenuItem>
                    <MenuItem value="Q3">Q3</MenuItem>
                    <MenuItem value="Q4">Q4</MenuItem>
                    {/* <MenuItem value="addChoice">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AddIcon style={{ marginRight: "8px" }} />
                      Add Choice Manually
                    </div>
                  </MenuItem> */}
                  </Select>
                  {editQuarterError && (
                    <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                      {editQuarterError}
                    </div>
                  )}
                  {/* {documentType === "addChoice" && (
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      id="outlined-adornment-manual-document-type"
                      className="span manual-document-type"
                      value={manualDocumentType}
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setManualDocumentType(event.target.value)
                      }
                      placeholder="Enter document type"
                    />
                  </div>
                )} */}
                </div>
              </div>
              <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  <div className="subHeading">Year</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{
                      marginBottom: "5px",
                      position: "relative",
                      left: "5px",
                    }}
                  />
                </div>
                <TextField
                  id="outlined-adornment-section"
                  className="span"
                  value={editYear}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    handleEditYear(e);
                  }}
                  // onChange={(event) => handleFieldChange("year", event.target.value)}
                  placeholder="Enter Year"
                  autoComplete="off"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {editYearError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                    {editYearError}
                  </div>
                )}
                {/* {editYearError && (
                    <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                      {editYearError}
                    </div>
                  )} */}
                {/* {validationErrors.name && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {validationErrors.year}
                  </div>
                )} */}
              </div>
              <div style={{ marginBottom: "10px", flex: 1 }}>

                <div style={{ marginBottom: '10px', flex: 1 }}>
                  <div className="labelnew">
                    {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
                    <div className="subHeading">
                      Publish Date
                      <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={editPublishDate}
                      onChange={(e) => {
                        handleEditPublishedDate(e);
                      }}
                      // maxDate={new Date()} // Setting the minimum date to the current date
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          autoComplete: "off",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {editPublishDateError && (
                    <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                      {editPublishDateError}
                    </div>
                  )}
                </div>
                {/* {validationErrors.name && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {validationErrors.publishDate}
                  </div>
                )} */}
              </div>

              {/* <div style={{ marginBottom: "15px" }}>
                <div className="label">
                  <div className="subHeading">Select Cover Image</div>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  //multiple
                  // value={handleEditCoverPicture}
                  onChange={handleEditCoverPicture}
                  ref={coverImageInputRef}
                />
                {coverPictureName && (
                  <div>
                    <span> {coverPictureName}</span>
                  </div>
                )}
              </div> */}
              <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  {/* <img  alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Select Cover Image
                    {/* <img
                      src={Asterisk}
                      alt="..."
                      style={{ marginBottom: "5px" }}
                    /> */}
                  </div>
                </div>
                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  <div
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDrop={handleDrop}
                  >
                    {/* <FileUpload value={files} onChange={setFiles} /> */}
                    <div>
                      <img
                        src={ByrneUpload}
                        alt=""
                        style={{ cursor: "default !important" }}
                      />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>Or</p>
                    </div>
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={handleUploadMedia}
                    />

                    {!coverPictureName && (
                      <p
                        onClick={() => fileRef.current?.click()}
                        style={{ color: "var(--redprimary)", cursor: "pointer" }}
                      >
                        Browse
                      </p>
                    )}

                    <div>
                      {coverPictureName && (
                        <>
                          <p style={{ fontSize: "12px" }}>{coverPictureName}</p>
                          <button
                            onClick={() => {
                              setCoverPicture(null);
                              setCoverPictureName("");
                              // setFileSelected("");
                              if (fileRef.current) {
                                fileRef.current.value = "";
                              }
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              borderRadius: "4px",
                              backgroundColor: "var(--blackprimary)",
                            }}
                          >
                            Clear
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
              </div>
              {/* <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Document Type</div>
                  </div>
                  <div className="documentTypeWrapper">
                    <Select
                      id="outlined-adornment-documentType"
                      className={`span ${editedItem.documentType === "addChoice"
                          ? "reduced-width"
                          : ""
                        }`}
                      value={editedItem.documentType}
                      style={{ width: "100%", height: "40px" }}
                      onChange={(event) =>
                        handleFieldChange("documentType", event.target.value)
                      }
                    >
                      <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
                      <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
                      <MenuItem value="BROCHURES">BROCHURES</MenuItem>
                      <MenuItem value="Authority Matrix">
                        Authority Matrix
                      </MenuItem>
                      <MenuItem value="Policy">Policy</MenuItem>
                      <MenuItem value="Process Map">Process Map</MenuItem>
                      <MenuItem value="Supplementry Document">
                        Supplementry Document
                      </MenuItem>
                      <MenuItem value="Form">Form</MenuItem>
                      <MenuItem value="Template">Template</MenuItem>
                      <MenuItem value="Service Level Agreement">
                        Service Level Agreement
                      </MenuItem>
                      <MenuItem value="Certificate">Certificate</MenuItem>
                      <MenuItem value="addChoice">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AddIcon style={{ marginRight: "8px" }} />
                          Add Choice Manually
                        </div>
                      </MenuItem>
                    </Select>
                    {editedItem.documentType === "addChoice" && (
                      <div style={{ marginTop: "8px" }}>
                        <TextField
                          id="outlined-adornment-manual-document-type"
                          className="span manual-document-type"
                          value={editedItem.manualDocumentType || ""}
                          style={{ width: "100%" }}
                          onChange={(event) =>
                            handleFieldChange(
                              "manualDocumentType",
                              event.target.value
                            )
                          }
                          placeholder="Enter document type"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Applicable To</div>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes("Sales")}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "Sales"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "Sales"
                                )
                            );
                          }}
                        />
                      }
                      label="Sales"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes("Operations")}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "Operations"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "Operations"
                                )
                            );
                          }}
                        />
                      }
                      label="Operations"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes("IT")}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "IT"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "IT"
                                )
                            );
                          }}
                        />
                      }
                      label="IT"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes(
                            "HR & Finance"
                          )}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [
                                  ...editedItem.applicableTo,
                                  "HR & Finance",
                                ]
                                : editedItem.applicableTo.filter(
                                  (item: string) =>
                                    item !== "HR & Finance"
                                )
                            );
                          }}
                        />
                      }
                      label="HR & Finance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={editedItem.applicableTo.includes(
                            "Health & Safety"
                          )}
                          onChange={(event) => {
                            const checked = event.target.checked;
                            handleFieldChange(
                              "applicableTo",
                              checked
                                ? [...editedItem.applicableTo, "Health & Safety"]
                                : editedItem.applicableTo.filter(
                                  (item: string) => item !== "Health & Safety"
                                )
                            );
                          }}
                        />
                      }
                      label="Health & Safety"
                    />
                  </FormGroup>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <div className="label">
                    <div className="subHeading">Status</div>
                  </div>
                  <TextField
                    id="outlined-adornment-status"
                    className="span"
                    value={editedItem.status}
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      handleFieldChange("status", event.target.value)
                    }
                    placeholder="Enter status"
                  />
                </div> */}
              <div className="actionDivTwo">
                {/* <Button
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleCloseEditDialog}
                  className={"cancelBtnnew"}
                >
                  Cancel
                </Button> */}
                {/* <Button
                  // sx={{ textTransform: "capitalize" }}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--blackprimary)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "var(--blackprimary)",
                    },
                  }}
                  onClick={handleUpdateItem}
                  className="PrimaryBlueButton"
                  disabled={isUploading}
                >
                  Update
                </Button> */}
              </div>
            </>
          )}
        </div>
      </Drawer>
      {content}
      {/* old code */}
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
            currentPage * itemsPerPage,
            folderContents.length
          )} of ${folderContents.length}`}
        </Typography>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {renderPageNumbers()}
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box> */}

      {/* new code */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2" sx={{ mr: 2 }}>
          {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
            currentPage * itemsPerPage,
            filteredData.length > 0 ? filteredData.length : folderContents.length
          )} of ${filteredData.length > 0 ? filteredData.length : folderContents.length}`}
        </Typography>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {renderPageNumbers()}
        <IconButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>



      {/* <Stack className="paginationBottom" spacing={2}>
          <Pagination
            count={
              folderContents.length === 0
                ? 1
                : Math.ceil(folderContents.length / itemsPerPage)
            }
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#e0001b", // Change the color of the selected pagination button
                color: "#ffffff", // Set text color to white
                position: "relative", // Ensure proper positioning for pseudo-element
              },
              "& .MuiPaginationItem-root.Mui-selected::after": {
                content: '""',
                position: "absolute",
                bottom: "-7px", // Adjust the position of the line as needed
                left: 0,
                width: "100%",
                height: "3px",
                backgroundColor: "#e0001b", // Set the color of the line to red
                borderRadius: "2px", // Add border-radius to the line
              },
              "& .MuiPaginationItem-root.Mui-selected:hover": {
                backgroundColor: "#e0001b", // Override hover effect color
              },
            }}
          />
        </Stack> */}

      <FilePreviewDialog
        open={openPreviewDialogPreview}
        onClose={handleClosePreview}
        selectedFile={selectedFilePreview}
        fileType={fileType}
        filePath={filePath}
        selectedFolderId={selectedFolderId}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#8c8c8f",
                fontWeight: "600",
                paddingBottom: "10px",
                paddingLeft: "10px",
                // marginRight: "-2510px",
              }}
            >
              Filter
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              className="filterLabels"
              key={"selectall"}
              control={
                <Checkbox
                  checked={isChecked}
                  color="default"
                  style={{ padding: "0px 9px 0px" }}
                />
              }
              label={"Select All"}
              onClick={() => handleSelectAll()}
              style={{
                // color: selectedLabels.includes(label)
                //   ? eventsReferenceColors[label]
                //   : "#8c8f",
                fontSize: "12px!important",
              }}
            />
            {yearsData &&
              yearsData.map((label: any) => (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={selectedLabels.includes(label)}
                      color="default"
                      style={{ padding: "0px 9px 0px" }}
                    />
                  }
                  label={label}
                  className={
                    selectedLabels.includes(label)
                      ? "filterLabels selected-label"
                      : "filterLabels"
                  }
                  onClick={() => handleLabelClick(label)}
                  style={{
                    // color: selectedLabels.includes(label)
                    //   ? eventsReferenceColors[label]
                    //   : "#8c8f",
                    fontSize: "12px!important",
                  }}
                />
              ))}
          </FormGroup>
        </div>
        <Button
          className="createtwo"
          variant="contained"
          // onClick={handleClose}
          onClick={handleApplyFilter}
          style={{
            //   fontSize:"10px",
            //   height:"20px",
            textTransform: "none",
            //   backgroundColor: '#e0001b',
            //   marginLeft: 'auto',
          }}
        >
          Apply
        </Button>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "14px",
                color: "#8c8c8f",
                fontWeight: "600",
                paddingBottom: "10px",
                paddingLeft: "10px",
                // marginRight: "-2510px",
              }}
            >
              Filter
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              className="filterLabels"
              key={"selectall"}
              control={
                <Checkbox
                  checked={isChecked2}
                  color="default"
                  style={{ padding: "0px 9px 0px" }}
                />
              }
              label={"Select All"}
              onClick={() => handleSelectAll2()}
              style={{
                // color: selectedLabels.includes(label)
                //   ? eventsReferenceColors[label]
                //   : "#8c8f",
                fontSize: "12px!important",
              }}
            />
            {applicableToData &&
              applicableToData.map((label: any) => (
                <FormControlLabel
                  key={label}
                  control={
                    <Checkbox
                      checked={selectedLabels.includes(label)}
                      color="default"
                      style={{ padding: "0px 9px 0px" }}
                    />
                  }
                  label={label}
                  className={
                    selectedLabels.includes(label)
                      ? "filterLabels selected-label"
                      : "filterLabels"
                  }
                  onClick={() => handleLabelClick2(label)}
                  style={{
                    // color: selectedLabels.includes(label)
                    //   ? eventsReferenceColors[label]
                    //   : "#8c8f",
                    fontSize: "12px!important",
                  }}
                />
              ))}
          </FormGroup>
        </div>
        <Button
          className="createtwo"
          variant="contained"
          // onClick={handleClose}
          onClick={handleApplyFilter2}
          style={{
            //   fontSize:"10px",
            //   height:"20px",
            textTransform: "none",
            //   backgroundColor: '#e0001b',
            //   marginLeft: 'auto',
          }}
        >
          Apply
        </Button>
      </Menu>
      <div>
        <Dialog open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <div className="DrawerDescriptionHeaderBox">
            <div style={{ color: "var(--redprimary)" }}>
              <DialogTitle>Description</DialogTitle>
            </div>
            <div style={{ marginRight: "30px", color: "#9D9B9E", cursor: "pointer" }}>
              <CancelIcon
                onClick={handleCloses} />
            </div>
          </div>
          <DialogContent>
            <div style={{ color: "#605D62" }} dangerouslySetInnerHTML={{ __html: dialogContent }} />
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default PostsEditorPage;

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Checkbox,
//   CircularProgress,
//   Divider,
//   Drawer,
//   FormControl,
//   FormControlLabel,
//   FormGroup,
//   Grid,
//   TextField,
//   Typography,
//   Snackbar,
//   MenuItem,
//   Select,
//   Radio,
//   RadioGroup,
//   IconButton,
//   DialogActions,
//   LinearProgress,
//   Pagination,
//   Stack,
//   Menu,
//   Tooltip,
// } from "@mui/material";
// import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
// import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
// import AddIcon from "@mui/icons-material/Add";
// import FolderIcon from "@mui/icons-material/Folder";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import CloseIcon from "@mui/icons-material/Close";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
// import Asterisk from "../../../Assets/Images/Asterisk.svg";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
// } from "@mui/material";
// import {
//   DataGrid,
//   GridRowId,
//   GridRenderCellParams,
//   GridRowParams,
//   GridColumnHeaderParams,
//   GridColDef,
// } from "@mui/x-data-grid";
// import {
//   useUploadPostDataMutation,
//   useCreatePostsFolderMutation,
//   useDeletePostItemMutation,
//   useGetPostItemsInFolderQuery,
//   useGetTopLevelPostItemsQuery,
//   useUpdatePostItemMutation,
//   useUploadGalleryFoldersMediaMutation,
// } from "../../../Services/APIs";
// import AlertYesNo from "../../Hooks/DialogYesNo";
// import { Box } from "@mui/material";
// import uploadblue from "../../../Assets/Images/uploadblue.svg";
// import FolderPath from "./PoliciesFolderPath";
// import { nanoid } from "nanoid";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import "./Styles.css";
// import folder from "../../../Assets/Images/folder.svg";
// import filee from "../../../Assets/Images/filee.svg";
// import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import calenderIcon from "../../../Assets/Images/calenderGrey.svg";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import LoaderComponent from "../../../hooks/LoaderComponent"; // Adjust the path as necessary
// import FilterListIcon from '@mui/icons-material/FilterList';
// import FilterMenu from "../../../hooks/FilterMenu";
// import SearchBar from '../../../hooks/SearchBar';
// interface CustomRowModel extends GridValidRowModel {
//   _id: string;
//   [key: string]: any; // Add any other properties that your rows might have
// }

// interface GridValidRowModel {
//   id: number;
//   title: string;
//   year:any;
//   publishDate:any;
//   status:any;
//   quarter:any;
//   isActive: boolean;
//   // Add other fields as needed
// }

// interface Posts {
//   title: any;
//   _id: string;
//   fileName: string;
//   fileUrl: string;
//   quarter: string;
//   year: string;
//   description: string;
//   releaseDate: any;
//   thumbnail: string;
//   coverPicture:string;
//   createdAt:string;
//   // newsTitle: string;
//   // newsImage: string;
//   // newsDescription: string;
//   // createdDate: string;
//   // isActive: boolean;
// }
// const PostsEditorPage = (props: any) => {
//   const { departmentsId, selectedDepartmentName } = props;

//   const [uploadPoliciesData] = useUploadPostDataMutation();
//   const [createPolicyFolder] = useCreatePostsFolderMutation();
//   const [deletePolicyItem] = useDeletePostItemMutation();
//   const [updatePolicyItem] = useUpdatePostItemMutation();
//   const {
//     data: topLevelItemsData,
//     isLoading: isTopLevelItemsLoading,
//     isError: isTopLevelItemsError,
//     refetch: refetchTopLevelItems,
//     isSuccess,
//   } = useGetTopLevelPostItemsQuery(undefined, {
//     selectFromResult: (result: any) => {
//       return result;
//     },
//   });

//   const sortedPosts =
//   topLevelItemsData ? [...topLevelItemsData].sort(
//       (a: Posts, b: Posts) =>
//         new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
//     ) : [];

//     console.log(sortedPosts,"sorted posts")
//   interface ImageCellProps {
//     value?: string; // URL of the image
//     active?: boolean; // Optional prop for indicating active state
//   }
//   const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
//     value && value.trim() !== "---" ? (
//       <img src={value} alt="" style={{ width: "30%", height: "auto" }} />
//     ) : (
//       <span>----</span>
//     );

//   // const topLevelItems = topLevelItemsData?.filter((i: any) => {
//   //   return i?.departmentId == departmentsId
//   // })
//   const topLevelItems = topLevelItemsData;
//   console.log(topLevelItemsData, topLevelItems, "topLevelItem11");

//   const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

//   const { data: policyItems, refetch: refetchPolicyItems } =
//     useGetPostItemsInFolderQuery(selectedFolderId || "");

//   const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
//   const [openUploadDialog, setOpenUploadDialog] = useState(false);
//   const [uploadOption, setUploadOption] = useState<"FILE" | "FOLDER" | null>(
//     null
//   );

//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
//   const [folderName, setFolderName] = useState("");
//   const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [alertActivation, setAlertActivation] = useState(false);
//   const [alertContent, setAlertContent] = useState("");

//   const [section, setSection] = useState("");
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [publishDate, setpublishDate] = useState("");
//   const [year, setYear] = useState("");
//   const [documentType, setDocumentType] = useState("");
//   const [quarter, setQuarterType] = useState("");
//   const [applicableTo, setApplicableTo] = useState<string[]>([]);
//   const [status, setStatus] = useState("");

//   const [selectedFolder, setSelectedFolder] = useState<any | null>(null);
//   const [folderContents, setFolderContents] = useState<any[]>([]);

//   const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
//     []
//   );

//   const [editedItem, setEditedItem] = useState<any | null>(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);

//   const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
//   const [selectedOption, setSelectedOption] = useState<
//     "FILE" | "FOLDER" | null
//   >(null);
//   const [manualDocumentType, setManualDocumentType] = useState("");

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [isUploading, setIsUploading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(15);
//   const [validationErrors, setValidationErrors] = useState({
//     name: "",
//     section: "",
//     title: "",
//     year: "",
//     description: "",
//     publishDate: "",
//     documentType: "",
//     quarter: "",
//     applicableTo: "",

//     status: "",
//   });

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   // const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
//   const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
//   const [openDialog2, setOpenDialog2] = useState(false);
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const [uploadGalleryFoldersMedia, { isLoading, isError }] =
//     useUploadGalleryFoldersMediaMutation();
//   const [coverPicture, setCoverPicture] = useState<File>();

//   const [editName, setEditName] = useState("");
//   const [editTitle, setEditTitle] = useState("");
//   const [editDescription, setEditDescription] = useState("");
//   const [editPublishDate, setEditPublishDate] = useState("");
//   console.log(editPublishDate, "editPublishDate");
//   const [editYear, setEditYear] = useState("");
//   const [editQuaterValue, setEditQuaterValue] = useState("");

//   //use states for validation
//   const [isFormValid, setIsFormValid] = useState(false);
//   const [titleError, setTitleError] = useState("");
//   const [descriptionError, setDescriptionError] = useState("");
//   const [quarterError, setQuarteError] = useState("");
//   const [yearError, setYearError] = useState("");
//   const [publishDateError, setPublishDateError] = useState("");
//   const [imageError, setImageError] = useState("");
//   const [isLoadingg, setIsLoadingg] = useState(false);
//   const showLoader = () => setIsLoadingg(true);
//   const hideLoader = () => setIsLoadingg(false);

//   // const [currentPage, setCurrentPage] = useState(1);
//   // const [itemsPerPage] = useState(7);
//   const [rows, setRows] = useState<CustomRowModel[]>([]);
//   const [filteredRows, setFilteredRows] = useState<CustomRowModel[]>([]);
//   const [paginatedRows, setPaginatedRows] = useState<CustomRowModel[]>([]);
//   const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
//   const [filterField, setFilterField] = useState<string | null>(null);
//   const [searchTerm, setSearchTerm] = useState<string>('');
//   const totalPages = Math.ceil(filteredRows.length / itemsPerPage);

//   useEffect(() => {
//     setFilteredRows(rows);
//   }, [rows]);

//   useEffect(() => {
//     applyFiltersAndSearch();
//   }, [searchTerm, rows, filterField]);

//   useEffect(() => {
//     paginateRows();
//   }, [currentPage, itemsPerPage, filteredRows]);

//   const applyFiltersAndSearch = () => {
//     let newFilteredRows = rows;

//     if (searchTerm) {
//       newFilteredRows = newFilteredRows.filter(row =>
//         columns.some(column =>
//           String(row[column.field as keyof CustomRowModel]).toLowerCase().includes(searchTerm.toLowerCase())
//         )
//       );
//     }

//     if (filterField) {
//       newFilteredRows = newFilteredRows.filter(row =>
//         (row[filterField as keyof typeof row] || '').toString().toLowerCase().includes(filterField.toLowerCase())
//       );
//     }

//     setFilteredRows(newFilteredRows);
//     setCurrentPage(1); // Reset to first page on filter or search change
//   };

//   const paginateRows = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const paginated = filteredRows.slice(startIndex, startIndex + itemsPerPage);
//     setPaginatedRows(paginated);
//   };

//   const handlePrevPage = () => {
//     setCurrentPage((prev) => Math.max(1, prev - 1));
//   };

//   const handleNextPage = () => {
//     setCurrentPage((prev) => Math.min(totalPages, prev + 1));
//   };

//   const handlePageClick = (page: number) => {
//     setCurrentPage(page);
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(
//         <Typography
//           key={i}
//           variant="body2"
//           sx={{
//             width: 30,
//             height: 30,
//             borderRadius: "4px",
//             backgroundColor: currentPage === i ? "#e0001b" : "transparent",
//             color: currentPage === i ? "white" : "inherit",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             cursor: "pointer",
//             mx: 0.5,
//           }}
//           onClick={() => handlePageClick(i)}
//         >
//           {i}
//         </Typography>
//       );
//     }
//     return pageNumbers;
//   };

//   const handleFilterIconClick = (event: React.MouseEvent<HTMLButtonElement>, field: string) => {
//     setFilterAnchorEl(event.currentTarget);
//     setFilterField(field);
//   };

//   const handleFilterClose = () => {
//     setFilterAnchorEl(null);
//     setFilterField(null);
//   };

//   const handleFilterChange = (field: string, selectedOptions: string[]) => {
//     const newFilteredRows = rows.filter((row) => {
//       return selectedOptions.includes((row as any)[field]);
//     });
//     setFilteredRows(newFilteredRows);
//     setCurrentPage(1); // Reset to first page on filter change
//   };

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   const handleClearSearch = () => {
//     setSearchTerm('');
//   };

//   useEffect(() => {
//     if (isSuccess && topLevelItemsData) {
//       const formattedRows = topLevelItemsData?.map((event: any, index: number) => ({
//         id: event?._id,
//         Id: index + 1,
//         fileName:event?.name,
//         title: event?.title,
//         year:event?. year,
//         description:event?. description,
//         publishDate:event?. publishDate,
//         documentType:event?. documentType,
//         quarter:event?. quarter,
//         applicableTo: event?.applicableTo.join(","),
//         status:event?. status,
//         coverPicture:event?. coverPicture,
//       }));
//       setRows(formattedRows);
//       setFilteredRows(formattedRows);
//       setIsLoadingg(false);
//     }
//   }, [isSuccess, topLevelItemsData]);

//   const handleEditTitle = (event: any) => {
//     setEditTitle(event.target.value);
//   };
//   const handleEditDescription = (event: any) => {
//     setEditDescription(event.target.value);
//   };
//   const handleEditName = (event: any) => {
//     setEditName(event.target.value);
//   };
//   const handleEditPublishedDate = (event: any) => {
//     console.log(event.target.value, "targetss");
//     setEditPublishDate(event.target.value);
//   };
//   const handleEditYear = (event: any) => {
//     setEditYear(event.target.value);
//   };
//   const handleEditQuaterValue = (event: any) => {
//     setEditQuaterValue(event.target.value as string);
//   };

//   useEffect(() => {
//     if (selectedFolderId) {
//       if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
//         setFolderContents(policyItems);
//       }
//     } else {
//       if (sortedPosts && !isTopLevelItemsLoading && !isTopLevelItemsError) {
//         setFolderContents(sortedPosts || []);
//       }
//     }
//   }, [
//     selectedFolderId,
//     policyItems,
//     sortedPosts,
//     isTopLevelItemsLoading,
//     isTopLevelItemsError,
//   ]);

//   useEffect(() => {
//     if (!openNewFolderDialog && !openUploadDialog && !openEditDialog) {
//       resetValues();
//     }
//   }, [openNewFolderDialog, openUploadDialog, openEditDialog]);

//   const handleFolderClick = (folderId: string | null) => {
//     if (folderId) {
//       const selectedFolder = folderContents.find(
//         (item: any) => item._id === folderId
//       );
//       if (selectedFolder) {
//         console.log(
//           `Folder clicked: ${selectedFolder.name} (${selectedFolder._id})`
//         );
//         setSelectedFolderId(selectedFolder._id as string);
//         setFolderPath((prevPath) => {
//           const folderIndex = prevPath.findIndex(
//             (folder) => folder.id === folderId
//           );
//           if (folderIndex !== -1) {
//             return prevPath.slice(0, folderIndex + 1);
//           }
//           return [
//             ...prevPath,
//             { id: selectedFolder._id, name: selectedFolder.name },
//           ];
//         });
//         refetchPolicyItems();
//         console.log("Refetching folder items");
//       }
//     } else {
//       console.log("Root folder clicked");
//       setSelectedFolderId(null);
//       setFolderPath([]);
//       refetchPolicyItems();
//       console.log("Refetching top-level items");
//     }
//   };

//   const handleGoBack = () => {
//     if (folderPath.length > 0) {
//       const updatedPath = folderPath.slice(0, -1);
//       setFolderPath(updatedPath);
//       const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
//       setSelectedFolderId(previousFolderId);
//       refetchPolicyItems();
//     }
//   };

//   const handleFolderSelection = async () => {
//     console.log("Attempting to select a folder...");
//     try {
//       const directoryHandle = await window.showDirectoryPicker();
//       console.log("Folder selected:", directoryHandle.name);
//       setSelectedFolder(directoryHandle);
//     } catch (error) {
//       console.error("Error selecting folder:", error);
//     }
//   };
//   const handleCoverPictureChange = (event: any) => {
//     setCoverPicture(event.target.files[0]);
//   };
//   const processSelectedFolder = async (
//     directoryHandle: FileSystemDirectoryHandle
//   ) => {
//     try {
//       setIsUploading(true);
//       setUploadProgress(0);

//       const totalItems = await countFolderItems(directoryHandle);
//       let processedItems = 0;

//       await processDirectory(
//         directoryHandle,
//         "",
//         selectedFolderId || "",
//         (progress) => {
//           processedItems++;
//           const percentage = Math.round((processedItems / totalItems) * 100);
//           setUploadProgress(percentage);
//         }
//       );

//       setIsUploading(false);
//       console.log("Folder processing completed.");
//     } catch (error) {
//       setIsUploading(false);
//       console.error("Error processing folder:", error);
//     }
//   };

//   const countFolderItems = async (
//     directoryHandle: FileSystemDirectoryHandle
//   ): Promise<number> => {
//     let count = 0;

//     for await (const entry of directoryHandle.values()) {
//       count++;
//       if (entry.kind === "directory") {
//         count += await countFolderItems(entry);
//       }
//     }

//     return count;
//   };

//   const processDirectory = async (
//     directoryHandle: FileSystemDirectoryHandle,
//     basePath: string,
//     parentFolderId: string,

//     onProgress: (progress: number) => void
//   ) => {
//     for await (const entry of directoryHandle.values()) {
//       const path = `${basePath}/${entry.name}`;
//       if (entry.kind === "file") {
//         const file = await entry.getFile();
//         await uploadFile(path, file, parentFolderId);
//         onProgress(1);
//       } else if (entry.kind === "directory") {
//         const newFolderId = await createDirectory(path, parentFolderId);
//         await processDirectory(entry, path, newFolderId, onProgress);
//       }
//     }
//   };

//   const createDirectory = async (path: string, parentId: string) => {
//     console.log(`Creating directory: ${path}`);
//     const pathParts = path.split("/").filter((part) => part !== "");
//     const folderName = pathParts[pathParts.length - 1];

//     const existingFolder = folderContents.find(
//       (item: any) => item.name === folderName && item.parentId === parentId
//     );

//     if (existingFolder) {
//       return existingFolder._id;
//     } else {
//       const newFolder = await createPolicyFolder({
//         name: folderName,
//         parentId: parentId,
//         section: section,
//         title: title,
//         year: year,
//         description: description,
//         publishDate: publishDate,
//         documentType: documentType,
//         quarter: quarter,
//         applicableTo: applicableTo.join(","),
//         status: status,
//         //@ts-ignore
//         departmentId: departmentsId,
//         departmentName: selectedDepartmentName,
//       }).unwrap();
//       return newFolder._id;
//     }
//   };
//   console.log(departmentsId, "departmentsId", selectedDepartmentName);
//   const uploadFile = async (
//     path: string,
//     file: File,
//     parentFolderId: string
//   ) => {
//     console.log(`Uploading file: ${path}`);
//     const formData = new FormData();
//     formData.append("policiesItem", file);
//     formData.append("parentId", parentFolderId);
//     formData.append("section", section);
//     formData.append("title", title);
//     formData.append("year", year);
//     formData.append("description", description);
//     formData.append("publishDate", publishDate);
//     formData.append("documentType", documentType);
//     formData.append("quarter", quarter);
//     formData.append("applicableTo", applicableTo.join(","));
//     formData.append("status", status);
//     formData.append("departmentId", departmentsId);
//     formData.append("departmentName", selectedDepartmentName);
//     try {
//       await uploadPoliciesData(formData);
//       console.log("File uploaded successfully");
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };

//   const handleCreateFolder = async () => {
//     if (selectedFolder) {
//       try {
//         await processSelectedFolder(selectedFolder);
//         setFolderName("");
//         setSection("");
//         setTitle("");
//         setDescription("");
//         setpublishDate("");
//         setDocumentType("");
//         setStatus("");
//         setUploadedFiles([]);
//         setOpenUploadDialog(false);

//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }

//         setSnackbarMessage("Folder and its contents created successfully");
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error("Error creating folder and its contents:", error);
//         setSnackbarMessage("Error creating folder and its contents");
//         setSnackbarOpen(true);
//       }
//     }
//   };
//   const handleChangeTitleField = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const inputValue = event.target.value;
//     const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

//     if (regex.test(inputValue) && inputValue.length <= 170) {
//       // If the input passes the validation and is within the character limit, update the state
//       setTitle(inputValue);
//       setTitleError("");
//     } else if (inputValue.length > 170) {
//       // If the input exceeds the character limit, set an error message
//       setTitleError("Title cannot exceed 170 characters.");
//     } else {
//       // If the input contains invalid characters, set an error message
//       setTitleError("Please enter only letters, numbers, and spaces.");
//     }
//   };
//   // const validateForm = () => {
//   //   let errors = {
//   //     name: "",
//   //     section: "",
//   //     title: "",
//   //     year: "",
//   //     description: "",
//   //     publishDate:"",
//   //     documentType: "",
//   //     quarter: "",
//   //     applicableTo: "",
//   //     status: "",
//   //   };

//   //   if (!folderName.trim()) {
//   //     errors.name = "Name is required";
//   //   }
//   //   if (!section.trim()) {
//   //     errors.section = "Section is required";
//   //   }
//   //   if (!title.trim()) {
//   //     errors.title = "title is required";
//   //   }
//   //   if (!year.trim()) {
//   //     errors.year = "year is required";
//   //   }
//   //   if (!description.trim()) {
//   //     errors.description = "description is required";
//   //   }
//   //   if (!publishDate.trim()) {
//   //     errors.publishDate = "publishDate is required";
//   //   }
//   //   if (!documentType.trim()) {
//   //     errors.documentType = "Document Type is required";
//   //   }
//   //   if (!quarter.trim()) {
//   //     errors.quarter = "Quarter Type is required";
//   //   }
//   //   if (applicableTo.length === 0) {
//   //     errors.applicableTo = "Applicable To is required";
//   //   }
//   //   if (!status.trim()) {
//   //     errors.status = "Status is required";
//   //   }

//   //   setValidationErrors(errors);
//   //   return Object.values(errors).every((error) => error === "");
//   // };
//   const resetFormFields = () => {
//     setTitleError("");
//     setQuarteError("");
//     setDescriptionError("");
//     setYearError("");
//     setImageError("");
//     setPublishDateError("");
//   };
//   const resetAllErrorAlert = () => {
//     setTitleError("");
//     setQuarteError("");
//     setDescriptionError("");
//     setYearError("");
//     setImageError("");
//     setPublishDateError("");
//     // setDeginError("");
//   };
//   const validateForm = () => {
//     let formIsValid = true;

//     if (title === "") {
//       setTitleError("Title is required");
//       formIsValid = false;
//     } else {
//       setTitleError(""); // Clear error when Title is valid
//     }

//     if (
//       description === "" ||
//       description.replace(/<[^>]*>/g, "").trim() === ""
//     ) {
//       setDescriptionError("Description is required");
//       formIsValid = false;
//     } else {
//       setDescriptionError(""); // Clear error when Description is valid
//     }
//     if (quarter === "") {
//       setQuarteError("Quarter is required");
//       formIsValid = false;
//     } else {
//       setQuarteError(""); // Clear error when Description is valid
//     }
//     if (year === "") {
//       setYearError("Year is required");
//       formIsValid = false;
//     } else {
//       setYearError(""); // Clear error when Description is valid
//     }

//     if (selectedFile === null && uploadedFiles.length == 0) {
//       setImageError("File is required");
//       formIsValid = false;
//     } else {
//       setImageError(""); // Clear error when selectedFiles is valid
//     }
//     if (publishDate === "") {
//       setPublishDateError("Publish date is required");
//       formIsValid = false;
//     } else {
//       setPublishDateError(""); // Clear error when eventDate is valid
//     }
//     setIsFormValid(formIsValid); // Update the form validation state
//     return {
//       formIsValid,
//     };
//   };

//   useEffect(() => {
//     validateForm();
//   }, [
//     title,
//     description,
//     uploadedFiles,
//     publishDate,
//     quarter,
//     year,
//     selectedFile,
//   ]);
//   console.log(uploadedFiles, "selectedFile", selectedFile);

//   const handleCreateFolderNew = async () => {
//     if (!validateForm()) {
//       return;
//     }

//     try {
//       const folderData = {
//         name: folderName,
//         parentId: selectedFolderId,
//         section: section,
//         title: title,
//         year: year,
//         description: description,
//         publishDate: publishDate,
//         documentType:
//           documentType === "addChoice" && manualDocumentType !== ""
//             ? manualDocumentType
//             : documentType,
//         quarter: quarter,
//         applicableTo: applicableTo.join(","),
//         status: status,
//         departmentId: departmentsId,
//         departmentName: selectedDepartmentName,
//       };

//       const createdFolder = await createPolicyFolder(folderData).unwrap();

//       setFolderName("");
//       setSection("");
//       setTitle("");
//       setDescription("");
//       setpublishDate("");
//       setDocumentType("");
//       setStatus("");
//       setUploadedFiles([]);

//       setOpenNewFolderDialog(false);

//       if (selectedFolderId) {
//         refetchPolicyItems();
//       } else {
//         refetchTopLevelItems();
//       }

//       setSnackbarMessage("Folder created successfully");
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error creating folder:", error);
//       setSnackbarMessage("Error creating folder");
//       setSnackbarOpen(true);
//     }
//   };

//   const handleFileChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (event.target.files && event.target.files.length > 0) {
//       setSelectedFile(event.target.files[0]);
//     }
//   };

//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     if (files) {
//       const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
//       const unsupportedFiles = Array.from(files).filter((file) => {
//         const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
//         return !supportedExtensions.includes(fileExtension);
//       });
//       if (unsupportedFiles.length > 0) {
//         window.alert(
//           "Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported"
//         );
//         event.target.value = "";
//         return;
//       }
//       const acceptedFiles = Array.from(files).filter((file) => {
//         const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
//         return supportedExtensions.includes(fileExtension);
//       });
//       setUploadedFiles(acceptedFiles);
//     }
//   };

//   const handleUploadFiles = async () => {
//     resetAllErrorAlert();
//     const { formIsValid } = validateForm();
//     if (!formIsValid) {
//       // There are validation errors, set errors state and don't submit the form
//       return;
//     }
//     if (uploadedFiles.length > 0) {
//       setIsUploading(true);
//       // const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
//       const supportedExtensions = ["pdf"];
//       const unsupportedFiles = uploadedFiles.filter((file) => {
//         const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
//         return !supportedExtensions.includes(fileExtension);
//       });

//       if (unsupportedFiles.length > 0) {
//         window.alert("Unsupported file type. Only PDF supported");
//         setIsUploading(false);
//         return;
//       }

//       const formData = new FormData();
//       uploadedFiles.forEach((file) => {
//         formData.append("policiesItem", file);
//       });
//       if (coverPicture) {
//         formData.append("coverPicture", coverPicture);
//       }
//       formData.append("parentId", selectedFolderId || "");
//       formData.append("section", section);
//       formData.append("title", title),
//         formData.append("year", year),
//         formData.append("description", description),
//         formData.append("publishDate", publishDate),
//         formData.append(
//           "documentType",
//           documentType === "addChoice" && manualDocumentType !== ""
//             ? manualDocumentType
//             : documentType
//         );
//       formData.append("quarter", quarter);
//       formData.append("applicableTo", applicableTo.join(","));
//       formData.append("status", status);
//       formData.append("departmentId", departmentsId);
//       formData.append("departmentName", selectedDepartmentName);

//       try {
//         await uploadPoliciesData(formData);
//         setUploadedFiles([]);
//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }
//         setOpenUploadDialog(false);
//         setSnackbarMessage("Files uploaded successfully");
//         setSnackbarOpen(true);
//         resetFormFields();
//       } catch (error) {
//         console.error("Error uploading files:", error);
//         setSnackbarMessage("Error uploading files");
//         setSnackbarOpen(true);
//       } finally {
//         setIsUploading(false); // Re-enable the button after the upload process completes
//       }
//     }
//   };

//   // const handleEditIssueNo = (event:any) =>{
//   //   setEditIssueNumber(event.target.value)
//   // }
//   const formatDate = (dateString: any) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };
//   const handleEditItem = (itemId: string) => {
//     const item = folderContents.find((item) => item._id === itemId);
//     console.log(item, "handleEditItem");
//     setCoverPicture(item?.coverPicture);
//     setEditName(item?.fileName);
//     setEditTitle(item?.title);
//     setEditDescription(item?.description);
//     // setEditPublishDate(item?.publishDate)
//     setEditPublishDate(formatDate(item?.publishDate));
//     setEditYear(item?.year);
//     setEditQuaterValue(item?.quarter);
//     // setEditIssueNumber(item?.issueNumber)
//     setEditTitle(item?.title);
//     if (item) {
//       const documentTypes = [
//         "CASE STUDIES",
//         "PRESENTATION",
//         "BROCHURES",
//         "Authority Matrix",
//         "Policy",
//         "Process Map",
//         "Supplementry Document",
//         "Form",
//         "Template",
//         "Service Level Agreement",
//         "Certificate",
//       ];
//       const quarterTypes = ["Q1", "Q2", "Q3", "Q4"];
//       if (quarterTypes.includes(item.quarter)) {
//         setEditedItem(item);
//       }
//       // } else {
//       //   setEditedItem({
//       //     ...item,
//       //     documentType: "addChoice",
//       //     manualDocumentType: item.documentType,
//       //   });
//       // }

//       setOpenEditDialog(true);
//     }
//   };

//   const handleFieldChange = (field: string, value: any) => {
//     if (field === "applicableTo") {
//       setEditedItem((prevItem: any) => ({
//         ...prevItem,
//         applicableTo: value,
//       }));
//     } else {
//       setEditedItem((prevItem: any) => ({
//         ...prevItem,
//         [field]: value,
//       }));
//     }
//   };
//   console.log(editedItem, "editedItem");
//   const handleUpdateItem = () => {
//     console.log(editTitle, coverPicture, editedItem._id, "handleUpdateItem");
//     const formData = new FormData();
//     formData.append("title", editTitle);
//     formData.append("description", editDescription);
//     formData.append("name", editName);
//     formData.append("publishDate", editPublishDate);
//     formData.append("year", editYear);
//     //@ts-ignore
//     formData.append("coverPicture", coverPicture);
//     formData.append("Quarter", editQuaterValue);
//     formData.append("id", editedItem._id);
//     console.log(formData, "formData");
//     //@ts-ignore
//     updatePolicyItem(formData);

//     refetchTopLevelItems();
//     refetchPolicyItems();
//     setSnackbarMessage("Changes have been published successfully");
//     setSnackbarOpen(true);
//     setOpenEditDialog(false);
//     setEditedItem(null);
//   };
//   const handleUpdateItemx = async () => {
//     if (editedItem) {
//       try {
//         const updatedData = {
//           name: editedItem.name || editedItem.fileName,
//           section: editedItem.section,
//           title: editedItem.title,
//           year: editedItem.year,
//           description: editedItem.description,
//           publishDate: editedItem.publishDate,
//           quarter: editedItem.quarter,
//           documentType:
//             editedItem.documentType === "addChoice" &&
//               editedItem.manualDocumentType !== ""
//               ? editedItem.manualDocumentType
//               : editedItem.documentType,
//           applicableTo: editedItem.applicableTo,
//           status: editedItem.status,
//           coverPicture: coverPicture,
//         };

//         // await updatePolicyItem({
//         //   id: editedItem._id,
//         //   data: updatedData,
//         // }).unwrap();

//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }

//         setSnackbarMessage("Item updated successfully");
//         setSnackbarOpen(true);
//         setOpenEditDialog(false);
//         setEditedItem(null);
//       } catch (error) {
//         console.error("Error updating item:", error);
//         setSnackbarMessage("Error updating item");
//         setSnackbarOpen(true);
//       }
//     }
//   };

//   const handleCloseNewFolderDialog = () => {
//     setOpenNewFolderDialog(false);
//     setUploadOption(null);
//     resetValues();
//   };

//   const handleCloseEditDialog = () => {
//     setValidationErrors({
//       name: "",
//       section: "",
//       title: "",
//       year: "",
//       description: "",
//       publishDate: "",
//       documentType: "",
//       quarter: "",
//       applicableTo: "",
//       status: "",
//     });
//     setOpenEditDialog(false);
//     resetValues();
//   };

//   const handleCloseUploadDialog = () => {
//     setOpenUploadDialog(false);
//     setValidationErrors({
//       name: "",
//       section: "",
//       title: "",
//       year: "",
//       description: "",
//       publishDate: "",
//       documentType: "",
//       quarter: "",
//       applicableTo: "",
//       status: "",
//     });
//     setUploadOption(null);
//     resetValues();
//   };

//   const handleDeleteItem = (itemId: string) => {
//     setSelectedItemId(itemId);
//     setAlertContent("Are you sure you want to delete this item?");
//     setAlertActivation(true);
//   };

//   const handlePublishDateChange = (event: any) => {
//     const date = event.target.value;
//     setpublishDate(date);

//     if (date) {
//       const year = new Date(date).getFullYear();
//       setYear(year.toString());
//     } else {
//       setYear("");
//     }
//   };
//   const getTodayDate = () => {
//     const today = new Date();
//     const yyyy = today.getFullYear();
//     const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
//     const dd = String(today.getDate()).padStart(2, "0");
//     return `${yyyy}-${mm}-${dd}`;
//   };

//   const handleConfirmDelete = async () => {
//     if (selectedItemId) {
//       try {
//         await deletePolicyItem(selectedItemId).unwrap();
//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }
//         setSnackbarMessage("Item deleted successfully");
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error("Error deleting item:", error);
//         setSnackbarMessage("Error deleting item");
//         setSnackbarOpen(true);
//       }
//       setSelectedItemId(null);
//       setAlertActivation(false);
//     }
//   };

//   const handleCancelDelete = () => {
//     setSelectedItemId(null);
//     setAlertActivation(false);
//   };

//   const resetValues = () => {
//     setFolderName("");
//     setSection("");
//     setTitle("");
//     setDescription("");
//     setpublishDate("");
//     setDocumentType("");
//     setApplicableTo([]);
//     setStatus("");
//     setUploadedFiles([]);
//     setSelectedFolder(null);
//     setEditedItem(null);
//   };
//   const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl1(event.currentTarget);
//   };
//   const handleClose1 = () => {
//     setAnchorEl1(null);
//   };
//   const handleUploadClick = () => {
//     setOpenConfirmationDialog(true);
//     setAnchorEl1(null);
//   };

//   const handleConfirmationOption = (option: "FILE" | "FOLDER") => {
//     setSelectedOption(option);
//     setOpenUploadDialog(true);
//     setOpenConfirmationDialog(false);
//     handleClose1();
//   };

//   // const handlePageChange = (
//   //   event: React.ChangeEvent<unknown>,
//   //   value: number
//   // ) => {
//   //   if (folderContents.length === 0) {
//   //     setCurrentPage(1);
//   //   } else {
//   //     setCurrentPage(value);
//   //   }
//   // };
//   // const totalPages = Math.max(
//   //   1,
//   //   Math.ceil(folderContents.length / itemsPerPage)
//   // );

//   // const handlePrevPage = () => {
//   //   setCurrentPage((prev) => Math.max(1, prev - 1));
//   // };

//   // const handleNextPage = () => {
//   //   setCurrentPage((prev) => Math.min(totalPages, prev + 1));
//   // };

//   // const handlePageClick = (page: React.SetStateAction<number>) => {
//   //   setCurrentPage(page);
//   // };

//   // const renderPageNumbers = () => {
//   //   const pageNumbers = [];
//   //   for (let i = 1; i <= totalPages; i++) {
//   //     pageNumbers.push(
//   //       <Typography
//   //         key={i}
//   //         variant="body2"
//   //         sx={{
//   //           width: 30,
//   //           height: 30,
//   //           borderRadius: "4px",
//   //           backgroundColor: currentPage === i ? "#e0001b" : "transparent",
//   //           color: currentPage === i ? "white" : "inherit",
//   //           display: "flex",
//   //           alignItems: "center",
//   //           justifyContent: "center",
//   //           cursor: "pointer",
//   //           mx: 0.5,
//   //         }}
//   //         onClick={() => handlePageClick(i)}
//   //       >
//   //         {i}
//   //       </Typography>
//   //     );
//   //   }
//   //   return pageNumbers;
//   // };
//   const columns: GridColDef<GridValidRowModel>[] = [

//     // {
//     //   field: "index",
//     //   headerName: "ID",
//     //   width: 80,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "ByrnePostTableCell",
//     //   valueGetter: (params: any) => {
//     //     const index = folderContents.findIndex(item => item._id === params.row._id);
//     //     return index + 1;
//     //   },
//     // },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       field: "name",
//       headerName: "Name",
//       width: 250,
//       sortable: false,
//       renderCell: (params: GridRenderCellParams) => {
//         const row = params.row;
//         const isFolder = row.type === "folder";
//         const fileName = row.fileName || "";
//         const folderName = row.name || "";
//         const itemName = isFolder ? folderName : fileName;

//         return (
//           <Tooltip
//             placement="right"
//             title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
//             arrow
//           >
//           <div style={{ display: "flex", alignItems: "center" }}>
//             {isFolder ? (
//               <FolderIcon style={{ marginRight: "8px" }} />
//             ) : (
//               <InsertDriveFileIcon style={{ marginRight: "8px" }} />
//             )}
//             <span>{itemName}</span>
//           </div>
//           </Tooltip>
//         );
//       },
//     },
//     // {
//     //   field: "section",
//     //   headerName: "Section",
//     //   width: 150,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     {
//       field: "title",
//       headerName: "Title",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//       renderCell: (params: GridRenderCellParams) => {
//         const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(null);

//         const handleTooltipOpen = (id: number) => {
//           setTooltipOpenCell(id);
//         };

//         const handleTooltipClose = () => {
//           setTooltipOpenCell(null);
//         };

//         return (
//           <Tooltip
//             open={tooltipOpenCell === params.row.id}
//             onOpen={() => handleTooltipOpen(params.row.id)}
//             onClose={handleTooltipClose}
//             title={params.value}
//             placement="right-start"
//             arrow
//           >
//             <div>{params.value}</div>
//           </Tooltip>
//         );
//       },
//     },
//     {
//       field: "description",
//       headerName: "Description",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderCell: (params: GridRenderCellParams) => {
//         const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(null);

//         const handleTooltipOpen = (id: number) => {
//           setTooltipOpenCell(id);
//         };

//         const handleTooltipClose = () => {
//           setTooltipOpenCell(null);
//         };

//         return (
//           <Tooltip
//             open={tooltipOpenCell === params.row.id}
//             onOpen={() => handleTooltipOpen(params.row.id)}
//             onClose={handleTooltipClose}
//             title={params.value || "No description"}
//             placement="right-start"
//             arrow
//           >
//             <div className="description-tooltip">{params.value || "No description"}</div>
//           </Tooltip>
//         );
//       },
//     },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       field: "publishDate",
//       headerName: "Publish Date",
//       width: 150,
//       sortable: false,
//       valueFormatter: (params: { value: string | number | Date }) => {
//         const date = new Date(params.value);
//         return date.toLocaleDateString();
//       },
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//     },
//     {
//       field: "quarter",
//       headerName: "Quarter",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//     },
//     {
//       field: "year",
//       headerName: "Year",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//     },
//     {
//       field: "coverPicture",
//       headerName: "Cover Picture",
//       width: 150,
//       headerClassName: "MarketingTableHeader",
//       cellClassName: "MarketingTableCell",
//       sortable: true,
//       valueGetter: (params: any) =>
//         params.value && params.value.trim() ? params.value : "---",
//       renderCell: (params: any) => <ImageCell value={params.value} />,
//     },
//     // {
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     //   field: "documentType",
//     //   headerName: "Document Type",
//     //   width: 250,
//     //   sortable: false,
//     // },
//     // {
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     //   field: "applicableTo",
//     //   headerName: "Applicable To",
//     //   width: 200,
//     //   sortable: false,
//     //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
//     //     const applicableTo = params.row.applicableTo;
//     //     if (Array.isArray(applicableTo)) {
//     //       return (
//     //         <div
//     //           style={{
//     //             whiteSpace: "pre-wrap",
//     //             textAlign: "center",
//     //             display: "flex",
//     //             flexDirection: "column",
//     //             alignItems: "center",
//     //             justifyContent: "center",
//     //             height: "100%",
//     //           }}
//     //         >
//     //           {applicableTo.map((value) => (
//     //             <span key={value}>{value}</span>
//     //           ))}
//     //         </div>
//     //       );
//     //     }
//     //     return applicableTo;
//     //   },
//     // },
//     // {
//     //   field: "status",
//     //   headerName: "Status",
//     //   width: 180,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       field: "actions",
//       headerName: "Actions",
//       width: 130,
//       sortable: false,
//       renderCell: (params: GridRenderCellParams) => {
//         const itemId = params.row._id;

//         return (
//           <div>
//              <Tooltip title="Edit">
//             <IconButton
//               sx={{ padding: "3px" }}
//               size="small"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 handleEditItem(itemId);
//               }}
//             >
//               <EditIcon />
//             </IconButton>
//             </Tooltip>
//             <Tooltip title="Delete">
//             <IconButton
//               sx={{ padding: "3px" }}
//               size="small"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 handleDeleteItem(itemId);
//               }}
//             >
//               <DeleteIcon />
//             </IconButton>
//             </Tooltip>
//           </div>
//         );
//       },
//     },
//   ];

//   const getRowClassName = (params: any) => {
//     let className = "custom-row-class"; // Default class

//     if (params.row.Id % 2 === 0) {
//       className += " even-row"; // Add even-row class for even IDs
//     } else {
//       className += " odd-row"; // Add odd-row class for odd IDs
//     }

//     return className;
//   };

//   const getCellClassName = (params: any) => {
//     return "eve";
//   };
//   let content;

//   if (isTopLevelItemsLoading) {
//     content = (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress style={{ color: "#009BAD" }} />
//       </div>
//     );
//   } else if (isTopLevelItemsError) {
//     content = (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <Typography>Error loading top-level items.</Typography>
//       </div>
//     );
//   } else {
//     content = (
//       <div
//         style={
//           {
//             // display: "flex",
//             // height: "75%",
//             // marginLeft: "15px",
//             // marginRight: "15px",
//           }
//         }
//         className="mainTable"
//       >
//         <div className="ByrnePost-table1">
//           <Box sx={{ flexGrow: 1, width: "100%" }}>
//             <>
//               {isLoadingg && <LoaderComponent />}
//               <DataGrid
//                 sx={{
//                   '& .MuiDataGrid-columnHeaders': {
//                     backgroundColor: '#FDE4E5 !important',
//                   },
//                   "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
//                     outline: "none",
//                   },
//                   border: "none !important",
//                   '.MuiDataGrid-cellContent': {
//         pointerEvents: 'none',
//       },
//               }}
//                 componentsProps={{
//                   footer: { style: { display: "none" } },
//                 }}
//                 rows={paginatedRows}
//                 columns={columns}
//                 // getRowId={(row) => row._id}
//                 pageSizeOptions={[15]}
//                 disableRowSelectionOnClick
//                 disableDensitySelector
//                 disableColumnFilter
//                 disableColumnMenu
//                 hideFooterPagination={true}
//                 disableColumnSelector={true}
//                 getRowClassName={getRowClassName}
//                 getCellClassName={getCellClassName}
//                 onRowClick={(params) => {
//                   const row = params.row;
//                   if (row.type === "folder") {
//                     handleFolderClick(row._id);
//                   }
//                 }}
//                 style={{
//                   color: "rgba(30, 30, 30, 1)!important",
//                   boxShadow: "20px !important",
//                   width: "100%",
//                 }}
//               />
//             </>
//           </Box>
//         </div>

//         <AlertYesNo
//           isAlertOpen={alertActivation}
//           handleAlertYes={handleConfirmDelete}
//           handleAlertClose={handleCancelDelete}
//         >
//           {alertContent}
//         </AlertYesNo>
//         <Snackbar
//           anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
//           open={snackbarOpen}
//           autoHideDuration={3000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <div
//             style={{
//               backgroundColor: "#e0001b",
//               color: "white",
//               padding: "10px",
//               borderRadius: "4px",
//               marginBottom: "20px",
//               marginRight: "24px",
//               fontSize: "14px !important",
//             }}
//           >
//             {snackbarMessage}
//           </div>
//         </Snackbar>
//       </div>
//     );
//   }
//   function handleOpenDialog(event: React.MouseEvent<HTMLLIElement>): void {
//     // throw new Error("Function not implemented.");
//     // console.log("Dialog opened");
//     setOpenNewFolderDialog(true);
//   }

//   const handleFileUpload = () => {
//     if (selectedFile) {
//       const formData = new FormData();
//       formData.append("file", selectedFile);

//       // Check if selectedFolderId is not null
//       if (selectedFolderId) {
//         formData.append("parentId", selectedFolderId);
//       } else {
//         // Handle the case when selectedFolderId is null
//         // For example, you can choose a default folder or display an error message to the user
//         console.log(
//           "Warning: No folder selected. Uploading to default location..."
//         );
//       }

//       // Make the API request to upload the file using the mutation hook
//       uploadGalleryFoldersMedia(formData)
//         .unwrap()
//         .then(() => {
//           // Close the upload dialog
//           handleCloseUploadDialog();
//           // Open the success dialog
//           setOpenDialog2(true);
//         })
//         .catch((error: any) => {
//           console.error("Error uploading file:", error);
//           // Optionally, show an error message to the user
//         });
//     } else {
//       console.log("No file selected.");
//       // Optionally, show a message to the user indicating that no file is selected
//     }
//   };

//   return (
//     <div>
//       {/* <Grid className="upperPart1">
//           <Grid container alignItems="center" style={{ display: "flex" }}>
//             <IconButton
//               onClick={handleGoBack}
//               disabled={folderPath.length === 0}
//             >
//               <ArrowBackIcon />
//             </IconButton>
//             <Grid item style={{ display: "flex", alignItems: "left" }}>
//               <Typography
//                 component="span"
//                 onClick={() => handleFolderClick(null)}
//                 style={{
//                   cursor: "pointer", color: "#e0001b", marginLeft: -"13px",
//                   position: "relative",
//                   right: "11px",
//                   fontSize:"18px",
//                   fontWeight:600
//                  }}
//               >
//                 Byrne Posts
//               </Typography>
//               <FolderPath
//                 path={folderPath}
//                 onFolderClick={handleFolderClick}
//                 selectedFolderId={selectedFolderId}
//               />
//             </Grid>
//           </Grid>
//         </Grid> */}
//       <div className="EventsHeaderStack">
//         <div className="EventsHeader">Byrne Posts</div>
//         <div className="new-event-btn ">
//           {/* <Grid className="new&uploadGrid"> */}
//           <Button
//             startIcon={<AddCircleOutlineRoundedIcon />}
//             // onClick={() => setOpenNewFolderDialog(true)}
//             onClick={handleClick1}
//             // className="newFolder"
//             // sx={{ textTransform: "capitalize", marginRight: "10px" }}
//             className="create"
//             sx={{ textTransform: "none", backgroundColor: "#e0001b" }}
//           >
//             New
//           </Button>
//           <Menu
//             anchorEl={anchorEl1}
//             open={Boolean(anchorEl1)}
//             onClose={handleClose1}
//           >
//             {/* <MenuItem
//                 sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                 //onClick={handleUploadClick}
//                 onClick={handleOpenDialog}
//               >
//                 <img src={folder} />
//                 <div className="Menuname">Folder</div>
//               </MenuItem> */}

//             <MenuItem
//               sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//               // onClick={handleFileUpload}
//               onClick={() => handleConfirmationOption("FILE")}
//             >
//               <img src={filee} />
//               <label>
//                 <div className="Menuname">Upload Files </div>
//                 <input
//                   id="fileInput"
//                   // type="file"
//                   onChange={handleFileChanges}
//                   style={{ display: "none" }}
//                 />
//               </label>
//             </MenuItem>
//             {/* <MenuItem
//                 sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                 //onClick={handleCloseUploadDialog}
//                 // onClick = {handleUploadClick}
//                 onClick={() => handleConfirmationOption("FOLDER")}
//               >
//                 <img src={filee} />
//                 <div className="Menuname">Upload Folder</div>
//               </MenuItem> */}
//           </Menu>
//           {/* <Button
//               startIcon={<img src={uploadblue} alt="Upload Icon" />}
//               onClick={handleUploadClick}
//               className="upload"
//               sx={{ textTransform: "capitalize" }}
//             >
//               UPLOAD
//             </Button> */}
//           {/* </Grid> */}
//         </div>
//       </div>
//       <div>
//         <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
//           <div className="Tablebreadcrumb">
//             <div className="Tablebreadcrumb1">Landing Page</div>
//             <span className="Tablebreadcrumbarrow-icon">
//               <NavigateNextIcon />
//             </span>
//             <span className="Tablebreadcrumb2">Byrne Posts</span>
//           </div>
//           <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
//             <SearchBar
//               searchTerm={searchTerm}
//               onSearchChange={handleSearchChange}
//               onClearSearch={handleClearSearch} // Add this line
//             />
//           </div>
//         </Stack>
//       </div>
//       {/* <div className="Tablebreadcrumb">
//           <IconButton
//             onClick={handleGoBack}
//             disabled={folderPath.length === 0}

//           >
//             <ArrowBackIcon />
//           </IconButton>
//           <span className="Tablebreadcrumb1">LANDING PAGE</span> */}
//       {/* <img src={Breadcrumbs} alt="" className="PolicyArrowIcon" /> */}
//       {/* <span className="PolicyArrowIcon">
//             <NavigateNextIcon />
//           </span>
//           <span className="Breadcrumbs2">Byrne Posts</span>
//           <FolderPath
//             path={folderPath}
//             onFolderClick={handleFolderClick}
//             selectedFolderId={selectedFolderId}
//           />
//         </div> */}

//       <Drawer
//         anchor="right"
//         classes={{
//           paper: "newPosOfDialog",
//         }}
//         open={openNewFolderDialog}
//         onClose={() => {
//           setOpenNewFolderDialog(false);
//           setValidationErrors({
//             name: "",
//             section: "",
//             title: "",
//             year: "",
//             description: "",
//             publishDate: "",
//             documentType: "",
//             quarter: "",
//             applicableTo: "",
//             status: "",
//           });
//         }}
//       >
//         {/* <div>
//             <Grid className="dialogTitle">
//               <Grid>
//                 <Typography
//                   className="newItem"
//                   style={{ textAlign: "left", color: "#02354d" }}
//                 >
//                   New Folder
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <IconButton onClick={() => setOpenNewFolderDialog(false)}>
//                   <CloseIcon />
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </div> */}
//         <div className="plusIcon">
//           <div style={{ marginLeft: "10px", marginTop: "15px" }}>
//             <img src={plusIcon} alt="Plus Icon" />
//           </div>
//           <Typography className="addpolicy">Add Documents</Typography>
//         </div>
//         <div>
//           <Divider />
//           <div style={{ marginBottom: "10px" }}>
//             <div className="label">
//               <div className="subHeading">Folder Name</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <TextField
//               id="outlined-adornment-weight"
//               className="span"
//               value={folderName}
//               style={{ width: "100%" }}
//               onChange={(event) => {
//                 setFolderName(event.target.value);
//                 setValidationErrors((prevErrors) => ({
//                   ...prevErrors,
//                   name: event.target.value.trim() ? "" : "Name is required",
//                 }));
//               }}
//               placeholder="Enter folder name"
//               autoComplete="off" // Add autoComplete attribute and set it to "off"
//             />

//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.name}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Section</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={section}
//               style={{ width: "100%" }}
//               onChange={(event) => {
//                 setSection(event.target.value);
//                 setValidationErrors((prevErrors) => ({
//                   ...prevErrors,
//                   section: event.target.value.trim()
//                     ? ""
//                     : "Section is required",
//                 }));
//               }}
//               placeholder="Enter section"
//             />

//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.section}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Document Type</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <div className="documentTypeWrapper">
//               <Select
//                 id="outlined-adornment-documentType"
//                 className={`span ${documentType === "addChoice" ? "reduced-width" : ""
//                   }`}
//                 value={documentType}
//                 style={{ width: "100%", height: "40px" }}
//                 onChange={(event) => {
//                   setDocumentType(event.target.value as string);
//                   setValidationErrors((prevErrors) => ({
//                     ...prevErrors,
//                     documentType: event.target.value.trim()
//                       ? ""
//                       : "Document Type is required",
//                   }));
//                 }}
//               >
//                 <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                 <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                 <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                 <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
//                 <MenuItem value="Policy">Policy</MenuItem>
//                 <MenuItem value="Process Map">Process Map</MenuItem>
//                 <MenuItem value="Supplementry Document">
//                   Supplementry Document
//                 </MenuItem>
//                 <MenuItem value="Form">Form</MenuItem>
//                 <MenuItem value="Template">Template</MenuItem>
//                 <MenuItem value="Service Level Agreement">
//                   Service Level Agreement
//                 </MenuItem>
//                 <MenuItem value="Certificate">Certificate</MenuItem>
//                 <MenuItem value="addChoice">
//                   <div style={{ display: "flex", alignItems: "center" }}>
//                     <AddIcon style={{ marginRight: "8px" }} />
//                     Add Choice Manually
//                   </div>
//                 </MenuItem>
//               </Select>
//               {documentType === "addChoice" && (
//                 <div style={{ marginTop: "8px" }}>
//                   <TextField
//                     id="outlined-adornment-manual-document-type"
//                     className="span manual-document-type"
//                     value={manualDocumentType}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       setManualDocumentType(event.target.value)
//                     }
//                     placeholder="Enter document type"
//                   />
//                 </div>
//               )}
//             </div>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.documentType}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Applicable To</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <FormGroup>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("Sales")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "Sales"]
//                           : prevApplicableTo.filter(
//                             (item: string) => item !== "Sales"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="Sales"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("Operations")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "Operations"]
//                           : prevApplicableTo.filter(
//                             (item: string) => item !== "Operations"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="Operations"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("IT")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "IT"]
//                           : prevApplicableTo.filter(
//                             (item: string) => item !== "IT"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="IT"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("HR & Finance")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "HR & Finance"]
//                           : prevApplicableTo.filter(
//                             (item) => item !== "HR & Finance"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="HR & Finance"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("Health & Safety")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "Health & Safety"]
//                           : prevApplicableTo.filter(
//                             (item) => item !== "Health & Safety"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="Health & Safety"
//               />
//             </FormGroup>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.applicableTo}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Status</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <TextField
//               id="outlined-adornment-status"
//               className="span"
//               value={status}
//               style={{ width: "100%" }}
//               onChange={(event) => {
//                 setStatus(event.target.value);
//                 setValidationErrors((prevErrors) => ({
//                   ...prevErrors,
//                   status: event.target.value.trim() ? "" : "Status is required",
//                 }));
//               }}
//               placeholder="Enter status"
//             />

//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.status}
//               </div>
//             )}
//           </div>
//           <div className="actionDivTwo">
//             <Button
//               sx={{ textTransform: "capitalize" }}
//               onClick={handleCreateFolderNew}
//               className="saveBtn"
//             >
//               Create Folder
//             </Button>
//           </div>
//         </div>
//       </Drawer>

//       <Dialog open={isUploading}>
//         <DialogTitle>Upload Progress</DialogTitle>
//         <DialogContent>
//           <Typography variant="body1">{uploadProgress}%</Typography>
//           <LinearProgress variant="determinate" value={uploadProgress} />
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => setIsUploading(false)}
//             disabled={uploadProgress < 100}
//           >
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={openConfirmationDialog}
//         onClose={() => setOpenConfirmationDialog(false)}
//       >
//         <DialogTitle>Select Upload Option</DialogTitle>
//         <DialogContent>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-around",
//               marginTop: "16px",
//             }}
//           >
//             <Button
//               variant="contained"
//               onClick={() => handleConfirmationOption("FILE")}
//               style={{ textTransform: "none", backgroundColor: "#e0001b" }}
//             >
//               File
//             </Button>
//             <Button
//               variant="contained"
//               onClick={() => handleConfirmationOption("FOLDER")}
//               style={{ textTransform: "none", backgroundColor: "#e0001b" }}
//             >
//               Folder
//             </Button>
//           </div>
//         </DialogContent>
//       </Dialog>

//       <Drawer
//         anchor="right"
//         classes={{
//           paper: "newPosOfDialog",
//         }}
//         open={openUploadDialog}
//         onClose={handleCloseUploadDialog}
//       >
//         <div>
//           <Grid className="dialogTitle">
//             <Grid>
//               <Typography
//                 className="newItem"
//                 style={{ textAlign: "left", color: "#02354d" }}
//               >
//                 {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
//               </Typography>
//             </Grid>
//             <Grid>
//               <IconButton onClick={() => setOpenUploadDialog(false)}>
//                 <CloseIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//         </div>
//         <div>
//           <Divider />
//           <div>
//             {selectedOption === "FILE" && (
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Select Files</div>
//                 </div>
//                 <input
//                   type="file"
//                   multiple
//                   accept=".pdf"
//                   onChange={handleFileChange}
//                 />
//                 <div className="error">{imageError}</div>
//               </div>
//             )}
//           </div>
//           {selectedOption === "FOLDER" && (
//             <div style={{ marginBottom: "10px" }}>
//               <div className="label">
//                 <div className="subHeading">Select Folder</div>
//               </div>
//               <button onClick={handleFolderSelection}>Select Folder</button>
//               {selectedFolder && <p>Selected Folder: {selectedFolder.name}</p>}
//             </div>
//           )}
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Select Cover Image</div>
//             </div>
//             <input
//               type="file"
//               accept="image/*"
//               multiple
//               // accept=".pdf,.doc,.docx,.ppt"
//               onChange={handleCoverPictureChange}
//             />
//             <div className="error">{imageError}</div>
//           </div>
//           {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Section</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={section}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setSection(event.target.value)}
//                 placeholder="Enter section"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.section}
//                 </div>
//               )}
//             </div> */}
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Title</div>
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={title}
//               style={{ width: "100%" }}
//               // onChange={(event) => setTitle(event.target.value)}
//               onChange={handleChangeTitleField}
//               placeholder="Enter Title"
//               autoComplete="off"
//             />
//             <div className="error">{titleError}</div>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.title}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Description</div>
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={description}
//               style={{ width: "100%" }}
//               onChange={(event) => setDescription(event.target.value)}
//               placeholder="Enter Description"
//               autoComplete="off"
//             />
//             <div className="error">{descriptionError}</div>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.description}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Quarter</div>
//             </div>
//             <div className="documentTypeWrapper">
//               <Select
//                 autoComplete="off"
//                 id="outlined-adornment-documentType"
//                 className={`span ${quarter === "addChoice" ? "reduced-width" : ""
//                   }`}
//                 value={quarter}
//                 displayEmpty
//                 renderValue={(selected) => {
//                   if (selected === "") {
//                     return (
//                       <span style={{ color: "#888888" }}>Select a Quarter</span>
//                     );
//                   }
//                   return selected;
//                 }}
//                 style={{ width: "100%", height: "40px" }}
//                 onChange={(event) =>
//                   setQuarterType(event.target.value as string)
//                 }
//               >
//                 <MenuItem value="Q1">Q1</MenuItem>
//                 <MenuItem value="Q2">Q2</MenuItem>
//                 <MenuItem value="Q3">Q3</MenuItem>
//                 <MenuItem value="Q4">Q4</MenuItem>
//                 {/* <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem> */}
//               </Select>
//               {/* {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )} */}
//             </div>
//             <div className="error">{quarterError}</div>
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Year</div>
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={year}
//               style={{ width: "100%" }}
//               placeholder="Enter Year"
//               InputProps={{ readOnly: true }}
//               autoComplete="off"
//             />
//             <div className="error">{yearError}</div>
//             {validationErrors.year && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.year}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "10px", flex: 1 }}>
//             <div className="label">
//               <div className="subHeading">Publish Date</div>
//             </div>
//             <TextField
//               fullWidth
//               id="outlined-adornment-weight"
//               className="span"
//               value={publishDate}
//               onChange={handlePublishDateChange}
//               inputProps={{ min: getTodayDate() }}
//               placeholder="Enter an event date"
//               type="date"
//               autoComplete="off"
//             />
//             <div className="error">{publishDateError}</div>
//             {validationErrors.publishDate && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.publishDate}
//               </div>
//             )}
//           </div>
//           {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Document Type1</div>
//               </div>
//               <div className="documentTypeWrapper">
//                 <Select
//                   id="outlined-adornment-documentType"
//                   className={`span ${documentType === "addChoice" ? "reduced-width" : ""
//                     }`}
//                   value={documentType}
//                   style={{ width: "100%", height: "40px" }}
//                   onChange={(event) =>
//                     setDocumentType(event.target.value as string)
//                   }
//                 >
//                   <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                   <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                   <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                   <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
//                   <MenuItem value="Policy">Policy</MenuItem>
//                   <MenuItem value="Process Map">Process Map</MenuItem>
//                   <MenuItem value="Supplementry Document">
//                     Supplementry Document
//                   </MenuItem>
//                   <MenuItem value="Form">Form</MenuItem>
//                   <MenuItem value="Template">Template</MenuItem>
//                   <MenuItem value="Service Level Agreement">
//                     Service Level Agreement
//                   </MenuItem>
//                   <MenuItem value="Certificate">Certificate</MenuItem>
//                   <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem>
//                 </Select>
//                 {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )}
//               </div>
//             </div> */}
//           {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Applicable To</div>
//               </div>
//               <FormGroup>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Sales")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Sales"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Sales"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Sales"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Operations")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Operations"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Operations"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Operations"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("IT")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "IT"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "IT"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="IT"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("HR & Finance")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "HR & Finance"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "HR & Finance"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="HR & Finance"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Health & Safety")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Health & Safety"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "Health & Safety"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Health & Safety"
//                 />
//               </FormGroup>
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Status</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-status"
//                 className="span"
//                 value={status}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setStatus(event.target.value)}
//                 placeholder="Enter status"
//               />
//             </div> */}
//           <div className="actionDivTwo">
//             {selectedOption === "FILE" && (
//               <Button
//                 sx={{ textTransform: "capitalize" }}
//                 onClick={handleUploadFiles}
//                 disabled={isUploading}
//                 className="saveBtn"
//               >
//                 Upload Files
//               </Button>
//             )}
//             {selectedOption === "FOLDER" && (
//               <Button
//                 sx={{ textTransform: "capitalize" }}
//                 onClick={handleCreateFolder}
//                 className="saveBtn"
//                 disabled={!selectedFolder}
//               >
//                 Upload Folder
//               </Button>
//             )}
//           </div>
//         </div>
//       </Drawer>

//       <Drawer
//         anchor="right"
//         classes={{
//           paper: "newPosOfDialog",
//         }}
//         open={openEditDialog}
//         onClose={handleCloseEditDialog}
//       >
//         {/* <div>
//             <Grid className="dialogTitle">
//               <Grid>
//                 <Typography
//                   className="newItem"
//                   style={{ textAlign: "left", color: "#02354d" }}
//                 >
//                   Edit Item
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <IconButton onClick={handleCloseEditDialog}>
//                   <CloseIcon />
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </div> */}

//         <div className="DrawerHeaderBox">
//           <Stack direction="row" alignItems="center" spacing={1}>
//             <img
//               style={{ height: "20px", width: "17px" }}
//               src={plusIcon}
//               alt="Plus Icon"
//             />
//             <div className="DrawerTitle">Edit Documents</div>
//           </Stack>
//           <div>
//             <CloseRoundedIcon
//               // onClick={handleCancel}
//               style={{
//                 cursor: "pointer",
//                 color: "whitesmoke",
//                 padding: "5px",
//                 borderRadius: "50%",
//                 transition: "background-color 0.3s",
//               }}
//               onMouseEnter={(e) => {
//                 e.currentTarget.style.backgroundColor =
//                   "rgba(255, 255, 255, 0.2)";
//               }}
//               onMouseLeave={(e) => {
//                 e.currentTarget.style.backgroundColor = "transparent";
//               }}
//             />
//           </div>
//         </div>
//         <div>
//           <Divider />
//           {editedItem && (
//             <>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Name</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-name"
//                   className="span"
//                   value={editName}
//                   style={{ width: "100%" }}
//                   // onChange={(event) =>
//                   //   handleFieldChange("name", event.target.value)
//                   // }
//                   onChange={(e) => {
//                     handleEditName(e);
//                   }}
//                   placeholder="Enter name"
//                   autoComplete="off"
//                 />
//               </div>
//               {/* <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Section</div>
//                   </div>
//                   <TextField
//                     id="outlined-adornment-section"
//                     className="span"
//                     value={editedItem.section}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       handleFieldChange("section", event.target.value)
//                     }
//                     placeholder="Enter section"
//                   />
//                 </div> */}
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Title</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-section"
//                   className="span"
//                   value={editTitle}
//                   style={{ width: "100%" }}
//                   onChange={(e) => {
//                     handleEditTitle(e);
//                   }}
//                   //onChange={(event) => handleFieldChange("title", event.target.value)}
//                   placeholder="Enter Title"
//                   autoComplete="off" // Add autoComplete attribute and set it to "off"
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.title}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Description</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-section"
//                   className="span"
//                   value={editDescription}
//                   style={{ width: "100%" }}
//                   onChange={(e) => {
//                     handleEditDescription(e);
//                   }}
//                   //onChange={(event) => handleFieldChange("description", event.target.value)}
//                   placeholder="Enter Description"
//                   autoComplete="off"
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.description}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Quarter</div>
//                 </div>
//                 <div className="documentTypeWrapper">
//                   <Select
//                     autoComplete="off"
//                     id="outlined-adornment-documentType"
//                     className={`span ${quarter === "addChoice" ? "reduced-width" : ""
//                       }`}
//                     value={editQuaterValue}
//                     style={{ width: "100%", height: "40px" }}
//                     onChange={(e) => {
//                       handleEditQuaterValue(e);
//                     }}
//                   // onChange={(event) =>
//                   //   handleFieldChange("quarter", event.target.value as string)
//                   // }
//                   >
//                     <MenuItem value="Q1">Q1</MenuItem>
//                     <MenuItem value="Q2">Q2</MenuItem>
//                     <MenuItem value="Q3">Q3</MenuItem>
//                     <MenuItem value="Q4">Q4</MenuItem>
//                     {/* <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem> */}
//                   </Select>
//                   {/* {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )} */}
//                 </div>
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Year</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-section"
//                   className="span"
//                   value={editYear}
//                   style={{ width: "100%" }}
//                   onChange={(e) => {
//                     handleEditYear(e);
//                   }}
//                   // onChange={(event) => handleFieldChange("year", event.target.value)}
//                   placeholder="Enter Year"
//                   autoComplete="off"
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.year}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "10px", flex: 1 }}>
//                 <div className="label">
//                   {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
//                   <div className="subHeading">
//                     Publish Date
//                     {/* <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} /> */}
//                   </div>
//                 </div>
//                 <TextField
//                   fullWidth
//                   id="outlined-adornment-weight"
//                   className="span"
//                   value={editPublishDate}
//                   onChange={(e) => {
//                     handleEditPublishedDate(e);
//                   }}
//                   // onChange={(event) => handleFieldChange("publishDate", event.target.value)}
//                   placeholder="Enter an event date"
//                   type="date"
//                 // inputProps={{ min: today }}
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.publishDate}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Select Cover Image</div>
//                 </div>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   multiple
//                   // accept=".pdf,.doc,.docx,.ppt"
//                   onChange={handleCoverPictureChange}
//                 />
//               </div>
//               {/* <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Document Type</div>
//                   </div>
//                   <div className="documentTypeWrapper">
//                     <Select
//                       id="outlined-adornment-documentType"
//                       className={`span ${editedItem.documentType === "addChoice"
//                           ? "reduced-width"
//                           : ""
//                         }`}
//                       value={editedItem.documentType}
//                       style={{ width: "100%", height: "40px" }}
//                       onChange={(event) =>
//                         handleFieldChange("documentType", event.target.value)
//                       }
//                     >
//                       <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                       <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                       <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                       <MenuItem value="Authority Matrix">
//                         Authority Matrix
//                       </MenuItem>
//                       <MenuItem value="Policy">Policy</MenuItem>
//                       <MenuItem value="Process Map">Process Map</MenuItem>
//                       <MenuItem value="Supplementry Document">
//                         Supplementry Document
//                       </MenuItem>
//                       <MenuItem value="Form">Form</MenuItem>
//                       <MenuItem value="Template">Template</MenuItem>
//                       <MenuItem value="Service Level Agreement">
//                         Service Level Agreement
//                       </MenuItem>
//                       <MenuItem value="Certificate">Certificate</MenuItem>
//                       <MenuItem value="addChoice">
//                         <div style={{ display: "flex", alignItems: "center" }}>
//                           <AddIcon style={{ marginRight: "8px" }} />
//                           Add Choice Manually
//                         </div>
//                       </MenuItem>
//                     </Select>
//                     {editedItem.documentType === "addChoice" && (
//                       <div style={{ marginTop: "8px" }}>
//                         <TextField
//                           id="outlined-adornment-manual-document-type"
//                           className="span manual-document-type"
//                           value={editedItem.manualDocumentType || ""}
//                           style={{ width: "100%" }}
//                           onChange={(event) =>
//                             handleFieldChange(
//                               "manualDocumentType",
//                               event.target.value
//                             )
//                           }
//                           placeholder="Enter document type"
//                         />
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Applicable To</div>
//                   </div>
//                   <FormGroup>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("Sales")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Sales"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Sales"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Sales"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("Operations")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Operations"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Operations"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Operations"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("IT")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "IT"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "IT"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="IT"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes(
//                             "HR & Finance"
//                           )}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [
//                                   ...editedItem.applicableTo,
//                                   "HR & Finance",
//                                 ]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) =>
//                                     item !== "HR & Finance"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="HR & Finance"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes(
//                             "Health & Safety"
//                           )}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Health & Safety"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Health & Safety"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Health & Safety"
//                     />
//                   </FormGroup>
//                 </div>
//                 <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Status</div>
//                   </div>
//                   <TextField
//                     id="outlined-adornment-status"
//                     className="span"
//                     value={editedItem.status}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       handleFieldChange("status", event.target.value)
//                     }
//                     placeholder="Enter status"
//                   />
//                 </div> */}
//               <div className="actionDivTwo">
//                 <Button
//                   sx={{ textTransform: "capitalize" }}
//                   onClick={handleUpdateItem}
//                   className="saveBtn"
//                 >
//                   Update
//                 </Button>
//               </div>
//             </>
//           )}
//         </div>
//       </Drawer>
//       {content}
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "flex-end",
//           marginTop: "20px",
//         }}
//       >
//         <Typography variant="body2" sx={{ marginRight: '10px', color: 'gray' }}>
//           {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(currentPage * itemsPerPage, filteredRows.length)} of ${filteredRows.length}`}
//         </Typography>
//         <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
//           <ArrowBackIosNewIcon />
//         </IconButton>
//         {renderPageNumbers()}
//         <IconButton onClick={handleNextPage} disabled={currentPage === totalPages}>
//           <ArrowForwardIosIcon />
//         </IconButton>
//         {filterField && (
//           <FilterMenu
//             anchorEl={filterAnchorEl}
//             onClose={handleFilterClose}
//             field={filterField}
//             onFilterChange={handleFilterChange}
//             options={filterField ? Array.from(new Set(rows.map(row => {
//               const value = row[filterField as keyof typeof row];
//               return typeof value === 'string' ? value : '';
//             }))).filter(value => value !== '') : []}
//           />
//         )}
//       </Box>

//       {/* <Stack className="paginationBottom" spacing={2}>
//           <Pagination
//             count={
//               folderContents.length === 0
//                 ? 1
//                 : Math.ceil(folderContents.length / itemsPerPage)
//             }
//             shape="rounded"
//             page={currentPage}
//             onChange={handlePageChange}
//             sx={{
//               "& .MuiPaginationItem-root.Mui-selected": {
//                 backgroundColor: "#e0001b", // Change the color of the selected pagination button
//                 color: "#ffffff", // Set text color to white
//                 position: "relative", // Ensure proper positioning for pseudo-element
//               },
//               "& .MuiPaginationItem-root.Mui-selected::after": {
//                 content: '""',
//                 position: "absolute",
//                 bottom: "-7px", // Adjust the position of the line as needed
//                 left: 0,
//                 width: "100%",
//                 height: "3px",
//                 backgroundColor: "#e0001b", // Set the color of the line to red
//                 borderRadius: "2px", // Add border-radius to the line
//               },
//               "& .MuiPaginationItem-root.Mui-selected:hover": {
//                 backgroundColor: "#e0001b", // Override hover effect color
//               },
//             }}
//           />
//         </Stack> */}
//     </div>
//   );
// };

// export default PostsEditorPage;

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Checkbox,
//   CircularProgress,
//   Divider,
//   Drawer,
//   FormControl,
//   FormControlLabel,
//   FormGroup,
//   Grid,
//   TextField,
//   Typography,
//   Snackbar,
//   MenuItem,
//   Select,
//   Radio,
//   RadioGroup,
//   IconButton,
//   DialogActions,
//   LinearProgress,
//   Pagination,
//   Stack,
//   Menu,
//   Tooltip,
// } from "@mui/material";
// import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
// import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
// import AddIcon from "@mui/icons-material/Add";
// import FolderIcon from "@mui/icons-material/Folder";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import CloseIcon from "@mui/icons-material/Close";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
// import Asterisk from "../../../Assets/Images/Asterisk.svg";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
// } from "@mui/material";
// import {
//   DataGrid,
//   GridRowId,
//   GridRenderCellParams,
//   GridRowParams,
//   GridColumnHeaderParams,
//   GridColDef,
// } from "@mui/x-data-grid";
// import {
//   useUploadPostDataMutation,
//   useCreatePostsFolderMutation,
//   useDeletePostItemMutation,
//   useGetPostItemsInFolderQuery,
//   useGetTopLevelPostItemsQuery,
//   useUpdatePostItemMutation,
//   useUploadGalleryFoldersMediaMutation,
// } from "../../../Services/APIs";
// import AlertYesNo from "../../Hooks/DialogYesNo";
// import { Box } from "@mui/material";
// import uploadblue from "../../../Assets/Images/uploadblue.svg";
// import FolderPath from "./PoliciesFolderPath";
// import { nanoid } from "nanoid";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import "./Styles.css";
// import folder from "../../../Assets/Images/folder.svg";
// import filee from "../../../Assets/Images/filee.svg";
// import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import calenderIcon from "../../../Assets/Images/calenderGrey.svg";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import LoaderComponent from "../../../hooks/LoaderComponent"; // Adjust the path as necessary
// import FilterListIcon from '@mui/icons-material/FilterList';
// import FilterMenu from "../../../hooks/FilterMenu";
// import SearchBar from '../../../hooks/SearchBar';
// interface CustomRowModel extends GridValidRowModel {
//   _id: string;
//   [key: string]: any; // Add any other properties that your rows might have
// }

// interface GridValidRowModel {
//   id: number;
//   title: string;
//   year:any;
//   publishDate:any;
//   status:any;
//   quarter:any;
//   isActive: boolean;
//   // Add other fields as needed
// }

// interface Posts {
//   title: any;
//   _id: string;
//   fileName: string;
//   fileUrl: string;
//   quarter: string;
//   year: string;
//   description: string;
//   releaseDate: any;
//   thumbnail: string;
//   coverPicture:string;
//   createdAt:string;
//   // newsTitle: string;
//   // newsImage: string;
//   // newsDescription: string;
//   // createdDate: string;
//   // isActive: boolean;
// }
// const PostsEditorPage = (props: any) => {
//   const { departmentsId, selectedDepartmentName } = props;

//   const [uploadPoliciesData] = useUploadPostDataMutation();
//   const [createPolicyFolder] = useCreatePostsFolderMutation();
//   const [deletePolicyItem] = useDeletePostItemMutation();
//   const [updatePolicyItem] = useUpdatePostItemMutation();
//   const {
//     data: topLevelItemsData,
//     isLoading: isTopLevelItemsLoading,
//     isError: isTopLevelItemsError,
//     refetch: refetchTopLevelItems,
//     isSuccess,
//   } = useGetTopLevelPostItemsQuery(undefined, {
//     selectFromResult: (result: any) => {
//       return result;
//     },
//   });

//   const sortedPosts =
//   topLevelItemsData ? [...topLevelItemsData].sort(
//       (a: Posts, b: Posts) =>
//         new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
//     ) : [];

//     console.log(sortedPosts,"sorted posts")
//   interface ImageCellProps {
//     value?: string; // URL of the image
//     active?: boolean; // Optional prop for indicating active state
//   }
//   const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
//     value && value.trim() !== "---" ? (
//       <img src={value} alt="" style={{ width: "30%", height: "auto" }} />
//     ) : (
//       <span>----</span>
//     );

//   // const topLevelItems = topLevelItemsData?.filter((i: any) => {
//   //   return i?.departmentId == departmentsId
//   // })
//   const topLevelItems = topLevelItemsData;
//   console.log(topLevelItemsData, topLevelItems, "topLevelItem11");

//   const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

//   const { data: policyItems, refetch: refetchPolicyItems } =
//     useGetPostItemsInFolderQuery(selectedFolderId || "");

//   const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
//   const [openUploadDialog, setOpenUploadDialog] = useState(false);
//   const [uploadOption, setUploadOption] = useState<"FILE" | "FOLDER" | null>(
//     null
//   );

//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
//   const [folderName, setFolderName] = useState("");
//   const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [alertActivation, setAlertActivation] = useState(false);
//   const [alertContent, setAlertContent] = useState("");

//   const [section, setSection] = useState("");
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [publishDate, setpublishDate] = useState("");
//   const [year, setYear] = useState("");
//   const [documentType, setDocumentType] = useState("");
//   const [quarter, setQuarterType] = useState("");
//   const [applicableTo, setApplicableTo] = useState<string[]>([]);
//   const [status, setStatus] = useState("");

//   const [selectedFolder, setSelectedFolder] = useState<any | null>(null);
//   const [folderContents, setFolderContents] = useState<any[]>([]);

//   const [folderPath, setFolderPath] = useState<{ id: string; name: string }[]>(
//     []
//   );

//   const [editedItem, setEditedItem] = useState<any | null>(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);

//   const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
//   const [selectedOption, setSelectedOption] = useState<
//     "FILE" | "FOLDER" | null
//   >(null);
//   const [manualDocumentType, setManualDocumentType] = useState("");

//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [isUploading, setIsUploading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(15);
//   const [validationErrors, setValidationErrors] = useState({
//     name: "",
//     section: "",
//     title: "",
//     year: "",
//     description: "",
//     publishDate: "",
//     documentType: "",
//     quarter: "",
//     applicableTo: "",

//     status: "",
//   });

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   // const currentItems = folderContents.slice(indexOfFirstItem, indexOfLastItem);
//   const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
//   const [openDialog2, setOpenDialog2] = useState(false);
//   const [selectedFile, setSelectedFile] = useState<File | null>(null);
//   const [uploadGalleryFoldersMedia, { isLoading, isError }] =
//     useUploadGalleryFoldersMediaMutation();
//   const [coverPicture, setCoverPicture] = useState<File>();

//   const [editName, setEditName] = useState("");
//   const [editTitle, setEditTitle] = useState("");
//   const [editDescription, setEditDescription] = useState("");
//   const [editPublishDate, setEditPublishDate] = useState("");
//   console.log(editPublishDate, "editPublishDate");
//   const [editYear, setEditYear] = useState("");
//   const [editQuaterValue, setEditQuaterValue] = useState("");

//   //use states for validation
//   const [isFormValid, setIsFormValid] = useState(false);
//   const [titleError, setTitleError] = useState("");
//   const [descriptionError, setDescriptionError] = useState("");
//   const [quarterError, setQuarteError] = useState("");
//   const [yearError, setYearError] = useState("");
//   const [publishDateError, setPublishDateError] = useState("");
//   const [imageError, setImageError] = useState("");
//   const [isLoadingg, setIsLoadingg] = useState(false);
//   const showLoader = () => setIsLoadingg(true);
//   const hideLoader = () => setIsLoadingg(false);

//   // const [currentPage, setCurrentPage] = useState(1);
//   // const [itemsPerPage] = useState(7);
//   const [rows, setRows] = useState<CustomRowModel[]>([]);
//   const [filteredRows, setFilteredRows] = useState<CustomRowModel[]>([]);
//   const [paginatedRows, setPaginatedRows] = useState<CustomRowModel[]>([]);
//   const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
//   const [filterField, setFilterField] = useState<string | null>(null);
//   const [searchTerm, setSearchTerm] = useState<string>('');
//   const totalPages = Math.ceil(filteredRows.length / itemsPerPage);

//   useEffect(() => {
//     setFilteredRows(rows);
//   }, [rows]);

//   useEffect(() => {
//     applyFiltersAndSearch();
//   }, [searchTerm, rows, filterField]);

//   useEffect(() => {
//     paginateRows();
//   }, [currentPage, itemsPerPage, filteredRows]);

//   const applyFiltersAndSearch = () => {
//     let newFilteredRows = rows;

//     if (searchTerm) {
//       newFilteredRows = newFilteredRows.filter(row =>
//         columns.some(column =>
//           String(row[column.field as keyof CustomRowModel]).toLowerCase().includes(searchTerm.toLowerCase())
//         )
//       );
//     }

//     if (filterField) {
//       newFilteredRows = newFilteredRows.filter(row =>
//         (row[filterField as keyof typeof row] || '').toString().toLowerCase().includes(filterField.toLowerCase())
//       );
//     }

//     setFilteredRows(newFilteredRows);
//     setCurrentPage(1); // Reset to first page on filter or search change
//   };

//   const paginateRows = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const paginated = filteredRows.slice(startIndex, startIndex + itemsPerPage);
//     setPaginatedRows(paginated);
//   };

//   const handlePrevPage = () => {
//     setCurrentPage((prev) => Math.max(1, prev - 1));
//   };

//   const handleNextPage = () => {
//     setCurrentPage((prev) => Math.min(totalPages, prev + 1));
//   };

//   const handlePageClick = (page: number) => {
//     setCurrentPage(page);
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = 1; i <= totalPages; i++) {
//       pageNumbers.push(
//         <Typography
//           key={i}
//           variant="body2"
//           sx={{
//             width: 30,
//             height: 30,
//             borderRadius: "4px",
//             backgroundColor: currentPage === i ? "#e0001b" : "transparent",
//             color: currentPage === i ? "white" : "inherit",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             cursor: "pointer",
//             mx: 0.5,
//           }}
//           onClick={() => handlePageClick(i)}
//         >
//           {i}
//         </Typography>
//       );
//     }
//     return pageNumbers;
//   };

//   const handleFilterIconClick = (event: React.MouseEvent<HTMLButtonElement>, field: string) => {
//     setFilterAnchorEl(event.currentTarget);
//     setFilterField(field);
//   };

//   const handleFilterClose = () => {
//     setFilterAnchorEl(null);
//     setFilterField(null);
//   };

//   const handleFilterChange = (field: string, selectedOptions: string[]) => {
//     const newFilteredRows = rows.filter((row) => {
//       return selectedOptions.includes((row as any)[field]);
//     });
//     setFilteredRows(newFilteredRows);
//     setCurrentPage(1); // Reset to first page on filter change
//   };

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   const handleClearSearch = () => {
//     setSearchTerm('');
//   };

//   useEffect(() => {
//     if (isSuccess && topLevelItemsData) {
//       const formattedRows = topLevelItemsData?.map((event: any, index: number) => ({
//         id: event?._id,
//         Id: index + 1,
//         fileName:event?.name,
//         title: event?.title,
//         year:event?. year,
//         description:event?. description,
//         publishDate:event?. publishDate,
//         documentType:event?. documentType,
//         quarter:event?. quarter,
//         applicableTo: event?.applicableTo.join(","),
//         status:event?. status,
//         coverPicture:event?. coverPicture,
//       }));
//       setRows(formattedRows);
//       setFilteredRows(formattedRows);
//       setIsLoadingg(false);
//     }
//   }, [isSuccess, topLevelItemsData]);

//   const handleEditTitle = (event: any) => {
//     setEditTitle(event.target.value);
//   };
//   const handleEditDescription = (event: any) => {
//     setEditDescription(event.target.value);
//   };
//   const handleEditName = (event: any) => {
//     setEditName(event.target.value);
//   };
//   const handleEditPublishedDate = (event: any) => {
//     console.log(event.target.value, "targetss");
//     setEditPublishDate(event.target.value);
//   };
//   const handleEditYear = (event: any) => {
//     setEditYear(event.target.value);
//   };
//   const handleEditQuaterValue = (event: any) => {
//     setEditQuaterValue(event.target.value as string);
//   };

//   useEffect(() => {
//     if (selectedFolderId) {
//       if (policyItems && !isTopLevelItemsLoading && !isTopLevelItemsError) {
//         setFolderContents(policyItems);
//       }
//     } else {
//       if (sortedPosts && !isTopLevelItemsLoading && !isTopLevelItemsError) {
//         setFolderContents(sortedPosts || []);
//       }
//     }
//   }, [
//     selectedFolderId,
//     policyItems,
//     sortedPosts,
//     isTopLevelItemsLoading,
//     isTopLevelItemsError,
//   ]);

//   useEffect(() => {
//     if (!openNewFolderDialog && !openUploadDialog && !openEditDialog) {
//       resetValues();
//     }
//   }, [openNewFolderDialog, openUploadDialog, openEditDialog]);

//   const handleFolderClick = (folderId: string | null) => {
//     if (folderId) {
//       const selectedFolder = folderContents.find(
//         (item: any) => item._id === folderId
//       );
//       if (selectedFolder) {
//         console.log(
//           `Folder clicked: ${selectedFolder.name} (${selectedFolder._id})`
//         );
//         setSelectedFolderId(selectedFolder._id as string);
//         setFolderPath((prevPath) => {
//           const folderIndex = prevPath.findIndex(
//             (folder) => folder.id === folderId
//           );
//           if (folderIndex !== -1) {
//             return prevPath.slice(0, folderIndex + 1);
//           }
//           return [
//             ...prevPath,
//             { id: selectedFolder._id, name: selectedFolder.name },
//           ];
//         });
//         refetchPolicyItems();
//         console.log("Refetching folder items");
//       }
//     } else {
//       console.log("Root folder clicked");
//       setSelectedFolderId(null);
//       setFolderPath([]);
//       refetchPolicyItems();
//       console.log("Refetching top-level items");
//     }
//   };

//   const handleGoBack = () => {
//     if (folderPath.length > 0) {
//       const updatedPath = folderPath.slice(0, -1);
//       setFolderPath(updatedPath);
//       const previousFolderId = updatedPath[updatedPath.length - 1]?.id || null;
//       setSelectedFolderId(previousFolderId);
//       refetchPolicyItems();
//     }
//   };

//   const handleFolderSelection = async () => {
//     console.log("Attempting to select a folder...");
//     try {
//       const directoryHandle = await window.showDirectoryPicker();
//       console.log("Folder selected:", directoryHandle.name);
//       setSelectedFolder(directoryHandle);
//     } catch (error) {
//       console.error("Error selecting folder:", error);
//     }
//   };
//   const handleCoverPictureChange = (event: any) => {
//     setCoverPicture(event.target.files[0]);
//   };
//   const processSelectedFolder = async (
//     directoryHandle: FileSystemDirectoryHandle
//   ) => {
//     try {
//       setIsUploading(true);
//       setUploadProgress(0);

//       const totalItems = await countFolderItems(directoryHandle);
//       let processedItems = 0;

//       await processDirectory(
//         directoryHandle,
//         "",
//         selectedFolderId || "",
//         (progress) => {
//           processedItems++;
//           const percentage = Math.round((processedItems / totalItems) * 100);
//           setUploadProgress(percentage);
//         }
//       );

//       setIsUploading(false);
//       console.log("Folder processing completed.");
//     } catch (error) {
//       setIsUploading(false);
//       console.error("Error processing folder:", error);
//     }
//   };

//   const countFolderItems = async (
//     directoryHandle: FileSystemDirectoryHandle
//   ): Promise<number> => {
//     let count = 0;

//     for await (const entry of directoryHandle.values()) {
//       count++;
//       if (entry.kind === "directory") {
//         count += await countFolderItems(entry);
//       }
//     }

//     return count;
//   };

//   const processDirectory = async (
//     directoryHandle: FileSystemDirectoryHandle,
//     basePath: string,
//     parentFolderId: string,

//     onProgress: (progress: number) => void
//   ) => {
//     for await (const entry of directoryHandle.values()) {
//       const path = `${basePath}/${entry.name}`;
//       if (entry.kind === "file") {
//         const file = await entry.getFile();
//         await uploadFile(path, file, parentFolderId);
//         onProgress(1);
//       } else if (entry.kind === "directory") {
//         const newFolderId = await createDirectory(path, parentFolderId);
//         await processDirectory(entry, path, newFolderId, onProgress);
//       }
//     }
//   };

//   const createDirectory = async (path: string, parentId: string) => {
//     console.log(`Creating directory: ${path}`);
//     const pathParts = path.split("/").filter((part) => part !== "");
//     const folderName = pathParts[pathParts.length - 1];

//     const existingFolder = folderContents.find(
//       (item: any) => item.name === folderName && item.parentId === parentId
//     );

//     if (existingFolder) {
//       return existingFolder._id;
//     } else {
//       const newFolder = await createPolicyFolder({
//         name: folderName,
//         parentId: parentId,
//         section: section,
//         title: title,
//         year: year,
//         description: description,
//         publishDate: publishDate,
//         documentType: documentType,
//         quarter: quarter,
//         applicableTo: applicableTo.join(","),
//         status: status,
//         //@ts-ignore
//         departmentId: departmentsId,
//         departmentName: selectedDepartmentName,
//       }).unwrap();
//       return newFolder._id;
//     }
//   };
//   console.log(departmentsId, "departmentsId", selectedDepartmentName);
//   const uploadFile = async (
//     path: string,
//     file: File,
//     parentFolderId: string
//   ) => {
//     console.log(`Uploading file: ${path}`);
//     const formData = new FormData();
//     formData.append("policiesItem", file);
//     formData.append("parentId", parentFolderId);
//     formData.append("section", section);
//     formData.append("title", title);
//     formData.append("year", year);
//     formData.append("description", description);
//     formData.append("publishDate", publishDate);
//     formData.append("documentType", documentType);
//     formData.append("quarter", quarter);
//     formData.append("applicableTo", applicableTo.join(","));
//     formData.append("status", status);
//     formData.append("departmentId", departmentsId);
//     formData.append("departmentName", selectedDepartmentName);
//     try {
//       await uploadPoliciesData(formData);
//       console.log("File uploaded successfully");
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };

//   const handleCreateFolder = async () => {
//     if (selectedFolder) {
//       try {
//         await processSelectedFolder(selectedFolder);
//         setFolderName("");
//         setSection("");
//         setTitle("");
//         setDescription("");
//         setpublishDate("");
//         setDocumentType("");
//         setStatus("");
//         setUploadedFiles([]);
//         setOpenUploadDialog(false);

//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }

//         setSnackbarMessage("Folder and its contents created successfully");
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error("Error creating folder and its contents:", error);
//         setSnackbarMessage("Error creating folder and its contents");
//         setSnackbarOpen(true);
//       }
//     }
//   };
//   const handleChangeTitleField = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     const inputValue = event.target.value;
//     const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

//     if (regex.test(inputValue) && inputValue.length <= 170) {
//       // If the input passes the validation and is within the character limit, update the state
//       setTitle(inputValue);
//       setTitleError("");
//     } else if (inputValue.length > 170) {
//       // If the input exceeds the character limit, set an error message
//       setTitleError("Title cannot exceed 170 characters.");
//     } else {
//       // If the input contains invalid characters, set an error message
//       setTitleError("Please enter only letters, numbers, and spaces.");
//     }
//   };
//   // const validateForm = () => {
//   //   let errors = {
//   //     name: "",
//   //     section: "",
//   //     title: "",
//   //     year: "",
//   //     description: "",
//   //     publishDate:"",
//   //     documentType: "",
//   //     quarter: "",
//   //     applicableTo: "",
//   //     status: "",
//   //   };

//   //   if (!folderName.trim()) {
//   //     errors.name = "Name is required";
//   //   }
//   //   if (!section.trim()) {
//   //     errors.section = "Section is required";
//   //   }
//   //   if (!title.trim()) {
//   //     errors.title = "title is required";
//   //   }
//   //   if (!year.trim()) {
//   //     errors.year = "year is required";
//   //   }
//   //   if (!description.trim()) {
//   //     errors.description = "description is required";
//   //   }
//   //   if (!publishDate.trim()) {
//   //     errors.publishDate = "publishDate is required";
//   //   }
//   //   if (!documentType.trim()) {
//   //     errors.documentType = "Document Type is required";
//   //   }
//   //   if (!quarter.trim()) {
//   //     errors.quarter = "Quarter Type is required";
//   //   }
//   //   if (applicableTo.length === 0) {
//   //     errors.applicableTo = "Applicable To is required";
//   //   }
//   //   if (!status.trim()) {
//   //     errors.status = "Status is required";
//   //   }

//   //   setValidationErrors(errors);
//   //   return Object.values(errors).every((error) => error === "");
//   // };
//   const resetFormFields = () => {
//     setTitleError("");
//     setQuarteError("");
//     setDescriptionError("");
//     setYearError("");
//     setImageError("");
//     setPublishDateError("");
//   };
//   const resetAllErrorAlert = () => {
//     setTitleError("");
//     setQuarteError("");
//     setDescriptionError("");
//     setYearError("");
//     setImageError("");
//     setPublishDateError("");
//     // setDeginError("");
//   };
//   const validateForm = () => {
//     let formIsValid = true;

//     if (title === "") {
//       setTitleError("Title is required");
//       formIsValid = false;
//     } else {
//       setTitleError(""); // Clear error when Title is valid
//     }

//     if (
//       description === "" ||
//       description.replace(/<[^>]*>/g, "").trim() === ""
//     ) {
//       setDescriptionError("Description is required");
//       formIsValid = false;
//     } else {
//       setDescriptionError(""); // Clear error when Description is valid
//     }
//     if (quarter === "") {
//       setQuarteError("Quarter is required");
//       formIsValid = false;
//     } else {
//       setQuarteError(""); // Clear error when Description is valid
//     }
//     if (year === "") {
//       setYearError("Year is required");
//       formIsValid = false;
//     } else {
//       setYearError(""); // Clear error when Description is valid
//     }

//     if (selectedFile === null && uploadedFiles.length == 0) {
//       setImageError("File is required");
//       formIsValid = false;
//     } else {
//       setImageError(""); // Clear error when selectedFiles is valid
//     }
//     if (publishDate === "") {
//       setPublishDateError("Publish date is required");
//       formIsValid = false;
//     } else {
//       setPublishDateError(""); // Clear error when eventDate is valid
//     }
//     setIsFormValid(formIsValid); // Update the form validation state
//     return {
//       formIsValid,
//     };
//   };

//   useEffect(() => {
//     validateForm();
//   }, [
//     title,
//     description,
//     uploadedFiles,
//     publishDate,
//     quarter,
//     year,
//     selectedFile,
//   ]);
//   console.log(uploadedFiles, "selectedFile", selectedFile);

//   const handleCreateFolderNew = async () => {
//     if (!validateForm()) {
//       return;
//     }

//     try {
//       const folderData = {
//         name: folderName,
//         parentId: selectedFolderId,
//         section: section,
//         title: title,
//         year: year,
//         description: description,
//         publishDate: publishDate,
//         documentType:
//           documentType === "addChoice" && manualDocumentType !== ""
//             ? manualDocumentType
//             : documentType,
//         quarter: quarter,
//         applicableTo: applicableTo.join(","),
//         status: status,
//         departmentId: departmentsId,
//         departmentName: selectedDepartmentName,
//       };

//       const createdFolder = await createPolicyFolder(folderData).unwrap();

//       setFolderName("");
//       setSection("");
//       setTitle("");
//       setDescription("");
//       setpublishDate("");
//       setDocumentType("");
//       setStatus("");
//       setUploadedFiles([]);

//       setOpenNewFolderDialog(false);

//       if (selectedFolderId) {
//         refetchPolicyItems();
//       } else {
//         refetchTopLevelItems();
//       }

//       setSnackbarMessage("Folder created successfully");
//       setSnackbarOpen(true);
//     } catch (error) {
//       console.error("Error creating folder:", error);
//       setSnackbarMessage("Error creating folder");
//       setSnackbarOpen(true);
//     }
//   };

//   const handleFileChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (event.target.files && event.target.files.length > 0) {
//       setSelectedFile(event.target.files[0]);
//     }
//   };

//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     if (files) {
//       const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
//       const unsupportedFiles = Array.from(files).filter((file) => {
//         const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
//         return !supportedExtensions.includes(fileExtension);
//       });
//       if (unsupportedFiles.length > 0) {
//         window.alert(
//           "Unsupported file type. Only PDF, DOC, DOCX, and PPT are supported"
//         );
//         event.target.value = "";
//         return;
//       }
//       const acceptedFiles = Array.from(files).filter((file) => {
//         const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
//         return supportedExtensions.includes(fileExtension);
//       });
//       setUploadedFiles(acceptedFiles);
//     }
//   };

//   const handleUploadFiles = async () => {
//     resetAllErrorAlert();
//     const { formIsValid } = validateForm();
//     if (!formIsValid) {
//       // There are validation errors, set errors state and don't submit the form
//       return;
//     }
//     if (uploadedFiles.length > 0) {
//       setIsUploading(true);
//       // const supportedExtensions = ["pdf", "doc", "docx", "ppt"];
//       const supportedExtensions = ["pdf"];
//       const unsupportedFiles = uploadedFiles.filter((file) => {
//         const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";
//         return !supportedExtensions.includes(fileExtension);
//       });

//       if (unsupportedFiles.length > 0) {
//         window.alert("Unsupported file type. Only PDF supported");
//         setIsUploading(false);
//         return;
//       }

//       const formData = new FormData();
//       uploadedFiles.forEach((file) => {
//         formData.append("policiesItem", file);
//       });
//       if (coverPicture) {
//         formData.append("coverPicture", coverPicture);
//       }
//       formData.append("parentId", selectedFolderId || "");
//       formData.append("section", section);
//       formData.append("title", title),
//         formData.append("year", year),
//         formData.append("description", description),
//         formData.append("publishDate", publishDate),
//         formData.append(
//           "documentType",
//           documentType === "addChoice" && manualDocumentType !== ""
//             ? manualDocumentType
//             : documentType
//         );
//       formData.append("quarter", quarter);
//       formData.append("applicableTo", applicableTo.join(","));
//       formData.append("status", status);
//       formData.append("departmentId", departmentsId);
//       formData.append("departmentName", selectedDepartmentName);

//       try {
//         await uploadPoliciesData(formData);
//         setUploadedFiles([]);
//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }
//         setOpenUploadDialog(false);
//         setSnackbarMessage("Files uploaded successfully");
//         setSnackbarOpen(true);
//         resetFormFields();
//       } catch (error) {
//         console.error("Error uploading files:", error);
//         setSnackbarMessage("Error uploading files");
//         setSnackbarOpen(true);
//       } finally {
//         setIsUploading(false); // Re-enable the button after the upload process completes
//       }
//     }
//   };

//   // const handleEditIssueNo = (event:any) =>{
//   //   setEditIssueNumber(event.target.value)
//   // }
//   const formatDate = (dateString: any) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };
//   const handleEditItem = (itemId: string) => {
//     const item = folderContents.find((item) => item._id === itemId);
//     console.log(item, "handleEditItem");
//     setCoverPicture(item?.coverPicture);
//     setEditName(item?.fileName);
//     setEditTitle(item?.title);
//     setEditDescription(item?.description);
//     // setEditPublishDate(item?.publishDate)
//     setEditPublishDate(formatDate(item?.publishDate));
//     setEditYear(item?.year);
//     setEditQuaterValue(item?.quarter);
//     // setEditIssueNumber(item?.issueNumber)
//     setEditTitle(item?.title);
//     if (item) {
//       const documentTypes = [
//         "CASE STUDIES",
//         "PRESENTATION",
//         "BROCHURES",
//         "Authority Matrix",
//         "Policy",
//         "Process Map",
//         "Supplementry Document",
//         "Form",
//         "Template",
//         "Service Level Agreement",
//         "Certificate",
//       ];
//       const quarterTypes = ["Q1", "Q2", "Q3", "Q4"];
//       if (quarterTypes.includes(item.quarter)) {
//         setEditedItem(item);
//       }
//       // } else {
//       //   setEditedItem({
//       //     ...item,
//       //     documentType: "addChoice",
//       //     manualDocumentType: item.documentType,
//       //   });
//       // }

//       setOpenEditDialog(true);
//     }
//   };

//   const handleFieldChange = (field: string, value: any) => {
//     if (field === "applicableTo") {
//       setEditedItem((prevItem: any) => ({
//         ...prevItem,
//         applicableTo: value,
//       }));
//     } else {
//       setEditedItem((prevItem: any) => ({
//         ...prevItem,
//         [field]: value,
//       }));
//     }
//   };
//   console.log(editedItem, "editedItem");
//   const handleUpdateItem = () => {
//     console.log(editTitle, coverPicture, editedItem._id, "handleUpdateItem");
//     const formData = new FormData();
//     formData.append("title", editTitle);
//     formData.append("description", editDescription);
//     formData.append("name", editName);
//     formData.append("publishDate", editPublishDate);
//     formData.append("year", editYear);
//     //@ts-ignore
//     formData.append("coverPicture", coverPicture);
//     formData.append("Quarter", editQuaterValue);
//     formData.append("id", editedItem._id);
//     console.log(formData, "formData");
//     //@ts-ignore
//     updatePolicyItem(formData);

//     refetchTopLevelItems();
//     refetchPolicyItems();
//     setSnackbarMessage("Changes have been published successfully");
//     setSnackbarOpen(true);
//     setOpenEditDialog(false);
//     setEditedItem(null);
//   };
//   const handleUpdateItemx = async () => {
//     if (editedItem) {
//       try {
//         const updatedData = {
//           name: editedItem.name || editedItem.fileName,
//           section: editedItem.section,
//           title: editedItem.title,
//           year: editedItem.year,
//           description: editedItem.description,
//           publishDate: editedItem.publishDate,
//           quarter: editedItem.quarter,
//           documentType:
//             editedItem.documentType === "addChoice" &&
//               editedItem.manualDocumentType !== ""
//               ? editedItem.manualDocumentType
//               : editedItem.documentType,
//           applicableTo: editedItem.applicableTo,
//           status: editedItem.status,
//           coverPicture: coverPicture,
//         };

//         // await updatePolicyItem({
//         //   id: editedItem._id,
//         //   data: updatedData,
//         // }).unwrap();

//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }

//         setSnackbarMessage("Item updated successfully");
//         setSnackbarOpen(true);
//         setOpenEditDialog(false);
//         setEditedItem(null);
//       } catch (error) {
//         console.error("Error updating item:", error);
//         setSnackbarMessage("Error updating item");
//         setSnackbarOpen(true);
//       }
//     }
//   };

//   const handleCloseNewFolderDialog = () => {
//     setOpenNewFolderDialog(false);
//     setUploadOption(null);
//     resetValues();
//   };

//   const handleCloseEditDialog = () => {
//     setValidationErrors({
//       name: "",
//       section: "",
//       title: "",
//       year: "",
//       description: "",
//       publishDate: "",
//       documentType: "",
//       quarter: "",
//       applicableTo: "",
//       status: "",
//     });
//     setOpenEditDialog(false);
//     resetValues();
//   };

//   const handleCloseUploadDialog = () => {
//     setOpenUploadDialog(false);
//     setValidationErrors({
//       name: "",
//       section: "",
//       title: "",
//       year: "",
//       description: "",
//       publishDate: "",
//       documentType: "",
//       quarter: "",
//       applicableTo: "",
//       status: "",
//     });
//     setUploadOption(null);
//     resetValues();
//   };

//   const handleDeleteItem = (itemId: string) => {
//     setSelectedItemId(itemId);
//     setAlertContent("Are you sure you want to delete this item?");
//     setAlertActivation(true);
//   };

//   const handlePublishDateChange = (event: any) => {
//     const date = event.target.value;
//     setpublishDate(date);

//     if (date) {
//       const year = new Date(date).getFullYear();
//       setYear(year.toString());
//     } else {
//       setYear("");
//     }
//   };
//   const getTodayDate = () => {
//     const today = new Date();
//     const yyyy = today.getFullYear();
//     const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
//     const dd = String(today.getDate()).padStart(2, "0");
//     return `${yyyy}-${mm}-${dd}`;
//   };

//   const handleConfirmDelete = async () => {
//     if (selectedItemId) {
//       try {
//         await deletePolicyItem(selectedItemId).unwrap();
//         if (selectedFolderId) {
//           refetchPolicyItems();
//         } else {
//           refetchTopLevelItems();
//         }
//         setSnackbarMessage("Item deleted successfully");
//         setSnackbarOpen(true);
//       } catch (error) {
//         console.error("Error deleting item:", error);
//         setSnackbarMessage("Error deleting item");
//         setSnackbarOpen(true);
//       }
//       setSelectedItemId(null);
//       setAlertActivation(false);
//     }
//   };

//   const handleCancelDelete = () => {
//     setSelectedItemId(null);
//     setAlertActivation(false);
//   };

//   const resetValues = () => {
//     setFolderName("");
//     setSection("");
//     setTitle("");
//     setDescription("");
//     setpublishDate("");
//     setDocumentType("");
//     setApplicableTo([]);
//     setStatus("");
//     setUploadedFiles([]);
//     setSelectedFolder(null);
//     setEditedItem(null);
//   };
//   const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
//     setAnchorEl1(event.currentTarget);
//   };
//   const handleClose1 = () => {
//     setAnchorEl1(null);
//   };
//   const handleUploadClick = () => {
//     setOpenConfirmationDialog(true);
//     setAnchorEl1(null);
//   };

//   const handleConfirmationOption = (option: "FILE" | "FOLDER") => {
//     setSelectedOption(option);
//     setOpenUploadDialog(true);
//     setOpenConfirmationDialog(false);
//     handleClose1();
//   };

//   // const handlePageChange = (
//   //   event: React.ChangeEvent<unknown>,
//   //   value: number
//   // ) => {
//   //   if (folderContents.length === 0) {
//   //     setCurrentPage(1);
//   //   } else {
//   //     setCurrentPage(value);
//   //   }
//   // };
//   // const totalPages = Math.max(
//   //   1,
//   //   Math.ceil(folderContents.length / itemsPerPage)
//   // );

//   // const handlePrevPage = () => {
//   //   setCurrentPage((prev) => Math.max(1, prev - 1));
//   // };

//   // const handleNextPage = () => {
//   //   setCurrentPage((prev) => Math.min(totalPages, prev + 1));
//   // };

//   // const handlePageClick = (page: React.SetStateAction<number>) => {
//   //   setCurrentPage(page);
//   // };

//   // const renderPageNumbers = () => {
//   //   const pageNumbers = [];
//   //   for (let i = 1; i <= totalPages; i++) {
//   //     pageNumbers.push(
//   //       <Typography
//   //         key={i}
//   //         variant="body2"
//   //         sx={{
//   //           width: 30,
//   //           height: 30,
//   //           borderRadius: "4px",
//   //           backgroundColor: currentPage === i ? "#e0001b" : "transparent",
//   //           color: currentPage === i ? "white" : "inherit",
//   //           display: "flex",
//   //           alignItems: "center",
//   //           justifyContent: "center",
//   //           cursor: "pointer",
//   //           mx: 0.5,
//   //         }}
//   //         onClick={() => handlePageClick(i)}
//   //       >
//   //         {i}
//   //       </Typography>
//   //     );
//   //   }
//   //   return pageNumbers;
//   // };
//   const columns: GridColDef<GridValidRowModel>[] = [

//     // {
//     //   field: "index",
//     //   headerName: "ID",
//     //   width: 80,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "ByrnePostTableCell",
//     //   valueGetter: (params: any) => {
//     //     const index = folderContents.findIndex(item => item._id === params.row._id);
//     //     return index + 1;
//     //   },
//     // },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       field: "name",
//       headerName: "Name",
//       width: 250,
//       sortable: false,
//       renderCell: (params: GridRenderCellParams) => {
//         const row = params.row;
//         const isFolder = row.type === "folder";
//         const fileName = row.fileName || "";
//         const folderName = row.name || "";
//         const itemName = isFolder ? folderName : fileName;

//         return (
//           <Tooltip
//             placement="right"
//             title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
//             arrow
//           >
//           <div style={{ display: "flex", alignItems: "center" }}>
//             {isFolder ? (
//               <FolderIcon style={{ marginRight: "8px" }} />
//             ) : (
//               <InsertDriveFileIcon style={{ marginRight: "8px" }} />
//             )}
//             <span>{itemName}</span>
//           </div>
//           </Tooltip>
//         );
//       },
//     },
//     // {
//     //   field: "section",
//     //   headerName: "Section",
//     //   width: 150,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     {
//       field: "title",
//       headerName: "Title",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//       renderCell: (params: GridRenderCellParams) => {
//         const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(null);

//         const handleTooltipOpen = (id: number) => {
//           setTooltipOpenCell(id);
//         };

//         const handleTooltipClose = () => {
//           setTooltipOpenCell(null);
//         };

//         return (
//           <Tooltip
//             open={tooltipOpenCell === params.row.id}
//             onOpen={() => handleTooltipOpen(params.row.id)}
//             onClose={handleTooltipClose}
//             title={params.value}
//             placement="right-start"
//             arrow
//           >
//             <div>{params.value}</div>
//           </Tooltip>
//         );
//       },
//     },
//     {
//       field: "description",
//       headerName: "Description",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderCell: (params: GridRenderCellParams) => {
//         const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(null);

//         const handleTooltipOpen = (id: number) => {
//           setTooltipOpenCell(id);
//         };

//         const handleTooltipClose = () => {
//           setTooltipOpenCell(null);
//         };

//         return (
//           <Tooltip
//             open={tooltipOpenCell === params.row.id}
//             onOpen={() => handleTooltipOpen(params.row.id)}
//             onClose={handleTooltipClose}
//             title={params.value || "No description"}
//             placement="right-start"
//             arrow
//           >
//             <div className="description-tooltip">{params.value || "No description"}</div>
//           </Tooltip>
//         );
//       },
//     },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       field: "publishDate",
//       headerName: "Publish Date",
//       width: 150,
//       sortable: false,
//       valueFormatter: (params: { value: string | number | Date }) => {
//         const date = new Date(params.value);
//         return date.toLocaleDateString();
//       },
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//     },
//     {
//       field: "quarter",
//       headerName: "Quarter",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//     },
//     {
//       field: "year",
//       headerName: "Year",
//       width: 150,
//       sortable: false,
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//           <span>{params.colDef.headerName}</span>
//           <Tooltip title="Filter">
//             <IconButton
//               onClick={(event) => handleFilterIconClick(event, params.field)}
//               size="small"
//               style={{ marginLeft: 'auto' }}
//             >
//               <FilterListIcon />
//             </IconButton>
//           </Tooltip>
//         </div>
//       ),
//     },
//     {
//       field: "coverPicture",
//       headerName: "Cover Picture",
//       width: 150,
//       headerClassName: "MarketingTableHeader",
//       cellClassName: "MarketingTableCell",
//       sortable: true,
//       valueGetter: (params: any) =>
//         params.value && params.value.trim() ? params.value : "---",
//       renderCell: (params: any) => <ImageCell value={params.value} />,
//     },
//     // {
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     //   field: "documentType",
//     //   headerName: "Document Type",
//     //   width: 250,
//     //   sortable: false,
//     // },
//     // {
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     //   field: "applicableTo",
//     //   headerName: "Applicable To",
//     //   width: 200,
//     //   sortable: false,
//     //   renderCell: (params: { row: { _id: GridRowId; applicableTo: any } }) => {
//     //     const applicableTo = params.row.applicableTo;
//     //     if (Array.isArray(applicableTo)) {
//     //       return (
//     //         <div
//     //           style={{
//     //             whiteSpace: "pre-wrap",
//     //             textAlign: "center",
//     //             display: "flex",
//     //             flexDirection: "column",
//     //             alignItems: "center",
//     //             justifyContent: "center",
//     //             height: "100%",
//     //           }}
//     //         >
//     //           {applicableTo.map((value) => (
//     //             <span key={value}>{value}</span>
//     //           ))}
//     //         </div>
//     //       );
//     //     }
//     //     return applicableTo;
//     //   },
//     // },
//     // {
//     //   field: "status",
//     //   headerName: "Status",
//     //   width: 180,
//     //   sortable: false,
//     //   headerClassName: "TableHeader",
//     //   cellClassName: "TableHeader",
//     // },
//     {
//       headerClassName: "TableHeader",
//       cellClassName: "ByrnePostTableCell",
//       field: "actions",
//       headerName: "Actions",
//       width: 130,
//       sortable: false,
//       renderCell: (params: GridRenderCellParams) => {
//         const itemId = params.row._id;

//         return (
//           <div>
//              <Tooltip title="Edit">
//             <IconButton
//               sx={{ padding: "3px" }}
//               size="small"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 handleEditItem(itemId);
//               }}
//             >
//               <EditIcon />
//             </IconButton>
//             </Tooltip>
//             <Tooltip title="Delete">
//             <IconButton
//               sx={{ padding: "3px" }}
//               size="small"
//               onClick={(e) => {
//                 e.stopPropagation();
//                 handleDeleteItem(itemId);
//               }}
//             >
//               <DeleteIcon />
//             </IconButton>
//             </Tooltip>
//           </div>
//         );
//       },
//     },
//   ];

//   const getRowClassName = (params: any) => {
//     let className = "custom-row-class"; // Default class

//     if (params.row.Id % 2 === 0) {
//       className += " even-row"; // Add even-row class for even IDs
//     } else {
//       className += " odd-row"; // Add odd-row class for odd IDs
//     }

//     return className;
//   };

//   const getCellClassName = (params: any) => {
//     return "eve";
//   };
//   let content;

//   if (isTopLevelItemsLoading) {
//     content = (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress style={{ color: "#009BAD" }} />
//       </div>
//     );
//   } else if (isTopLevelItemsError) {
//     content = (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <Typography>Error loading top-level items.</Typography>
//       </div>
//     );
//   } else {
//     content = (
//       <div
//         style={
//           {
//             // display: "flex",
//             // height: "75%",
//             // marginLeft: "15px",
//             // marginRight: "15px",
//           }
//         }
//         className="mainTable"
//       >
//         <div className="ByrnePost-table1">
//           <Box sx={{ flexGrow: 1, width: "100%" }}>
//             <>
//               {isLoadingg && <LoaderComponent />}
//               <DataGrid
//                 sx={{
//                   '& .MuiDataGrid-columnHeaders': {
//                     backgroundColor: '#FDE4E5 !important',
//                   },
//                   "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
//                     outline: "none",
//                   },
//                   border: "none !important",
//                   '.MuiDataGrid-cellContent': {
//         pointerEvents: 'none',
//       },
//               }}
//                 componentsProps={{
//                   footer: { style: { display: "none" } },
//                 }}
//                 rows={paginatedRows}
//                 columns={columns}
//                 // getRowId={(row) => row._id}
//                 pageSizeOptions={[15]}
//                 disableRowSelectionOnClick
//                 disableDensitySelector
//                 disableColumnFilter
//                 disableColumnMenu
//                 hideFooterPagination={true}
//                 disableColumnSelector={true}
//                 getRowClassName={getRowClassName}
//                 getCellClassName={getCellClassName}
//                 onRowClick={(params) => {
//                   const row = params.row;
//                   if (row.type === "folder") {
//                     handleFolderClick(row._id);
//                   }
//                 }}
//                 style={{
//                   color: "rgba(30, 30, 30, 1)!important",
//                   boxShadow: "20px !important",
//                   width: "100%",
//                 }}
//               />
//             </>
//           </Box>
//         </div>

//         <AlertYesNo
//           isAlertOpen={alertActivation}
//           handleAlertYes={handleConfirmDelete}
//           handleAlertClose={handleCancelDelete}
//         >
//           {alertContent}
//         </AlertYesNo>
//         <Snackbar
//           anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
//           open={snackbarOpen}
//           autoHideDuration={3000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <div
//             style={{
//               backgroundColor: "#e0001b",
//               color: "white",
//               padding: "10px",
//               borderRadius: "4px",
//               marginBottom: "20px",
//               marginRight: "24px",
//               fontSize: "14px !important",
//             }}
//           >
//             {snackbarMessage}
//           </div>
//         </Snackbar>
//       </div>
//     );
//   }
//   function handleOpenDialog(event: React.MouseEvent<HTMLLIElement>): void {
//     // throw new Error("Function not implemented.");
//     // console.log("Dialog opened");
//     setOpenNewFolderDialog(true);
//   }

//   const handleFileUpload = () => {
//     if (selectedFile) {
//       const formData = new FormData();
//       formData.append("file", selectedFile);

//       // Check if selectedFolderId is not null
//       if (selectedFolderId) {
//         formData.append("parentId", selectedFolderId);
//       } else {
//         // Handle the case when selectedFolderId is null
//         // For example, you can choose a default folder or display an error message to the user
//         console.log(
//           "Warning: No folder selected. Uploading to default location..."
//         );
//       }

//       // Make the API request to upload the file using the mutation hook
//       uploadGalleryFoldersMedia(formData)
//         .unwrap()
//         .then(() => {
//           // Close the upload dialog
//           handleCloseUploadDialog();
//           // Open the success dialog
//           setOpenDialog2(true);
//         })
//         .catch((error: any) => {
//           console.error("Error uploading file:", error);
//           // Optionally, show an error message to the user
//         });
//     } else {
//       console.log("No file selected.");
//       // Optionally, show a message to the user indicating that no file is selected
//     }
//   };

//   return (
//     <div>
//       {/* <Grid className="upperPart1">
//           <Grid container alignItems="center" style={{ display: "flex" }}>
//             <IconButton
//               onClick={handleGoBack}
//               disabled={folderPath.length === 0}
//             >
//               <ArrowBackIcon />
//             </IconButton>
//             <Grid item style={{ display: "flex", alignItems: "left" }}>
//               <Typography
//                 component="span"
//                 onClick={() => handleFolderClick(null)}
//                 style={{
//                   cursor: "pointer", color: "#e0001b", marginLeft: -"13px",
//                   position: "relative",
//                   right: "11px",
//                   fontSize:"18px",
//                   fontWeight:600
//                  }}
//               >
//                 Byrne Posts
//               </Typography>
//               <FolderPath
//                 path={folderPath}
//                 onFolderClick={handleFolderClick}
//                 selectedFolderId={selectedFolderId}
//               />
//             </Grid>
//           </Grid>
//         </Grid> */}
//       <div className="EventsHeaderStack">
//         <div className="EventsHeader">Byrne Posts</div>
//         <div className="new-event-btn ">
//           {/* <Grid className="new&uploadGrid"> */}
//           <Button
//             startIcon={<AddCircleOutlineRoundedIcon />}
//             // onClick={() => setOpenNewFolderDialog(true)}
//             onClick={handleClick1}
//             // className="newFolder"
//             // sx={{ textTransform: "capitalize", marginRight: "10px" }}
//             className="create"
//             sx={{ textTransform: "none", backgroundColor: "#e0001b" }}
//           >
//             New
//           </Button>
//           <Menu
//             anchorEl={anchorEl1}
//             open={Boolean(anchorEl1)}
//             onClose={handleClose1}
//           >
//             {/* <MenuItem
//                 sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                 //onClick={handleUploadClick}
//                 onClick={handleOpenDialog}
//               >
//                 <img src={folder} />
//                 <div className="Menuname">Folder</div>
//               </MenuItem> */}

//             <MenuItem
//               sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//               // onClick={handleFileUpload}
//               onClick={() => handleConfirmationOption("FILE")}
//             >
//               <img src={filee} />
//               <label>
//                 <div className="Menuname">Upload Files </div>
//                 <input
//                   id="fileInput"
//                   // type="file"
//                   onChange={handleFileChanges}
//                   style={{ display: "none" }}
//                 />
//               </label>
//             </MenuItem>
//             {/* <MenuItem
//                 sx={{ display: "flex", alignItems: "center", gap: "8px" }}
//                 //onClick={handleCloseUploadDialog}
//                 // onClick = {handleUploadClick}
//                 onClick={() => handleConfirmationOption("FOLDER")}
//               >
//                 <img src={filee} />
//                 <div className="Menuname">Upload Folder</div>
//               </MenuItem> */}
//           </Menu>
//           {/* <Button
//               startIcon={<img src={uploadblue} alt="Upload Icon" />}
//               onClick={handleUploadClick}
//               className="upload"
//               sx={{ textTransform: "capitalize" }}
//             >
//               UPLOAD
//             </Button> */}
//           {/* </Grid> */}
//         </div>
//       </div>
//       <div>
//         <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
//           <div className="Tablebreadcrumb">
//             <div className="Tablebreadcrumb1">Landing Page</div>
//             <span className="Tablebreadcrumbarrow-icon">
//               <NavigateNextIcon />
//             </span>
//             <span className="Tablebreadcrumb2">Byrne Posts</span>
//           </div>
//           <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
//             <SearchBar
//               searchTerm={searchTerm}
//               onSearchChange={handleSearchChange}
//               onClearSearch={handleClearSearch} // Add this line
//             />
//           </div>
//         </Stack>
//       </div>
//       {/* <div className="Tablebreadcrumb">
//           <IconButton
//             onClick={handleGoBack}
//             disabled={folderPath.length === 0}

//           >
//             <ArrowBackIcon />
//           </IconButton>
//           <span className="Tablebreadcrumb1">LANDING PAGE</span> */}
//       {/* <img src={Breadcrumbs} alt="" className="PolicyArrowIcon" /> */}
//       {/* <span className="PolicyArrowIcon">
//             <NavigateNextIcon />
//           </span>
//           <span className="Breadcrumbs2">Byrne Posts</span>
//           <FolderPath
//             path={folderPath}
//             onFolderClick={handleFolderClick}
//             selectedFolderId={selectedFolderId}
//           />
//         </div> */}

//       <Drawer
//         anchor="right"
//         classes={{
//           paper: "newPosOfDialog",
//         }}
//         open={openNewFolderDialog}
//         onClose={() => {
//           setOpenNewFolderDialog(false);
//           setValidationErrors({
//             name: "",
//             section: "",
//             title: "",
//             year: "",
//             description: "",
//             publishDate: "",
//             documentType: "",
//             quarter: "",
//             applicableTo: "",
//             status: "",
//           });
//         }}
//       >
//         {/* <div>
//             <Grid className="dialogTitle">
//               <Grid>
//                 <Typography
//                   className="newItem"
//                   style={{ textAlign: "left", color: "#02354d" }}
//                 >
//                   New Folder
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <IconButton onClick={() => setOpenNewFolderDialog(false)}>
//                   <CloseIcon />
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </div> */}
//         <div className="plusIcon">
//           <div style={{ marginLeft: "10px", marginTop: "15px" }}>
//             <img src={plusIcon} alt="Plus Icon" />
//           </div>
//           <Typography className="addpolicy">Add Documents</Typography>
//         </div>
//         <div>
//           <Divider />
//           <div style={{ marginBottom: "10px" }}>
//             <div className="label">
//               <div className="subHeading">Folder Name</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <TextField
//               id="outlined-adornment-weight"
//               className="span"
//               value={folderName}
//               style={{ width: "100%" }}
//               onChange={(event) => {
//                 setFolderName(event.target.value);
//                 setValidationErrors((prevErrors) => ({
//                   ...prevErrors,
//                   name: event.target.value.trim() ? "" : "Name is required",
//                 }));
//               }}
//               placeholder="Enter folder name"
//               autoComplete="off" // Add autoComplete attribute and set it to "off"
//             />

//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.name}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Section</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={section}
//               style={{ width: "100%" }}
//               onChange={(event) => {
//                 setSection(event.target.value);
//                 setValidationErrors((prevErrors) => ({
//                   ...prevErrors,
//                   section: event.target.value.trim()
//                     ? ""
//                     : "Section is required",
//                 }));
//               }}
//               placeholder="Enter section"
//             />

//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.section}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Document Type</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <div className="documentTypeWrapper">
//               <Select
//                 id="outlined-adornment-documentType"
//                 className={`span ${documentType === "addChoice" ? "reduced-width" : ""
//                   }`}
//                 value={documentType}
//                 style={{ width: "100%", height: "40px" }}
//                 onChange={(event) => {
//                   setDocumentType(event.target.value as string);
//                   setValidationErrors((prevErrors) => ({
//                     ...prevErrors,
//                     documentType: event.target.value.trim()
//                       ? ""
//                       : "Document Type is required",
//                   }));
//                 }}
//               >
//                 <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                 <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                 <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                 <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
//                 <MenuItem value="Policy">Policy</MenuItem>
//                 <MenuItem value="Process Map">Process Map</MenuItem>
//                 <MenuItem value="Supplementry Document">
//                   Supplementry Document
//                 </MenuItem>
//                 <MenuItem value="Form">Form</MenuItem>
//                 <MenuItem value="Template">Template</MenuItem>
//                 <MenuItem value="Service Level Agreement">
//                   Service Level Agreement
//                 </MenuItem>
//                 <MenuItem value="Certificate">Certificate</MenuItem>
//                 <MenuItem value="addChoice">
//                   <div style={{ display: "flex", alignItems: "center" }}>
//                     <AddIcon style={{ marginRight: "8px" }} />
//                     Add Choice Manually
//                   </div>
//                 </MenuItem>
//               </Select>
//               {documentType === "addChoice" && (
//                 <div style={{ marginTop: "8px" }}>
//                   <TextField
//                     id="outlined-adornment-manual-document-type"
//                     className="span manual-document-type"
//                     value={manualDocumentType}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       setManualDocumentType(event.target.value)
//                     }
//                     placeholder="Enter document type"
//                   />
//                 </div>
//               )}
//             </div>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.documentType}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Applicable To</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <FormGroup>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("Sales")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "Sales"]
//                           : prevApplicableTo.filter(
//                             (item: string) => item !== "Sales"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="Sales"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("Operations")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "Operations"]
//                           : prevApplicableTo.filter(
//                             (item: string) => item !== "Operations"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="Operations"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("IT")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "IT"]
//                           : prevApplicableTo.filter(
//                             (item: string) => item !== "IT"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="IT"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("HR & Finance")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "HR & Finance"]
//                           : prevApplicableTo.filter(
//                             (item) => item !== "HR & Finance"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="HR & Finance"
//               />
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={applicableTo.includes("Health & Safety")}
//                     onChange={(event) => {
//                       const checked = event.target.checked;
//                       setApplicableTo((prevApplicableTo) =>
//                         checked
//                           ? [...prevApplicableTo, "Health & Safety"]
//                           : prevApplicableTo.filter(
//                             (item) => item !== "Health & Safety"
//                           )
//                       );
//                       setValidationErrors((prevErrors) => ({
//                         ...prevErrors,
//                         applicableTo: "",
//                       }));
//                     }}
//                   />
//                 }
//                 label="Health & Safety"
//               />
//             </FormGroup>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.applicableTo}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Status</div>
//               <img src={Asterisk} alt="..." style={{ marginBottom: "5px" }} />
//             </div>
//             <TextField
//               id="outlined-adornment-status"
//               className="span"
//               value={status}
//               style={{ width: "100%" }}
//               onChange={(event) => {
//                 setStatus(event.target.value);
//                 setValidationErrors((prevErrors) => ({
//                   ...prevErrors,
//                   status: event.target.value.trim() ? "" : "Status is required",
//                 }));
//               }}
//               placeholder="Enter status"
//             />

//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.status}
//               </div>
//             )}
//           </div>
//           <div className="actionDivTwo">
//             <Button
//               sx={{ textTransform: "capitalize" }}
//               onClick={handleCreateFolderNew}
//               className="saveBtn"
//             >
//               Create Folder
//             </Button>
//           </div>
//         </div>
//       </Drawer>

//       <Dialog open={isUploading}>
//         <DialogTitle>Upload Progress</DialogTitle>
//         <DialogContent>
//           <Typography variant="body1">{uploadProgress}%</Typography>
//           <LinearProgress variant="determinate" value={uploadProgress} />
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => setIsUploading(false)}
//             disabled={uploadProgress < 100}
//           >
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={openConfirmationDialog}
//         onClose={() => setOpenConfirmationDialog(false)}
//       >
//         <DialogTitle>Select Upload Option</DialogTitle>
//         <DialogContent>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-around",
//               marginTop: "16px",
//             }}
//           >
//             <Button
//               variant="contained"
//               onClick={() => handleConfirmationOption("FILE")}
//               style={{ textTransform: "none", backgroundColor: "#e0001b" }}
//             >
//               File
//             </Button>
//             <Button
//               variant="contained"
//               onClick={() => handleConfirmationOption("FOLDER")}
//               style={{ textTransform: "none", backgroundColor: "#e0001b" }}
//             >
//               Folder
//             </Button>
//           </div>
//         </DialogContent>
//       </Dialog>

//       <Drawer
//         anchor="right"
//         classes={{
//           paper: "newPosOfDialog",
//         }}
//         open={openUploadDialog}
//         onClose={handleCloseUploadDialog}
//       >
//         <div>
//           <Grid className="dialogTitle">
//             <Grid>
//               <Typography
//                 className="newItem"
//                 style={{ textAlign: "left", color: "#02354d" }}
//               >
//                 {selectedOption === "FILE" ? "Upload Files" : "Select Folder"}
//               </Typography>
//             </Grid>
//             <Grid>
//               <IconButton onClick={() => setOpenUploadDialog(false)}>
//                 <CloseIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//         </div>
//         <div>
//           <Divider />
//           <div>
//             {selectedOption === "FILE" && (
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Select Files</div>
//                 </div>
//                 <input
//                   type="file"
//                   multiple
//                   accept=".pdf"
//                   onChange={handleFileChange}
//                 />
//                 <div className="error">{imageError}</div>
//               </div>
//             )}
//           </div>
//           {selectedOption === "FOLDER" && (
//             <div style={{ marginBottom: "10px" }}>
//               <div className="label">
//                 <div className="subHeading">Select Folder</div>
//               </div>
//               <button onClick={handleFolderSelection}>Select Folder</button>
//               {selectedFolder && <p>Selected Folder: {selectedFolder.name}</p>}
//             </div>
//           )}
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Select Cover Image</div>
//             </div>
//             <input
//               type="file"
//               accept="image/*"
//               multiple
//               // accept=".pdf,.doc,.docx,.ppt"
//               onChange={handleCoverPictureChange}
//             />
//             <div className="error">{imageError}</div>
//           </div>
//           {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Section</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-section"
//                 className="span"
//                 value={section}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setSection(event.target.value)}
//                 placeholder="Enter section"
//               />
//               {validationErrors.name && (
//                 <div style={{ color: "red", fontSize: "12px" }}>
//                   {validationErrors.section}
//                 </div>
//               )}
//             </div> */}
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Title</div>
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={title}
//               style={{ width: "100%" }}
//               // onChange={(event) => setTitle(event.target.value)}
//               onChange={handleChangeTitleField}
//               placeholder="Enter Title"
//               autoComplete="off"
//             />
//             <div className="error">{titleError}</div>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.title}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Description</div>
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={description}
//               style={{ width: "100%" }}
//               onChange={(event) => setDescription(event.target.value)}
//               placeholder="Enter Description"
//               autoComplete="off"
//             />
//             <div className="error">{descriptionError}</div>
//             {validationErrors.name && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.description}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Quarter</div>
//             </div>
//             <div className="documentTypeWrapper">
//               <Select
//                 autoComplete="off"
//                 id="outlined-adornment-documentType"
//                 className={`span ${quarter === "addChoice" ? "reduced-width" : ""
//                   }`}
//                 value={quarter}
//                 displayEmpty
//                 renderValue={(selected) => {
//                   if (selected === "") {
//                     return (
//                       <span style={{ color: "#888888" }}>Select a Quarter</span>
//                     );
//                   }
//                   return selected;
//                 }}
//                 style={{ width: "100%", height: "40px" }}
//                 onChange={(event) =>
//                   setQuarterType(event.target.value as string)
//                 }
//               >
//                 <MenuItem value="Q1">Q1</MenuItem>
//                 <MenuItem value="Q2">Q2</MenuItem>
//                 <MenuItem value="Q3">Q3</MenuItem>
//                 <MenuItem value="Q4">Q4</MenuItem>
//                 {/* <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem> */}
//               </Select>
//               {/* {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )} */}
//             </div>
//             <div className="error">{quarterError}</div>
//           </div>
//           <div style={{ marginBottom: "15px" }}>
//             <div className="label">
//               <div className="subHeading">Year</div>
//             </div>
//             <TextField
//               id="outlined-adornment-section"
//               className="span"
//               value={year}
//               style={{ width: "100%" }}
//               placeholder="Enter Year"
//               InputProps={{ readOnly: true }}
//               autoComplete="off"
//             />
//             <div className="error">{yearError}</div>
//             {validationErrors.year && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.year}
//               </div>
//             )}
//           </div>
//           <div style={{ marginBottom: "10px", flex: 1 }}>
//             <div className="label">
//               <div className="subHeading">Publish Date</div>
//             </div>
//             <TextField
//               fullWidth
//               id="outlined-adornment-weight"
//               className="span"
//               value={publishDate}
//               onChange={handlePublishDateChange}
//               inputProps={{ min: getTodayDate() }}
//               placeholder="Enter an event date"
//               type="date"
//               autoComplete="off"
//             />
//             <div className="error">{publishDateError}</div>
//             {validationErrors.publishDate && (
//               <div style={{ color: "red", fontSize: "12px" }}>
//                 {validationErrors.publishDate}
//               </div>
//             )}
//           </div>
//           {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Document Type1</div>
//               </div>
//               <div className="documentTypeWrapper">
//                 <Select
//                   id="outlined-adornment-documentType"
//                   className={`span ${documentType === "addChoice" ? "reduced-width" : ""
//                     }`}
//                   value={documentType}
//                   style={{ width: "100%", height: "40px" }}
//                   onChange={(event) =>
//                     setDocumentType(event.target.value as string)
//                   }
//                 >
//                   <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                   <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                   <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                   <MenuItem value="Authority Matrix">Authority Matrix</MenuItem>
//                   <MenuItem value="Policy">Policy</MenuItem>
//                   <MenuItem value="Process Map">Process Map</MenuItem>
//                   <MenuItem value="Supplementry Document">
//                     Supplementry Document
//                   </MenuItem>
//                   <MenuItem value="Form">Form</MenuItem>
//                   <MenuItem value="Template">Template</MenuItem>
//                   <MenuItem value="Service Level Agreement">
//                     Service Level Agreement
//                   </MenuItem>
//                   <MenuItem value="Certificate">Certificate</MenuItem>
//                   <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem>
//                 </Select>
//                 {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )}
//               </div>
//             </div> */}
//           {/* <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Applicable To</div>
//               </div>
//               <FormGroup>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Sales")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Sales"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Sales"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Sales"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Operations")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Operations"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "Operations"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Operations"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("IT")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "IT"]
//                             : prevApplicableTo.filter(
//                               (item: string) => item !== "IT"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="IT"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("HR & Finance")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "HR & Finance"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "HR & Finance"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="HR & Finance"
//                 />
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={applicableTo.includes("Health & Safety")}
//                       onChange={(event) => {
//                         const checked = event.target.checked;
//                         setApplicableTo((prevApplicableTo) =>
//                           checked
//                             ? [...prevApplicableTo, "Health & Safety"]
//                             : prevApplicableTo.filter(
//                               (item) => item !== "Health & Safety"
//                             )
//                         );
//                       }}
//                     />
//                   }
//                   label="Health & Safety"
//                 />
//               </FormGroup>
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <div className="label">
//                 <div className="subHeading">Status</div>
//               </div>
//               <TextField
//                 id="outlined-adornment-status"
//                 className="span"
//                 value={status}
//                 style={{ width: "100%" }}
//                 onChange={(event) => setStatus(event.target.value)}
//                 placeholder="Enter status"
//               />
//             </div> */}
//           <div className="actionDivTwo">
//             {selectedOption === "FILE" && (
//               <Button
//                 sx={{ textTransform: "capitalize" }}
//                 onClick={handleUploadFiles}
//                 disabled={isUploading}
//                 className="saveBtn"
//               >
//                 Upload Files
//               </Button>
//             )}
//             {selectedOption === "FOLDER" && (
//               <Button
//                 sx={{ textTransform: "capitalize" }}
//                 onClick={handleCreateFolder}
//                 className="saveBtn"
//                 disabled={!selectedFolder}
//               >
//                 Upload Folder
//               </Button>
//             )}
//           </div>
//         </div>
//       </Drawer>

//       <Drawer
//         anchor="right"
//         classes={{
//           paper: "newPosOfDialog",
//         }}
//         open={openEditDialog}
//         onClose={handleCloseEditDialog}
//       >
//         {/* <div>
//             <Grid className="dialogTitle">
//               <Grid>
//                 <Typography
//                   className="newItem"
//                   style={{ textAlign: "left", color: "#02354d" }}
//                 >
//                   Edit Item
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <IconButton onClick={handleCloseEditDialog}>
//                   <CloseIcon />
//                 </IconButton>
//               </Grid>
//             </Grid>
//           </div> */}

//         <div className="DrawerHeaderBox">
//           <Stack direction="row" alignItems="center" spacing={1}>
//             <img
//               style={{ height: "20px", width: "17px" }}
//               src={plusIcon}
//               alt="Plus Icon"
//             />
//             <div className="DrawerTitle">Edit Documents</div>
//           </Stack>
//           <div>
//             <CloseRoundedIcon
//               // onClick={handleCancel}
//               style={{
//                 cursor: "pointer",
//                 color: "whitesmoke",
//                 padding: "5px",
//                 borderRadius: "50%",
//                 transition: "background-color 0.3s",
//               }}
//               onMouseEnter={(e) => {
//                 e.currentTarget.style.backgroundColor =
//                   "rgba(255, 255, 255, 0.2)";
//               }}
//               onMouseLeave={(e) => {
//                 e.currentTarget.style.backgroundColor = "transparent";
//               }}
//             />
//           </div>
//         </div>
//         <div>
//           <Divider />
//           {editedItem && (
//             <>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Name</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-name"
//                   className="span"
//                   value={editName}
//                   style={{ width: "100%" }}
//                   // onChange={(event) =>
//                   //   handleFieldChange("name", event.target.value)
//                   // }
//                   onChange={(e) => {
//                     handleEditName(e);
//                   }}
//                   placeholder="Enter name"
//                   autoComplete="off"
//                 />
//               </div>
//               {/* <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Section</div>
//                   </div>
//                   <TextField
//                     id="outlined-adornment-section"
//                     className="span"
//                     value={editedItem.section}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       handleFieldChange("section", event.target.value)
//                     }
//                     placeholder="Enter section"
//                   />
//                 </div> */}
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Title</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-section"
//                   className="span"
//                   value={editTitle}
//                   style={{ width: "100%" }}
//                   onChange={(e) => {
//                     handleEditTitle(e);
//                   }}
//                   //onChange={(event) => handleFieldChange("title", event.target.value)}
//                   placeholder="Enter Title"
//                   autoComplete="off" // Add autoComplete attribute and set it to "off"
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.title}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Description</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-section"
//                   className="span"
//                   value={editDescription}
//                   style={{ width: "100%" }}
//                   onChange={(e) => {
//                     handleEditDescription(e);
//                   }}
//                   //onChange={(event) => handleFieldChange("description", event.target.value)}
//                   placeholder="Enter Description"
//                   autoComplete="off"
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.description}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Quarter</div>
//                 </div>
//                 <div className="documentTypeWrapper">
//                   <Select
//                     autoComplete="off"
//                     id="outlined-adornment-documentType"
//                     className={`span ${quarter === "addChoice" ? "reduced-width" : ""
//                       }`}
//                     value={editQuaterValue}
//                     style={{ width: "100%", height: "40px" }}
//                     onChange={(e) => {
//                       handleEditQuaterValue(e);
//                     }}
//                   // onChange={(event) =>
//                   //   handleFieldChange("quarter", event.target.value as string)
//                   // }
//                   >
//                     <MenuItem value="Q1">Q1</MenuItem>
//                     <MenuItem value="Q2">Q2</MenuItem>
//                     <MenuItem value="Q3">Q3</MenuItem>
//                     <MenuItem value="Q4">Q4</MenuItem>
//                     {/* <MenuItem value="addChoice">
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <AddIcon style={{ marginRight: "8px" }} />
//                       Add Choice Manually
//                     </div>
//                   </MenuItem> */}
//                   </Select>
//                   {/* {documentType === "addChoice" && (
//                   <div style={{ marginTop: "8px" }}>
//                     <TextField
//                       id="outlined-adornment-manual-document-type"
//                       className="span manual-document-type"
//                       value={manualDocumentType}
//                       style={{ width: "100%" }}
//                       onChange={(event) =>
//                         setManualDocumentType(event.target.value)
//                       }
//                       placeholder="Enter document type"
//                     />
//                   </div>
//                 )} */}
//                 </div>
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Year</div>
//                 </div>
//                 <TextField
//                   id="outlined-adornment-section"
//                   className="span"
//                   value={editYear}
//                   style={{ width: "100%" }}
//                   onChange={(e) => {
//                     handleEditYear(e);
//                   }}
//                   // onChange={(event) => handleFieldChange("year", event.target.value)}
//                   placeholder="Enter Year"
//                   autoComplete="off"
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.year}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "10px", flex: 1 }}>
//                 <div className="label">
//                   {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
//                   <div className="subHeading">
//                     Publish Date
//                     {/* <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} /> */}
//                   </div>
//                 </div>
//                 <TextField
//                   fullWidth
//                   id="outlined-adornment-weight"
//                   className="span"
//                   value={editPublishDate}
//                   onChange={(e) => {
//                     handleEditPublishedDate(e);
//                   }}
//                   // onChange={(event) => handleFieldChange("publishDate", event.target.value)}
//                   placeholder="Enter an event date"
//                   type="date"
//                 // inputProps={{ min: today }}
//                 />
//                 {validationErrors.name && (
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {validationErrors.publishDate}
//                   </div>
//                 )}
//               </div>
//               <div style={{ marginBottom: "15px" }}>
//                 <div className="label">
//                   <div className="subHeading">Select Cover Image</div>
//                 </div>
//                 <input
//                   type="file"
//                   accept="image/*"
//                   multiple
//                   // accept=".pdf,.doc,.docx,.ppt"
//                   onChange={handleCoverPictureChange}
//                 />
//               </div>
//               {/* <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Document Type</div>
//                   </div>
//                   <div className="documentTypeWrapper">
//                     <Select
//                       id="outlined-adornment-documentType"
//                       className={`span ${editedItem.documentType === "addChoice"
//                           ? "reduced-width"
//                           : ""
//                         }`}
//                       value={editedItem.documentType}
//                       style={{ width: "100%", height: "40px" }}
//                       onChange={(event) =>
//                         handleFieldChange("documentType", event.target.value)
//                       }
//                     >
//                       <MenuItem value="CASE STUDIES">CASE STUDIES</MenuItem>
//                       <MenuItem value="PRESENTATION">PRESENTATION</MenuItem>
//                       <MenuItem value="BROCHURES">BROCHURES</MenuItem>
//                       <MenuItem value="Authority Matrix">
//                         Authority Matrix
//                       </MenuItem>
//                       <MenuItem value="Policy">Policy</MenuItem>
//                       <MenuItem value="Process Map">Process Map</MenuItem>
//                       <MenuItem value="Supplementry Document">
//                         Supplementry Document
//                       </MenuItem>
//                       <MenuItem value="Form">Form</MenuItem>
//                       <MenuItem value="Template">Template</MenuItem>
//                       <MenuItem value="Service Level Agreement">
//                         Service Level Agreement
//                       </MenuItem>
//                       <MenuItem value="Certificate">Certificate</MenuItem>
//                       <MenuItem value="addChoice">
//                         <div style={{ display: "flex", alignItems: "center" }}>
//                           <AddIcon style={{ marginRight: "8px" }} />
//                           Add Choice Manually
//                         </div>
//                       </MenuItem>
//                     </Select>
//                     {editedItem.documentType === "addChoice" && (
//                       <div style={{ marginTop: "8px" }}>
//                         <TextField
//                           id="outlined-adornment-manual-document-type"
//                           className="span manual-document-type"
//                           value={editedItem.manualDocumentType || ""}
//                           style={{ width: "100%" }}
//                           onChange={(event) =>
//                             handleFieldChange(
//                               "manualDocumentType",
//                               event.target.value
//                             )
//                           }
//                           placeholder="Enter document type"
//                         />
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Applicable To</div>
//                   </div>
//                   <FormGroup>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("Sales")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Sales"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Sales"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Sales"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("Operations")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Operations"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Operations"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Operations"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes("IT")}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "IT"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "IT"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="IT"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes(
//                             "HR & Finance"
//                           )}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [
//                                   ...editedItem.applicableTo,
//                                   "HR & Finance",
//                                 ]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) =>
//                                     item !== "HR & Finance"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="HR & Finance"
//                     />
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={editedItem.applicableTo.includes(
//                             "Health & Safety"
//                           )}
//                           onChange={(event) => {
//                             const checked = event.target.checked;
//                             handleFieldChange(
//                               "applicableTo",
//                               checked
//                                 ? [...editedItem.applicableTo, "Health & Safety"]
//                                 : editedItem.applicableTo.filter(
//                                   (item: string) => item !== "Health & Safety"
//                                 )
//                             );
//                           }}
//                         />
//                       }
//                       label="Health & Safety"
//                     />
//                   </FormGroup>
//                 </div>
//                 <div style={{ marginBottom: "15px" }}>
//                   <div className="label">
//                     <div className="subHeading">Status</div>
//                   </div>
//                   <TextField
//                     id="outlined-adornment-status"
//                     className="span"
//                     value={editedItem.status}
//                     style={{ width: "100%" }}
//                     onChange={(event) =>
//                       handleFieldChange("status", event.target.value)
//                     }
//                     placeholder="Enter status"
//                   />
//                 </div> */}
//               <div className="actionDivTwo">
//                 <Button
//                   sx={{ textTransform: "capitalize" }}
//                   onClick={handleUpdateItem}
//                   className="saveBtn"
//                 >
//                   Update
//                 </Button>
//               </div>
//             </>
//           )}
//         </div>
//       </Drawer>
//       {content}
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "flex-end",
//           marginTop: "20px",
//         }}
//       >
//         <Typography variant="body2" sx={{ marginRight: '10px', color: 'gray' }}>
//           {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(currentPage * itemsPerPage, filteredRows.length)} of ${filteredRows.length}`}
//         </Typography>
//         <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
//           <ArrowBackIosNewIcon />
//         </IconButton>
//         {renderPageNumbers()}
//         <IconButton onClick={handleNextPage} disabled={currentPage === totalPages}>
//           <ArrowForwardIosIcon />
//         </IconButton>
//         {filterField && (
//           <FilterMenu
//             anchorEl={filterAnchorEl}
//             onClose={handleFilterClose}
//             field={filterField}
//             onFilterChange={handleFilterChange}
//             options={filterField ? Array.from(new Set(rows.map(row => {
//               const value = row[filterField as keyof typeof row];
//               return typeof value === 'string' ? value : '';
//             }))).filter(value => value !== '') : []}
//           />
//         )}
//       </Box>

//       {/* <Stack className="paginationBottom" spacing={2}>
//           <Pagination
//             count={
//               folderContents.length === 0
//                 ? 1
//                 : Math.ceil(folderContents.length / itemsPerPage)
//             }
//             shape="rounded"
//             page={currentPage}
//             onChange={handlePageChange}
//             sx={{
//               "& .MuiPaginationItem-root.Mui-selected": {
//                 backgroundColor: "#e0001b", // Change the color of the selected pagination button
//                 color: "#ffffff", // Set text color to white
//                 position: "relative", // Ensure proper positioning for pseudo-element
//               },
//               "& .MuiPaginationItem-root.Mui-selected::after": {
//                 content: '""',
//                 position: "absolute",
//                 bottom: "-7px", // Adjust the position of the line as needed
//                 left: 0,
//                 width: "100%",
//                 height: "3px",
//                 backgroundColor: "#e0001b", // Set the color of the line to red
//                 borderRadius: "2px", // Add border-radius to the line
//               },
//               "& .MuiPaginationItem-root.Mui-selected:hover": {
//                 backgroundColor: "#e0001b", // Override hover effect color
//               },
//             }}
//           />
//         </Stack> */}
//     </div>
//   );
// };

// export default PostsEditorPage;
