// import { Box, Stack, Typography } from "@mui/material";
// import React, { useRef, useState } from "react";
// import "./Styles.css";
// import { Link } from "react-router-dom";
// import newsIcon from "../../Assets/Images/Hero1.jpg";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { useGetRemoNewsDataQuery } from "../../Services/APIs";
// import Slider from "react-slick";
// import ErrorNews from "./ErrorNews";
// import ByrnePostIcon from "../../Assets/landingPageIcons/post.svg";
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// const ByrnePosts = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const {
//     data: newsData,
//     error,
//     isLoading,
//     isSuccess,
//   } = useGetRemoNewsDataQuery();
//   const truncatedTitleLength = 80;
//   const remoNews = newsData?.result;

//   const sliderRef = useRef(null);

//   const handleNext = () => {
//     if (sliderRef.current) {
//       (sliderRef.current as Slider).slickNext();
//     }
//   };

//   const handleBack = () => {
//     if (sliderRef.current) {
//       (sliderRef.current as Slider).slickPrev();
//     }
//   };

//   if (error) {
//     return <ErrorNews />;
//   }

//   const currentDate = new Date();
//   const options: Intl.DateTimeFormatOptions = {
//     weekday: "long",
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   };
//   const currentDay = currentDate.toLocaleDateString("en-US", options);

//   const settings = {
//     prevArrow: <ArrowBackIosIcon className="custom-prev" onClick={handleBack} />,
//     nextArrow: <ArrowForwardIosIcon className="custom-next" onClick={handleNext} />,
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 2,
//     slidesToScroll: 2,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   if (isLoading) {
//     return <div>
////     <CircularProgress />
// //   </div>;
//   }

//   if (!isSuccess || !remoNews || !Array.isArray(remoNews)) {
//     return <div>Invalid data format</div>;
//   }

//   //const activeNewss = remoNews.filter((item: any) => item.isActive === true);
// //  const activeNews = remoNews?.filter((item:any)=>{
// //   return item?.isActive === true && item?.newsReference === "Industry"
// //  })
// //  console.log(activeNews,"activeNewss",remoNews)

//  const activeNewss = remoNews.filter((item: any) => item.isActive === true);
//  const activeNews = remoNews?.filter((item:any)=>{
//    return item?.isActive === true && item?.newsReference.startsWith("Byrne Posts")
//   })

//   return (
//     <Box className="newspost">
//       <Stack
//         direction="row"
//         justifyContent="space-between"
//         sx={{ marginBottom: 1 }}
//       >
//         <div className="newsHeader1" style={{ display: "flex", alignItems: "center" }}>
//             <img src={ByrnePostIcon} alt="Contactdirectory" style={{ marginRight: "10px", width: "20px", height: "auto" }} />
//             <span> Byrne Posts</span>
//           </div>
//         <div>
//           <Link className="viewnewsHeader" to="/viewAllNews">
//             View All
//           </Link>
//         </div>
//       </Stack>
//       {activeNews.length === 0 ? (
//         <Typography
//           variant="body1"
//           color="textSecondary"
//           align="center"
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginTop: "100px",
//           }}
//         >
//           No news has been published yet
//         </Typography>
//       ) : (
//         // <Slider {...settings} ref={sliderRef}>
//         //   {activeNews.map((news, index) => (
//         //     <div key={index}>
//         //       <div>
//         //         <img
//         //           src={news?.newsImage}
//         //           alt="news"
//         //           className="newsImage"
//         //         />
//         //       </div>
//         //     </div>
//         //   ))}
//         // </Slider>
//          <Box className="newsGrid">
//           {activeNews.map((news, index) => (
//             <Box key={index} className="newsItem">
//               <img
//                 src={news?.newsImage}
//                 alt="news"
//                 className="newsImage"
//               />
//             </Box>
//           ))}
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ByrnePosts;

// import { Box, Stack, Typography } from "@mui/material";
// import React, { useRef, useState } from "react";
// import "./Styles.css";
// import { Link } from "react-router-dom";
// import newsIcon from "../../Assets/Images/Hero1.jpg";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import { useGetRemoNewsDataQuery } from "../../Services/APIs";
// import ErrorNews from "./ErrorNews";
// import ByrnePostIcon from "../../Assets/landingPageIcons/post.svg";
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// const ByrnePosts = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const { data: newsData, error, isLoading, isSuccess } = useGetRemoNewsDataQuery();
//   const truncatedTitleLength = 80;
//   const remoNews = newsData?.result;

//   const itemsPerPage = 1;
//   const totalSteps = Math.ceil((remoNews?.length || 0) / itemsPerPage);

//   const handleNext = () => {
//     if (activeStep < totalSteps - 1) {
//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     }
//   };

//   const handleBack = () => {
//     if (activeStep > 0) {
//       setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     }
//   };

//   if (error) {
//     return <ErrorNews />;
//   }

//   const currentDate = new Date();
//   const options: Intl.DateTimeFormatOptions = {
//     weekday: "long",
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   };
//   const currentDay = currentDate.toLocaleDateString("en-US", options);

//   if (isLoading) {
//     return <div>
////     <CircularProgress />
////   </div>;
//   }

//   if (!isSuccess || !remoNews || !Array.isArray(remoNews)) {
//     return <div>Invalid data format</div>;
//   }

//   const activeNews = remoNews.filter((item: any) => item.isActive === true && item.newsReference.startsWith("Byrne Posts"));
//   const visibleNews = activeNews.slice(activeStep * itemsPerPage, activeStep * itemsPerPage + itemsPerPage);

//   return (
//     <Box className="newspost">
//       <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: 1 }}>
//         <div className="newsHeader1" style={{ display: "flex", alignItems: "center" }}>
//           <img src={ByrnePostIcon} alt="Contactdirectory" style={{ marginRight: "10px", width: "20px", height: "auto" }} />
//           <span> Byrne Posts</span>
//         </div>
//         <div>
//           <Link className="viewnewsHeader" to="/viewAllNews">
//             View All
//           </Link>
//         </div>
//       </Stack>
//       {activeNews.length === 0 ? (
//         <Typography
//           variant="body1"
//           color="textSecondary"
//           align="center"
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginTop: "100px",
//           }}
//         >
//           No news has been published yet
//         </Typography>
//       ) : (
//         <Box>
//           <Box className="newsGrid">
//             {visibleNews.map((news, index) => (
//               <Box key={index} className="newsItem">
//                 <img src={news?.newsImage} alt="news" className="newsImage" />
//               </Box>
//             ))}
//           </Box>
//           <Box className="navigationButtons" sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
//             <ArrowBackIosIcon className="custom-prev" onClick={handleBack} sx={{ cursor: activeStep === 0 ? "not-allowed" : "pointer" }} />
//             <ArrowForwardIosIcon className="custom-next" onClick={handleNext} sx={{ cursor: activeStep === totalSteps - 1 ? "not-allowed" : "pointer" }} />
//           </Box>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default ByrnePosts;

import React, { Fragment, useRef, useState } from "react";
import {
  useGetRemoNewsActiveDataQuery,
  useGetAllPostsQuery,
} from "../../Services/APIs";
import {
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Box,
  Paper,
  Stack,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Styles.css";
import ByrnePostIcon from "../../Assets/NewByrneIcons/Post.svg";
import default_pdf_thumbnail from "../../Assets/new-content-editor/Thumbnail Img - png.png";
// import default_pdf_thumbnail from "../../Assets/content editor icons/Thumbnail Img without word.svg";
import FilePreview from "../../Components/FilePreview/filePreview";
import moment from "moment";
import LoaderComponent from "../../hooks/LoaderComponent";

// interface News {
//     _id: string;
//     newsTitle: string;
//     newsImage: string;
//     newsDescription: string;
//     createdDate: string;
//     isActive: boolean;
// }
interface Posts {
  title: any;
  _id: string;
  fileName: string;
  fileUrl: string;
  quarter: string;
  year: string;
  description: string;
  releaseDate: any;
  thumbnail: string;
  createdAt: any;
  coverPicture: string;
  publishDate:string;
  // newsTitle: string;
  // newsImage: string;
  // newsDescription: string;
  // createdDate: string;
  // isActive: boolean;
}
const ByrnePost: React.FC = () => {
  // const { data: newsData, error, isLoading } = useGetRemoNewsActiveDataQuery();
  const { data: postsData, error, isLoading } = useGetAllPostsQuery();
  console.log(postsData, "postssssss");
  const sliderRef = useRef<Slider | null>(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedFile, setSelectedFile] = useState<Posts | null>(null);
  const truncatedTitleLength = 250;
  if (isLoading) {
    return <LoaderComponent />;
  }

  if (error) {
    if ("status" in error && "data" in error) {
      return (
        <div>
          Error: {error.status} - {JSON.stringify(error.data)}
        </div>
      );
    }
    if ("message" in error) {
      return <div>Error: {error.message}</div>;
    }
    return <div>An unknown error occurred</div>;
  }

  // const filteredNewsData = newsData?.result.filter((news: News) => news.isActive) || [];

  const quarterPeriod =
    postsData?.result
      ?.filter((post: { quarter: any }) => post.quarter)
      .map((post: { quarter: any }) => post.quarter) || [];
  const settings = {
    dots: false,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,

    customPaging: function() {
      return (
        <div
          style={{
            width: "5px",
            height: "5px",
            borderRadius: "50%",
            background: "#ccc",
            marginLeft:"30px"
          }}
        ></div>
      );
    },
    appendDots: (dots: string | any[]) => (
      <div>
        <ul> {dots.slice(0, 3)} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleThumbnailClick = (file: Posts) => {
    setSelectedFile(file);
    setOpenPreview(true);
  };


  const sortedPosts =
    postsData ? [...postsData].sort(
      (a: Posts, b: Posts) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ) : [];

  console.log(sortedPosts, "sortedposts");

  return (
    <div style={{ background: "#f1f1f1" }}>
      <Box>
        <Paper className="paper">
          <CardContent sx={{ pb: "16px!important", borderRadius: "10px" }}>
            <div>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              marginBottom="10px"
              margin="0px 10px 10px 10px"
            >
              <div
                className="NewPostHeader"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={ByrnePostIcon}
                  alt="Byrne Posts"
                  style={{ marginRight: "10px", width: "20px", height: "auto" }}
                />
                <span>Byrne Posts</span>
              </div>
              <div>
                <Link className="viewnewsHeaderNew" to="/byrnePostReadMorePage">
                  View All
                </Link>
              </div>
            </Stack>
            </div>
            <Slider ref={(c) => (sliderRef.current = c)} {...settings}>
              {sortedPosts.map((news: Posts) => (
                <Fragment key={news._id}>
                  <Card
                    sx={{
                      maxHeight: 268,
                      boxShadow: "none !important",
                      margin: "0px 10px 0px 10px",
                      border: "1px solid",

                      borderColor: "lightgray", // Set light color here
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  >
                    <CardMedia
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        // height: "100%",
                        
                      }}
                      component="div"
                    >
                      <div
                        style={{
                          width: "100%",

                          borderRadius: "4px",
                          overflow: "hidden",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      >
                         <div style={{ color: "blue" }}>
                              <Grid
                                container
                                sx={{
                                  position: "absolute",
                                  top: 15,
                                  right: 5,
                                  textAlign: "center",
                                  color: "#ffffff",
                                  padding: "3px 10px",
                                  zIndex: 1,
                                  fontSize: "12px",
                                  backgroundColor: "#423e44",
                                  borderRadius: "3px",
                                  width: "85px",
                                }}
                              >
                            {news.publishDate ? moment(news.publishDate).format('DD MMM YYYY') : 'Invalid Date'} <br />
                          </Grid>
                          <img
                          src={news.coverPicture || default_pdf_thumbnail}
                          alt="Img"
                          style={{
                            width: "100%",
                            height: "300px",
                            // objectFit: "cover",
                            filter: "brightness(85%)",
                            objectPosition: "top",
                            cursor: 'pointer'
                          }}
                          onClick={() => {  handleThumbnailClick(news) }}
                        />
                        </div>
                        
                        {/* {news.title} */}
                      </div>
                      <div
                        //                       style={{
                        //     backgroundColor: "white",
                        //     padding: "10px",
                        //     borderRadius: "8px",
                        //     position: "absolute",
                        //     top: "85%",
                        //     left: "50%",
                        //     transform: "translate(-50%, -50%)",
                        //     zIndex: 1,
                        //     maxWidth:"100%"
                        // }}
                        style={{
                          backgroundColor: "#423e44",
                          padding: "3px 10px 3px 10px",
                          // borderRadius: "8px",
                          position: "absolute",
                          top: "83%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 1,
                          width: "101%",
                          maxHeight: "60px",
                          overflow: "hidden",
                          boxSizing: "border-box",
                          textAlign: "center",
                        }}
                      >

                        <div
                          className="clamped-text"

                          style={{
                            textAlign: "left",
                            color: "#ffffff",
                            fontSize: "14px",
                            fontWeight: "bold",
                            // marginBottom: "5px",
                          }}
                        >
                          {news?.title?.slice(0, truncatedTitleLength)}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "#ffffff",
                            fontSize: "12px",
                          }}
                        >
                          <div>
                            {" "}
                            <p>
                              Period: {news?.quarter},{news?.year}
                            </p>
                          </div>
                        </div>
                      </div>
                    </CardMedia>
                  </Card>
                </Fragment>
              ))}
            </Slider>
          </CardContent>
        </Paper>
      </Box>
      <FilePreview
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        fileUrl={selectedFile?.fileUrl || ""}
        fileName={selectedFile?.fileName || ""}
      />
    </div>
  );
};

// Custom Arrow Components
const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "-10px" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "-10px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

export default ByrnePost;
