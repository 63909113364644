
import ITRequestApproveRejectForm from "./ItRequestApproveRejectFirstApproverForm";
import ITRequestAllLevelsRejectedViewForm from "./itRequestRejectViewForm";
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Dialog,
    Slide,
    DialogActions,
    DialogTitle,
    DialogContent,
    Snackbar,
    Tooltip,
    IconButton,
    CircularProgress,
  } from "@mui/material";
  import Alert, { AlertColor } from "@mui/material/Alert";
  import CancelIcon from "@mui/icons-material/Cancel";
  import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
  import fileIcon from "../../Assets/Images/filePdf.svg";
  import "./ItRequestStyles.css";
  import InputLabel from "@mui/material/InputLabel";
  import MenuItem from "@mui/material/MenuItem";
  import FormControl from "@mui/material/FormControl";
  import Select, { SelectChangeEvent } from "@mui/material/Select";
  import dayjs, { Dayjs } from "dayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
  import NavigateNextIcon from "@mui/icons-material/NavigateNext";
  import { Link, useLocation, useNavigate } from "react-router-dom";
  import defaultImage from "../../Assets/Images/default-image.jpg";
  import { TransitionProps } from "@mui/material/transitions";
  import "./ItRequestStyles.css";
  import {
    useGetItRequestsDataQuery,
    useGetItRequestsByIdQuery,
    useUpdateItRequestsMutation,
    useDeleteItRequestsMutation,
    useCreateItRequestsMutation,
    useGetItRequestByRequestedIdQuery,
  } from "../../Services/APIs";
  import { CSVLink } from "react-csv";
  import ApproveRejectFooterButton from "./ApproveRejectFooterButton";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  //imports for approve/reject button
  // import {
  //   Box,
  //   Button,
  //   Popover,
  //   TextField,
  //   Typography,
  //   Grid,
  // } from "@mui/material";
  // import React, { useState } from "react";
  import { Popover } from "@mui/material";
  import "./ItRequestStyles.css";
  import { Close } from "@mui/icons-material";
  import approveIcon from "../../Assets/it-request/approve-info.png";
  import rejectIcon from "../../Assets/it-request/reject-info.png";
  import closeIcon from "../../Assets/it-request/closed.png";
  import commentsIcon from "../../Assets/it-request/comments-icon.png";
  import { useParams } from "react-router-dom";
  
  // import { useLocation } from 'react-router-dom';
  import {
    useApproveOrRejectWorkflowRunMutation,
    useGetWorkflowRunByRequestIdQuery,
  } from "../../Services/workFlowAPIs";
  import OtherDetails from "./OtherDetails";
  import LoaderComponent from "../../hooks/LoaderComponent";
  import IconText from "../IconText/IconText";
  import { PDFExport } from "@progress/kendo-react-pdf";
  import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
  import WorkflowHistory from "./WorkflowHistory";
import ITRequestApproveRejectThirdApproverForm from "./itRequestApproveRejectThirdApproverForm";
import ViewForm from "./ViewForm";
const PdfExport = (props: any) => {
    const {requestedId,approveReject,approveRejected,setApproveReject,setApproveRejected
        ,workflowHistoryData,Approve,setApproveRejectthird,approveRejectthird,
        approveView,setApproveView,approvalStatus,
        getITRequestFormDetailsUsingRequestId,isEditable,employeeData} =props
    const pdfExportComponent = createRef<PDFExport>();
    const [pdfexport, setPdfexport] = useState<any>(false);
console.log(approveReject,approveRejected,"approveReject")
// let Download = "downloadcheck"
const resetStates = useCallback(() => {
    // if (approveReject ){
        // setApproveReject(false);
    // }else{
    if(Approve == "first"){
      setApproveReject(false);
    }
    // else if(Approve == "second"){
        setApproveRejected(false);
    // }
  }, [setApproveReject, setApproveRejected]);
      // useEffect(() => {
      //   if (approveReject || approveRejected) {
      //       setTimeout(() => {
      //     if (pdfExportComponent.current) {
      //       pdfExportComponent.current.save();
      //     }
      //   }, 1000);
      //     setTimeout(() => {
      //       resetStates();
      //     }, 2000);
      //   }
      // }, [approveReject,approveRejected]);

      const [isContentReady, setIsContentReady] = useState(false);


      useEffect(() => {
        if (approveReject || approveRejected || approveRejectthird || approveView) {
          // Set a timeout to ensure all content is rendered
          const timer = setTimeout(() => {
            setIsContentReady(true);
          }, 1000);
    
          return () => clearTimeout(timer);
        }
      }, [approveReject, approveRejected,approveRejectthird,approveView]);
    
      useEffect(() => {
        if (isContentReady && pdfExportComponent.current) {
          pdfExportComponent.current.save();
          
          // Reset states after PDF is saved
          const resetTimer = setTimeout(() => {
            // setApproveReject(false);
            // setApproveRejected(false);
            setIsContentReady(false);
            if(Approve == "first"){
              setApproveReject(false);
            }else if(Approve == "second"){
                setApproveRejected(false);
            }else if(Approve == "third"){
              setApproveRejectthird(false)
            }else if(Approve == "view"){
              setApproveView(false)
            }
            setIsContentReady(false);
          }, 2000);
    
          return () => clearTimeout(resetTimer);
        }
      }, [isContentReady, setApproveReject, setApproveRejected,setApproveRejectthird,setApproveView]);
    
      const showOtherDetails = approvalStatus === "Completed" && employeeData?.roles?.IT_Admin;
    return(
        <PDFExport
        paperSize="A4"
        scale={0.4}
        keepTogether="p"
        forcePageBreak=".page-break"
        ref={pdfExportComponent}
        fileName={`${requestedId}.pdf`}
      >
        {approveReject && 
        <>
         <ITRequestApproveRejectForm   Download = {"downloadcheck"}
          isPdfExport={true}/>
           <div
                    className="page-break"
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                   <Box
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        margin: "40px",
                        padding: "20px 20px",
                      }}
                    > <WorkflowHistory
                            workflowHistoryData={workflowHistoryData}
                          /></Box> 
                    {/* {activeTab === "work" && ( */}
                    {/* <Box
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        margin: "40px",
                        padding: "20px 20px",
                      }}
                    >
                        <>
                          <WorkflowHistory
                            workflowHistoryData={workflowHistoryData}
                          />
                        </>
                    </Box> */}
                    {/* )} */}
                </div>
       
        </>
         }
        {approveRejected && <>
            <ITRequestAllLevelsRejectedViewForm  Download = {"downloadcheck"}
            isPdfExport={true} 
            isotherdetails={true} 
            showOtherDetails={showOtherDetails}/>
            <div
                    className="page-break"
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                     <Box
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    margin: "40px",
                    padding: "20px 20px",
                  }}
                >
                      <WorkflowHistory
                      workflowHistoryData={workflowHistoryData}
                    />
                    </Box>
            {/* {(activeTab === "work") && ( */}
                {/* <Box
                  style={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    margin: "40px",
                    padding: "20px 20px",
                  }}
                >
                  <>
                    <WorkflowHistory
                      workflowHistoryData={workflowHistoryData}
                    />
                  </>
                </Box> */}
            {/* )} */}
            </div>
        </> }
        {approveRejectthird && 
        <>
        <ITRequestApproveRejectThirdApproverForm Download = {"downloadcheck"}
         isPdfExport={true}
        />
        <div
                    className="page-break"
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                   <Box
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        margin: "40px",
                        padding: "20px 20px",
                      }}
                    > <WorkflowHistory
                            workflowHistoryData={workflowHistoryData}
                          /></Box> 
                </div>
        </>}
        {approveView &&
        <>
        <ViewForm Download = {"downloadcheck"}
         isPdfExport={true}/>
        </>

        }
        </PDFExport>
    )
}
export default PdfExport;