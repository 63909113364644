import { CLIENT_URL } from '../Constants/URL';

export const EMAIL_SUBJECT = "Request [ID] Submitted for your approval";

export const EMAIL_TEMPLATE = `
  <div style="font-size: 14px; font-family: Arial; color:black;">
    <span> Dear [Approver Name],</span><br><br>
    <span> An IT request ID: [ID] has been submitted by [Requestor Name] for your actions.</span><br><br>
    <span> Request Details: [Request Details]</span><br><br>
    <span> Please <a href= "${CLIENT_URL}itRequests">login</a> to review the request and provide your approval.</span><br><br>
    <span> Regards,</span><br>
    <span> Human Resources Team</span>
  </div>
`;


// Utility function to handle single or multiple email addresses
const formatEmails = (emails: string | string[]): string => {
    return Array.isArray(emails) ? emails.join(", ") : emails;
};

// Function to create the email object with dynamic data
export const createEmail = (
    to: string | string[],
    cc: string | string[],
    approverName: string | string[],
    requestId: string,
    requestorName: string,
    requestDetails: string
) => {

    // Function to dynamically format approver names
    const formatApproverNames = (approvers: string | string[]): string => {
        if (Array.isArray(approvers)) {
            return approvers?.map((name, index) => `${name}`)?.join(', ');
        } else {
            return `${approvers}`; // Single approver scenario
        }
    };

    // Format the approver names dynamically
    const formattedApproverNames = formatApproverNames(approverName);

    const emailBody = EMAIL_TEMPLATE
        ?.replace("[Approver Name]", formattedApproverNames)
        ?.replace("[ID]", requestId)
        ?.replace("[Requestor Name]", requestorName)
        ?.replace("[Request Details]", requestDetails);

    const emailSubject = EMAIL_SUBJECT.replace("[ID]", requestId);

    const emailData = {
        to: formatEmails(to),
        cc: formatEmails(cc),
        subject: emailSubject,
        html: emailBody,
    };

    return emailData;
};