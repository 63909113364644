import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import store from "./Store/storeConfiguration";
import config from "./config";

export const msalConfig = {
  auth: {
    clientId: config.appId,
    authority:"https://login.microsoftonline.com/common/oauth2/v2.0/authorize? ",
    redirectUri: window.location.origin,
  },
  cache : {
    cacheLocation : "localStorage",
    storeAuthStateInCookie : true
  }
};

const pca = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
