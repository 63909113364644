import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import UpArraw from "../../Assets/Images/Vector (5).svg";
import excelIcon from "../../Assets/Images/ZipIcon.png";
import { useUploadProfileImagesZipfileMutation } from "../../Services/APIs";
import AlertOk from "../../hooks/AlertOk";
import { Snackbar } from "@mui/material";

interface FileDialogProps {
  open: boolean;
  onClose: () => void;
  onFileAccepted: (file: File) => void;
  refreshTableData: () => void; 
}

const FileDialog12: React.FC<FileDialogProps> = ({ open, onClose, onFileAccepted, refreshTableData, }) => {
  const [UploadProfileImagesZipfile] = useUploadProfileImagesZipfileMutation();
  const [file, setFile] = useState<File | null>(null);
  const [browseDisabled, setBrowseDisabled] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const formatFileSize = (sizeInBytes: number) => {
    if (sizeInBytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return `${parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  };

  const handleFileUpload = (selectedFile: File) => {
    if (!selectedFile.name.endsWith(".zip")) {
      // alert("Please select a ZIP file (.zip) for profile upload.");
      return;
    }
    setFile(selectedFile);
    setBrowseDisabled(true);
    setShowActions(true);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileUpload(e.target.files[0]);
      // setAlertContent("Please select a ZIP file (.zip) for profile upload.");
      // setAlertActivationForCancel(true); // Activate alert
    }
   
  };

  const handleAccept1 = async () => {
    if (!file) {
      // alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("zipfile", file);

    try {
      // Upload the zip file to the server
      await UploadProfileImagesZipfile(formData).unwrap();
      // alert("File uploaded successfully!");
      onFileAccepted(file);
      refreshTableData();
      onClose();
    } catch (error) {
      // alert("File upload failed. Please try again.");
      console.error("Error uploading file:", error);
    }
    setSnackbarMessage("Bulk Upload Images successfully");
    setSnackbarOpen(true);
  };
  const handleAccept = async () => {
    if (!file) {
      // No file selected, exit the function
      return;
    }
  
    const formData = new FormData();
    formData.append("zipfile", file);
  
    try {
      // Upload the zip file to the server
      await UploadProfileImagesZipfile(formData)
        .unwrap()
        .then(() => {
          // Call these functions only if the upload is successful
          onFileAccepted(file);
          refreshTableData();
          onClose();
        });
  
      // Set the success message and open the snackbar
      setSnackbarMessage("Bulk Upload Images successfully");
      setSnackbarOpen(true);
    } catch (error) {
      // Handle any error that occurs during the upload
      console.error("Error uploading file:", error);
    }
  };
  
  const handleCancel = () => {
    resetDialog();
  };

  const resetDialog = () => {
    setFile(null);
    setBrowseDisabled(false);
    setShowActions(false);
  };

  useEffect(() => {
    if (!open) {
      resetDialog();
    }
  }, [open]);
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [alertActivationForCancel, setAlertActivationForCancel] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleAlertYesForCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };

  const handleAlertNoForCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };
  const handleAlertClosee = () => {
    setAlertActivationForCancel(false);
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "700px",
            borderRadius: "10px",
          },
        },
      }}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <Stack direction="row" justifyContent="space-between">
        <DialogTitle>Upload Profile ZIP File</DialogTitle>
        <CloseRoundedIcon onClick={onClose} style={{ cursor: "pointer" }} />
      </Stack>
      <DialogContent>
        <DialogContentText>
          Each image file should be named with the corresponding employee code.
        </DialogContentText>
      </DialogContent>
      <Grid container>
        <Grid item xs={12} md={6}>
          <DialogContent>
            <Box
              className="dialogBox"
              onDragOver={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault();
                const files = event.dataTransfer.files;
                if (files.length > 0) {
                  handleFileUpload(files[0]);
                }
              }}
            >
              <img src={UpArraw} alt="Upload Icon" style={{ height: "50px", width: "50px" }} />
              <Typography variant="body1" color="rgba(125, 125, 125, 1)" sx={{ marginTop: "16px" }}>
                Drag and drop here
              </Typography>
              <hr style={{ width: "100%", margin: "16px 0", border: "none", borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }} />
              <Button
              style={{backgroundColor:"var(--redprimary)"}}
                variant="contained"
                className="PrimaryBuleButton"
                onClick={() => document.getElementById("fileInput")?.click()}
                disabled={browseDisabled}
              >
                Browse
              </Button>
              <input id="fileInput" type="file" hidden onChange={handleFileInputChange} />
            </Box>
          </DialogContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <DialogContent>
            <Box>
              <div className="DateandYear">Uploaded File</div>
              <div style={{
                alignItems: "center",
                borderRadius: "8px",
                height: "58px",
                width: "278px",
                backgroundColor: "rgba(246, 246, 246, 1)",
                padding: "0 16px",
                position: "relative",
              }}>
                {file ? (
                  <Box sx={{ marginTop: "16px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <Stack direction="row" alignItems="center">
                      <img src={excelIcon} className="excelIcon" alt="File Icon" style={{ marginRight: "16px" }} />
                      <Typography variant="body2" style={{
                        flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
                      }}>
                        {file.name}
                      </Typography>
                    </Stack>
                    <Typography  className="file-Size1" variant="body2">
                      {formatFileSize(file.size)}</Typography>
                  </Box>
                ) : (
                  <Typography variant="body2">No file selected</Typography>
                )}
              </div>
            </Box>
          </DialogContent>
          {showActions && (
            <DialogContent>
              <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ marginTop: 2 }}>
                <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                <Button style={{backgroundColor:"var(--redprimary)"}}  variant="contained" onClick={handleAccept}>Accept</Button>
              </Stack>
            </DialogContent>
          )}
        </Grid>
      </Grid>
      <AlertOk
        isAlertOpen={alertActivationForCancel}
        handleAlertYes={handleAlertYesForCancel}
        handleAlertClose={handleAlertNoForCancel}
        handleAlertClosee={handleAlertClosee} 
      >
        {alertContent}
      </AlertOk>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#009BAD",
            fontWeight: "600",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "73px",
            marginRight: "45px",
            fontSize: "14px !important",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </Dialog>
  );
};

export default FileDialog12;
