import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from "../Constants/URL";


const BASE_URL = API_URL;

export const workflowApi = createApi({
  reducerPath: 'workflowApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    createWorkflowConfig: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: '/createWorkflow/config',
        method: 'POST',
        body: formData,

      }),
    }),
    getWorkflowConfigDetails: builder.query<any, void>({
      query: () => ({
        url: '/getWorkFlowConfigDetails',
        method: "GET",
      }),
    }),
    //NO NEED TO USE THIS API SLICE BECAUSE STARTING THE WORK FLOW RUN IS INTEGRATED WITH WORK FLOW CONFIG, 
    //SO IF A FORM IS SUBMITTED CREATING WORK FLOW CONFIG API GET TRIGGERED AS WELL AS THE WORK FLOW RUN WILL GET 
    //STARTED W.R.TO THE CONFIG DATA 
    startWorkflowRun: builder.mutation({
      query: (workflowRunData) => ({
        url: '/workflow/run/start',
        method: 'POST',
        body: workflowRunData,
      }),
    }),
    approveOrRejectWorkflowRun: builder.mutation({
      query: ({ runId, approverId, action, approverName, comments,level,
        priority,
        type,
        domain,
        email,
        database,
        vpnId,
        distribution,
        application,
        security,
        domainId,
        emailBox,
        device,
        backup,
        devServer,
        terminalServer,
        fileServer,
        systemDetails,
        phone,
        simPackage,
        internetUser,
        communication,
        itRemark
      }) => ({
        url: '/workflow/run/approve-reject',
        method: 'POST',
        body: {
          runId, approverId, action, approverName, comments,level, priority, type,
          domain,
          email,
          database,
          vpnId,
          distribution,
          application,
          security,
          domainId,
          emailBox,
          device,
          backup,
          devServer,
          terminalServer,
          fileServer,
          systemDetails,
          phone,
          simPackage,
          internetUser,
          communication,
          itRemark,
        },
      }),
    }),
    getWorkflowRunByRequestId: builder.query<any, string>({
      query: (requestId) => ({
        url: `/workflow/run/request/${requestId}`,
        method: 'GET',
      }),
    }),

    createITAdminDetails: builder.mutation<any, any>({
      query: (itAdminData) => ({
        url: '/createITAdminDetails',
        method: 'POST',
        body: itAdminData,
      }),
    }),
    getITAdminDetails: builder.query<any, void>({
      query: () => ({
        url: '/getITAdminDetails',
        method: 'GET',
      }),
    }),
    updateITAdminDetails: builder.mutation<any, { id: string, itAdminData: any }>({
      query: ({ id, itAdminData }) => ({
        url: `/updateITAdminDetails/${id}`,
        method: 'PUT',
        body: itAdminData,
      }),
    }),
    deleteITAdminDetails: builder.mutation<any, string>({
      query: (id) => ({
        url: `/deleteITAdminDetails/${id}`,
        method: 'DELETE',
      }),
    }),
    createITManagerDetails: builder.mutation<any, any>({
      query: (itManagerData) => ({
        url: '/createITManagerDetails',
        method: 'POST',
        body: itManagerData,
      }),
    }),
    getITManagerDetails: builder.query<any, void>({
      query: () => ({
        url: '/getITManagerDetails',
        method: 'GET',
      }),
    }),
    updateITManagerDetails: builder.mutation<any, { id: string, itManagerData: any }>({
      query: ({ id, itManagerData }) => ({
        url: `/updateITManagerDetails/${id}`,
        method: 'PUT',
        body: itManagerData,
      }),
    }),
    deleteITManagerDetails: builder.mutation<any, string>({
      query: (id) => ({
        url: `/deleteITManagerDetails/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});


export const {
  useCreateWorkflowConfigMutation,
  useGetWorkflowConfigDetailsQuery,
  useStartWorkflowRunMutation,
  useApproveOrRejectWorkflowRunMutation,
  useGetWorkflowRunByRequestIdQuery,
  useCreateITAdminDetailsMutation,
  useGetITAdminDetailsQuery,
  useUpdateITAdminDetailsMutation,
  useDeleteITAdminDetailsMutation,
  useCreateITManagerDetailsMutation,
  useGetITManagerDetailsQuery,
  useUpdateITManagerDetailsMutation,
  useDeleteITManagerDetailsMutation,
} = workflowApi;
