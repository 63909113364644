import { utils, writeFile } from 'xlsx';

export const generateExcelTemplate = () => {
  const headers = [
    "employeeName",
    "employeeCode",
    "designation",
    "employeeEmail",
    "mobileNumber",
    "address",
    "Location",
    "SubLocation",
    "Ext",
    "DirectNo",
    "Sno",
    "department",
    // "bannerView",
    // "coverPictureURL",
    // "profileIcon"
  ];

  const worksheet = utils.aoa_to_sheet([headers]);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Template");

  // Define date formats for specific columns
  const dateFormat = 'mm-dd/-yyyy';
  
  // Apply date format to 'dateOfJoining' and 'dateOfBirth' columns
  for (let col = 5; col <= 6; col++) {
    const cell = worksheet[utils.encode_cell({ r: 1, c: col })];
    if (cell) {
      cell.z = dateFormat;
    }
  }

  // Generate Excel file and trigger download
  writeFile(workbook, 'user_template.xlsx');
};