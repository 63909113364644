import React, { useState } from "react";
import "./Stylesnew.css";
import departmentNew from "../../Assets/Images/departmentNew.svg";
import dashboard from "../../Assets/content editor icons/Landing page.svg";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Button from "@mui/material/Button";
import DepartmentPage from "../../Assets/content editor icons/department.svg";
// import AnnouncementEditor from "../../Pages/ContentEditorPages/AnnouncementPage/AnnouncementEditor";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Plusbuttonforsliderincontenteditor from "../../Assets/Images/Plusbuttonforsliderincontenteditor.svg";
// import DepartmentPage from "../../Assets/Images/departmentContentEditor/DepartmentPage.svg";
import quicklinkshovers from "../../Assets/Images/quicklinkshovers.svg";
import NavigationMenu from "../../Assets/Images/NavigationMenu.svg";
import Navigationmenuchain from "../../Assets/content editor icons/Quicklinks.svg";
import { Stack, Switch, Tooltip } from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
// import ContentElementsslider from "./ContentElementslider";
import quickLinks from "./../../Assets/Images/quicklinksBig.svg";

const ContentEditorslider = (props: any) => {
  const {
    activeDepartmentNames,
    inActiveDepartmentNames,
    handleClickDepartments,
    handleSelect,
    handleActiveDepartments,
  } = props;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  console.log(isSidebarOpen, "isSidebarOpen");
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const {
    myLinkActiveStatus,
    handleOnChangeMyLinksActiveStatus,
    selectedDepartmentList,
    handleClickContentEditor,
    itemsList2,
    itemsList,
    handleClickNew,
    showNavigationMenu,
  } = props;
  console.log(selectedDepartmentList, "selectedDepartmentList");
  const [showSelect, setShowSelect] = useState<boolean>(false);

  const handleToggleSelect = () => {
    setShowSelect((prevShowSelect) => !prevShowSelect);
  };
  function handleToggleSidebar(action: any) {
    // Toggle the state of the sidebar
    setIsSidebarOpen(!isSidebarOpen);
  }
  const [selectedDepartment, setSelectedDepartment] =
    useState<any>("Landing page");
  const [selectedContentEditor, setselectedContentEditor] =
    useState<any>("Announcement");
  const [selectedDepartmentName, setSelectedDepartmentName] = useState<any>("");
  const [LandingPageMenuItem, setLandingPageMenuItem] = useState<string | null>(
    "Events"
  );
  const [DepartmentMenuItem, setDepartmentMenuItem] = useState<string | null>(
    "Dept Documents"
  );
  //   const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>("Events");

  const [accordionState, setAccordionState] = useState<{
    [key: string]: boolean;
  }>({
    "Landing Page": true,
    "Navigation Menu": false,
    "Department Menu": false,
  });

  // const handleAccordionToggle = (accordionName: string) => {
  //   setAccordionState((prevState) => ({
  //     ...prevState,
  //     [accordionName]: !prevState[accordionName],
  //   }));
  // };

  const handleAccordionToggle = (accordionName: string) => {
    setAccordionState((prevState) => {
      const newState = { ...prevState };

      // Close all other accordions except the one being toggled
      Object.keys(newState).forEach((key) => {
        if (key !== accordionName) {
          newState[key] = false;
        }
      });

      // Toggle the state of the selected accordion
      newState[accordionName] = !prevState[accordionName];

      return newState;
    });
  };
  const [active, setActive] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  // const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  // const handleAccordionToggle = () => {
  //   setIsAccordionOpen(!isAccordionOpen);
  // };

  // const handleClickDepartments = (menuItem) => {
  //   setSelectedMenuItem(menuItem);
  // };
  return (
    <div>
      {/* <div
        className={`side-navbar Side-Navbar ${isSidebarOpen ? "open" : ""}`}
      >
        <div className="side-navheader">
          <div className="SidebarHeader ContentEditorHeader">Content Editor</div>
          <span
           className="LeftRightarrowicon"
            onClick={() => {
              handleToggleSidebar(false);
            }}
          >
             {isSidebarOpen ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
          </span>
        </div>

        <div className="side-navpageicon">
          <div className="sidenavbarlistt">
            <p className="SidebarsubHeading">PAGES</p>
            <img
              className="Plusbuttonforsliderincontenteditor"
              src={Plusbuttonforsliderincontenteditor}
              onClick={handleToggleSelect}
            />
          </div>
        </div>
        <ul className="sidenavbarul">
          <li
            className={`sidenavbarlist ${selectedMenuItem === "Landing page" ? "active" : ""}`}
             onClick={() => {
              setSelectedMenuItem("Landing page");
              handleClickDepartments("Landing page");
            }}
          >
            {isSidebarOpen ? (
              <img className="Tooltipimg" src={dashboard} />
            ) : (
            <Tooltip className="Tooltipimg" title="Landing Page" placement="right" arrow>
            <img src={dashboard} />
            </Tooltip>
            )}
            <p className="SidebarTitles" >Landing Page</p>
          </li>
          <li
           className={`sidenavbarlist ${selectedMenuItem === "Navigation Menu" ? "active" : ""}`}

            onClick={() => {
              setSelectedMenuItem("Navigation Menu");
              handleClickDepartments("Navigation Menu");
            }}
          >
            {isSidebarOpen ? (
              <img className="Tooltipimg" src={Navigationmenuchain} />
            ) : (
            <Tooltip className="Tooltipimg" title="Navigation Menu " placement="right" arrow>
            <img src={Navigationmenuchain} />
            </Tooltip>
            )}
            <p className="SidebarTitles">Navigation Menu </p>

          </li>
         <li
             className={`sidenavbarlist ${selectedMenuItem === "Department Page" ? "active" : ""}`}
            onClick={() => {
               setSelectedMenuItem("Department Page");
              handleClickDepartments("Department Page");
            }}
          >
             {isSidebarOpen ? (
               <img className="Tooltipimg" src={DepartmentPage} />
              ) : (
            <Tooltip className="Tooltipimg" title="DepartmentPage" placement="right" arrow>
          <img src={DepartmentPage} />
          </Tooltip>
          )}
            <p className="SidebarTitles">Department Page</p>

          </li>
          {activeDepartmentNames
          ?.sort((a:any, b:any) => a?.order - b?.order)
          ?.map((i: any) => (
            <li
            key={i._id}
            className={`sidenavbarlist ${selectedMenuItem === i._id ? "active" : ""}`}
              onClick={() => {
                setSelectedMenuItem(i._id);
                handleClickDepartments(i);
              }}
            >
              {isSidebarOpen ? (
              <img className="Tooltipimg" src={i?.departmentIcon} />
             ) : (
              <Tooltip  className="Tooltipimg" title={i?.departmentTitle} placement="right" arrow>
              <img src={i?.departmentIcon} />
              </Tooltip>
               )}
              <p className="SidebarTitles">{i?.departmentTitle}</p>

            </li>
          ))}
        </ul>
        <div className="select-container">
          <div>
            {showSelect && (
              <select className="input" onChange={handleSelect}>
                <option selected>Select Page </option>

                {inActiveDepartmentNames?.map((i: any) => (
                  <option>
                    <img src={departmentNew} />
                    {i?.departmentTitle}
                    </option>
                ))}
              </select>
            )}
          </div>
          {showSelect && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                size="small"
                variant="contained"
                onClick={handleActiveDepartments}
              >
                save
              </Button>
            </div>
          )}
        </div>
      </div> */}
      <div className="Side-Navbar-New Side-Navbar">
        <div className="Side-NavBarheader">
          <div className="SidebarHeader ContentEditorHeader">
            Content Editor
          </div>
        </div>
        {/* <div className="SideNavbar-navpageicon">
          <p className="SidebarsubHeading">PAGES</p>
          <div>
            <img
              className="Plusbuttonforsliderincontenteditor"
              src={Plusbuttonforsliderincontenteditor}
              onClick={handleToggleSelect}
            />
          </div>
        </div> */}
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          padding="5px"
          paddingTop="15px"
          // className={`sidenavbarlistneww ${active ? "active3" : ""}`}
        >
          <Stack display="flex" flexDirection="row" alignItems="center">
            <img
              className="contenteditorimg"
              // style={{ marginRight: "10px", color: "white" }}
              src={dashboard}
            />
            <p className="SidebarTitles ">Landing Page </p>
          </Stack>
          <div onClick={() => handleAccordionToggle("Landing Page")}>
            {accordionState["Landing Page"] ? (
              <ExpandLessRoundedIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ExpandMoreRoundedIcon
                style={{ color: "white", cursor: "pointer" }}
              />
            )}
          </div>
        </Stack>

        {/* Old Landingpage Codes */}

        {/* <div
          className={`accordion-content ${
            accordionState["Landing Page"] ? "open" : ""
          }`}
        >
          {itemsList?.map((i: any) => (
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              padding="5px 30px "
              marginTop="5px"
              className={`sidenavbarlistnew ${
                LandingPageMenuItem === i.label ? "active1" : ""
              }`}
              onClick={() => {
                setLandingPageMenuItem(i.label);
                handleClickContentEditor(i?.label);
              }}
            >
              <Stack display="flex" flexDirection="row" alignItems="center">
                <img className="contenteditorimg" src={i?.Icon} style={{ width: '22px' }}/>
                <img
                        src={i?.iconHover}
                        alt={`${i?.iconHover} hover icon`}
                        style={{ width: "18px" }}
                      />

                <p className="SidebarTitles">{i?.label}</p>
                <p className="SidebarTitleshover">{i?.label}</p>

              </Stack>
            </Stack>
          ))}
        </div> */}

        {/* New Landingpage Codes */}

        <div
          className={`accordion-content ${
            accordionState["Landing Page"] ? "open" : ""
          }`}
        >
          {itemsList?.map((i: any) => (
            <Stack
              key={i.label}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              padding="5px 30px"
              marginTop="5px"
              className={`sidenavbarlistnew ${
                LandingPageMenuItem === i.label ? "active1" : ""
              }`}
              onClick={() => {
                setLandingPageMenuItem(i.label);
                handleClickContentEditor(i?.label);
              }}
            >
              <Stack display="flex" flexDirection="row" alignItems="center">
                <img
                  className="contenteditorimg"
                  src={i?.Icon}
                  style={{ width: "22px" }}
                />
                <img
                  className="contenteditorimghover"
                  src={i?.iconHover}
                  alt={`${i?.iconHover} hover icon`}
                  style={{ width: "22px" }}
                />
                <p className="SidebarTitles">{i?.label}</p>
                <p className="SidebarTitleshover">{i?.label}</p>
              </Stack>
            </Stack>
          ))}
        </div>

        {/* <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          padding="5px"
          paddingTop="20px"
        >
          <Stack display="flex" flexDirection="row" alignItems="center">
            <img style={{ marginRight: "10px" }} src={Navigationmenuchain} />
            <p className="SidebarTitles">Navigation Menu </p>
          </Stack>
          <div
            onClick={() => handleAccordionToggle("Navigation Menu")}
            style={{ cursor: "pointer" }}
          >
            {accordionState["Navigation Menu"] ? (
              <ExpandLessRoundedIcon style={{ color: "white" }} />
            ) : (
              <ExpandMoreRoundedIcon style={{ color: "white" }} />
            )}
          </div>
        </Stack> */}
        <div
          className={`accordion-content ${
            accordionState["Navigation Menu"] ? "open" : ""
          }`}
        >
          {/* <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="5px"
            paddingTop="10px"
            className={`sidenavbarlistnew ${selectedMenuItem === "Quick Links" ? "active1" : ""
              }`}
            onClick={() => {
              setSelectedMenuItem("Quick Links");
              handleClickContentEditor("Quick Links");
            }}
            className={`sidenavbarlistnew ${
              LandingPageMenuItem === "Quick Links" ? "active1" : ""
            }`}
            onClick={() => {
              setLandingPageMenuItem("Quick Links");
              handleClickContentEditor("Quick Links");
            }}
          >
            <Stack display="flex" flexDirection="row" alignItems="center">
              <img className="contenteditorimg" src={quickLinks} />
              <p className="SidebarTitles">Quick Links</p>
            </Stack>
          </Stack> */}
          {/* <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            padding="5px 30px "
            marginTop="15px"
            // className={`sidenavbarlistnew ${selectedMenuItem === "Quick Links" ? "active1" : ""
            //   }`}
          >
            <Switch
              checked={myLinkActiveStatus}
              size="small"
              onChange={handleOnChangeMyLinksActiveStatus}
            />
            <p className="SidebarTitles">Enable Mylinks</p>
          </Stack> */}
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="5px 30px "
            marginTop="15px"
            // className={`sidenavbarlistnew ${isSidebarOpen
            //   ? selectedMenuItem === "Add quick links"
            //     ? "active1"
            //     : ""
            //   : ""
            //   }`}
            className={`sidenavbarlistnew ${
              LandingPageMenuItem === "Add Quick Links" ? "active1" : ""
            }`}
            onClick={() => {
              setLandingPageMenuItem("Add Quick Links");
              handleClickContentEditor("Quick Links");
              // setSelectedMenuItem("Add quick links");
              handleClickNew();
            }}
          >
            <Stack display="flex" flexDirection="row" alignItems="center">
              <img className="contenteditorimg" src={quickLinks} />

              <p className="SidebarTitles">Add Quick Links</p>
            </Stack>
          </Stack>
        </div>
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          padding="5px"
          paddingTop="10px"
        >
          <Stack display="flex" flexDirection="row" alignItems="center">
            {/* <img style={{ marginRight: "10px" }} src={DepartmentPage} /> */}
            <p className="SidebarTitles2">Department Page</p>
          </Stack>
        </Stack>
        {/* <ul className="sidenavbarul">
         
          <li
             className={`sidenavbarlist ${selectedMenuItem === "Department Page" ? "active" : ""}`}
            onClick={() => {
               setSelectedMenuItem("Department Page");
              handleClickDepartments("Department Page");
            }}
          >
             {isSidebarOpen ? (
               <img className="Tooltipimg" src={DepartmentPage} />
              ) : (
            <Tooltip className="Tooltipimg" title="DepartmentPage" placement="right" arrow>
          <img src={DepartmentPage} />
          </Tooltip>
          )}
            <p style={{fontWeight:"600"}} className="SidebarTitles">Department Page</p>

          </li> */}

        {/* {activeDepartmentNames
          ?.sort((a:any, b:any) => a?.order - b?.order)
          ?.map((i: any) => (
            <li
            key={i._id}
            className={`sidenavbarlist ${selectedMenuItem === i._id ? "active" : ""}`}
              onClick={() => {
                setSelectedMenuItem(i._id);
                handleClickDepartments(i);
              }}
            >
              {isSidebarOpen ? (
              <img className="Tooltipimg" src={i?.departmentIcon} />
             ) : (
              <Tooltip  className="Tooltipimg" title={i?.departmentTitle} placement="right" arrow>
              <img src={i?.departmentIcon} />
              </Tooltip>
               )}
              <p className="SidebarTitles">{i?.departmentTitle}</p>

            </li>
          ))} */}

        {/* </ul> */}
        {/* {activeDepartmentNames
          ?.sort((a: any, b: any) => a?.order - b?.order)
          ?.map((i: any) => (
            <Stack
              key={i._id}
              className={`sidenavbarlist ${selectedMenuItem === i._id ? "active" : ""
                }`}
              onClick={() => {
                setSelectedMenuItem(i._id);
                handleClickDepartments(i);
              }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              padding="5px"
              paddingTop="20px"
            >
              <Stack display="flex" flexDirection="row" alignItems="center">
                <img style={{ marginRight: "10px" }} src={i?.departmentIcon} />
                <p className="SidebarTitles">{i?.departmentTitle}</p>
              </Stack>
              <div
                onClick={() => handleAccordionToggle("Department Menu")}
                style={{ cursor: "pointer" }}
              >
                {accordionState["Department Menu"] ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </div>
            </Stack>
          ))}
        <div
          className={`accordion-content ${accordionState["Department Menu"] ? "open" : ""
            }`}
        >

          {itemsList2.map((i: any) => (
            <li
              // className="sidenavbarlist"
              className={`sidenavbarlist ${selectedMenuItem === i.label ? "active" : ""}`}
              onClick={() => {
                setSelectedMenuItem(i.label)
                handleClickContentEditor(i?.label);
              }}
            >
              {isSidebarOpen ? (
                <img className="Tooltipimg" src={i?.Icon} />
              ) : (
                <Tooltip className="Tooltipimg" title={i?.label} placement="right" arrow>
                  <img src={i?.Icon} />
                </Tooltip>
              )}
              <p className="SidebarTitles">{i?.label}</p>
            </li>
          ))}
        </div> */}
        {/* 
        {activeDepartmentNames
          ?.sort((a: any, b: any) => a?.order - b?.order)
          ?.map((department: any) => (
            <div key={department._id}> */}
        {/* Department Title */}
        {/* <Stack
                className={`sidenavbarlist ${selectedMenuItem === department._id ? "active" : ""}`}
                onClick={() => {
                  setSelectedMenuItem(department._id);
                  handleClickDepartments(department);
                }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                padding="5px"
                paddingTop="20px"
              >
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <img style={{ marginRight: "10px" }} src={department?.departmentIcon} />
                  <p className="SidebarTitles">{department?.departmentTitle}</p>
                </Stack>
                <div
                  onClick={() => handleAccordionToggle(`Department Menu ${department._id}`)}
                  style={{ cursor: "pointer" }}
                >
                  {accordionState[`Department Menu ${department._id}`] ? (
                    <ExpandLessRoundedIcon />
                  ) : (
                    <ExpandMoreRoundedIcon />
                  )}
                </div>
              </Stack> */}
        {/* Department Menu */}
        {/* <div
                className={`accordion-content ${accordionState[`Department Menu ${department._id}`] ? "open" : ""}`}
              >
                {itemsList2.map((item: any) => (
                  <li
                    className={`sidenavbarlist ${selectedMenuItem === item.label ? "active" : ""}`}
                    onClick={() => {
                      setSelectedMenuItem(item.label)
                      handleClickContentEditor(item?.label);
                    }}
                  >
                    {isSidebarOpen ? (
                      <img className="Tooltipimg" src={item?.Icon} />
                    ) : (
                      <Tooltip className="Tooltipimg" title={item?.label} placement="right" arrow>
                        <img src={item?.Icon} />
                      </Tooltip>
                    )}
                    <p className="SidebarTitles">{item?.label}</p>
                  </li>
                ))}
              </div>
            </div>
          ))} */}
        {activeDepartmentNames
          ?.sort((a: any, b: any) => a?.order - b?.order)
          ?.map((department: any) => (
            <div key={department._id}>
              {/* Department Title */}
              <Stack
                //  className={`sidenavbarlistnew ${active ? "active3" : ""}`}
                onClick={() => {
                  setDepartmentMenuItem(department._id);
                  handleClickDepartments(department);
                }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                padding="6px"
                // paddingTop="10px"
              >
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <img
                    className="filter-blue"
                    src={department?.departmentIcon}
                  />
                  <p className="SidebarTitles">{department?.departmentTitle}</p>
                </Stack>
                <div
                  onClick={() =>
                    handleAccordionToggle(`Department Menu ${department._id}`)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {accordionState[`Department Menu ${department._id}`] ? (
                    <ExpandLessRoundedIcon style={{ color: "white" }} />
                  ) : (
                    <ExpandMoreRoundedIcon style={{ color: "white" }} />
                  )}
                </div>
              </Stack>
              {/* Department Menu */}

              {/* Old Deparments Codes */}
              {/* <div
                className={`accordion-content ${
                  accordionState[`Department Menu ${department._id}`]
                    ? "open"
                    : ""
                }`}
              >
                {itemsList2.map((item: any) => (
                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    // justifyContent="space-between"
                    padding="5px 30px "
                       marginTop="5px"
                    // marginTop="10px"
                    className={`sidenavbarlistnew ${
                      DepartmentMenuItem === item.label ? "active1" : ""
                    }`}
                    onClick={() => {
                      setDepartmentMenuItem(item.label);
                      handleClickContentEditor(item?.label);
                    }}
                  >
                    {isSidebarOpen ? (
                      <img className="Tooltipimg" src={item?.Icon} style={{ width: '22px' }} />
                    ) : (
                      <Tooltip
                        className="Tooltipimg"
                        title={item?.label}
                        placement="right"
                        arrow
                      >
                        <img src={item?.Icon} style={{ width: '22px' }}/>
                      </Tooltip>
                    )}
                    <p className="SidebarTitlesnew">{item?.label}</p>
                  </Stack>
                ))}
              </div> */}

              {/* New Department Codes*/}
              <div
                className={`accordion-content ${
                  accordionState[`Department Menu ${department._id}`]
                    ? "open"
                    : ""
                }`}
              >
                {itemsList2.map((item: any) => (
                  <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    padding="5px 30px"
                    marginTop="5px"
                    className={`sidenavbarlistnew ${
                      DepartmentMenuItem === item.label ? "active1" : ""
                    }`}
                    onClick={() => {
                      setDepartmentMenuItem(item.label);
                      handleClickContentEditor(item?.label);
                    }}
                    onMouseEnter={() => setHoveredItem(item.label)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    {isSidebarOpen ? (
                      <img
                        className="Tooltipimg"
                        src={
                          DepartmentMenuItem === item.label ||
                          hoveredItem === item.label
                            ? item?.iconHover
                            : item?.Icon
                        }
                        style={{ width: "22px" }}
                      />
                    ) : (
                      <Tooltip
                        className="Tooltipimg"
                        title={item?.label}
                        placement="right"
                        arrow
                      >
                        <img
                          src={
                            DepartmentMenuItem === item.label ||
                            hoveredItem === item.label
                              ? item?.iconHover
                              : item?.Icon
                          }
                          style={{ width: "22px" }}
                        />
                      </Tooltip>
                    )}
                    <p
                      className={`SidebarTitlesnew ${
                        DepartmentMenuItem === item.label ||
                        hoveredItem === item.label
                          ? "SidebarTitlesnewhover"
                          : ""
                      }`}
                    >
                      {item?.label}
                    </p>
                  </Stack>
                ))}
              </div>
            </div>
          ))}

        <div className="select-container">
          <div>
            {showSelect && (
              <select className="input" onChange={handleSelect}>
                <option selected>Select Page </option>

                {inActiveDepartmentNames?.map((i: any) => (
                  <option>
                    <img src={departmentNew} />
                    {i?.departmentTitle}
                  </option>
                ))}
              </select>
            )}
          </div>
          {showSelect && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Button
                size="small"
                variant="contained"
                onClick={handleActiveDepartments}
              >
                save
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentEditorslider;
