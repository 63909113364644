import React, { useMemo, useRef } from "react";
import { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import activeView from "../../../Assets/Images/activeView.svg";
import Breadcrumbs from "../../../Assets/Images/Arrow right.svg";
import plusIcon from "../../../Assets/Images/Plusicon - white background.svg";
import ByrneUpload from "../../../Assets/Images/ByrneUpload.svg";
import { API_URL, CLIENT_URL } from "../../../Constants/URL";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Typography,
  Snackbar,
  Tooltip,
  MenuItem,
  Select,
  Pagination,
  PaginationItem,
  LinearProgress,
} from "@mui/material";
// import Dropzone from "react-dropzone";
import title from "../../../Assets/Images/title.svg";
import image from "../../../Assets/Images/image.svg";
import isActive from "../../../Assets/Images/isActive.svg";
import Attachment from "../../../Assets/Images/Attachment.svg";
import recipientEmail from "../../../Assets/Images/recipientEmail.svg";
import shareasemail from "../../../Assets/Images/shareasemail.svg";
import descripton from "../../../Assets/Images/description.svg";
import comments from "../../../Assets/Images/comments.svg";
import publish from "../../../Assets/Images/publish.svg";
import Asterisk from "../../../Assets/Images/Asterisk.svg";
import like1 from "../../../Assets/Images/like1.svg";
import save from "../../../Assets/Images/save.svg";
import cancel from "../../../Assets/Images/cancel.svg";
import birthday from "../../../Assets/Images/birthday.jpg";
import copylink from "../../../Assets/Images/copy link.svg";
import calenderIcon from "../../../Assets/Images/calenderGrey.svg";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import ReactSwitch from "react-switch";
// import FileUpload from "react-material-file-upload";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import Switch from "@mui/material/Switch";
import girl from "../../../Assets/Images/girl.jpg";
import love from "../../../Assets/Images/love.svg";
import view from "../../../Assets/Images/viewNew.svg";
import browse from "../../../Assets/Images/browse.svg";
import {
  useGetCeoDataQuery,
  useCreateCeoMessageMutation,
  useUpdateCeoMessageMutation,
  useCreateheroBannerFromOthersMutation,
  useDeleteOtherDataInheroBannerMutation,
  useUploadNewsDescriptionImageMutation,
  useSendDataToHeroBannerMutation,
  useGetRemoNewsItemByIdQuery
} from "../../../Services/APIs";
import "./Styles.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Badge from "@mui/material/Badge";
import AlertYesNo from "../../Hooks/DialogYesNo";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/system";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import InfoIcon2 from "../../../Assets/Images/infoIcon2.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LoaderComponent from "../../../hooks/LoaderComponent"; // Adjust the path as necessary
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterMenu from "../../../hooks/FilterMenu";
import CancelIcon from '@mui/icons-material/Cancel';
import SearchBar from '../../../hooks/SearchBar';
import PreviewIcon from "../../../Assets/NewByrneIcons/preview-fill.svg";
import ImageSlider from "./ImageSlider";
import { format, isValid } from "date-fns";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PublishIcon from '@mui/icons-material/Publish';
import ImageVideoSlider from "./ImageSlider";
import 'react-quill/dist/quill.snow.css'; // Add styles for the snow theme
import axios from "axios";
import * as XLSX from "xlsx";
import exportIcon from "../../../Assets/Byrne - icons/Excel.svg";
import { error } from "console";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const QuillClipboard = Quill.import('modules/clipboard');
class Clipboard extends QuillClipboard {
  async onPaste(e: ClipboardEvent) {
    const clipboardData = e.clipboardData;
    if (!clipboardData) return;
    const items = clipboardData.items;
    let blob = null;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        blob = items[i].getAsFile();
      }
    }
    if (blob !== null) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("file", blob);
      try {
        const response = await axios.post(`${API_URL}upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const quill = this.quill;
        const range = quill.getSelection();
        const imageUrl = response.data.url; // URL of the uploaded image
        quill.insertEmbed(range?.index ?? 0, "image", imageUrl); // Use nullish coalescing
      } catch (error) {
        console.error("Error uploading image", error);
      }
    } else {
      super.onPaste(e);
    }
  }
}
Quill.register('modules/clipboard', Clipboard);
const Video = Quill.import('formats/video');

class VideoBlot extends Video {
  static create(value: any) {
    const node = super.create(value);
    node.setAttribute('controls', true); // Allow video controls
    node.setAttribute('src', value);
    return node;
  }

  static formats(node: { getAttribute: (arg0: string) => any; }) {
    return node.getAttribute('src');
  }
}

VideoBlot.blotName = 'video';
VideoBlot.className = 'ql-video';
VideoBlot.tagName = 'video';

Quill.register(VideoBlot);

interface GridValidRowModel {
  id: number;
  newsReference: string;
  newsSection: string;
  Title: string;
  Description: string;
  Designation: string;
  Image: any;
  newsImage: any;
  fileName: string;
  imageName: string;
  fileType: any;
  isActive: boolean;
  isDraft: boolean;
  addToHeroBanner: boolean;
  newsOrder: number;
  newsPublishDate: Date;
  // Add other fields as needed
}

interface ReactQuillOnChange {
  (value: string, delta: any, source: any, editor: any): void;
}

const label = { inputProps: { "aria-label": "Switch demo" } };

interface ChangeEvent<T = unknown> {
  target: T;
}
interface CeoDetailsProps {
  ceoMessageId: string;
}
interface switchCellProps {
  value?: boolean;
  active?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}
const SwitchCell: React.FC<switchCellProps> = ({ active, onChange }) => (
  <Switch checked={active} onChange={onChange} />
);
interface EditCellProps {
  onClick: () => void; // Define the onClick prop as a function that takes no arguments and returns void
}
const EditCell: React.FC<EditCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px" }} onClick={onClick}>
    <ModeEditIcon />
  </IconButton>
);
// const DeleteCell: React.FC<EditCellProps> = ({ onClick }) => (
//   <IconButton onClick={onClick}>
//     <ClearSharpIcon />
//   </IconButton>
// );
interface DeleteCellProps {
  onClick: () => void;
}
const DeleteCell: React.FC<DeleteCellProps> = ({ onClick }) => (
  <IconButton sx={{ padding: "3px" }} onClick={onClick}>
    <DeleteIcon />
  </IconButton>
);

const BadgeCell: React.FC<{ active: boolean; value: React.ReactNode }> = ({
  active,
  value,
}) => (
  <>
    {active ? (
      <>{value}</>
    ) : (
      <Badge badgeContent="Draft" color="primary">
        {value}
      </Badge>
    )}
  </>
);
interface ImageCellProps {
  value?: string; // URL of the image
  active?: boolean; // Optional prop for indicating active state
}
const ImageCell: React.FC<ImageCellProps> = ({ value }) => {
  // Check if value is a string before using .trim()
  if (typeof value === "string" && value.trim() !== "---") {
    return <img src={value} alt="" style={{ width: "30%", height: "auto" }} />;
  } else {
    return <span>----</span>;
  }
};

// const ImageCell: React.FC<ImageCellProps> = ({ value }) =>
//   value && value.trim() !== "---" ? (
// //     <img src={value} alt="" style={{ width: "30%", height: "auto" }} />
// //   ) : (
// //     <span>----</span>
// //   );
// const ImageCell: React.FC<ImageCellProps> = ({ value }) => {
//   // Check if value is a string before using .trim()
//   if (typeof value === "string" && value.trim() !== "---") {
//     return <img src={value} alt="" style={{ width: "30%", height: "auto" }} />;
//   } else {
//     return <span>----</span>;
//   }
// };
//const HeroBannerTable = () => {
export default function NewsEditor(props: any) {
  const {
    HeroBannerData,
    updateItem,
    sendItem,
    deleteItem,
    isSuccess,
    isLoading,
    refetch,
  } = props;
  console.log(updateItem, "HeroBannerData:", HeroBannerData);
  //to add to other components
  const [addToHeroBannerToggleUpdation] = useSendDataToHeroBannerMutation();//new mutation
  const [sendItemToHeroBanner] = useCreateheroBannerFromOthersMutation();
  const [deleteItemToHeroBanner] = useDeleteOtherDataInheroBannerMutation();
  const [uploadNewsDescriptionImage] = useUploadNewsDescriptionImageMutation();
  //for alert
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  //for alert
  const [openOne, setOpenOne] = React.useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [dataId, setDataId] = useState<any>("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  //snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tagOption, setTagOption] = useState("");
  const [sectionTagOption, setSectionTagOption] = useState("");
  const [manualTag, setManualTag] = useState("");
  const [sectionManualTag, setSectionManualTag] = useState("");
  const [tagError, setTagError] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedSectionTag, setSelectedSectionTag] = useState("");
  const [sectionOption, setSectionOption] = React.useState("");
  const [sectionError, setSectionError] = React.useState("");
  const [publishDate, setpublishDate] = useState<Date | null>(null);
  const [year, setYear] = useState("");
  const [yearError, setYearError] = useState("");
  const [publishDateError, setPublishDateError] = useState("");
  const [tooltipOpenCell, setTooltipOpenCell] = useState<string | null>(null);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const showLoader = () => setIsLoadingg(true);
  const hideLoader = () => setIsLoadingg(false);
  // const [tooltipOpenCell, setTooltipOpenCell] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [filterField, setFilterField] = useState<string | null>(null);
  const [filteredRows, setFilteredRows] = useState<GridValidRowModel[]>([]);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [paginatedRows, setPaginatedRows] = useState<GridValidRowModel[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [filterSelectedOptions, setFilterSelectedOptions] = useState<{ [key: string]: string[] }>({});

  const { data: selectedNewsData, refetch: refetchNewsItem } = useGetRemoNewsItemByIdQuery(dataId ?? "", {
    skip: !dataId, // Skip the query if dataId is falsy
  })

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  // useEffect(() => {
  //   const startIndex = (page - 1) * pageSize;
  //   setPaginatedRows(filteredRows.slice(startIndex, startIndex + pageSize));
  // }, [page, pageSize, filteredRows]);

  useEffect(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = Math.min(filteredRows.length, startIndex + pageSize); // Fix end index calculation
    setPaginatedRows(filteredRows.slice(startIndex, endIndex));
  }, [page, pageSize, filteredRows]);

  useEffect(() => {
    const newFilteredRows = rows.filter((row) =>
      columns.some((column) =>
        String(row[column.field as keyof GridValidRowModel])
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
    setFilteredRows(newFilteredRows);
    setPage(1);
  }, [searchTerm, rows]);

  const handleFilterIconClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterField(field);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterField(null);
  };

  const handleFilterChange = (field: string, selectedOptions: string[]) => {
    setFilterSelectedOptions(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    // If no options are selected, display all rows
    if (selectedOptions.length === 0) {
      setFilteredRows(rows);
      return;
    }

    // Filter rows based on the selected options for the field
    const newFilteredRows = rows.filter((row) => {
      const cellValue = String((row as any)[field]).toLowerCase();

      // Check if the cell value matches any of the selected options
      return selectedOptions.some(option =>
        cellValue.includes(option.toLowerCase()) && cellValue !== ""
      );
    });

    setFilteredRows(newFilteredRows);
    setPage(1);
  };
  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   const newFilteredRows = rows.filter((row) => {
  //     return selectedOptions.includes((row as any)[field]);
  //   });
  //   setFilteredRows(newFilteredRows);
  // };


  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   const newFilteredRows = rows.filter((row) => {
  //     const cellValue = (row as any)[field];
  //     return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
  //   });
  //   setFilteredRows(newFilteredRows);
  // };

  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   setFilterSelectedOptions(prevState => ({
  //     ...prevState,
  //     [field]: selectedOptions,
  //   }));

  //   // If no options are selected, display all rows
  //   if (selectedOptions.length === 0) {
  //     setFilteredRows(rows);
  //     return;
  //   }

  //   // Filter rows based on the selected options for the field
  //   const newFilteredRows = rows.filter((row) => {
  //     const cellValue = String((row as any)[field]).toLowerCase();

  //     // Check if the cell value matches any of the selected options
  //     return selectedOptions.some(option =>
  //       cellValue.includes(option.toLowerCase()) && cellValue !== ""
  //     );
  //   });

  //   setFilteredRows(newFilteredRows);
  //   setPage(1);
  // };

  const [sortModel, setSortModel] = useState<any>([]);

  const handleSortModelChange = (model: any) => {
    setSortModel(model);
    setPage(1); // Reset to first page when sorting changes
  };
  useEffect(() => {
    let tempRows = [...filteredRows]; // Create a copy to avoid mutating the original array

    // Apply sorting
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      tempRows.sort((a, b) => {
        const aValue = (a[field as keyof typeof a] || '').toString().toLowerCase();
        const bValue = (b[field as keyof typeof b] || '').toString().toLowerCase();
        if (sort === 'asc') {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }

    // Apply pagination
    const startIndex = (page - 1) * pageSize;
    setPaginatedRows(tempRows.slice(startIndex, startIndex + pageSize));
  }, [filteredRows, page, pageSize, sortModel]);

  //old code
  // const handleFilterChange = (field: string, selectedOptions: string[]) => {
  //   setFilterSelectedOptions(prevState => ({
  //     ...prevState,
  //     [field]: selectedOptions,
  //   }));

  //   const newFilteredRows = rows.filter((row) => {
  //     const cellValue = (row as any)[field];
  //     return selectedOptions.includes(cellValue) && cellValue !== "" && cellValue !== null && cellValue !== undefined;
  //   });

  //   setFilteredRows(newFilteredRows);
  //   setPage(1);
  // };


  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  // Handle cell click to toggle tooltip visibility
  const handleCellClick = (eventDescription: string) => {
    setTooltipOpenCell((prev) =>
      prev === eventDescription ? null : eventDescription
    );
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const newhandleCellClick = (content: any) => {
    setDialogContent(content);
    setDialogOpen(true);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };
  //for populating the table
  console.log(HeroBannerData, 'HeroBannerData')
  useEffect(() => {
    if (isSuccess && HeroBannerData) {
      const formattedRows = HeroBannerData.result.map((ceoMessage: any, index: number) => ({
        // const rows = isSuccess
        // ? HeroBannerData.result.map((ceoMessage: any, index: number) => ({
        id: ceoMessage?._id,
        Id: index + 1,
        Title: ceoMessage?.newsTitle,
        Description: ceoMessage?.shortDescription,
        Image: ceoMessage?.newsImage,
        // Image: ceoMessage?.newsImage.length === 0 ? ceoMessage.newsImageInArray[0]?.url : ceoMessage?.newsImage[0]?.url,
        // Image: ceoMessage?.newsImage?.length > 0 ? ceoMessage?.newsImage[0]?.url : ceoMessage?.newsImageInArray?.[0]?.url ?? null,
        isActive: ceoMessage?.isActive,
        isDraft: ceoMessage?.isDraft,
        fileName: ceoMessage?.fileName,
        fileType: ceoMessage?.fileType,
        newsReference: ceoMessage?.newsReference,
        newsSection: ceoMessage?.newsSection,
        enableLikes: ceoMessage?.enableLikes,
        enableComments: ceoMessage?.enableComments,
        sharedAsEmail: ceoMessage?.sharedAsEmail,
        addToHeroBanner: ceoMessage?.addToHeroBanner,
        modifiedDate: ceoMessage?.updatedAt,
        publishedDate: ceoMessage?.createdAt,
        status: ceoMessage?.isActive ? "Published" : "Draft", // Add status field
        newsOrder: ceoMessage?.newsOrder,
        newsPublishDate: ceoMessage?.newsPublishDate,
      }));
      setRows(formattedRows);
      setFilteredRows(formattedRows);
      setIsLoadingg(false);
    }
  }, [isSuccess, HeroBannerData]);

  console.log(rows, "Rowsssss",);
  const [checkedyesisActive, setCheckedyesisActive] = useState<boolean>(true);
  const [checkednoisActive, setCheckednoisActive] = useState<boolean>(false);
  const [isActives, setIsActives] = useState<boolean>(false);
  const [enablelikes, setEnableLikes] = useState<boolean>(false);
  const [enableCommands, setCommands] = useState<boolean>(false);
  const [sharedAsEmails, setSharedEmails] = useState<boolean>(false);
  const [checkedyesEnableLikes, setCheckedyesEnableLikes] =
    useState<boolean>(true);
  const [checkednoEnableLikes, setCheckednoEnableLikes] =
    useState<boolean>(false);
  const [checkedyesEnableCommands, setCheckedyesEnableCommands] =
    useState<boolean>(true);
  const [checkednoEnableCommands, setCheckednoEnableCommands] =
    useState<boolean>(false);
  const [checkedyesSharedAsEmail, setCheckedyesSharedAsEmail] =
    useState<boolean>(true);
  const [checkednoSharedAsEmail, setCheckednoSharedAsEmail] =
    useState<boolean>(false);
  console.log(
    checkednoEnableLikes,
    checkednoEnableCommands,
    checkednoEnableCommands,
    checkednoSharedAsEmail,
    "ddddd"
  );
  console.log(isActives, "isActives", checkedyesisActive);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [Title, setTitle] = useState<any>("");
  const [Description, setDescription] = useState<any>("");
  const [orderValue, setOrderValue] = useState<any>("");
  const [existingImage, setExistingImage] = useState(null);
  const [designations, setDesignations] = useState<any>("");
  const [ceoName, setCeoName] = useState<any>("");
  console.log(ceoName, "ceoName");
  const [sectionName, setSectionName] = useState<any>("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [orderError, setOrderError] = useState("");
  const [empError, setEmpError] = useState("");
  const [deginError, setDeginError] = useState("");
  const [imageError, setImageError] = useState("");
  const [state, setState] = useState({
    warningMsg: "",
  });
  const [state1, setState1] = useState({
    files: [],
  });
  const [state2, setState2] = useState({
    files: [],
  });
  const [filename1, setFilename1] = useState<any>("");
  const [filename2, setFilename2] = useState<any>("");
  const [base1, setBase1] = useState<any>("");
  const [base2, setBase2] = useState<any>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const stripHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const [columnHeaders, setcolumnHeaders] = React.useState<any>({
    id: true,
    newsTitle: true,
    newsDescription: true,
    newsReference: true,
    createdAt: true,
    addToHeroBanner: true,
  });
  const handleExport = () => {
    console.log("columnHeaders:", columnHeaders);
    const tableData = HeroBannerData && HeroBannerData.result ? HeroBannerData.result : [];

    // Mapping through table data to format rows
    const formattedRows = tableData.map((item: any) => {
      const cleanedItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        const cleanedKey = key.replace(/"/g, ""); // Remove quotes from keys if necessary
        cleanedItem[cleanedKey] = item[key]; // Assign cleaned data
      });
      const formattedDate = cleanedItem.eventDate
        ? format(new Date(cleanedItem.eventDate), "dd/MM/yyyy")
        : "N/A";
      const formattedEventEndDate = cleanedItem.eventEndDate
        ? format(new Date(cleanedItem.eventEndDate), "dd/MM/yyyy")
        : "N/A";
      const formattedPublishedDateDate = cleanedItem.createdAt
        ? format(new Date(cleanedItem.createdAt), "dd/MM/yyyy")
        : "N/A";
      let exportData: Record<string, any> = {}; // Prepare data for export
      if (columnHeaders["newsTitle"]) {
        const maxLength = 100; // Example limit for title
        exportData["newsTitle"] = cleanedItem.newsTitle
          ? cleanedItem.newsTitle.substring(0, maxLength)
          : "N/A";
      }

      if (columnHeaders["newsDescription"]) {
        const maxLength = 32000; // Safe limit to avoid exceeding Excel's limit
        const cleanedDescription = cleanedItem.newsDescription
          ? cleanedItem.newsDescription.replace(/<\/?[^>]+(>|$)/g, "") // Strip HTML tags
          : "N/A";
        exportData["newsDescription"] = cleanedDescription.length > maxLength
          ? cleanedDescription.substring(0, maxLength) + "..."
          : cleanedDescription; // Truncate if too long
      }
      if (columnHeaders["newsReference"])
        exportData["News Reference"] = cleanedItem.newsReference ?? "N/A";

      if (columnHeaders["createdAt"])
        exportData["Published Date"] = formattedPublishedDateDate ?? "N/A";

      if (columnHeaders["addToHeroBanner"])
        exportData["Add to Hero Banner"] = cleanedItem.addToHeroBanner
          ? "Yes"
          : "No"; // Convert boolean to "Yes" or "No"



      // if (columnHeaders["eventDate"])
      //   exportData["Event Start Date"] = formattedDate ?? "N/A";
      // if (columnHeaders["eventTime"]) {
      //   exportData["Event Start Time"] = cleanedItem.eventTime
      //     ? new Date(`1970-01-01T${cleanedItem.eventTime}`).toLocaleTimeString('en-US', {
      //       hour: '2-digit',
      //       minute: '2-digit',
      //       hour12: true,
      //     }) // Format time to include AM/PM
      //     : "N/A";
      // }
      // if (columnHeaders["eventEndDate"])
      //   exportData["Event End Date"] = formattedEventEndDate ?? "N/A";

      // if (columnHeaders["eventEndTime"]) {
      //   exportData["Event End Time"] = cleanedItem.eventEndTime
      //     ? new Date(`1970-01-01T${cleanedItem.eventEndTime}`).toLocaleTimeString('en-US', {
      //       hour: '2-digit',
      //       minute: '2-digit',
      //       hour12: true,
      //     }) // Format time to include AM/PM
      //     : "N/A";
      // }

      // if (columnHeaders["eventsReference"])
      //   exportData["Tag"] = cleanedItem.eventOrganizedBy ?? "N/A";




      return exportData; // Return formatted row for export
    });

    // Create a new workbook and sheet with the formatted rows
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedRows);

    // Calculate and set the column widths dynamically
    // const colWidths = formattedRows.reduce((acc: number[], row: any) => {
    //   Object.keys(row).forEach((key, i) => {
    //     const cellValue = row[key]?.toString() || "";
    //     const cellWidth = cellValue.length;
    //     if (!acc[i] || cellWidth > acc[i]) {
    //       acc[i] = cellWidth;
    //     }
    //   });
    //   return acc;
    // }, []);

    const columnWidths: { [key: string]: number } = {
      "News Title": 15,
      "News Description": 30,
      "News Reference": 20,
      "Published Date": 15,
      "Add to Hero Banner": 15,
    };


    // Apply the calculated column widths to the sheet
    // ws["!cols"] = columnWidths.map((width: any) => ({ wch: width }));
    ws["!cols"] = Object.keys(columnWidths).map((key: string, i: number) => ({ wch: columnWidths[key] }));

    // Append the sheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "ExportedData");

    // Write the workbook to an Excel file
    XLSX.writeFile(wb, "NewsData.xlsx");
  };

  const columns = [
    // { field: "ceoMessageId", headerName: "ID", width: 250, sortable: false },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <EditCell onClick={() => handleEditClick(params.row.id)} />
    //   ),
    // },
    // {
    //   field: "Delete",
    //   headerName: "Delete",
    //   width: 70,
    //   sortable: false,
    //   renderCell: (params: any) => (
    //     <DeleteCell onClick={() => handleEditDelete(params.row.id)} />
    //   ),
    // },
    // {
    //   field: "Id",
    //   headerName: "ID",
    //   width: 50,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'newsTableCell'
    // },
    {
      field: "Title",
      headerName: "Title",
      width: 200,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      // renderCell: (params: any) => (
      //   <BadgeCell active={params.row.isActive} value={params.row.Title} />
      // ),
      // renderCell: (params: GridRenderCellParams) => {
      //   const truncatedValue =
      //     params.value.length > 30
      //       ? params.value.substring(0, 30) + "..."
      //       : params.value;
      //   return (
      //     <div style={{ cursor: "pointer" }}>
      //       <Tooltip
      //         placement="bottom-start"
      //         title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
      //       // arrow
      //       >
      //         <div
      //           className="descriptionceo"
      //           dangerouslySetInnerHTML={{ __html: truncatedValue }}
      //         />
      //       </Tooltip>
      //     </div>
      //   );
      // },
      renderCell: (params: GridRenderCellParams) => {
        const maxLength = 30;
        const truncatedValue = params.value.length > maxLength
          ? params.value.substring(0, maxLength) + "..."
          : params.value;

        // Check if the text is truncated
        const isTruncated = params.value.length > maxLength;

        return (
          <div >
            {isTruncated ? (
              <Tooltip
                placement="bottom-start"
                title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
                style={{ cursor: "pointer" }}
              >
                <div dangerouslySetInnerHTML={{ __html: truncatedValue }} />
              </Tooltip>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: truncatedValue }}
                style={{ cursor: "default" }} />
            )}
          </div>
        );
      },
    },
    // { field: "ceoName", headerName: "Name", width: 200, sortable: false },
    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "TableHeader",
    //   cellClassName: "newsTableCell",
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const truncatedValue =
    //       params.value.length > 30
    //         ? params.value.substring(0, 30) + "..."
    //         : params.value;

    //     return (
    //       <div style={{cursor:"pointer"}}>
    //       <Tooltip
    //         open={tooltipOpenCell === params.row.id}
    //         placement="right-start"
    //         title={<div dangerouslySetInnerHTML={{ __html: params.value }} />}
    //         arrow
    //       >
    //         <div
    //           className="descriptionceo"
    //           dangerouslySetInnerHTML={{ __html: truncatedValue }}
    //           onClick={() => handleCellClick(params.row.id)}
    //         />
    //       </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "HeroBannerTableCell",
    //   valueGetter: (params: { value: any; }) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const truncatedValue =
    //       params.value.length > 30
    //         ? params.value.substring(0, 30) + "..."
    //         : params.value;

    //     return (
    //       <div style={{ cursor: "pointer" }}
    //         className="descriptionceo"
    //         dangerouslySetInnerHTML={{ __html: truncatedValue }}
    //         onClick={() => newhandleCellClick(params.value)}
    //       />
    //     );
    //   },
    // },


    // {
    //   field: "Description",
    //   headerName: "Description",
    //   width: 250,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   valueGetter: (params: { value: any; }) => (params.value ? params.value : "----"),
    //   renderCell: (params: GridRenderCellParams) => {
    //     // Sanitize the HTML content
    //     const sanitizedValue = stripHtml(params.value);

    //     // Truncate the sanitized value if necessary
    //     const truncatedValue =
    //       sanitizedValue.length > 30 ? sanitizedValue.substring(0, 30) + "..." : sanitizedValue;

    //     return (
    //       <div
    //         style={{ cursor: "pointer" }}
    //         className="descriptionceo"
    //         onClick={() => newhandleCellClick(sanitizedValue)} // Ensure this function handles plain text
    //       >
    //         {truncatedValue} {/* Render as plain text */}
    //       </div>
    //     );
    //   },
    // },

    {
      field: "Description",
      headerName: "Description",
      width: 250,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      // Fallback to "----" when the description is empty
      valueGetter: (params: { value: any }) => params.value && params.value.trim() !== "" ? params.value : "----",
      renderCell: (params: GridRenderCellParams) => {
        // Sanitize the HTML content
        const sanitizedValue = stripHtml(params.value);

        // Handle case where the value is "----" or empty
        const displayValue = sanitizedValue && sanitizedValue !== "----" ? sanitizedValue : "----";

        // Truncate the sanitized value if necessary
        const truncatedValue =
          displayValue.length > 30 ? displayValue.substring(0, 30) + "..." : displayValue;

        return (
          <div
            style={{ cursor: "pointer" }}
            className="descriptionceo"
            onClick={() => newhandleCellClick(params.value)} // Ensure this function handles plain text
          >
            {truncatedValue} {/* Render as plain text */}
          </div>
        );
      },
    },


    // {
    //   field: "newsReference",
    //   headerName: "Tag",
    //   width: 120,
    //   headerClassName: "TableHeader",
    //   cellClassName: "newsTableCell",
    //   sortable: true,
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    // },
    {
      field: "newsReference",
      headerName: "Tag",
      width: 200,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      valueGetter: (params: any) => (params.value ? params.value : "----"),
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{params.colDef.headerName}</span>
          <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <span>{params.value || "----"}</span>
      ),
      // renderCell: (params: GridRenderCellParams) => {
      //   const [tooltipOpenCell, setTooltipOpenCell] = useState<null | number>(null);

      //   const handleTooltipOpen = (id: number) => {
      //     setTooltipOpenCell(id);
      //   };

      //   const handleTooltipClose = () => {
      //     setTooltipOpenCell(null);
      //   };

      //   return (
      //     <div >
      //     <Tooltip
      //       open={tooltipOpenCell === params.row.id}
      //       onOpen={() => handleTooltipOpen(params.row.id)}
      //       onClose={handleTooltipClose}
      //       title={params.value || "----"}
      //       placement="left"
      //       arrow
      //     >
      //       <span>{params.value || "----"}</span>
      //     </Tooltip>
      //     </div>
      //   );
      // },
    },
    // {
    //   field: "newsSection",
    //   headerName: "Section",
    //   width: 120,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "newsTableCell",
    //   sortable: true,
    //   valueGetter: (params: any) => (params.value ? params.value : "----"),
    //   renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
    //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //       <span>{params.colDef.headerName}</span>
    //       <Tooltip title="Filter">
    //       <IconButton
    //         onClick={(event) => handleFilterIconClick(event, params.field)}
    //         size="small"
    //         style={{ marginLeft: 'auto' }}
    //       >
    //         <FilterListIcon />
    //       </IconButton>
    //       </Tooltip>
    //     </div>
    //   ),
    // },

    // {
    //   field: "ceoDesignation",
    //   headerName: "Designation",
    //   width: 150,
    //   sortable: false,
    // },
    // {
    //   field: "Image",
    //   headerName: "Image",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   valueGetter: (params: any) => {
    //     const { newsImage, newsImageInArray } = params.row;
    //     console.log('params.....:', params);
    //     console.log('newsImage:', newsImage);
    //     console.log('newsImageInArray:', newsImageInArray);
    //     return params?.value
    //     // return newsImage?.length === 1 ? newsImage[0]?.url : newsImageInArray;
    //   },
    //   renderCell: (params: any) => <ImageCell value={params?.value} />,
    // },
    {
      field: "Image",
      headerName: "Image",
      width: 150,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      valueGetter: (params: any) => {
        // Check if params.value exists and is a string before trimming
        const value = params?.value;

        if (typeof value === "string" && value.trim()) {
          return value;
        }

        return "---"; // Placeholder if no valid string is found
      },
      renderCell: (params: any) => <ImageCell value={params.value} />,
    },
    // {
    //   field: "newsOrder",
    //   headerName: "Order",
    //   // type: "email",
    //   width: 160,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   sortable: false,
    //   renderCell: (params: GridRenderCellParams) => {
    //     return (
    //       <div
    //         style={{ overflow: 'hidden', textOverflow: 'ellipsis', cursor: "default" }}
    //         title="" // Set title to an empty string to hide tooltip
    //       >
    //         {params.value}
    //       </div>
    //     );
    //   },
    // },



    {
      field: "newsPublishDate",
      headerName: "Publish Date",
      width: 170,
      cellClassName: "NewTableCell",
      headerClassName: "NewTableHeader",
      sortable: true,
      renderCell: (params: any) => {
        const dateTimeString = params.value as string;

        if (dateTimeString) {
          const date = new Date(dateTimeString);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();

          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const formattedHours = String(hours).padStart(2, "0");

          // const formattedDateTime = `${year}-${month}-${day} | ${formattedHours}:${minutes} ${ampm}`;
          const formattedDateTime = `${year}-${month}-${day}`;
          return <span>{formattedDateTime}</span>;
        }

        return <span> - </span>;
      },
    },

    {
      field: "modifiedDate",
      headerName: "Modified On",
      width: 170,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: true,
      renderCell: (params: any) => {
        const dateTimeString = params.value as string;

        if (dateTimeString) {
          const date = new Date(dateTimeString);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
          const year = date.getFullYear();

          let hours = date.getHours();
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          const formattedHours = String(hours).padStart(2, "0");

          const formattedDateTime = `${year}-${month}-${day} | ${formattedHours}:${minutes} ${ampm}`;

          return <span>{formattedDateTime}</span>;
        }

        return <span> - </span>;
      },
    },
    // {
    //   field: "modifiedDate",
    //   headerName: "Modified On",
    //   width: 170,
    //   headerClassName: "EventsTableHeader",
    //   cellClassName: "EventsTableCell",
    //   sortable: true,
    //   renderCell: (params: any) => {
    //     const dateTimeString = params.value as string;

    //     if (dateTimeString) {
    //       const [datePart, timePart] = dateTimeString.split("T");
    //       const [year, month, day] = datePart.split("-");
    //       const [hours, minutes] = timePart.split(":");
    //       const formattedDateTime = `${day}-${month}-${year} | ${hours}:${minutes}`;

    //       return <span>{formattedDateTime}</span>;
    //     }

    //     return <span> - </span>;
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      // renderCell: (params: any) => {
      //   return <span>{params.value}</span>;
      // },
      renderHeader: (params: GridColumnHeaderParams<GridValidRowModel>) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{params.colDef.headerName}</span>
          <Tooltip title="Filter">
            <IconButton
              onClick={(event) => handleFilterIconClick(event, params.field)}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "isActive",
      headerName: "IsActive",
      type: "image",
      width: 100,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => (
        <div className="custom-switch">
          <Tooltip
            title={params.row.isActive ? "Item activated" : "Item deactivated"}
            placement="bottom"
          >
            <Switch
              checked={params.row.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChangeIsActiveToggle(event, params?.row?.id)
              }
            />
          </Tooltip>
        </div>
      ),
    },
    // {
    //   field: "EnableLikes",
    //   headerName: "EnableLikes",
    //   width: 110,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'newsTableCell',
    //   renderCell: (params: any) => (
    //     <div style={{ margin: "15px" }}>
    //       <SwitchCell
    //         active={params.row.enableLikes}
    //         onChange={(event: any, checked: any) =>
    //           handleSwitchChangeForLikes(event, checked, params?.row?.id)
    //         }
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   field: "enableComments",
    //   headerName: "EnableComments",
    //   type: "number",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'newsTableCell',
    //   renderCell: (params: any) => (
    //     <div style={{ margin: "40px" }}>
    //       <SwitchCell
    //         active={params.row.enableComments}
    //         onChange={(event: any, checked: any) =>
    //           handleSwitchChangeForComments(event, checked, params?.row?.id)
    //         }
    //       />
    //     </div>
    //   ),
    // },
    // {
    //   field: "sharedAsEmail",
    //   headerName: "ShareAsEmail",
    //   type: "email",
    //   width: 150,
    //   sortable: true,
    //   headerClassName: 'TableHeader',
    //   cellClassName: 'newsTableCell',
    //   renderCell: (params: any) => (
    //     <div style={{ margin: "20px" }}>
    //       <SwitchCell
    //         active={params.row.sharedAsEmail}
    //         onChange={(event: any, checked: any) =>
    //           handleSwitchChangeForShareAsEmail(event, checked, params?.row?.id)
    //         }
    //       />
    //     </div>
    //   ),
    // },

    //New Code
    {
      field: "addToHeroBanner",
      headerName: "Add to Hero Banner",
      width: 160,
      sortable: true,
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      renderCell: (params: any) => (
        <div style={{ margin: "30px" }} className="custom-switch">
          <SwitchCell
            active={params?.row?.addToHeroBanner || false} // Set default value in case it's undefined
            onChange={(event: any, checked: boolean) =>
              handleSwitchChangeForAddingToHeroBanner(
                event,
                checked,
                params?.row?.id,
                params.row.addToHeroBanner
              )
            }
          />
        </div>
      ),
    },
    //Old Code
    // {
    //   field: "addToHeroBanner",
    //   headerName: "Add to Hero Banner",
    //   width: 160,
    //   sortable: true,
    //   headerClassName: "NewTableHeader",
    //   cellClassName: "NewTableCell",
    //   renderCell: (params: any) => (
    //     <div style={{ margin: "30px" }} className="custom-switch">
    //       <SwitchCell
    //         active={params?.row?.addToHeroBanner}
    //         onChange={(event: any, checked: any) =>
    //           handleSwitchChangeForAddingToHeroBanner(
    //             event,
    //             checked,
    //             params?.row?.id,
    //             params.row.addToHeroBanner
    //           )
    //         }
    //       />
    //     </div>
    //   ),
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 100, // Adjust the width as needed
      headerClassName: "NewTableHeader",
      cellClassName: "NewTableCell",
      sortable: false,
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Edit">
            <span >
              <EditCell onClick={() => handleEditClick(params.row.id)} />
            </span>
          </Tooltip>

          <Tooltip title="Delete">
            <span>
              <DeleteCell onClick={() => handleEditDelete(params.row.id)} />
            </span>
          </Tooltip>
        </div>
      ),
      // renderHeader: () => <div style={{ marginLeft: "7px",color:"#707073" }}>Actions</div>,
    },
  ];

  //New code
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  // const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  console.log(uploadedFiles, "uploadedFiles")
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [filePreviews, setFilePreviews] = useState<string[]>([]);
  const [uploadError, setUploadError] = useState<string>("");

  const imageUrls = uploadedFiles.map((file) =>
    file instanceof File ? URL.createObjectURL(file) : file.url
  );


  // const mediaItems = uploadedFiles.map((file) => {
  //   // Create an object URL for preview purposes
  //   const url = file instanceof File ? URL?.createObjectURL(file) : file.url;

  //   // Ensure the type is correctly asserted as 'image' or 'video'
  //   const type: 'image' | 'video' = file.type?.startsWith('image/') ? 'image' : 'video';

  //   return {
  //     type: type,
  //     url: url,
  //   };
  // });


  const fileRef = React.useRef<HTMLInputElement | null>(null);
  const fileRef1 = React.useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File | null>();
  const [selectedFiles1, setSelectedFiles1] = useState<File | null>();
  const [fileSelected, setFileSelected] = useState<any>("");
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const [fileSelected1, setFileSelected1] = useState<any>("");
  const [alertActivationForCancel, setAlertActivationForCancel] =
    useState<boolean>(false);
  const [names, setNames] = useState<any>("");
  const [names1, setNames1] = useState<any>("");
  const [description, setDescriptions] = useState<string>(""); // Initialize state for description
  // const [page, setPage] = useState(1); // Initial page state
  // const [pageSize, setPageSize] = useState(7); // Initial page size state

  // Calculate the starting index of rows to display based on current page and page size
  // const startIndex = (page - 1) * pageSize;
  // Slice the rows array to get the subset of rows to display on the current page
  // const paginatedRows = rows.slice(startIndex, startIndex + pageSize);

  useEffect(() => {
    console.log("Updated tagOption state:", sectionTagOption);
  }, [sectionTagOption]);
  useEffect(() => {
    console.log("Rows data:", rows);
  }, [rows]);

  console.log(selectedFiles?.name, "fileSelected");
  const handleAlertYes = () => {
    deleteItem(dataId);
    setAlertActivation(false);
    refetch();
    //snackBar alert
    setSnackbarOpen(true);
    setSnackbarMessage("Data has been deleted successfully");
  };
  const handleAlertCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };
  const handleAlertYesForCancel = () => {
    handleDrawerClose();
    setAlertActivationForCancel(false);
    setAlertContent("");
  };
  const handleAlertNoCancel = () => {
    setAlertActivationForCancel(false);
    setAlertContent("");
  };

  useEffect(() => {
    if (selectedNewsData?.result && dataId == selectedNewsData?.result?._id && openOne) {
      let newsdata = selectedNewsData?.result
      setDescription(newsdata?.newsDescription);
      setDescriptions(newsdata?.newsDescription);
    }
  }, [dataId, selectedNewsData, openOne])
  const handleEditClick = (id: any) => {
    setOpenOne(true);
    setIsEditMode(true);
    console.log(id, "editId");
    setOpenPreview(false);
    setDataId(id);
    const data = rows.filter((i: any) => {
      return i.id == id;
    });

    setExistingImage(data[0].Image);
    const existingTag = data[0].newsReference;
    setSelectedTag(existingTag);

    const predefinedTags = [
      "Corporate Announcements",
      "Industry News",
      "Marketing News",
      "Project News",
      "Staff News",
      "Training News",
    ];
    if (predefinedTags.includes(existingTag)) {
      setTagOption(existingTag);
      setManualTag("");
    } else {
      setTagOption("Add Choice Manually");
      setManualTag(existingTag);
    }

    const existingSectionTag = data[0].newsSection;
    setSelectedSectionTag(existingSectionTag);

    const predefinedSectionTags = [
      "Event",
      "Conference",
      "Showcase",
      "Appointment",
      "Anniversary",
      "Award",
    ];
    if (predefinedSectionTags.includes(existingSectionTag)) {
      setSectionTagOption(existingSectionTag);
      setSectionManualTag("");
    } else {
      setSectionTagOption("Add Choice Manually ");
      setSectionManualTag(existingSectionTag);
    }
    console.log(data, "datadata");
    setTitle(data[0].Title);
    // setDescription(data[0].Description);
    // setDescriptions(data[0].Description);
    setDesignations(data[0].Designation);
    setCeoName(data[0].newsReference);
    setSectionName(data[0].newsSection);
    setSelectedFiles(data[0].Image);
    setFileSelected(data[0].Image);
    setSelectedFileName(data[0].fileName);
    // setOrderValue(data[0].newsOrder);
    const newsPublishDate = data[0]?.newsPublishDate;
    const parsedDate = newsPublishDate ? new Date(newsPublishDate) : null;
    setpublishDate(parsedDate);  // Setting the Date object directly
    console.log(newsPublishDate, "preset publish date:", data[0]);

    console.log(data[0].newsPublishDate, "preset publish date:", data[0])
    // setUploadedFiles(data[0].Image);
    // setFileSelected(data[0].Image);
    // setFileNames([data[0].fileName]);
    // setSelectedFileName(data[0].fileName);

    // const imageData = data[0]?.Image;
    // const fileName = data[0]?.fileName || 'Unknown';
    // const imageArray = Array.isArray(imageData) ? imageData : (imageData ? [imageData] : []);

    // const updatedFiles = imageArray.map(img => {
    //   if (img instanceof File) return img;
    //   // If it's a string (URL), create a new object with name and url properties
    //   return { 
    //     name: fileName,
    //     url: typeof img === 'string' ? img : ''
    //   };
    // });

    // const imageData = data[0]?.Image;
    // let fileName = data[0]?.fileName;

    // // Try to derive a fileName from the image URL if it's missing
    // if (!fileName && imageData) {
    //   const imageUrl = Array.isArray(imageData) ? imageData[0] : imageData;
    //   fileName = imageUrl?.split('/').pop()?.split('?')[0] || 'Unknown';
    // }

    // const imageArray = Array.isArray(imageData) ? imageData : (imageData ? [imageData] : []);

    // const updatedFiles = imageArray.map(img => {
    //   if (img instanceof File) return img;
    //   // If it's a string (URL), create a new object with name and url properties
    //   return {
    //     name: fileName || 'Unknown',
    //     url: typeof img === 'string' ? img : ''
    //   };
    // });


    // setUploadedFiles(updatedFiles);
    // setFileSelected(updatedFiles.length > 0 ? updatedFiles[0] : null);
    // setFileNames(updatedFiles.map(file => file.name || fileName));
    // setSelectedFileName(fileName);

    // const imageArray = Array.isArray(data[0].Image) ? data[0].Image : [data[0].Image];
    // setUploadedFiles(imageArray);
    // setFileSelected(imageArray.length > 0 ? imageArray[0] : null);
    // setFileNames([data[0].fileName]);
    // setSelectedFileName(data[0].fileName);

    setCheckedyesisActive(data[0].isActive);
    setCheckednoisActive(!data[0].isActive);
    // setCheckedyesEnableLikes(data[0].enableLikes);
    // setCheckednoEnableLikes(!data[0].enableLikes);
    // setCheckedyesEnableCommands(data[0].enableComments);
    // setCheckednoEnableCommands(!data[0].enableComments);
    // setCheckedyesSharedAsEmail(data[0].sharedAsEmail);
    // setCheckednoSharedAsEmail(!data[0].sharedAsEmail);
    setNames(data[0].imageName);
    // setSelectedFiles(ceoImage)
    // setSelectedFiles1()
    //isActive
    console.log(data, "dataaa");
  };
  const handleEditDelete = (id: any) => {
    setDataId(id);
    setAlertContent("Do you want to delete this data ?");
    setAlertActivation(true);
  };
  //for table switches
  // old code 

  const handleSwitchChangeForAddingToHeroBanner = (
    event: any,
    checked: any,
    id: any,
    activeStatus: any //status of hero banner toggle
  ) => {
    //To get the data to be sent to hero banner
    const data = rows.filter((i: any) => {
      return i.id == id;
    });
    console.log(data, "data");
    if (data[0].isActive) {
      //To send data to HeroBanner
      const HeroBannerDatas = {
        heroBannerId: data[0].id,
        heroBannerTitle: data[0].Title,
        heroBannerDescription: data[0].Description,
        heroBannerFile: data[0].Image,
        fileType: data[0].fileType,
        isActive: true,
        // time: Time,
        // expiresOn: ExpiresOn,
        enableLikes: enablelikes,
        enableComments: enableCommands,
        sharedAsEmail: sharedAsEmails,
        isDraft: false,
        // selectedFilesData:selectedFilesData
      };

      //@ts-ignore
      // const formData = createFormData(HeroBannerData);
      //  const formData = HeroBannerData;
      console.log(activeStatus, HeroBannerDatas, "activeStatus", data);
      if (activeStatus) {
        console.log("delete hero  banner")
        //remove data from hero banner
        deleteItemToHeroBanner(id);
        //to update the addToHeroBanner toggle.
        const formData = new FormData();
        const data = {
          newsId: id,
          addToHeroBanner: false,
          onlyActiveStatus: true,
        };
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        addToHeroBannerToggleUpdation(formData).then((res: any) => {
          console.log(res, "Update Response");
          refetch();
        });
      } else {
        //save function
        console.log(HeroBannerDatas, "sending data to hero banner");
        //addToHeroBanner.
        sendItemToHeroBanner(HeroBannerDatas).then((res: any) => {
          refetch();
          console.log("sending data to hero banner through addToHeroBannerToggleUpdation");
        });
        //to update the addToHeroBanner toggle.
        const formData = new FormData();
        const data = {
          newsId: id,
          addToHeroBanner: true,
          onlyActiveStatus: true,
        };
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        addToHeroBannerToggleUpdation(formData).then((res: any) => {
          console.log(res, "Update Response");
          refetch();
        });
      }
    } else {
      setSnackbarMessage("Draft data cannaot be added to hero banner");
      setSnackbarOpen(true);
    }
  };


  // New code 
  // const handleSwitchChangeForAddingToHeroBanner = (
  //   event: any,
  //   checked: boolean,
  //   id: string,
  //   activeStatus: boolean
  // ) => {
  //   const updatedRows = rows.map((row) =>
  //     String(row.id) === id ? { ...row, addToHeroBanner: checked } : row
  //   );


  //   // Update the state or call the API to persist the changes
  //   setRows(updatedRows); // Example of setting state with updated rows

  //   const data = updatedRows.find((i) => i.id === Number(id));


  //   if (!data) {
  //     return;
  //   }

  //   const HeroBannerDatas = {
  //     heroBannerId: data.id,
  //     heroBannerTitle: data.Title,
  //     heroBannerDescription: data.Description,
  //     heroBannerFile: data.Image,
  //     fileType: data.fileType,
  //     isActive: checked, // Based on the switch status
  //     enableLikes: enablelikes,
  //     enableComments: enableCommands,
  //     sharedAsEmail: sharedAsEmails,
  //     isDraft: data.isDraft || false, // Keep draft status unchanged if already published
  //   };

  //   // Perform further action to update the hero banner data
  //   console.log(HeroBannerDatas);
  // }

  const handleChangeIsActiveToggle = (
    event: ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(id, event.target.checked, "statussss");
    const formData = new FormData();
    const data = {
      newsId: id,
      isActive: event.target.checked,
      onlyActiveStatus: true,
    };
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleSwitchChangeForLikes = (event: any, checked: any, id: any) => {
    console.log(event.target.checked, "statussss");
    const formData = new FormData();
    // setNewTabStatus(event.target.checked);
    const data = {
      newsId: id,
      enableLikes: checked,
      onlyActiveStatus: true,
    };
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleSwitchChangeForComments = (event: any, checked: any, id: any) => {
    console.log(event.target.checked, "statussss");
    const formData = new FormData();
    const data = {
      newsId: id,
      enableComments: checked,
      onlyActiveStatus: true,
    };
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleSwitchChangeForShareAsEmail = (
    event: any,
    checked: any,
    id: any
  ) => {
    console.log(event.target.checked, "statussss");
    const formData = new FormData();
    const data = {
      newsId: id,
      sharedAsEmail: checked,
      onlyActiveStatus: true,
    };
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateItem(formData).then((res: any) => {
      console.log(res, "Update Response");
      refetch();
    });
  };
  const handleChangeEnableLikesyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesEnableLikes(event.target.checked);
    setCheckednoEnableLikes(!event.target.checked);
    setEnableLikes(event.target.checked);
  };
  const handleChangeEnableLikesno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoEnableLikes(event.target.checked);
    setCheckedyesEnableLikes(!event.target.checked);
    setEnableLikes(!event.target.checked);
  };
  const handleChangeEnableCommandsyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event.target.checked);
    setCheckedyesEnableCommands(event.target.checked);
    setCheckednoEnableCommands(!event.target.checked);
    setCommands(event.target.checked);
  };
  const handleChangeEnableCommandsno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoEnableCommands(event.target.checked);
    setCheckedyesEnableCommands(!event.target.checked);
    setCommands(!event.target.checked);
  };
  const handleChangeSharedAsEmailyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesSharedAsEmail(event.target.checked);
    setCheckednoSharedAsEmail(!event.target.checked);
    setSharedEmails(event.target.checked);
  };
  const handleChangeSharedAsEmailno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoSharedAsEmail(event.target.checked);
    setCheckedyesSharedAsEmail(!event.target.checked);
    setSharedEmails(!event.target.checked);
  };
  const resetFormFields = () => {
    setTitle("");
    setTitleError("");
    setOrderError("");
    setPublishDateError("");
    setpublishDate(null);
    setDescription("");
    setTagOption("");
    setSelectedFileName("");
    setManualTag("");
    setSectionTagOption("");
    setUploadedFiles([]);
    setSectionManualTag("");
    setFileSelected("");
    setDescriptionError("");
    setEmpError("");
    setImageError("");
    setSelectedFiles(null);
    setIsEditMode(false);
  };
  const handleDrawerOpen = () => {
    setOpenOne(true);
    setIsEditMode(false);
    setOpenPreview(false);
    setCheckedyesisActive(false);
    // setSelectedFileName("");
    setCheckednoisActive(true);
    setCheckedyesEnableLikes(false);
    setCheckednoEnableLikes(true);
    setCheckedyesEnableCommands(false);
    setCheckednoEnableCommands(true);
    setCheckedyesSharedAsEmail(false);
    setCheckednoSharedAsEmail(true);
    setUploadedFiles([]); // Reset the uploaded files
    setFileNames([]); // Reset the file names
    setDescription("");
    resetFormFields();
  };
  const handleCancel = () => {
    if (
      Title === "" &&
      Description === "" &&
      designations === "" &&
      selectedFiles === null &&
      uploadedFiles === null &&
      ceoName === "" &&
      sectionName === "" &&
      orderValue === "" &&
      publishDate === null
    ) {
      handleDrawerClose();
    } else {
      setAlertContent("Do you want to discard the changes?");
      setAlertActivationForCancel(true);
    }
  };
  const handleDrawerClose = () => {
    setOpenOne(false);
    resetFormFields();
  };
  // const handleClickPreview = () => {
  //   setOpenPreview(true);
  // };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  const handleChangeisActiveyes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedyesisActive(event.target.checked);
    //setCheckedyesisActive(!checkedyesisActive);
    setCheckednoisActive(!event.target.checked);
    setIsActives(event.target.checked);
  };

  const handleChangeisActiveno = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckednoisActive(event.target.checked);
    //setCheckednoisActive(!checkednoisActive);
    setCheckedyesisActive(!event.target.checked);
    setIsActives(!event.target.checked);
    console.log(event.target.checked, "no");
  };
  // const handleChangeTitleField = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const inputValue = event.target.value;
  //   const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

  //   if (inputValue.trim() === "") {
  //     // If the input is empty, set an error message
  //     setTitleError("Title is required.");
  //   } else if (!regex.test(inputValue)) {
  //     // If the input contains invalid characters, set an error message
  //     setTitleError("Please enter only letters, numbers, and spaces.");
  //   } else {
  //     // If the input passes the validation, update the state and clear error
  //     setTitle(inputValue);
  //     setTitleError("");
  //   }
  // };
  // const handleChangeTitleField = (event: any) => {
  //   console.log(event.target.value);
  //   setTitle(event.target.value);
  // };
  const handleChangeTitleField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only letters, numbers, and spaces

    if (regex.test(inputValue) && inputValue.length <= 170) {
      // If the input passes the validation and is within the character limit, update the state
      setTitle(inputValue);
      setTitleError("");
    } else if (inputValue.length > 170) {
      // If the input exceeds the character limit, set an error message
      setTitleError("Title cannot exceed 170 characters.");
    } else {
      // If the input contains invalid characters, set an error message
      setTitleError("Please enter only letters, numbers, and spaces.");
    }
  };


  const handlePublishDateChange = (date: Date | null) => {
    if (date && !isNaN(date.getTime())) {
      try {
        console.log(date.toISOString());
        setpublishDate(date);
        setPublishDateError('');
        setYearError("");
        if (date) {
          const year = new Date(date).getFullYear();
          setYear(year.toString());
        } else {
          setYear("");
        }
      } catch (error) {
        console.error("Invalid date:", error);
        setPublishDateError('Invalid date selected');
      }
    } else {
      setpublishDate(null);
      setPublishDateError('Please select a valid date');
    }
  };

  const handleChangeDescriptionField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z0-9\s.,!?'"()-]*$/; // Allow letters, numbers, spaces, and common punctuation

    if (regex.test(inputValue)) {
      // If the input passes the validation, update the state
      setDescription(inputValue);
      setDescriptionError("");
    } else {
      // If the input contains invalid characters, set an error message
      setDescriptionError(
        "Please enter only letters, numbers, spaces, and common punctuation."
      );
    }
  };
  const handleChangeOrderTitleField = (event: any) => {
    console.log(event.target.value);
    setOrderValue(event.target.value);
    setOrderError("");
  };
  const handleChangeDescriptionFields: ReactQuillOnChange = (
    value,
    delta,
    source,
    editor
  ) => {
    setDescription(value); // Update description state with new value
    console.log("descriptionValue", value)
    setDescriptionError("");
    const regex = /^[a-zA-Z0-9\s.,!?'"()-]*$/; // Allow letters, numbers, spaces, and common punctuation

    if (regex.test(value)) {
      // If the input passes the validation, clear the error message
      setDescriptionError("");
    } else {
      // If the input contains invalid characters, set an error message
      // setDescriptionError(
      //   "Please enter only letters, numbers, spaces, and common punctuation."
      // );
    }
  };


  const quillRef = useRef<ReactQuill | null>(null);
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files?.[0]; // Add null check
      if (!file) return; // Exit if no file is selected
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await axios.post(`${API_URL}upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (quillRef.current) { // Add null check
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          const imageUrl = response.data.url; // URL of the uploaded image
          quill.insertEmbed(range?.index ?? 0, "image", imageUrl); // Use nullish coalescing
        }
      } catch (error) {
        console.error("Error uploading image", error);
      }
    };
  };

  const videoHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();

    input.onchange = async () => {
      const file = input.files?.[0]; // Add null check
      if (!file) return; // Exit if no file is selected

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(`${API_URL}upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (quillRef.current) { // Add null check
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          const videoUrl = response.data.url; // URL of the uploaded video
          quill.insertEmbed(range?.index ?? 0, "video", videoUrl); // Use nullish coalescing
        }
      } catch (error) {
        console.error("Error uploading video", error);
      }
    };
  };



  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
        video: videoHandler,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  }), []);

  //   const quillRef = useRef<ReactQuill | null>(null);

  //   const handleImageUpload = async (file: any) => {
  //     const formData = new FormData();
  //     formData.append('file', file);

  //     try {
  //       const response = await uploadNewsDescriptionImage(formData);
  //       if ('data' in response) {
  //         const imageUrl = response.data.urls[0];
  //         const editor = quillRef.current?.getEditor();
  //         if (editor) {
  //           const range = editor.getSelection();
  //           if (range) {
  //             editor.insertEmbed(range.index, 'image', imageUrl); // Insert image at cursor
  //           } else {
  //             // If there is no selection, insert the image at the end of the content
  //             editor.insertEmbed(editor.getLength(), 'image', imageUrl);
  //           }
  //         }
  //       } else {
  //         console.error('Upload failed:', response.error);
  //       }
  //     } catch (error) {
  //       console.error('Error uploading image:', error);
  //     }
  //   };

  //   const imageHandler = () => {
  //     const input = document.createElement('input');
  //     input.setAttribute('type', 'file');
  //     input.setAttribute('accept', 'image/*');
  //     input.click();

  //     input.onchange = async () => {
  //       if (input.files && input.files.length > 0) {
  //         const file = input.files[0];
  //         if (file) {
  //           await handleImageUpload(file);
  //         }
  //       } else {
  //         console.error('No file selected');
  //       }
  //     };
  //   };


  //  const modules = {
  //     // #3 Add "image" to the toolbar
  //     toolbar: [["bold", "italic", "image"]],

  //   }


  const moduless = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],  // Add image here
      ['clean'],  // Clear formatting
    ],
  };

  // const modules = {
  //   toolbar: {
  //     container: [
  //       [{ header: '1' }, { header: '2' }, { font: [] }],
  //       [{ list: 'ordered' }, { list: 'bullet' }],
  //       ['bold', 'italic', 'underline'],
  //       ['image'],
  //     ],
  //     handlers: {
  //       image: imageHandler, // Custom handler for image upload
  //     },
  //   },
  // };




  const handleChangeDesignation = (event: any) => {
    console.log(event.target.value);
    setDesignations(event.target.value);
    setDeginError("");
  };
  const handleChangeOrganizerName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const regex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces

    if (inputValue.trim() === "") {
      // If the input is empty, set an error message
      setEmpError("Organizer name is required.");
    } else if (!regex.test(inputValue)) {
      // If the input contains invalid characters, set an error message
      setEmpError(
        "Please enter only letters and spaces for the organizer name."
      );
    } else {
      // If the input passes the validation, update the state and clear error
      setCeoName(inputValue);
      setEmpError("");
    }
  };

  useEffect(() => {
    state1.files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    state2.files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);
  const addFile = (file: any) => {
    setFilename1(file[0].name);
    console.log(file[0].name);
    setState1({
      files: file.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };
  const addFile1 = (file: any) => {
    setFilename2(file[0].name);
    console.log(file[0].name);
    setState2({
      files: file.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
    });
  };
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };
  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };
  const handleDrop = (event: any) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log(files);
    // You can handle the dropped files here, for example, upload them
    handleUploadMediaDragging({ target: { files } });
  };
  const handleUploadMediaDragging = (event: any) => {
    // Handle file upload logic here
    const files = event.target.files;
    console.log(files);
    setSelectedFiles(event?.target?.files?.[0]);
    setSelectedFileName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    //@ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
    };
  };
  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setCheckedyesisActive(isChecked);
    setCheckednoisActive(!isChecked);
    setIsActives(isChecked);
  };
  const handleChangeSwitchEnableLikes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesEnableLikes(isChecked);
    setCheckednoEnableLikes(!isChecked);
    setEnableLikes(isChecked);
  };
  const handleChangeSwitchEnableCommands = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesEnableCommands(isChecked);
    setCheckednoEnableCommands(!isChecked);
    setCommands(isChecked);
  };
  const handleChangeSwitchShareAsEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setCheckedyesSharedAsEmail(isChecked);
    setCheckednoSharedAsEmail(!isChecked);
    setSharedEmails(isChecked);
  };

  //New code
  const onFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event?.target?.files;
    if (newFiles) {
      const filesArray = Array.from(newFiles); // Convert FileList to array
      setUploadedFiles(filesArray);
      setFileNames(filesArray.map(file => file.name));
      setUploadError("");
      setImageError("");

      const fileReaders: Promise<string>[] = filesArray.map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target?.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileReaders)
        .then(results => setFilePreviews(results))
        .catch(err => console.error("File reading error", err));
    }
  };
  const handleRemoveFile = (indexToRemove: number) => {
    // Remove the specific file from uploadedFiles array
    const newUploadedFiles = uploadedFiles.filter((_, index) => index !== indexToRemove);
    setUploadedFiles(newUploadedFiles);

    // Update file names and previews accordingly
    const newFileNames = fileNames.filter((_, index) => index !== indexToRemove);
    setFileNames(newFileNames);

    const newFilePreviews = filePreviews.filter((_, index) => index !== indexToRemove);
    setFilePreviews(newFilePreviews);

    // If inputFileRef is used to handle the input file element
    if (inputFileRef.current && newUploadedFiles.length === 0) {
      inputFileRef.current.value = "";
    }
  };

  const clearUploadedFiles = () => {
    setUploadedFiles([]);
    setFileNames([]);
    setFilePreviews([]);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  const onFileDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles) {
      onFilesChange({ target: { files: droppedFiles } } as React.ChangeEvent<HTMLInputElement>);
    }
  };


  const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles(event?.target?.files?.[0]);
    setSelectedFileName(event?.target?.files?.[0].name);
    setImageError("");
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected(e.target?.result);
      //   setImageError("");
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };
  // const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log(event?.target?.files?.[0].name)
  //   setSelectedFiles(event?.target?.files?.[0]);
  //   setNames(event?.target?.files?.[0].name);
  //   let reader = new FileReader();
  //   // @ts-ignore
  //   reader.readAsDataURL(event?.target?.files?.[0]);
  //   reader.onload = (e) => {
  //     console.log(e.target?.result, "kkkkttt");
  //     setFileSelected(e.target?.result);
  //     setImageError("");
  //     //@ts-ignore
  //     // var eee4 = window.atob(e.target?.result)
  //     // console.log(eee4,'rrrrrrthds')
  //   };
  // };


  // const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event?.target?.files?.[0];
  //   if (!file) return; // Exit if no file is selected

  //   setSelectedFiles(file);
  //   setSelectedFileName(file.name);
  //   setImageError("");

  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = (e) => {
  //     const result = e.target?.result as string;

  //     if (!result) return; // Exit if reading failed

  //     // Create an image to check its dimensions
  //     const img = new Image();
  //     img.src = result;

  //     img.onload = () => {
  //       if (img.width === 3840 && img.height === 2160) {
  //         // If the dimensions match, set the file
  //         setFileSelected(result);
  //       } else {
  //         // If dimensions do not match, show an error
  //         setImageError("Image must be 3840x2160 pixels.");
  //       }
  //     };

  //     img.onerror = () => {
  //       setImageError("Failed to load the image.");
  //     };
  //   };
  // };


  const handleClick = (message: React.SetStateAction<string>) => () => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleFileSelect1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event?.target?.files?.[0].name)
    setSelectedFiles1(event?.target?.files?.[0]);
    setNames1(event?.target?.files?.[0].name);
    let reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(event?.target?.files?.[0]);
    reader.onload = (e) => {
      console.log(e.target?.result, "kkkkttt");
      setFileSelected1(e.target?.result);
      //@ts-ignore
      // var eee4 = window.atob(e.target?.result)
      // console.log(eee4,'rrrrrrthds')
    };
  };

  const handleCopyLink = () => {
    const pathToCopy = `${CLIENT_URL}newsContentPage`;

    navigator.clipboard
      .writeText(pathToCopy)
      .then(() => {
        // alert(`Link copied: ${pathToCopy}`);
        handleClick("Link copied successfully")();
        handleDrawerClose();
      })
      .catch((error) => {
        console.error("Unable to copy link", error);
      });
  };
  interface dataInterface {
    newsTitle: string;
    newsDescription: string;
    // newsImage: string;
    imageName: string;
    newsReference: string;
    // newsSection: string;
    isActive: boolean;
    enableLikes: boolean;
    enableComments: boolean;
    sharedAsEmail: boolean;
    isDraft: boolean;
  }
  interface dataInterfacez {
    newsTitle: string;
    newsDescription: string;
    // newsImage: File[];
    imageName: string;
    newsReference: string;
    // newsSection: string;
    isActive: boolean;
    enableLikes: boolean;
    enableComments: boolean;
    sharedAsEmail: boolean;
    isDraft: boolean;
  }
  const createFormDataz = (data: dataInterfacez) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    // Append each file in uploadedFiles if available
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append("file", file);
      });
    }
    return formData;
  };

  const createFormData = (data: dataInterface) => {
    const formData = new FormData();
    // Append each property of ceoData individually
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    // Append the file if selectedFiles is defined
    if (selectedFiles) {
      formData.append("file", selectedFiles);
    }
    return formData;
  };
  const resetAllErrorAlert = () => {
    setTitleError("");
    setOrderError("");
    setDescriptionError("");
    setEmpError("");
    setUploadedFiles([]);
    setImageError("");
    setDeginError("");
  };
  const validateForm = () => {
    let formIsValid = true;
    if (Title === "" || Title?.replace(/<[^>]*>/g, "")?.trim() === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }
    else {
      setTitleError(""); // Clear error if valid
    }

    // Tag validation
    if (tagOption === "") {
      setTagError("Tag is required");
      formIsValid = false;
    } else if (tagOption === "addChoice" && manualTag === "") {
      setTagError("Manual Tag is required");
      formIsValid = false;
      // Show alert
      // alert("Manual Tag is required");
    } else {
      setTagError(""); // Clear the error if the tag is valid
    }
    if (publishDate === null || !publishDate) {
      setPublishDateError("Publish Date is required");
      formIsValid = false;
    }
    else {
      setPublishDateError(""); // Clear error if valid
    }
    // Check for duplicate order values
    //    const orderExists = HeroBannerData?.result?.some((item: { newsOrder: number; }) => 
    //     item.newsOrder === parseInt(orderValue) && (isEditMode ? item.newsOrder !== orderValue : true)
    // );

    // if (orderExists) {
    //     formIsValid = false;
    //     setOrderError("Order number already exists"); // Error for duplicate order
    //     setSnackbarMessage("Order number already exists");
    //     setSnackbarOpen(true);
    //     setTimeout(() => setSnackbarOpen(false), 2000); // Close snackbar after 2 seconds
    // }


    // Section Tag validation
    // if (sectionTagOption === "") {
    //   setSectionError("Section Tag is required");
    //   formIsValid = false;
    // } else if (sectionTagOption === "addChoice" && sectionManualTag === "") {
    //   setSectionError("Section Manual Tag is required");
    //   formIsValid = false;
    //   // Show alert
    //   // alert("Section Manual Tag is required");
    // } else {
    //   setSectionError(""); // Clear the error if the section tag is valid
    // }


    if (
      Description === "" ||
      Description?.replace(/<[^>]*>/g, "")?.trim() === ""
    ) {
      setDescriptionError("Description is required");
      formIsValid = false;
    }
    else {
      setDescriptionError(""); // Clear error if valid
    }
    if (selectedFiles === null) {
      setImageError("Image is required");
      formIsValid = false;
    }
    // if (!uploadedFiles || uploadedFiles.length === 0) {
    //   setImageError("Image is required");
    //   formIsValid = false;
    // } else {
    //   setImageError(""); // Clear error if valid
    // }

    // if (tagOption === "") {
    //   setTagError("Tag is required");
    //   formIsValid = false;
    // } else if (tagOption === "addChoice" && manualTag === "") {
    //   setTagError("Manual Tag is required");
    //   formIsValid = false;
    //   // Show alert
    //   // alert("Manual Tag is required");
    // }
    // if (sectionTagOption === "") {
    //   setSectionError("Section Tag is required");
    //   formIsValid = false;
    // } else if (sectionTagOption === "addChoice" && sectionManualTag === "") {
    //   setSectionError("Section Manual Tag is required");
    //   formIsValid = false;
    //   // Show alert
    //   // alert("Section Manual Tag is required");
    // }
    setIsFormValid(formIsValid); // Update the form validation state
    return {
      formIsValid,
    };
  };
  useEffect(() => {
    validateForm();
  }, [Title, Description, selectedFiles, uploadedFiles,
    sectionError, tagError, orderError]);

  const handleClickPreview = () => {
    if (isFormValid) {
      setOpenPreview(true);
    }
  };

  const handlePublish = () => {
    resetAllErrorAlert();
    // Form validations
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      // There are validation errors, set errors state and don't submit the form
      return;
    }
    const ceoData = {
      newsTitle: Title,
      newsDescription: Description,
      newsImage: fileSelected,
      imageName: names,
      isActive: true,
      enableLikes: enablelikes,
      enableComments: enableCommands,
      sharedAsEmail: sharedAsEmails,
      newsReference:
        tagOption === "addChoice" && manualTag !== "" ? manualTag : tagOption,
      newsSection:
        sectionTagOption === "addChoice" && sectionManualTag !== ""
          ? sectionManualTag
          : sectionTagOption,
      isDraft: false,
    };
    const formData = createFormData(ceoData);
    if (!isEditMode) {
      sendItem(formData);
      console.log("saved ceo data");
      refetch();
    } else {
      formData.append("newsId", dataId);
      formData.append("previousFile", fileSelected);
      formData.append("previousFileName", selectedFileName);
      updateItem(formData);
      refetch();
      refetchNewsItem();
    }
    setTitle("");
    setDescription("");
    setFileSelected("");
    setSelectedFileName("");
    setDesignations("");
    setOrderValue("");
    setCeoName("");
    setSectionName("");
    setIsActives(false);
    handleDrawerClose();
    //snackBar alert
    setSnackbarOpen(true);
    setSnackbarMessage("Data has been published successfully");
  };

  //old function
  // const handleSubmit = () => {
  //   resetAllErrorAlert();
  //   // Form validations
  //   const { formIsValid } = validateForm();
  //   if (!formIsValid) {
  //     // There are validation errors, set errors state and don't submit the form
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("Fill all fields to publish");
  //     return;
  //   }
  //   // const trimmedTitle = Title?.trim();

  //   // const trimmedDescription = Description.replace(/<[^>]*>/g, "").trim();
  //   // const finalDescription = trimmedDescription ? Description : "";
  //   setIsUploading(true);
  //   setUploadProgress(0);
  //   const newsData = {
  //     newsTitle: Title,
  //     id: rows.length + 1,
  //     newsDescription: Description,
  //     newsImage: fileSelected,
  //     imageName: names,
  //     isActive: true,
  //     enableLikes: enablelikes,
  //     enableComments: enableCommands,
  //     sharedAsEmail: sharedAsEmails,
  //     newsReference:
  //       tagOption === "AddChoice" && manualTag !== "" ? manualTag : tagOption,
  //     newsSection:
  //       sectionTagOption === "AddChoice2" && sectionManualTag !== ""
  //         ? sectionManualTag
  //         : sectionTagOption,
  //     isDraft: false,
  //   };
  //   console.log("Submitting newsData:", newsData);

  //   const formData = createFormData(newsData);
  //   if (!isEditMode) {
  //     sendItem(formData).then(() => {
  //       refetch();
  //     });
  //   } else {
  //     formData.append("newsId", dataId);
  //     formData.append("previousFile", fileSelected);
  //     formData.append("previousFileName", selectedFileName);
  //     updateItem(formData).then(() => {
  //       refetch();
  //     });
  //   }
  //   setTitle("");
  //   setDescription("");
  //   setFileSelected("");
  //   setDesignations("");
  //   setCeoName("");
  //   setSectionName("");
  //   setIsActives(false);
  //   //snackBar alert
  //   setSnackbarOpen(true);
  //   setSnackbarMessage("Changes have been published successfully");
  //   handleDrawerClose();

  // };
  const handleSubmit = () => {
    resetAllErrorAlert();

    // Form validations
    const { formIsValid } = validateForm();
    if (!formIsValid) {
      setSnackbarOpen(true);
      setSnackbarMessage("Fill all fields to publish");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const newsData = {
      newsTitle: Title,
      id: rows.length + 1,
      newsDescription: Description,
      //  newsImage: uploadedFiles,  // Use uploadedFiles state here
      imageName: names,
      isActive: true,
      enableLikes: enablelikes,
      enableComments: enableCommands,
      sharedAsEmail: sharedAsEmails,
      newsReference:
        tagOption === "Add Choice Manually" && manualTag !== "" ? manualTag : tagOption,
      isDraft: false,
      // newsOrder: orderValue,
      newsPublishDate: publishDate
    };

    console.log("Submitting newsData:", newsData);

    const formData = createFormData(newsData);

    const processSubmit = async () => {
      try {
        // const formData = createFormDataz(newsData);

        // Append each file in the uploadedFiles array
        // uploadedFiles.forEach((file, index) => {
        //   formData.append(`uploadedFiles[${index}]`, file);
        // });

        if (!isEditMode) {
          await sendItem(formData);
        } else {
          formData.append("newsId", dataId);

          // Append the previous file information
          // uploadedFiles.forEach((file, index) => {
          //   formData.append(`previousFile[${index}]`, file);
          // });
          formData.append("previousFile", fileSelected);

          formData.append("previousFileName", selectedFileName);
          await updateItem(formData);
        }

        refetch();

        // Clear form fields
        setTitle("");
        setDescription("");
        setUploadedFiles([]); // Reset uploadedFiles state
        setDesignations("");
        setOrderValue("");
        setCeoName("");
        setSectionName("");
        setIsActives(false);
        setpublishDate(null);
        setSnackbarOpen(true);
        setSnackbarMessage("Changes have been published successfully");
        handleDrawerClose();
      } finally {
        setIsUploading(false); // Re-enable the button after the upload process completes
      }
    };


    processSubmit();
  };
  console.log(fileSelected, "fileSelectedewf")
  // const handleSubmit = () => {
  //   resetAllErrorAlert();

  //   // Form validations
  //   const { formIsValid } = validateForm();
  //   if (!formIsValid) {
  //     // There are validation errors, set errors state and don't submit the form
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("Fill all fields to publish");
  //     return;
  //   }

  //   setIsUploading(true);
  //   setUploadProgress(0);

  //   const newsData = {
  //     newsTitle: Title,
  //     id: rows.length + 1,
  //     newsDescription: Description,
  //     newsImage: fileSelected,
  //     imageName: names,
  //     isActive: true,
  //     enableLikes: enablelikes,
  //     enableComments: enableCommands,
  //     sharedAsEmail: sharedAsEmails,
  //     newsReference:
  //       tagOption === "Add Choice Manually" && manualTag !== "" ? manualTag : tagOption,
  //     // newsSection:
  //     //   sectionTagOption === "Add Choice Manually " && sectionManualTag !== ""
  //     //     ? sectionManualTag
  //     //     : sectionTagOption,
  //     isDraft: false,
  //   };

  //   console.log("Submitting newsData:", newsData);

  //   const formData = createFormData(newsData);

  //   const processSubmit = async () => {
  //     try {
  //       if (!isEditMode) {
  //         await sendItem(formData);
  //       } else {
  //         formData.append("newsId", dataId);
  //         formData.append("previousFile", fileSelected);
  //         formData.append("previousFileName", selectedFileName);
  //         await updateItem(formData);
  //       }
  //       refetch();

  //       setTitle("");
  //       setDescription("");
  //       setFileSelected("");
  //       setDesignations("");
  //       setCeoName("");
  //       setSectionName("");
  //       setIsActives(false);

  //       // Snackbar alert
  //       setSnackbarOpen(true);
  //       setSnackbarMessage("Changes have been published successfully");
  //       handleDrawerClose();
  //     } finally {
  //       setIsUploading(false); // Re-enable the button after the upload process completes
  //     }
  //   };

  //   processSubmit();
  // };

  //old function
  // const handleSaveAsDraft = () => {

  //   //form validations
  //   let formIsValid = true;

  //   setIsUploading(true);
  //   setUploadProgress(0);
  //   resetAllErrorAlert();

  //   if (Title === "") {
  //     setTitleError("Title is required");
  //     formIsValid = false;
  //   }

  //   if (Title === "" && Description === "") {
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("Fill all data to save");
  //   } else {
  //     // const trimmedTitle = Title?.trim();

  //     // const trimmedDescription = Description.replace(/<[^>]*>/g, "").trim();
  //     // const finalDescription = trimmedDescription ? Description : "";

  //     const ceoData = {
  //       newsTitle: Title,
  //       id: rows.length + 1,
  //       newsDescription: Description,
  //       newsImage: fileSelected,
  //       imageName: names,
  //       isActive: false,
  //       enableLikes: enablelikes,
  //       enableComments: enableCommands,
  //       sharedAsEmail: sharedAsEmails,
  //       newsReference:
  //         tagOption === "AddChoice" && manualTag !== "" ? manualTag : tagOption,
  //       newsSection:
  //         sectionTagOption === "AddChoice2" && sectionManualTag !== ""
  //           ? sectionManualTag
  //           : sectionTagOption,
  //       //isDraft: false,
  //       isDraft: true,
  //     };
  //     const formData = createFormData(ceoData);
  //     if (!isEditMode) {
  //       sendItem(formData).then(() => {
  //         refetch();
  //       });
  //       console.log("saved ceo data");
  //     } else {
  //       formData.append("newsId", dataId);
  //       formData.append("previousFile", fileSelected);
  //       formData.append("previousFileName", selectedFileName);
  //       updateItem(formData).then(() => {
  //         refetch();
  //       });
  //     }
  //     setTitle("");
  //     setDescription("");
  //     setFileSelected("");
  //     setDesignations("");
  //     setCeoName("");
  //     setSectionName("");
  //     setIsActives(false);
  //     //snackBar alert
  //     setSnackbarOpen(true);
  //     setSnackbarMessage("Data has been saved successfully");
  //     handleDrawerClose();
  //   }
  // };
  useEffect(() => {
    setIsButtonDisabled(Title === "");
  }, [Title]);


  const handleSaveAsDraft = () => {
    //   const orderExists = HeroBannerData?.result?.some((item: { newsOrder: number; }) => 
    //     item.newsOrder === parseInt(orderValue) && (isEditMode ? item.newsOrder !== orderValue : true)
    // );
    resetAllErrorAlert();
    let formIsValid = true;

    if (Title === "" || Title?.replace(/<[^>]*>/g, "")?.trim() === "") {
      setTitleError("Title is required");
      formIsValid = false;
    }
    if (!publishDate || publishDate === null) {
      setPublishDateError("Publish Date is required");
      formIsValid = false;
    }
    if (tagOption === "") {
      setTagError("Tag is required");
      formIsValid = false;
    } else if (tagOption === "addChoice" && manualTag === "") {
      setTagError("Manual Tag is required");
      formIsValid = false;
      // Show alert
      // alert("Manual Tag is required");
    }


    //   if (orderExists) {
    //     // setOrderError("Order number already exists");
    //     formIsValid = false;
    //     //Show the error snackbar and hide after 4 seconds
    //       setSnackbarMessage("Order number already exists");
    //       setSnackbarOpen(true);
    //       setTimeout(() => setSnackbarOpen(false), 2000)
    // }


    if (!formIsValid) {
      setIsLoadingg(false);
      return;
    }

    setIsLoadingg(true);

    const ceoData = {
      newsTitle: Title,
      id: rows.length + 1,
      newsDescription: Description,
      newsImage: fileSelected,
      imageName: names,
      isActive: false,
      enableLikes: enablelikes,
      enableComments: enableCommands,
      sharedAsEmail: sharedAsEmails,
      newsReference: tagOption === "Add Choice Manually" && manualTag !== "" ? manualTag : tagOption,
      isDraft: true,
      newsOrder: orderValue,
      newsPublishDate: publishDate
    };
    const formData = createFormData(ceoData);

    const processSave = async () => {
      try {
        // const formData = createFormData(ceoData);

        if (!isEditMode) {
          await sendItem(formData);
        } else {
          formData.append("newsId", dataId);
          formData.append("previousFile", fileSelected);
          formData.append("previousFileName", selectedFileName);
          await updateItem(formData);
        }

        refetch();
        setTitle("");
        setDescription("");
        setFileSelected("");
        setDesignations("");
        setOrderValue("");
        setpublishDate(null);
        setCeoName("");
        setSectionName("");
        setIsActives(false);

        // Show success snackbar message

        setSnackbarMessage("Data has been saved successfully");
        setSnackbarOpen(true);
        handleDrawerClose();
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          // setOrderError(error.response.data.message);
          setSnackbarMessage(error.response.data.message);
          setSnackbarMessage('An error occurred while saving data.');
        } if (!error) {
          setSnackbarMessage("Data has been saved successfully");
        } else {
          console.error(error.response.data.message, "Error saving data:", error, error.response);
          setSnackbarMessage("An error occurred while saving data.");
        }
        setSnackbarOpen(true);
      } finally {
        setIsUploading(false); // Re-enable the button after the upload process completes
        setIsLoadingg(false);
      }
    };

    processSave();
  };

  // const handleSaveAsDraft = () => {
  //   resetAllErrorAlert();
  //   let formIsValid = true;

  //   if (Title === "" || Title?.replace(/<[^>]*>/g, "")?.trim() === "") {
  //     setTitleError("Title is required");
  //     formIsValid = false;
  //   }
  //   if (orderValue === "") {
  //     setOrderError("Order is required");
  //     formIsValid = false;
  //   } if (orderValue ==="0"){
  //     setOrderError("Zero is not Allowed as an Order value");
  //     formIsValid = false;
  //   }

  //   if (!formIsValid) {
  //     setIsLoadingg(false);
  //     return;
  //   }

  //   setIsLoadingg(true);

  //   const ceoData = {
  //     newsTitle: Title,
  //     id: rows.length + 1,
  //     newsDescription: Description,
  //     newsImage: fileSelected,
  //     imageName: names,
  //     isActive: false,
  //     enableLikes: enablelikes,
  //     enableComments: enableCommands,
  //     sharedAsEmail: sharedAsEmails,
  //     newsReference:
  //       tagOption === "Add Choice Manually" && manualTag !== "" ? manualTag : tagOption,
  //     // newsSection:
  //     //   sectionTagOption === "Add Choice Manually " && sectionManualTag !== ""
  //     //     ? sectionManualTag
  //     //     : sectionTagOption,
  //     isDraft: true,
  //     newsOrder: orderValue
  //   };
  //   const formData = createFormData(ceoData);

  //   const processSave = async () => {
  //     try {

  //       const formData = createFormDataz(ceoData);
  //       if (!isEditMode) {
  //         await sendItem(formData);
  //       } else {
  //         formData.append("newsId", dataId);
  //         formData.append("previousFileName", selectedFileName);
  //         await updateItem(formData);
  //       }

  //       // if (!isEditMode) {
  //       //   await sendItem(formData);
  //       //   console.log("saved ceo data");
  //       // } else {
  //       //   formData.append("newsId", dataId);
  //       //   formData.append("previousFile", fileSelected);
  //       //   formData.append("previousFileName", selectedFileName);
  //       //   await updateItem(formData);
  //       // }
  //       refetch();
  //       setTitle("");
  //       setDescription("");
  //       setFileSelected("");
  //       setDesignations("");
  //       setOrderValue("");
  //       setCeoName("");
  //       setSectionName("");
  //       setIsActives(false);
  //       //snackBar alert
  //       setSnackbarOpen(true);

  //       handleDrawerClose();
  //     } catch (error:any) {
  //       if (error.response && error.response.data.message) {
  //         setOrderError(error.response.data.message);
  //         setSnackbarMessage(error.response.data.message);
  //       } else {
  //         console.error("Error saving data:", error);
  //       }
  //     } 
  //     finally {
  //       setIsUploading(false); // Re-enable the button after the upload process completes
  //       setIsLoadingg(false);
  //       setSnackbarMessage("Data has been saved successfully");
  //     }
  //   };

  //   processSave();

  // };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage); // Update the page state
  };

  const getRowClassName = (params: GridRowParams) => {
    let className = "custom-row-class"; // Default class

    if (params.row.Id % 2 === 0) {
      className += " even-row"; // Add even-row class for even IDs
    } else {
      className += " odd-row"; // Add odd-row class for odd IDs
    }

    return className;
  };
  const getCellClassName = () => {
    return "eve";
  };
  const [files, setFiles] = useState<File[]>([]);
  let content;

  if (isLoading) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        {/* <CircularProgress style={{ color: "#E0001B0D " }} /> */}
        <LoaderComponent />
      </div>
    );
  } else if (isSuccess) {
    content = (
      <div
      // style={{
      //   display: "flex",
      //   height: "85%",
      //   marginLeft: "15px",
      //   marginRight: "15px",
      // }}
      >
        {/* <Stack direction="column" spacing={2} sx={{ width: '100%', alignItems: 'center' }}> */}
        <div className="newsData-table1">
          <Box sx={{ flexGrow: 1, width: "100%", height: "430px" }}>
            <>
              {isLoadingg && <LoaderComponent />}
              <DataGrid
                rows={paginatedRows}
                columns={columns}
                sx={{
                  //           '& .MuiDataGrid-row:hover': {
                  //   cursor: 'pointer',
                  // },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#D0D0D0 !important',
                  },
                  ".MuiDataGrid-cellContent": {
                    pointerEvents: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
                  border: "none !important",
                }}
                componentsProps={{
                  footer: { style: { display: "none" } },
                }}
                disableDensitySelector
                getRowClassName={getRowClassName}
                onSortModelChange={(model) => handleSortModelChange(model)}
                // rowCount={filteredRows.length}
                // paginationModel={{ page: page - 1, pageSize }}
                // onPaginationModelChange={(model) => {
                //   setPage(model.page + 1);
                //   setPageSize(model.pageSize);
                // }}
                rowCount={filteredRows.length} // Update to ensure correct count
                paginationMode="server" // Server mode ensures manual handling
                paginationModel={{ page: page - 1, pageSize }}
                onPaginationModelChange={(model) => {
                  setPage(model.page + 1);
                  setPageSize(model.pageSize);
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector={true}
                getCellClassName={getCellClassName}
                pageSizeOptions={[15]}

                style={{
                  color: "rgba(30, 30, 30, 1)!important",
                  boxShadow: "20px !important",
                  width: "100%",
                }}
              />
            </>
          </Box>
        </div>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ marginRight: "10px", color: "gray" }}
          >
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              rows.length
            )} of ${rows.length}`}
          </Typography>
          <Pagination
            count={Math.ceil(rows.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "#666",
                "&.Mui-selected": {
                  backgroundColor: "#e0001b",
                  color: "white",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#e0001b", // Ensures the color stays the same on hover
                  },
                },
              },
              "& .MuiIconButton-root": {
                color: "#666",
              },
            }}
          />
        </div> */}
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "20px" }}>
          <Typography variant="body2" sx={{ marginRight: '10px', color: 'gray' }}>
            {/* {`${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, rows.length)} of ${rows.length}`} */}
            {`${(page - 1) * pageSize + 1} - ${Math.min(
              page * pageSize,
              filteredRows.length
            )} of ${filteredRows.length}`}
          </Typography>
          <Pagination
            count={Math.ceil(filteredRows.length / pageSize)}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  ),
                  next: (props) => (
                    <IconButton {...props} size="small">
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
                {...item}
              />
            )}
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#666',
                '&.Mui-selected': {
                  backgroundColor: 'var(--blackprimary)',
                  color: 'white',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: 'var(--blackprimary)', // Ensures the color stays the same on hover
                  },
                },
              },
              '& .MuiIconButton-root': {
                color: '#666',
              },
            }}
          />
          {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      filteredRows
                        .map((row) => {
                          const value = row[filterField as keyof typeof row];
                          if (filterField === "expiresOn" && value) {
                            const date = new Date(value);
                            if (isValid(date)) {
                              return format(date, 'yyyy-MM-dd');
                            }
                          }
                          return value;
                        })
                        .filter(
                          (value) =>
                            value !== null &&
                            value !== undefined &&
                            value !== "" &&
                            value !== "NaN-aN-aN"
                        )
                    )
                  ).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
                  : []
              }
              selectedOptions={filterSelectedOptions[filterField || ""] || []}
              hideCheckboxes={searchTerm !== ""}
            />
          )}

          {/* {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
             options={
                filterField
                  ? Array.from(
                      new Set(
                        rows
                          .map((row) => row[filterField as keyof typeof row])
                          .filter((value) => value !== null && value !== undefined && value !== "")
                      )
                    )
                  : []
              }
            />
          )} */}

          {/* {filterField && (
            <FilterMenu
              anchorEl={filterAnchorEl}
              onClose={handleFilterClose}
              field={filterField}
              onFilterChange={handleFilterChange}
              options={
                filterField
                  ? Array.from(
                    new Set(
                      rows
                        .map((row) => row[filterField as keyof typeof row])
                        .filter((value) => value !== null && value !== undefined && value !== "")
                    )
                  )
                  : []
              }
              selectedOptions={filterSelectedOptions[filterField] || []} // Pass selectedOptions here
            />
          )} */}


        </div>

        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Pagination
            count={Math.ceil(rows.length / pageSize)} // Calculate the total number of pages
            shape="rounded"
            page={page}
            onChange={handlePageChange}
            hidePrevButton // Hide the previous button
            hideNextButton // Hide the next button
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: '#e0001b', // Change the color of the selected pagination button
                color: '#ffffff', // Set text color to white
                position: 'relative', // Ensure proper positioning for pseudo-element
              },
              '& .MuiPaginationItem-root.Mui-selected::after': {
                content: '""',
                position: 'absolute',
                bottom: '-7px', // Adjust the position of the line as needed
                left: 0,
                width: '100%',
                height: '3px',
                backgroundColor: '#e0001b', // Set the color of the line to red
                borderRadius: '2px', // Add border-radius to the line
              },
              '& .MuiPaginationItem-root.Mui-selected:hover': {
                backgroundColor: '#e0001b', // Override hover effect color
              },
            }}
          />
        </div> */}
        {/* </Stack> */}
        <AlertYesNo
          isAlertOpen={alertActivation}
          handleAlertYes={handleAlertYes}
          handleAlertClose={handleAlertCancel}
        >
          {alertContent}
        </AlertYesNo>
        <AlertYesNo
          isAlertOpen={alertActivationForCancel}
          handleAlertYes={handleAlertYesForCancel}
          handleAlertClose={handleAlertNoCancel}
        >
          {alertContent}
        </AlertYesNo>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#E0001B ",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "74px",
              fontWeight: "600",
              // margin-bottom: 74px;
              // margin-right: 40px;
              marginRight: "45px",
              fontSize: "14px ! impotant",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
          onClose={() => setSnackbarOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#E0001B",
              fontWeight: "600",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "73px",
              marginRight: "45px",
              fontSize: "14px !important",
            }}
          >
            {snackbarMessage}
          </div>
        </Snackbar>
      </div>
    );
  }
  return (
    <div>
      <div className="NewsHeaderStack">
        <div className="contenteditorheader">News</div>
        <div className="newsDescription-style TableDescription">
          The News section delivers the latest updates, achievements, and
          important information from across the organization. Keeping everyone
          informed, this section ensures transparency and promotes a
          well-informed workforce.
        </div>
        <Dialog open={isUploading}>
          <LoaderComponent />
        </Dialog>
        <div className="new">
          <Button
            startIcon={<AddCircleOutlineRoundedIcon style={{ height: "16px" }} />}
            onClick={handleDrawerOpen}
            className="create"
            sx={{ textTransform: "capitalize" }}
          >
            New
          </Button>
          <Drawer
            anchor={"right"}
            classes={{
              paper: "newPosOfDialog",
            }}
            open={openOne}
          // onClose={handleDrawerClose}
          >
            {/* <Grid className="dialogTitle">
                  <Grid>
                    <Button
                      startIcon={
                        <img src={copylink} alt="" style={{ width: "12px" }} />
                      }
                      onClick={handleCopyLink}
                    >
                      <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Copy Link
                      </span>
                    </Button>
                    <Button
                      startIcon={
                        <img src={publish} alt="" style={{ width: "12px" }} />
                      }
                      onClick={handlePublish}
                    >
                      {/* <img
                        src={publish}
                        alt=""
                        style={{ width: "12px", marginRight: "5px" }}
                      /> */}
            {/* <span
                        style={{
                          color: "#606C74",
                          textTransform: "capitalize",
                          fontSize: "12px",
                        }}
                      >
                        Publish
                      </span>
                    </Button>
                  </Grid>
                  <Grid>
                    <Button onClick={handleDrawerClose}>
                      <img src={cancel} alt="" style={{ width: "13px" }} />
                    </Button>
                  </Grid>
                </Grid> */}
            <div className="DrawerHeaderBoxNew">
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* <img
                  style={{ height: "20px", width: "17px" }}
                  src={plusIcon}
                  alt="Plus Icon"
                /> */}
                <div>
                  <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff", height: "16px" }} />
                </div>
                <div className="DrawerTitle"></div> <div className="DrawerTitle">   {isEditMode ? (
                  <Typography>Edit Item</Typography>
                ) : (
                  <Typography>Add Item</Typography>
                )}
                </div>
                <div>
                  {isFormValid && (
                    <Tooltip placement="top-start" title="Click to Preview">
                      <img
                        style={{ height: "20px", width: "17px", marginTop: "5px" }}
                        src={PreviewIcon}
                        alt="Preview Icon"
                        onClick={handleClickPreview}
                      />
                    </Tooltip>
                  )}
                </div>
              </Stack>
              {/* <div> */}
              <Stack direction="row" alignItems="center" spacing={1}>
                <div>
                  {isFormValid && (
                    <Tooltip placement="top-start" title="Click to Publish">
                      <PublishIcon
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "var(--blackprimary)",
                          color: "white !important",
                          height: "17px",
                          // marginTop:"4px",
                          "&:hover": {
                            backgroundColor: "var(--blackprimary)",
                          },
                          display: isButtonDisabled ? "none" : "inline-flex",
                          cursor: "pointer",
                        }}
                        onClick={handleSubmit}
                        className={"PrimaryBlueButton"}
                      />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <Tooltip placement="top-start" title="Click to Save">
                    <BookmarkBorderIcon
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--blackprimary)",
                        color: "white !important",
                        height: "17px",
                        // marginTop:"2px",
                        "&:hover": {
                          backgroundColor: "var(--blackprimary)",
                        },
                        display: isButtonDisabled ? "none" : "inline-flex",
                        cursor: "pointer",
                      }}
                      onClick={handleSaveAsDraft}
                      className={"PrimaryBlueButton"}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip placement="top-start" title="Click to Cancel">
                    <CloseRoundedIcon
                      onClick={handleCancel}
                      style={{
                        cursor: "pointer",
                        color: "whitesmoke",
                        padding: "5px",
                        borderRadius: "50%",
                        transition: "background-color 0.3s",
                        height: "16px",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          "rgba(255, 255, 255, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    />
                  </Tooltip>
                </div>
              </Stack>
              {/* </div> */}
            </div>
            <div>
              <Divider />
              {/* <Typography
                className="newItem"
                style={{
                  textAlign: "left",
                  color: "#02354d",
                }}
              >
                New Item
              </Typography> */}
              {/* {isEditMode ? (
                <Typography
                  className="newItem"
                  style={{
                    textAlign: "left",
                    color: "#02354d",
                  }}
                >
                  Edit Item
                </Typography>
              ) : (
                <Typography className="newItem">New Item</Typography>
              )} */}
              <div style={{ marginBottom: "10px" }}>
                <div className="labelnew" style={{ marginTop: "45px" }}>
                  {/* <Tooltip title="Enter the title" placement="right">
                    <img src={title} alt="" className="subHeadingimg" />
                  </Tooltip> */}
                  <div className="subHeading">Title</div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Tooltip title="Maximum 170 characters are allowed" placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    {/* <Tooltip title="Required" placement="right"> */}
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{
                        marginBottom: "5px",
                        position: "relative",
                        left: "6px",
                      }}
                    />
                    {/* </Tooltip> */}
                  </div>
                </div>
                <TextField
                  id="outlined-adornment-weight"
                  className="span"
                  value={Title}
                  style={{ width: "100%" }}
                  onChange={handleChangeTitleField}
                  placeholder="Enter the news title"
                  autoComplete="off" // Add autoComplete attribute and set it to "off"
                />
                <div className="error">{titleError}</div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <div className="labelnew">
                  {/* <img src={descripton} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">Tag</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{ marginBottom: "5px" }}
                  />
                </div>
                {/* <TextField
                    id="outlined-adornment-weight"
                    className="span"
                    style={{ width: "100%" }}
                    value={ceoName}
                    onChange={handleChangeOrganizerName}
                    placeholder="Enter a name"
                  /> */}
                <div className="documentTypeWrapper">
                  <Select
                    id="outlined-adornment-tag"
                    className={`span ${tagOption === "Add Choice Manually" ? "reduced-width" : ""
                      }`}
                    value={tagOption}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(event) => {
                      setTagOption(event.target.value as string);
                      console.log(tagOption, "sectionTagoption");
                    }}
                    placeholder="Select a Tag"
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return (
                          <span className="place-holder">Select relevant tag</span>
                        );
                      }
                      return selected;
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select a Tag
                    </MenuItem>
                    <MenuItem value="Corporate Announcements">Corporate Announcements</MenuItem>
                    <MenuItem value="Industry News">Industry News</MenuItem>
                    <MenuItem value="Marketing News">Marketing News</MenuItem>
                    <MenuItem value="Project News">
                      Project News
                    </MenuItem>
                    <MenuItem value="Staff News">Staff News</MenuItem>
                    <MenuItem value="Training News">Training News</MenuItem>
                    {/* <MenuItem value="National">National</MenuItem>
                      <MenuItem value="Sports">Sports</MenuItem>
                      <MenuItem value="Science">Science</MenuItem>
                      <MenuItem value="Technology">Technology</MenuItem>
                      <MenuItem value="Health">Health</MenuItem>
                      <MenuItem value="Education">Education</MenuItem>
                      <MenuItem value="Government">Government</MenuItem>
                      <MenuItem value="Transport">Transport</MenuItem>
                      <MenuItem value="Business">Business</MenuItem> */}
                    {/* Add more predefined tag options here */}
                    <MenuItem value="Add Choice Manually">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                          position: "relative",
                          right: "12px",
                        }}
                      >
                        <AddIcon
                          style={{
                            marginRight: "0px",
                            fontSize: "12px",
                            position: "relative",
                            right: "0px",
                          }}
                        />
                        Add Choice Manually
                      </div>
                    </MenuItem>
                  </Select>
                  {tagOption === "Add Choice Manually" && (
                    <div className="manualTag">
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={manualTag}
                        style={{ width: "100%" }}
                        onChange={(event) => setManualTag(event.target.value)}
                        placeholder="Enter tag"
                        autoComplete="off" // Add autoComplete attribute and set it to "off"
                        inputProps={{ maxLength: 30 }} // Set character count limit to 30
                      />
                    </div>
                  )}
                </div>
                <div className="error">{tagError}</div>
              </div>

              <div style={{ marginBottom: '10px', flex: 1 }}>
                <div className="labelnew">
                  {/* <img src={calenderIcon} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">
                    Publish Date
                    <img src={Asterisk} alt="..." style={{ marginBottom: '5px' }} />
                  </div>
                </div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={publishDate}
                    onChange={handlePublishDateChange}
                    // maxDate={new Date()} // Setting the minimum date to the current date
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        autoComplete: "off",
                      },
                    }}
                  />
                </LocalizationProvider>
                {publishDateError && (
                  <div style={{ color: "red", marginTop: "5px", fontSize: "12px" }}>
                    {publishDateError}
                  </div>
                )}
              </div>
              {/* <div style={{ marginBottom: "10px" }}>
                <div className="labelnew" style={{ marginTop: "45px" }}> */}
              {/* <img src={title} alt="" className="subHeadingimg" />
                  <div className="subHeading">Order</div>
                  <img
                      src={Asterisk}
                      alt="..."
                      style={{
                        marginBottom: "5px",
                        position: "relative",
                        left: "6px",
                      }}
                    />
                </div>

                <TextField
                  id="outlined-adornment-weight"
                  className="span"
                  style={{ width: "100%" }}
                  value={orderValue}
                  onChange={handleChangeOrderTitleField}
                  placeholder="Enter the order of this NewsItem"
                  type="number"
                  autoComplete="off" // Add autoComplete attribute and set it to "off"
                />
                <div className="error">{orderError}</div>
              </div> */}
              {/* <div style={{ marginBottom: "10px" }}>
                <div className="labelnew">
                  <img src={descripton} alt="" className="subHeadingimg" />
                  <div className="subHeading">Section</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{ marginBottom: "5px" }}
                  />
                </div>
                <div className="documentTypeWrapper">
                  <Select
                    id="outlined-adornment-section"
                    className={`span ${
                      sectionTagOption === "Add Choice Manually " ? "reduced-width" : ""
                    }`}
                    value={sectionTagOption}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(event) =>
                      setSectionTagOption(event.target.value as string)
                    }
                    placeholder="Select a Section"
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return (
                          <span style={{ color: "#888888" }}>Select a Section</span>
                        );
                      }
                      return selected;
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select a Section
                    </MenuItem>
                    <MenuItem value="Event">Event</MenuItem>
                    <MenuItem value="Conference">Conference</MenuItem>
                    <MenuItem value="Showcase">Showcase </MenuItem>
                    <MenuItem value="Appointment">Appointment </MenuItem>
                    <MenuItem value="Anniversary">Anniversary </MenuItem>
                    <MenuItem value="Award">Award </MenuItem> */}
              {/* Add more predefined section options here */}
              {/* <MenuItem value="Add Choice Manually ">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                          position: "relative",
                          right: "12px",
                        }}
                      >
                        <AddIcon
                          style={{
                            marginRight: "0px",
                            fontSize: "16px",
                            position: "relative",
                            right: "0px",
                          }}
                        />
                        Add Choice Manually
                      </div>
                    </MenuItem>
                  </Select>
                  {sectionTagOption === "Add Choice Manually " && (
                    <div className="manualTag">
                      <TextField
                        id="outlined-adornment-weight"
                        className="span"
                        value={sectionManualTag}
                        style={{ width: "100%" }}
                        onChange={(event) => {
                          console.log(
                            sectionTagOption,
                            "sectionTagoption",
                            sectionManualTag
                          );
                          setSectionManualTag(event.target.value);
                        }}
                        placeholder="Enter tag"
                        autoComplete="off" // Add autoComplete attribute and set it to "off"
                        inputProps={{ maxLength: 30 }} // Set character count limit to 30
                      />
                    </div>
                  )}
                </div>
                <div className="error">{sectionError}</div>
              </div> */}
              {/* <div
            style={{
              flexDirection: "column",
              display: "flex",
              // paddingBottom: "10px",
              // paddingTop: "10px",
            }}
          > */}

              {/* </div> */}
              <div style={{ marginBottom: "10px" }}>
                <div className="labelnew">
                  {/* <img src={descripton} alt="" className="subHeadingimg" /> */}
                  <div className="subHeading">Description</div>
                  <img
                    src={Asterisk}
                    alt="..."
                    style={{ marginBottom: "5px" }}
                  />
                </div>
                {/* <TextField
                    id="outlined-multiline-static"
                    // label="Multiline"
                    multiline
                    rows={4}
                    style={{ width: "100%" }}
                    value={Description}
                    onChange={handleChangeDescriptionField}
                  /> */}




                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={Description}
                  modules={modules}
                  placeholder="Enter a brief description of the news"
                  onChange={handleChangeDescriptionFields} // Pass handleChangeDescriptionField as onChange handler
                />
                <div className="error">{descriptionError}</div>
              </div>
              {/* <div style={{ marginBottom: "10px" }}>                
                  <div className="label">
                    <img src={image} alt="" className="subHeadingimg" />
                    <div className="subHeading">Designation</div>
                  </div>
                  <TextField
                    id="outlined-adornment-weight"
                    className="span"
                    style={{ width: "100%" }}
                    value={designations}
                    onChange={handleChangeDesignation}
                    placeholder="Enter value here"
                  />
                  <div className="error">{deginError}</div>
                </div> */}

              {/* New Code */}
              {/* <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  <img src={image} alt="" className="subHeadingimg" />
                  <div className="subHeading">Image(s)</div>
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Preferred image dimension only 3840 * 2160" placement="bottom-start">
                      <img src={InfoIcon2} alt="Info" className="InfoIcon" />
                    </Tooltip>
                    <img
                      src={Asterisk}
                      alt="Asterisk"
                      style={{ marginBottom: "5px", position: "relative", left: "6px" }}
                    />
                  </div>
                </div>
                <Grid container direction="column" alignItems="center" justifyContent="center" textAlign="center" className="svg">
                  <div onDragOver={onDragOverHandler} onDrop={onFileDropHandler}>
                    <div>
                      <img src={ByrneUpload} alt="Upload Icon" />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>Or</p>
                    </div>
                    <input
                      ref={inputFileRef}
                      hidden
                      type="file"
                      accept="image/*"
                      // multiple
                      onChange={onFilesChange}
                    />
                    {!fileNames.length && (
                      <p onClick={() => inputFileRef.current?.click()} style={{ color: "var(--redprimary)", cursor: "pointer" }}>
                        Browse
                      </p>
                    )} */}
              {/* <div className="error">{uploadError}</div> */}
              {/* <div className="error">{imageError}</div>
                  </div>
                </Grid>
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <>
                    <div className="subHeadingnew">No of Selected Files: {uploadedFiles.length}</div>
                    <div style={{ marginBottom: "10px" }}>
                      <div className="subHeadingnew">Selected Files:</div>
                      <ul className="fileList">
                        {uploadedFiles.map((file, index) => (
                          <li key={index} className="fileItem">
                            <span className="fileName">
                              {file instanceof File ? file.name : (file.name || 'Unknown')}
                            </span>
                            <button className="deleteButton" onClick={() => handleRemoveFile(index)}>
                              <span role="img" aria-label="delete">
                                <DeleteIcon />
                              </span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </div> */}

              <div style={{ marginBottom: "15px" }}>
                <div className="labelnew">
                  <img src={image} alt="" className="subHeadingimg" />
                  <div className="subHeading">Image</div>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Tooltip title="Upload image dimension only 3840 * 2160"
                        placement="bottom-start">
                        <img src={InfoIcon2} alt="..." className="InfoIcon" />
                      </Tooltip>
                    </div>
                    <img
                      src={Asterisk}
                      alt="..."
                      style={{
                        marginBottom: "5px",
                        position: "relative",
                        left: "6px",
                      }}
                    />
                  </div>
                </div>
                <Grid
                  direction="column"
                  container
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  className="svg"
                >
                  <div
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDrop={handleDrop}
                  >
                    {/* <FileUpload value={files} onChange={setFiles} /> */}
                    <div>
                      <img src={ByrneUpload} alt="" />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>Or</p>
                    </div>
                    <input
                      ref={fileRef}
                      hidden
                      type="file"
                      accept="image/*, video/*"
                      multiple
                      onChange={handleUploadMedia}
                    />

                    {!selectedFileName && (
                      <p
                        onClick={() => fileRef.current?.click()}
                        style={{ color: "#E0001B ", cursor: "pointer" }}
                      >
                        Browse
                      </p>
                    )}

                    <div>
                      {selectedFileName && (
                        <>
                          <p style={{ fontSize: "12px" }}>{selectedFileName}</p>
                          <button
                            onClick={() => {
                              setSelectedFiles(null);
                              setSelectedFileName("");
                              setFileSelected("");
                              if (fileRef.current) {
                                fileRef.current.value = "";
                              }
                            }}
                            style={{
                              padding: "5px",
                              backgroundColor: "var(--blackprimary)",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          >
                            Clear
                          </button>
                        </>
                      )}
                    </div>
                    <div className="error">{imageError}</div>
                  </div>
                </Grid>
              </div>

              <Grid>
                {/* <Box className="flexBetween">
                  <div className="label2" >
                    <img src={isActive} alt="" className="subHeadingimg" />
                    <div className="subHeading" >

                      IsActive
                    </div>
                  </div>

                  <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedyesisActive}
                          onChange={handleChangeSwitch} // Use a single handler for the switch
                        />
                      }
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                    <FormControlLabel
                      control={<div />} // Empty control for aligning labels
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                  </Box>
                </Box> */}
                {/* <Box className="flexBetween">
                  <div className="label2">
                    <img src={like1} alt="" className="subHeadingimg" />
                    <div className="subHeading">EnableLikes</div>
                  </div> */}
                {/* <Box>
                            <img
                              src={like1}
                              alt=""
                              style={{ width: "15px", marginRight: "15px" }}
                            />
                            <span className={classes.subHeading} >EnableLikes </span>
                          </Box> */}
                {/* <Box className="flexBetween">

                    <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedyesEnableLikes}
                            onChange={handleChangeSwitchEnableLikes} // Use a single handler for the switch
                            sx={{
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#ED2026',
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#ED2026',
                              },
                            }}
                            />
                        }
                        label={<Typography sx={{ fontSize: 12 }}></Typography>}
                      />
                      <FormControlLabel
                        control={<div />} // Empty control for aligning labels
                        label={<Typography sx={{ fontSize: 12 }}></Typography>}
                      />
                    </Box>
                  </Box> */}
                {/* <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>Yes</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkedyesEnableLikes}
                            onChange={handleChangeEnableLikesyes}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>No</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkednoEnableLikes}
                            onChange={handleChangeEnableLikesno}
                          />
                        }
                      />
                    </Box> */}
                {/* </Box>
              </Grid> */}
                {/* 
              <Grid>
                <Box className={"flexBetween"}>
                  <div className={"label2"}>
                    <img src={comments} alt="" className={"subHeadingimg"} />
                    <div className={"subHeading"}>EnableCommands</div>
                  </div>

                  <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedyesEnableCommands}
                          onChange={handleChangeSwitchEnableCommands} // Use a single handler for the switch
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#ED2026',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#ED2026',
                            },
                          }}
                          />
                      }
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                    <FormControlLabel
                      control={<div />} // Empty control for aligning labels
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                  </Box> */}

                {/* <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>Yes</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkedyesEnableCommands}
                            onChange={handleChangeEnableCommandsyes}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>No</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkednoEnableCommands}
                            onChange={handleChangeEnableCommandsno}
                          />
                        }
                      />
                    </Box> */}
                {/* </Box>
                <Box className="flexBetween">
                  <div className="label2">
                    <img src={shareasemail} alt="" className="subHeadingimg" />
                    <div className="subHeading">ShareAsEmail</div>
                  </div>

                  <Box style={{ display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedyesSharedAsEmail}
                          onChange={handleChangeSwitchShareAsEmail} // Use a single handler for the switch
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                              color: '#ED2026',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                              backgroundColor: '#ED2026',
                            },
                          }}
                          />
                      }
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                    <FormControlLabel
                      control={<div />} // Empty control for aligning labels
                      label={<Typography sx={{ fontSize: 12 }}></Typography>}
                    />
                  </Box> */}

                {/* <Box style={{ display: "flex" }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>Yes</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkedyesSharedAsEmail}
                            onChange={handleChangeSharedAsEmailyes}
                          />
                        }
                      />
                      <FormControlLabel
                        label={
                          <Typography sx={{ fontSize: 12 }}>No</Typography>
                        }
                        control={
                          <Checkbox
                            checked={checkednoSharedAsEmail}
                            onChange={handleChangeSharedAsEmailno}
                          />
                        }
                      />
                    </Box> */}
                {/* </Box> */}
              </Grid>
              {/* <div style={{ marginBottom: "15px" }}>                
                  <div className={"label"}>
                    <img src={Attachment} alt="" className={"subHeadingimg"} />
                    <div className={"subHeading"}>Attachments</div>
                  </div>

                  <Grid
                    direction="column"
                    container
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    className="svg"
                  >
                    <div>
                      <img src={browse} alt="" />
                    </div>
                    <div>
                      <p>Drag and drop here</p>
                    </div>
                    <div>
                      <p>or</p>
                    </div>
                    <input
                      ref={fileRef1}
                      type="file"
                      // accept="image/*,video/*"
                      onChange={handleFileSelect1}
                      hidden
                    />

                    {!selectedFiles1?.name && (
                      <p
                        onClick={() => fileRef1.current?.click()}
                        style={{ color: "#009BAD" }}
                      >
                        Browse
                      </p>
                    )}

                    <div>
                      {selectedFiles?.name && (
                        <>
                          <p style={{ fontSize: "12px" }}>
                            {selectedFiles1?.name}
                          </p>
                          <button
                            onClick={() => {
                              setSelectedFiles1(null);
                              if (fileRef1.current) {
                                fileRef1.current.value = "";
                              }
                            }}
                            style={{
                              padding: "5px",
                              border: "none",
                              borderRadius: "4px",
                            }}
                          >
                            Clear
                          </button>
                        </>
                      )}
                    </div>
                  </Grid>
                </div> */}
            </div>

            <div className={"actionDivTwo12"}>
              {/* <Tooltip
              placement="top-start"
              title={
                  isFormValid
                    ? "Click to Preview"
                    : "Fill fields to preview data"
                }
              >
                <span>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                      },
                    }}
                    onClick={handleClickPreview}
                    disabled={!isFormValid}
                    className={"PrimaryBlueButton"}
                  >
                    Preview
                  </Button>
                </span>
              </Tooltip> */}

              <Drawer
                anchor={"right"}
                classes={{
                  paper: "newPosOfDialog",
                }}
                open={openPreview}
                onClose={handleClosePreview}
              >
                <div></div>
                <div>
                  <div className="DrawerHeaderBoxNew">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {/* <img
                        style={{ height: "20px", width: "17px" }}
                        src={plusIcon}
                        alt="Plus Icon"
                      /> */}
                      <div>
                        <AddCircleOutlineRoundedIcon style={{ marginTop: "4px", color: "#ffffff", height: "16px" }} />
                      </div>
                      <div className="DrawerTitle">Preview</div>
                    </Stack>
                    {/* <div> */}
                    <CloseRoundedIcon
                      onClick={handleCancel}
                      style={{
                        cursor: "pointer",
                        color: "whitesmoke",
                        padding: "5px",
                        borderRadius: "50%",
                        transition: "background-color 0.3s",
                        height: "16px",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          "rgba(255, 255, 255, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    />
                    {/* </div> */}
                  </div>
                  {/* <Grid className={"dialogTitle"}>
                      <Grid>
                        <Button
                          startIcon={
                            <img
                              src={copylink}
                              alt=""
                              style={{ width: "12px" }}
                            />
                          }
                        >
                          <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Copy Link
                          </span>
                        </Button>
                        <Button
                          startIcon={
                            <img
                              src={publish}
                              alt=""
                              style={{ width: "12px" }}
                            />
                          }
                        >
                          <span
                            style={{
                              color: "#606C74",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            Publish
                          </span>
                        </Button>
                      </Grid>
                      <Grid>
                        <Button onClick={handleClosePreview}>
                          <img src={cancel} alt="" style={{ width: "13px" }} />
                        </Button>
                      </Grid>
                    </Grid> */}
                </div>
                <div>
                  {/* <div>
                      <Divider />
                      <p className={"newItem"}>Preview</p>
                    </div> */}
                  <Grid>
                    <Box>
                      {/* <Typography
                          style={{
                            textAlign: "left",
                            marginTop: "15px",
                            color: "#009BAD",
                          }}
                        >
                          News
                        </Typography> */}
                      <img
                        src={fileSelected}
                        alt=""
                        className="eventbackgroundImageNew"
                      />
                      {/* {fileSelected?.length > 0 && <ImageSlider images={fileSelected} />} */}
                      {/* <img
  src={
    uploadedFiles[0] instanceof File
      ? URL.createObjectURL(uploadedFiles[0])
      : (uploadedFiles[0] as { url: string })?.url || ''
  }
  alt=""
  className="eventbackgroundImageNew"
/> */}
                      {/* <img
        src={
          uploadedFiles[0] instanceof File
            ? URL.createObjectURL(uploadedFiles[0])
            : (uploadedFiles[0] as { url: string })?.url || ""
        }
        alt=""
        className="eventbackgroundImageNew"
      /> */}

                      {/* Image Slider for multiple images */}
                      {/* <ImageSlider images={imageUrls} /> */}
                      {/* <ImageVideoSlider media={mediaItems} /> */}

                    </Box>
                    <Grid>
                      <div>
                        <Typography className="NewpreviewHeading" style={{ flex: '1', textAlign: 'left' }}>
                          {Title}
                        </Typography>
                      </div>
                      <Typography className="NewpreviewTag" style={{ flex: '1', textAlign: 'right' }}>
                        {tagOption === "Add Choice Manually" ? manualTag : tagOption}
                      </Typography>
                      <br></br>
                      <Box>
                        Description
                        <div
                          className="previewParagraph1"
                          dangerouslySetInnerHTML={{ __html: Description }}
                        />
                      </Box>
                      <br></br>
                      <Typography className={"NewspreviewParagraph"}>
                        {sectionTagOption === "Add Choice Manually "
                          ? manualTag
                          : sectionTagOption}
                      </Typography>
                      <br></br>
                    </Grid>
                    <Grid className="iconDiv">
                      {/* <div className="iconView">
                        <span>
                          <img src={love} alt="" />
                        </span>
                        <span>0</span>
                      </div> */}
                    </Grid>
                  </Grid>
                </div>
                <div className={"actionDivTwo"}>
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleClosePreview}
                    className="editbtnnew"
                  >
                    Back
                  </Button>
                  {/* <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "var(--blackprimary)",
                      color: "white !important",
                      "&:hover": {
                        backgroundColor: "var(--blackprimary)",
                      },
                    }}
                    onClick={handleSaveAsDraft}
                    autoFocus
                    className="PrimaryBlueButton"
                  >
                    Save
                  </Button> */}
                </div>
              </Drawer>
              {/* <Button
                sx={{ textTransform: "capitalize" }}
                onClick={handleCancel}
                className={"cancelBtnnew"}
              >
                Cancel
              </Button> */}

              {/* <Tooltip
                title={
                  isFormValid
                    ? "Click to Publish"
                    : "Fill fields to Publish data"
                }
              >
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                  color: "white !important",
                  "&:hover": {
                    backgroundColor: isFormValid ? "var(--redprimary)" : "gray",
                  },
                }}
                onClick={() => {
                  handleSubmit();
                }}
                // autoFocus
                className={"PrimaryBlueButton"}
                disabled={!isFormValid}
              >
                Publish
              </Button>
              </Tooltip> */}


              {/* {isFormValid && (
                <Button
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "white",
                    color: "var(--blackprimary) !important",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                    border: "1px solid var(--blackprimary)"
                  }}
                  onClick={handleSubmit}
                  // autoFocus
                  disabled={!isFormValid}
                  className={"PrimaryBlueButton"}
                >
                  Publish
                </Button>
                // </Tooltip>
              )} */}

              {/* <Tooltip
                title={
                  isButtonDisabled
                    ? "Click to Save"
                    : "Fill Title fields to Save data"
                }
              > */}
              {/* <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--blackprimary)",
                  color: "white !important",
                  "&:hover": {
                    backgroundColor: "var(--blackprimary)",
                  },
                  display: isButtonDisabled ? "none" : "inline-flex", // Hide button when disabled
                }}
                onClick={handleSaveAsDraft}
                className={"PrimaryBlueButton"}
                disabled={isButtonDisabled}
              >
                save
              </Button> */}
              {/* </Tooltip> */}
            </div>
          </Drawer>
        </div>
      </div>
      <div>
        <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <div className="Tablebreadcrumb">
            <div className="Tablebreadcrumb1">Landing Page</div>

            <span className="Tablebreadcrumbarrow-icon">
              <NavigateNextIcon />
            </span>
            <span className="Tablebreadcrumb2">News</span>
          </div>
          <Tooltip title="Download Here">
            <div className="export-div" style={{ marginLeft: "620px" }}>
              {/* <span>
                </span> */}
              <span className="export-btn">
                {" "}
                {/* <CSVLink
                        data={tableData}
                        filename={"table-data.csv"}
                        style={{ textDecoration: "none", color: "#423e44" }}
                      > */}
                <img
                  src={exportIcon}
                  alt=""
                  className="exportIcon"
                  style={{
                    width: "25px",
                    height: "30px",

                  }}
                  onClick={handleExport}
                />
                {/* </CSVLink> */}
              </span>
            </div>
          </Tooltip>
          <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
            <SearchBar
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch} // Add this line
            />
          </div>
        </Stack>
      </div>
      {content}

      <div>
        <Dialog open={dialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <div className="DrawerDescriptionHeaderBox">
            <div style={{ color: "var(--redprimary)" }}>
              <DialogTitle>Description</DialogTitle>
            </div>
            <div style={{ marginRight: "30px", color: "#9D9B9E", cursor: "pointer" }}>
              <CancelIcon
                onClick={handleClose} />
            </div>
          </div>
          <DialogContent>
            <div className="ReadmoreherobannerDesc" dangerouslySetInnerHTML={{ __html: dialogContent }} />
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
