// import React, { Fragment, useRef } from "react";
// import { useGetRemoNewsDataQuery } from "../../Services/APIs";
// import { CircularProgress, Typography, Card, CardContent, CardMedia, Box, Paper, Button } from "@mui/material";
// import { Link } from "react-router-dom";
// import moment from "moment";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { CalendarIcon } from "@mui/x-date-pickers/icons";
// import "./NewsStyles.css";
// import LoaderComponent from "../../hooks/LoaderComponent";

// interface News {
//     _id: string;
//     newsTitle: string;
//     newsImage: string;
//     newsDescription: string;
//     createdAt: string;
//     isActive: boolean;
//     newsReference: string;
//     category: string;
// }

// const NewsSection: React.FC<{ title: string, filteredNewsData: News[], RESEARCHPAGE: string, sliderRef: React.RefObject<Slider>, settings: any }> = ({ title, filteredNewsData, RESEARCHPAGE, sliderRef, settings }) => {
//     const truncatedTitleLength = 30;

//     const newsReferenceColors: { [key: string]: string } = {
//         "Marketing News": "#762E7F",
//         "Staff News": "#F46202",
//         "Case studies": "#FFDF00",
//         "Corporate Announcements": "#221E1F",
//         "Industry News": "#6C6D70",
//         "Training News": "#179949",
//         "Project News": "#ED2026"
//     };

//     return (
//         <Box>
//             <div className="industryheader" style={{ display: "flex", alignItems: "center" }}>
//                 <span>{title.toUpperCase()}</span>
//             </div>
//             <Paper className="Topnews-paper">
//                 <CardContent sx={{ pb: "16px!important", borderRadius: "10px", backgroundColor: title === "Industry News" ? "#DCEEF0" : title === "Staff News" ? "#FFECEC" : title === "Case Studies" ? "#E8FFEE" : title === "Corporate Announcements" ? "#FFFADE" :title === "Marketing News" ? "#5BB298": title === "Training News" ? "lightcyan"  : title === "Project News" ? "#FFFADE" : "lightcyan" }}>
                 
//                  <Slider ref={(c) => (sliderRef)} {...settings}
//               slidesToShow={Math.min(5, filteredNewsData.length)}
//               slidesToScroll={Math.min(5, filteredNewsData.length)}
//             >
//               {filteredNewsData?.map((news: News) => {
//                 const truncatedDescriptionLength = news.newsTitle.length > 30 ? 38 : 70;
//                 return (
//                   <Fragment key={news._id}>
//                     <Card
//                       sx={{
//                         maxHeight: 450,
//                         maxWidth:270,
//                         boxShadow: "none !important",
//                         margin: "0px 10px 0px 10px",
//                         border: "1px solid",
//                         borderColor: "lightgray", // Set light color here
//                         borderRadius: "8px",
//                         "&:hover": {
//                           borderColor: "#ED2026", // Change border color on hover
//                         },
//                       }}
//                     >
//                       <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
//                         <CardMedia
//                           style={{ objectFit: "cover" }}
//                           component="img"
//                           height="160"
//                           image={news.newsImage}
//                           alt={news.newsTitle}
//                         />
//                       </Link>
//                       <CardContent className="customCardContent" style={{height:"100px"}}>
//                       <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
//                         <Button>
//                           <Typography className="ByrneNewsReference" style={{ backgroundColor: newsReferenceColors[news.newsReference] || "green" , width:"auto",whiteSpace:"nowrap"}}>
//                             <Fragment>
//                               <div dangerouslySetInnerHTML={{ __html: news.newsReference }} />
//                             </Fragment>
//                           </Typography>
//                         </Button>
//                         </Link>
//                       <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
//                         <Typography className="newsHeaderindustry12" paragraph={true}>
//                           <Fragment>
//                             <div dangerouslySetInnerHTML={{ __html: news.newsTitle.slice(0, 60) }} />
//                           </Fragment>
//                         </Typography>
//                         </Link>
//                       <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
//                         <Typography className="newsHeaderindustry" paragraph={true}>
//                           <Fragment>
//                             <div dangerouslySetInnerHTML={{ __html: news.newsDescription.slice(0, truncatedDescriptionLength) + "..." }} />
//                           </Fragment>
//                         </Typography>
//                         </Link>
//                         <div className="eventdate12">
//                           <CalendarIcon className="clock" style={{ height: "17px", marginLeft: "4px" }} />
//                           <Typography className="eventdate12">
//                             {news.createdAt ? moment(news.createdAt).format('MMMM DD, YYYY') : 'Invalid Date'}
//                           </Typography>
//                         </div>
//                         <Typography>
//                         </Typography>
//                       </CardContent>
//                     </Card>
//                   </Fragment>
//                 );
//               })}
//             </Slider>
//                 </CardContent>
//             </Paper>
//         </Box>

//     );
// };

// const IndustryNews: React.FC = () => {
//     const { data: newsData, error, isLoading } = useGetRemoNewsDataQuery();
//     const RESEARCHPAGE = "/ReadMoreNewsPage";
//     const sliderRef1 = useRef<Slider | null>(null);
//     const sliderRef2 = useRef<Slider | null>(null);
//     const sliderRef3 = useRef<Slider | null>(null);
//     const sliderRef4 = useRef<Slider | null>(null);
//     const sliderRef5 = useRef<Slider | null>(null);
//     const sliderRef6 = useRef<Slider | null>(null);
//     const sliderRef7 = useRef<Slider | null>(null);


//     if (isLoading) {
//         return <LoaderComponent />;
//     }

//     if (error) {
//         if ("status" in error && "data" in error) {
//             return (
//                 <div>
//                     Error: {error.status} - {JSON.stringify(error.data)}
//                 </div>
//             );
//         }
//         if ("message" in error) {
//             return <div>Error: {error.message}</div>;
//         }
//     }

//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 5,
//         slidesToScroll: 1,
//         autoplaySpeed: 3000,
//         arrows: false,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     const filteredTrainingNewss = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Training News');
//     const filteredProjectNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Project News');
//     const filteredIndustryNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Industry News');
//     const filteredStaffNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Staff News');
//     const filteredCaseStudies = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Case Studies');
//     const filteredCorporateAnnouncements = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Corporate Announcements');
//     const filteredMarketingNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Marketing News');

//     return (
//         <div style={{ background: "#f1f1f1" }}>
//             {filteredTrainingNewss.length > 0 && <NewsSection title="Training News" filteredNewsData={filteredTrainingNewss} RESEARCHPAGE={RESEARCHPAGE} sliderRef={sliderRef6} settings={settings} />}
//             {filteredProjectNews.length > 0 && <NewsSection title="Project News" filteredNewsData={filteredProjectNews} RESEARCHPAGE={RESEARCHPAGE} sliderRef={sliderRef7} settings={settings} />}
//             {filteredIndustryNews.length > 0 && <NewsSection title="Industry News" filteredNewsData={filteredIndustryNews} RESEARCHPAGE={RESEARCHPAGE} sliderRef={sliderRef1} settings={settings} />}
//             {filteredStaffNews.length > 0 && <NewsSection title="Staff News" filteredNewsData={filteredStaffNews} RESEARCHPAGE={RESEARCHPAGE} sliderRef={sliderRef2} settings={settings} />}
//             {filteredCaseStudies.length > 0 && <NewsSection title="Case Studies" filteredNewsData={filteredCaseStudies} RESEARCHPAGE={RESEARCHPAGE} sliderRef={sliderRef3} settings={settings} />}
//             {filteredCorporateAnnouncements.length > 0 && <NewsSection title="Corporate Announcement" filteredNewsData={filteredCorporateAnnouncements} RESEARCHPAGE={RESEARCHPAGE} sliderRef={sliderRef4} settings={settings} />}
//             {filteredMarketingNews.length > 0 && <NewsSection title="Marketing News" filteredNewsData={filteredMarketingNews} RESEARCHPAGE={RESEARCHPAGE} sliderRef={sliderRef5} settings={settings} />}
//         </div>
//     );
// };

// export default IndustryNews;



  import React, { Fragment, useRef } from "react";
  import { useGetRemoNewsDataQuery } from "../../Services/APIs";
  import { Typography, Card, CardContent, CardMedia, Box, Paper, Button,Grid } from "@mui/material";
  import { Link } from "react-router-dom";
  import moment from "moment";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
  import { CalendarIcon } from "@mui/x-date-pickers/icons";
  import "./NewsStyles.css";
  import LoaderComponent from "../../hooks/LoaderComponent";
  import NewsImageSlider from "../../Components/News/ImageSlider";
  import { Stack } from "@mui/system";

  interface News {
      shortDescription: any;
      _id: string;
      newsTitle: string;
      newsImage: string;
      newsDescription: string;
      createdAt: string;
      isActive: boolean;
      newsReference: string;
      category: string;
  }

  const NewsSection: React.FC<{ title: string, filteredNewsData: News[], RESEARCHPAGE: string }> = ({ title, filteredNewsData, RESEARCHPAGE }) => {
    const RESEARCHPAGES = "/viewAllNewsByPreference";

    const truncatedTitleLength = 30;
    
    const newsReferenceColors: { [key: string]: string } = {
      "Marketing News": "#762E7F",
      "Staff News": "#F46202",
      "Case studies": "#FFDF00",
      "Corporate Announcements": "#221E1F",
      "Industry News": "#6C6D70",
      "Training News": "#179949",
      "Project News": "#ED2026"
  };

      return (
          <Box sx={{ marginBottom: 4}}>
              {/* <div className="industryheader" style={{ display: "flex", alignItems: "center" }}>
                  <span>{title.toUpperCase()}</span>
              </div> */}
               <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              marginBottom="10px"
              margin="0px 10px 10px 10px"
            >
            <div className="industryheader" style={{ display: "flex", alignItems: "center" }}>
                  <span>{title.toUpperCase()}</span>
              </div>
              <div>
              <Link className="viewnewsHeader"
              to={`${RESEARCHPAGES}/${title}`}>
               View All
              </Link>
              </div>
            </Stack>
              <Box sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: 2,
                  backgroundColor: title === "Industry News" ? "#DCEEF0" : title === "Staff News" ? "#FFECEC" : title === "Case Studies" ? "#E8FFEE" : title === "Corporate Announcements" ? "#FFFADE" : title === "Marketing News" ? "#5BB298" : title === "Training News" ? "#C1F6C8" : title === "Project News" ? "#FFFADE" : "lightcyan",
                  padding: 2,
                  borderRadius: 3,
                  
              }}>
                <Grid container spacing={2}>
                  {filteredNewsData?.slice(0, 5).map((news: News) => (
                         <Grid item xs={12} sm={6} md={4} lg={2.4} key={news._id}>
                      <Card key={news._id} sx={{ 
                          flex: '1 1 calc(33.333% - 16px)', 
                          // minWidth: 150, 
                          // maxWidth: 215,
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: "none !important",
                          backgroundColor: 'white',
                          border: "1px solid",
                          borderColor: "lightgray", // Set light color here
                          borderRadius: "8px",
                          transition: 'border-color 0.3s ease-in', /* Transition effect for border color */
                          "&:hover": {
                            borderColor: "#423E44", // Change border color on hover
                          },
                      }}>
                <NewsImageSlider news={news} RESEARCHPAGE={RESEARCHPAGE} />

                          {/* <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
                          <CardMedia
                              component="img"
                              height="140"
                              image={news.newsImage}
                              alt={news.newsTitle}
                          />
                          </Link> */}
                          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                              <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
                          <Button>
                            <Typography className="ByrneNewsReference" style={{ backgroundColor: newsReferenceColors[news.newsReference] || "green" , width:"auto",whiteSpace:"nowrap"}}>
                              <Fragment>
                                <div dangerouslySetInnerHTML={{ __html: news.newsReference }} />
                              </Fragment>
                            </Typography>
                          </Button>
                          </Link>
                        <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
                          <Typography className="newsHeaderindustry12" paragraph={true}>
                            <Fragment>
                              {/* <div dangerouslySetInnerHTML={{ __html: news.newsTitle.slice(0, 60) }} /> */}
                              <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.newsTitle.length > 60
                                  ? news.newsTitle.slice(0, 60) + '...'
                                  : news.newsTitle,
                            }}
                          />
                            </Fragment>
                          </Typography>
                          </Link>
                        <Link to={`${RESEARCHPAGE}/${news._id}`} style={{ textDecoration: "none" }}>
                          <Typography className="newsHeaderindustry" paragraph={true}>
                            <Fragment>
                              {/* <div dangerouslySetInnerHTML={{ __html: news.newsDescription.slice(0, 110) + "..." }} /> */}
                              <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.shortDescription?.length > 65
                                  ? news.shortDescription.slice(0, 65) + '...'
                                  : news.shortDescription,
                            }}
                          />
                            </Fragment>
                          </Typography>
                          </Link>
                          <div className="industryeventdate12">
                            <CalendarIcon className="clock" style={{ height: "17px", marginLeft: "2px",color:"#605D62" }} />
                            <Typography className="industryeventdate12" style={{color:"#605D62"}}>
                              {news.createdAt ? moment(news.createdAt).format('MMMM DD, YYYY') : 'Invalid Date'}
                            </Typography>
                          </div>
                          <Typography>
                          </Typography>
                          </CardContent>
                      </Card>
                      </Grid>
                  ))}
                  </Grid>
              </Box>
          </Box>
      );
  };

  const IndustryNews: React.FC = () => {
      const { data: newsData, error, isLoading } = useGetRemoNewsDataQuery();
      const RESEARCHPAGE = "/ReadMoreNewsPage";

      if (isLoading) {
          return <LoaderComponent />;
      }

      if (error) {
          if ("status" in error && "data" in error) {
              return (
                  <div>
                      Error: {error.status} - {JSON.stringify(error.data)}
                  </div>
              );
          }
          if ("message" in error) {
              return <div>Error: {error.message}</div>;
          }
      }
      const filteredTrainingNewss = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Training News');
      const filteredProjectNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Project News');
      const filteredIndustryNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Industry News');
      const filteredStaffNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Staff News');
      const filteredCaseStudies = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Case Studies');
      const filteredCorporateAnnouncements = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Corporate Announcements');
      const filteredMarketingNews = newsData?.result.filter((news: News) => news.isActive && news.newsReference === 'Marketing News');

      return (
          <Box sx={{ padding: 3, backgroundColor: "#f1f1f1" }}>
              {filteredTrainingNewss.length > 0 && <NewsSection title="Training News" filteredNewsData={filteredTrainingNewss} RESEARCHPAGE={RESEARCHPAGE}  />}
              {filteredProjectNews.length > 0 && <NewsSection title="Project News" filteredNewsData={filteredProjectNews} RESEARCHPAGE={RESEARCHPAGE} />}
              {filteredIndustryNews.length > 0 && <NewsSection title="Industry News" filteredNewsData={filteredIndustryNews} RESEARCHPAGE={RESEARCHPAGE}  />}
              {filteredStaffNews.length > 0 && <NewsSection title="Staff News" filteredNewsData={filteredStaffNews} RESEARCHPAGE={RESEARCHPAGE}  />}
              {filteredCaseStudies.length > 0 && <NewsSection title="Case Studies" filteredNewsData={filteredCaseStudies} RESEARCHPAGE={RESEARCHPAGE}  />}
              {filteredCorporateAnnouncements.length > 0 && <NewsSection title="Corporate Announcement" filteredNewsData={filteredCorporateAnnouncements} RESEARCHPAGE={RESEARCHPAGE}  />}
              {filteredMarketingNews.length > 0 && <NewsSection title="Marketing News" filteredNewsData={filteredMarketingNews} RESEARCHPAGE={RESEARCHPAGE} />}
          </Box>
      );
  };

  export default IndustryNews;