import ImageIcon from "@mui/icons-material/Image";
import { Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import { JSX } from "react/jsx-runtime";
import editIcon from "../../Assets/eventSearch/editIcon.svg";
import Avatar from '@mui/material/Avatar';

import SearchResultsLoader from "./SearchResultsLoader";
import { Link } from 'react-router-dom';

interface EventSearchTabProps {
    isLoading: any;
    searchResults: any;
    searchPerformed: any;
    
}

const EventSearchTab: React.FC<EventSearchTabProps> = ({
    isLoading,
    searchResults,
    searchPerformed,
   
}) => {
 
    function stripHtmlTags(htmlString: any) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        return tempElement.textContent || tempElement.innerText || "";
      }
      const formatEventDate = (startDateString: any, endDateString: any) => {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
    
        const startDay = startDate.getDate();
        const endDay = endDate.getDate();
        const month = startDate.toLocaleString('default', { month: 'long' });
    
        const formattedDateRange = (startDay === endDay) ? `${startDay}` : `${startDay}-${endDay}`;
    
        return { formattedDateRange, month };
      };
    
      const formatEventTime = (timeString: any) => {
        const [hour, minute] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hour, 10), parseInt(minute, 10));
    
        const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    
        return formattedTime;
      };
    return (
        <>
          <Grid
    item
    xs={12}
    md={12}
    style={{
      border: "1px solid #c1c1c1",
      backgroundColor: "#fff",
      height: "400px",
      width: "100%",
      padding: "30px",
      borderRadius: "8px",
      
    }}
  >
    <Typography >Search Result</Typography>

    <div style={{ height: "300px", overflowY: "scroll",padding: '16px', }}> 
      <List sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}>
      <SearchResultsLoader
                            isLoading={isLoading}
                            searchResults={searchResults}
                            searchPerformed={searchPerformed}
                            contentType="event"
                        />
                        {!isLoading && searchResults.length > 0 && (
                   searchResults.map((result:any, index:any) => (
                    result.contentType === 'event' && (
                      <ListItem key={index} style={{border: "1px solid rgba(0, 0, 0, 0.16)", width: "100%", marginBottom: "10px",borderRadius: "8px" }}>
                      <ListItemAvatar style={{ backgroundColor: '#f2f2f2', marginRight: '10px'}}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <Typography variant="body2" color="textSecondary" align="center">
                                  {formatEventDate(result.eventDate, result.eventEndDate).formattedDateRange}                          
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary" align="center">
                                  {formatEventDate(result.eventDate, result.eventEndDate).month} 
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary" align="center">
                                  {formatEventTime(result.eventTime)} 
                                  </Typography>
                                </div>
                              </ListItemAvatar>
                         {/* <ListItemText primary={result.eventTitle} secondary={stripHtmlTags(result.eventDescription)} />  */}
                           <ListItemText
                      style={{ color: "#605D62" }} // This will apply a global style for the ListItemText
                      primary={result.eventTitle}
                      secondary={stripHtmlTags(result.eventDescription)}
                      primaryTypographyProps={{
                        style: {
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#605D62"
                        }
                      }}
                      secondaryTypographyProps={{
                        style: {
                          fontSize: "12px",
                          color: "#605D62"
                        }
                      }}
                    />

                        {/* <ListItemText
        primary={
          <Typography variant="body2" style={{ paddingLeft: '10px' }}>
            {result.eventTitle}
          </Typography>
        }
        secondary={
          <Typography variant="body2" style={{  paddingLeft: '10px' }}>
            {stripHtmlTags(result.eventDescription)}
          </Typography>
        }
      /> */}
                        <ListItemAvatar>
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <Link to={`/viewAllEvents/${result._id}`}>
                          <img src={editIcon} alt="edit icon" />
                          </Link>
                          </div>
                        </ListItemAvatar>
                        {/* <ListItemAvatar>
                         <a
                         href={`/#/viewAllEvents/${result._id}`} 
                         target="_blank"                        
                          rel="noopener noreferrer"            
                                     >
                             <img src={editIcon} alt="edit icon" />
                               </a>
                       </ListItemAvatar> */}
                       {/* <ListItemAvatar>
                                     <a
                               href={`/#/ReadMoreNewsPage/${result._id}`}
                                  target="_blank"                        
                                    rel="noopener noreferrer"             
                              style={{ position: 'absolute', top: '10px', right: '10px' }} 
                                >
                            <img src={editIcon} alt="edit icon" />
                                </a>
                            </ListItemAvatar> */}

                      </ListItem>
                    )
                  ))
                )}
                {!isLoading && searchResults.length === 0 && !searchPerformed && (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '100px'
                    }}
                  >
                    <Typography>No search results</Typography>
                  </Box>
                )}
      
      </List>
    </div>
  </Grid>
            
        </>
    );
};

export default EventSearchTab;