import * as React from "react";
import ImageIcon from "@mui/icons-material/Image";
import { Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, Button, } from "@mui/material";
import { useState,useEffect } from "react";
import { JSX } from "react/jsx-runtime";
import editIcon from "../../Assets/eventSearch/editIcon.svg";
import fileExcel from "../../Assets/Images/excel.svg";
import folder from "../../Assets/Images/folder-red.svg";
import filePdf from "../../Assets/Images/pdf.svg";
import filePPT from "../../Assets/Images/ppt.svg";
import fileWord from "../../Assets/Images/word.svg";
import FilePreviewDialog from "../DialogPreview/FilePreviewDialog";
import SearchResultsLoader from "./SearchResultsLoader";
import filterIcon from "../../Assets/eventSearch/filter icon.svg";
import filterHoverIcon from "../../Assets/eventSearch/filter icon - hover.svg";
import FilterMenu from "./FilterMenu";
import LoaderComponent from "../../hooks/LoaderComponent";
import { Link } from 'react-router-dom';
interface DocumentSearchTabProps {
    isLoading: any;
    searchResults: any;
    searchPerformed: any;
    filePaths: any;
    handleEditClick: any;
}
interface SearchResult {
    type: string;
    title: string;
    description: string;
    name: string;
    employeeName: string;
    designation: string;
    DirectNo: string;
    Location: string;
    SubLocation: string;
    address: string;
    department: string;
    mobileNumber: string;
    profileIcon: string;
    Ext: string;
    employeeEmail: string;
    // contentLookup:string;
    sizeInKB: string;
    contentType: string;
    newsDescription: string;
    newsTitle: string;
    eventTitle: string;
    eventDescription: string;
    _id: string;
    eventDate: string;
    eventTime: string;
    eventEndDate: string;
    fileUrl: string;
    fileName: string;
  }
const departments = [
    'Sales and Marketing', 'Human Resource', 'Common Forum', 'Finance', 
    'Operations', 'ISO', 'HR', 'Health and Safety', 'Information Technology'
  ];
  
const DocumentSearchTab: React.FC<DocumentSearchTabProps> = ({
    isLoading,
    searchResults,
    searchPerformed,
    filePaths,
    handleEditClick
}) => {
    // filter
    const [isHovered, setIsHovered] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [anchorE1, setAnchorE1] = React.useState<null | HTMLElement>(null);
    const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [tempSelectedDepartments, setTempSelectedDepartments] = useState<string[]>([]);
    // file previw
    const [selectedFilePreview, setSelectedFilePreview] = useState<any>(null);
    const [openPreviewDialogPreview, setOpenPreviewDialogPreview] = useState(false);
    const handleOpenPreview = (fileUrl: string, fileName: string) => {
        setOpenPreviewDialogPreview(true);
        setSelectedFilePreview({ fileUrl, fileName });
        console.log('Selected File:', { fileName, fileUrl });
    };

    const handleClosePreview = () => {
        setOpenPreviewDialogPreview(false);
    };
    const fileType = selectedFilePreview?.fileName?.split(".")?.pop() || "";
    const filePath = selectedFilePreview?.fileUrl || "";

    const getDocumentIcon = (fileName: string): JSX.Element => {

        const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';
        switch (fileExtension) {
            case 'pdf':
                return <img src={filePdf} alt="PDF" />;
            case 'doc':
            case 'docx':
                return <img src={fileExcel} alt="Excel" />;
            case 'ppt':
            case 'pptx':
                return <img src={filePPT} alt="PowerPoint" />;
            case 'xls':
            case 'xlsx':
                return <img src={fileWord} alt="Excel" />;
            // Add more cases as needed for other file types
            default:
                return <ImageIcon />
        }
    };

    useEffect(() => {
      const storedDepartments = JSON.parse(localStorage.getItem('selectedDepartments') || '[]');
      setSelectedDepartments(storedDepartments);
      setIsChecked(storedDepartments.length === departments.length);
  }, []);

  // Update local storage whenever selectedDepartments changes
  useEffect(() => {
      localStorage.setItem('selectedDepartments', JSON.stringify(selectedDepartments));
  }, [selectedDepartments]);


    const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorE1(event.currentTarget);
        setIsHovered(true);
      };
    
      const handleCloseFilterMenu = () => {
        setAnchorE1(null);
        setIsHovered(false);
      };
    
      const handleSelectAll = () => {
        if (isChecked) {
          setSelectedDepartments([]);
        } else {
          setSelectedDepartments(departments);
        }
        setIsChecked(!isChecked);
      };
      const handleApplyFilters = () => {
        // Apply the selected filters
        console.log('Filters applied:', selectedDepartments);
        // Implement filter application logic here
      };
      const generatePath = (departmentName: string, departmentId: string) => {
        if (departmentId === '65fbdb0cf232ab18149de9af') {
          return `/#/SalesandMarketing/${departmentId}`;
        } else {
          return `/#/departmentPage/department/${departmentId}`;
        }
      };
      const hasDocuments = searchResults.some((result:any) => result.contentType === 'document');

    return (
        <>
            <Grid
                item
                xs={12}
                md={12}
                style={{
                    border: "1px solid #c1c1c1",
                    backgroundColor: "#fff",
                    height: "400px",
                    width: "100%",
                    padding: "30px",
                    borderRadius: "8px",
                }}
            >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography>Search Result</Typography>
                        {!isLoading && hasDocuments && (
        <>
          <Button onClick={handleOpenFilterMenu}>
          <img
                    src={isHovered || open ? filterHoverIcon : filterIcon}
                    alt="filter"
                    style={{ width: '30px', height: '30px' }} //resize filter icon 
                  />
          </Button>
          <FilterMenu
            anchorEl={anchorE1}
            open={Boolean(anchorE1)}
            onClose={handleCloseFilterMenu}
            onApplyFilters={handleApplyFilters}
            onSelectAll={handleSelectAll}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
            isChecked={isChecked}
            departments={departments}
          />
        </>
      )}
                    </div>
                    <div style={{ height: "318px", overflowY: "scroll" }}>
                    {isLoading ? (
              <LoaderComponent />
            ) : (
              <>
                        <SearchResultsLoader
                            isLoading={isLoading}
                            searchResults={searchResults}
                            searchPerformed={searchPerformed}
                            contentType="document"
                        />
{/* {!isLoading && hasDocuments && (
        <>
          <Button onClick={handleOpenFilterMenu}>
          <img
                    src={isHovered || open ? filterHoverIcon : filterIcon}
                    alt="filter"
                  />
          </Button>
          <FilterMenu
            anchorEl={anchorE1}
            open={Boolean(anchorE1)}
            onClose={handleCloseFilterMenu}
            onApplyFilters={handleApplyFilters}
            onSelectAll={handleSelectAll}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
            isChecked={isChecked}
            departments={departments}
          />
        </>
      )} */}
                        {/* {!isLoading && searchResults.length > 0 && (
                            <List
                                sx={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    bgcolor: "background.paper",
                                }}
                            >
                                {searchResults
                                  .filter((result:any) => result.contentType === 'document')
                                  .filter((result:any) => selectedDepartments.length === 0 || selectedDepartments.includes(result.departmentName))
                                .map((result: any, index: any) => (
                                    result.contentType === 'document' && (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                width: "100%",
                                                // height: "40px",
                                                padding: "6px",
                                                '&:hover': {
                                                    border: '1px solid',
                                                    borderColor: '#d0cccc',
                                                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 4px",
                                                },
                                            }}
                                            component={result.type === 'folder' ? Link : 'div'}
                                  to={result.type === 'folder' ? generatePath(result.departmentName, result.departmentId) : undefined}
                                        >
                                            <ListItemAvatar 
                                            sx={{ minWidth: "40px" }}
                                            onClick={() => handleEditClick(result.departmentId,result._id,result.parentId,result.type,result
                                            )}
                                            >
                                                {result.type === 'folder' ? (
                                                    <img src={folder} alt="Folder" />
                                                ) : (
                                                    getDocumentIcon(result.fileName)
                                                )}
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={result.fileName}
                                                secondary={filePaths[result._id]}
                                                primaryTypographyProps={{ fontSize: '14px' }}
                                                secondaryTypographyProps={{ fontSize: '12px' }}
                                                style={result.type === 'folder' ? { color: 'black' } : {}} 
                                            />
                                            {result.type !== 'folder' && (
                                                <ListItemAvatar  sx={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={editIcon}
                                                        alt="edit icon"
                                                        onClick={() => handleOpenPreview(result.fileUrl, result.fileName)}
                                                    />
                                                </ListItemAvatar>
                                            )}
                                        </ListItem>
                                    )
                                ))}
                            </List>
                        )} */}
                        {!isLoading && searchResults.length > 0 && (
    <List
        sx={{
            width: "100%",
            maxWidth: "100%",
            bgcolor: "background.paper",
        }}
    >
        {searchResults
            .filter((result: any) => result.contentType === 'document')
            .filter((result: any) => selectedDepartments.length === 0 || selectedDepartments.includes(result.departmentName))
            .map((result: any, index: any) => (
                <ListItem
                key={index}
                sx={{
                    width: "100%",
                    padding: "6px",
                    '&:hover': {
                        border: '1px solid',
                        borderColor: '#d0cccc',
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 4px",
                    },
                }}
                component={result.type === 'folder' ? 'div' : 'div'} 
                onClick={() => {
                    if (result.type === 'folder') {
                        // Navigate to the folder in the same window
                        const path = generatePath(result.departmentName, result.departmentId);
                        
                        window.location.href = path; 
                    } else {
                        handleEditClick(result.departmentId, result._id, result.parentId, result.type, result);
                    }
                }}
            >
                <ListItemAvatar
                    sx={{ minWidth: "40px" }}
                >
                    {result.type === 'folder' ? (
                        <img src={folder} alt="Folder" />
                    ) : (
                        getDocumentIcon(result.fileName)
                    )}
                </ListItemAvatar>
                <ListItemText
                      primary={result.type === 'folder' ? result.name : result.fileName} 
                    secondary={filePaths[result._id]}
                    primaryTypographyProps={{ fontSize: '12px',color:"#605D62", fontWeight:"600" }}
                    secondaryTypographyProps={{ fontSize: '10px',color:"#605D62" }}
                    style={result.type === 'folder' ? { color: 'black' } : {}}
                />
                {result.type !== 'folder' && (
                    <ListItemAvatar sx={{ cursor: 'pointer' }}>
                        <img
                            src={editIcon}
                            alt="edit icon"
                            onClick={() => handleOpenPreview(result.fileUrl, result.fileName)}
                        />
                    </ListItemAvatar>
                )}
            </ListItem>
            ))}
    </List>
)}


{searchPerformed && searchResults.length > 0 && (
      searchResults
        .filter((result: any) => result.contentType === 'document')
        .length > 0 && (
        searchResults
          .filter((result: any) => result.contentType === 'document')
          .every(
            (result: any) =>
              selectedDepartments.length > 0 &&
              !selectedDepartments.includes(result.departmentName)
          ) && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '130px',
            }}
          >
            <Typography>No search results</Typography>
          </Box>
        )
      )
    )}
   </>
    )}


                        {!isLoading && searchResults.length === 0 && !searchPerformed && (
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '130px'
                                }}
                            >
                                <Typography>No search results</Typography>
                            </Box>
                        )}


                    </div>
                </div>
            </Grid>
            < FilePreviewDialog
                open={openPreviewDialogPreview}
                onClose={handleClosePreview}
                selectedFile={selectedFilePreview}
                fileType={fileType}
                filePath={filePath}
                selectedFolderId={"selectedFolderId"}
            />
        </>
    );
};

export default DocumentSearchTab;