import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  CardMedia,
  Grid,
  List,
  Stack,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useGetRemoNewsDataQuery, useGetRemoNewsItemByIdQuery } from "../../../Services/APIs";
import "./Styles.css";
import { formatDistanceToNow } from "date-fns";
import IconText from "../../IconText/IconText";
import PageBack from "../../PageBackBox/PageBack";
import { AspectRatio } from "@mui/icons-material";
import NewsImageSlider from "../../News/ImageSlider";
// import PageBack from "../PageBackBox/PageBack";
// import Navheader from "../SubNavigationHeader/Navheader";

interface NewsItem {
  updatedAt: string | number | Date;
  _id: string;
  newsTitle: string;
  newsImage: string;
  newsDescription: string;
  newsReference: string;
  category?: string;
  isActive: boolean; // Add isActive property
}

const ReadMoreNewsPage: React.FC = () => {

  const { id } = useParams<{ id: string }>();
  const truncatedTitleLength = 80;
  const { data: newsData } = useGetRemoNewsDataQuery();
  const { data: selectedNewsData } = useGetRemoNewsItemByIdQuery(id + "")
  const RESEARCHPAGES = "/viewAllNewsByPreference";
  const RESEARCHPAGENEW = "/ReadMoreNewsPage";
  // const maxWords = 170;
  const isSmallScreen = useMediaQuery('(max-width:1024px)');
  const maxWords = isSmallScreen ? 120 : 170;

  const [recentNewsBig, setrecentNewsBig] = useState<NewsItem | null>(null);
  const [selectedNews, setSelectedNews] = useState<NewsItem | null>(null);
  const newsReferenceColors: { [key: string]: string } = {
    "Marketing News": "#762E7F",
    "Staff News": "#F46202",
    "Case studies": "#FFDF00",
    "Corporate Announcements": "#221E1F",
    "Industry News": "#6C6D70",
    "Byrne News": "#179949",
    "Byrne Posts": "#ED2026",
  };
  console.log("News Data:", newsData);
  console.log("Event ID:", id);

  useEffect(() => {
    if (id && selectedNewsData?.result) {
      const selectedNews = selectedNewsData?.result
      console.log("selected news",selectedNews)
      if (selectedNews) {
        setrecentNewsBig(selectedNews);
        setSelectedNews(selectedNews); // Set the selected news initially
      } else {
        console.log("Selected news not found.");
      }
    } else {
      console.log("Event ID or news data not available.");
    }
  }, [id, selectedNewsData])


  // useEffect(() => {
  //   if (id && newsData?.result) {
  //     const selectedNews = newsData.result.find(
  //       (news: NewsItem) => news._id === id && news.isActive // Filter by isActive property
  //     );

  //     if (selectedNews) {
  //       setrecentNewsBig(selectedNews);
  //       setSelectedNews(selectedNews); // Set the selected news initially
  //     } else {
  //       console.log("Selected news not found.");
  //     }
  //   } else {
  //     console.log("Event ID or news data not available.");
  //   }
  // }, [id, newsData]);

  const navigationData = [
    { label: "News & Events", to: "/viewAllNews" },
    { label: "Home", to: "/home" },
    { label: "News & Events", to: "/viewAllNews" },
    { label: "News ReadMore", to: "" },
  ];

  // Filter news list by category of recentNewsBig and isActive property
  const filteredNewsList = recentNewsBig
    ? newsData?.result.filter(
      (news: NewsItem) =>
        news.newsReference === recentNewsBig.newsReference &&
        news._id !== selectedNews?._id && // Exclude the selected news item
        news.isActive // Filter by isActive property
    )
    : [];

  const currentDate = new Date();

  return (
    <div style={{ background: "#f1f1f1" }}>
      {/* <Navheader />
      <PageBack navigationData={navigationData} /> */}
      <IconText />
      <PageBack navigationData={navigationData} />
      <Paper className="nationalBox2">
        <Grid container>
          {selectedNews && (
            <Grid item xs={12} md={8}>
              <div style={{ padding: "26px" }}>
                <Link
                  to={`${RESEARCHPAGES}/${selectedNews.newsReference}`}
                  style={{ textDecoration: "none", color: "var(--redprimary)" }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      // marginTop: "20px",
                    }}
                  >
                    {selectedNews.newsTitle}
                  </div>
                </Link>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div>
                    <Link
                      to={`${RESEARCHPAGES}/${selectedNews.newsReference}`}
                      style={{ textDecoration: "none", color: "var(--redprimary)" }}
                    >
                      {/* <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginTop: "20px",
                        }}
                      >
                        {selectedNews.newsTitle}
                      </div> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "16px",
                          marginBottom: "16px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              newsReferenceColors[selectedNews.newsReference] ||
                              "green",
                          }}
                          className="NewsReferenceleft"
                        >
                          {selectedNews?.newsReference}
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div style={{ display: "flex", alignItems: "center", color: "#333333", opacity: "0.6", fontSize: "12px" }}>
                      <p style={{ marginLeft: "18px", marginRight: "10px", flex: "0 1 auto" }}>
                        {formatDistanceToNow(new Date(selectedNews.updatedAt), { addSuffix: true }).includes("about") ?
                          formatDistanceToNow(new Date(selectedNews.updatedAt), { addSuffix: true, includeSeconds: true }).replace('about ', '') :
                          formatDistanceToNow(new Date(selectedNews.updatedAt), { addSuffix: true })}
                      </p>
                    </div>
                  </div>
                </div>
                <div key={"newsBig"}>
                  <div>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} md={7}>
                        <NewsImageSlider news={selectedNews} RESEARCHPAGE={RESEARCHPAGENEW} readMore={true} />
                      </Grid> */}

                      <Grid item xs={12} md={12}>
                        {/* <div>
                          <div
                            className="newsdescriptionreadmorepage1"
                            dangerouslySetInnerHTML={{
                              __html: selectedNews?.newsDescription
                                ? selectedNews?.newsDescription.slice(0, 12600)
                                : "",
                            }}
                          />
                          {selectedNews?.newsDescription &&
                            selectedNews.newsDescription.length > 11200 &&
                            " ..."}
                        </div> */}
                        <div
                          className="newsdescriptionreadmorepage1"
                          dangerouslySetInnerHTML={{
                            __html: selectedNews?.newsDescription?.split(' ').slice(0, maxWords).join(' '),
                          }}
                          style={{ textAlign: "left" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div
                        className="newsdescriptionreadmorepage1"
                      >
                        <Fragment>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: selectedNews?.newsDescription?.split(' ').slice(maxWords).join(' '),
                            }}
                            style={{ textAlign: "left" }}
                          />
                        </Fragment>
                      </div>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
          )}
          <Grid
            sx={{ borderRight: "1px solid #e6e6e6", margin: "20px 0px" }}
            item
            md={0.1}
          ></Grid>
          <Grid item xs={12} md={3.9}>
            <div style={{ padding: "26px" }}>
              {recentNewsBig && (
                <div>
                  <p className="readmoreheading-left-rightside">
                    More News On {recentNewsBig?.newsReference}
                  </p>
                </div>
              )}
              {filteredNewsList.map((news: any, index: number) => (
                <List
                  className="customList"
                  key={news._id}
                  onClick={() => setSelectedNews(news)}
                >
                  <div className="maindiv-stack">
                    <div className="stack-list">
                      <div>
                        <NewsImageSlider news={news} RESEARCHPAGE={RESEARCHPAGENEW} />

                        {/* <img
                          className="nationalSmallimg"
                          src={news.newsImage}
                          alt="news"
                          width="140px"
                        /> */}
                      </div>
                      <div>
                        <div style={{ fontSize: "16px", color: "#333333", opacity: "0.6", fontWeight: "500", cursor: "pointer" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                news.newsTitle.length > 90
                                  ? news.newsTitle.slice(0, 90) + '...'
                                  : news.newsTitle,
                            }}
                          />
                        </div>
                        <div className="stack-list3">
                          {/* <div className="newsTitle-forreadmorerightside">
                          {news.newsDescription?.slice(0, 60)}
                        </div> */}
                          <div

                            className="newsforreadmorerightsidedescription"
                            //   dangerouslySetInnerHTML={{
                            //     __html: news.newsDescription?.slice(0, truncatedTitleLength)+"...",
                            //   }}
                            // />
                            dangerouslySetInnerHTML={{
                              __html:
                                news.shortDescription?.length > 80
                                  ? news.shortDescription.slice(0, truncatedTitleLength) + '...'
                                  : news.shortDescription,
                            }}
                          />

                          <div className="newsTitle-forreadmorerightside">
                            <p
                              style={{
                                marginLeft: "0px",
                                marginRight: "10px",
                                flex: "0 1 auto",
                              }}
                            >
                              {/* {formatDistanceToNow(new Date(news.createdDate), {
                              addSuffix: true,
                            }).includes("about")
                              ? formatDistanceToNow(
                                  new Date(news.createdDate),
                                  { addSuffix: true, includeSeconds: true }
                                ).replace("about ", "")
                              : formatDistanceToNow(
                                  new Date(news.createdDate),
                                  { addSuffix: true }
                                )} */}
                              <div style={{ display: "flex", alignItems: "center", color: "#333333", opacity: "0.6", marginTop: "20px", fontSize: "12px" }}>
                                <p style={{ marginLeft: "18px", marginRight: "10px", flex: "0 1 auto" }}>
                                  {formatDistanceToNow(new Date(news.updatedAt), { addSuffix: true }).includes("about") ?
                                    formatDistanceToNow(new Date(news.updatedAt), { addSuffix: true, includeSeconds: true }).replace('about ', '') :
                                    formatDistanceToNow(new Date(news.updatedAt), { addSuffix: true })}
                                </p>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </List>
              ))}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ReadMoreNewsPage;
