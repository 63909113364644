// import React, { useState } from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import Dialog, { DialogProps } from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogActions from '@mui/material/DialogActions';
// import { Grid, Stack } from '@mui/material';
// import UpArraw from '../../Assets/Images/file-upload 1.svg';
// import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
// import excelIcon from '../../Assets/Images/vscode-icons_file-type-excel.svg';

// import './styles.css';

// interface FileDialogProps {
//     open: boolean;
//     onClose: () => void;
//     onFileAccepted: (file: File) => void;
// }

// const FileDialog: React.FC<FileDialogProps> = ({ open, onClose, onFileAccepted }) => {
//     const [file, setFile] = useState<File | null>(null);
//     const [fullWidth, setFullWidth] = React.useState(true);
//     const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');
//     const [uploadPercentage, setUploadPercentage] = useState(0);
//     const [uploadComplete, setUploadComplete] = useState(false);
//     const [hovering, setHovering] = useState(false); // State to track hover

//     const formatFileSize = (sizeInBytes: number) => {
//         if (sizeInBytes === 0) return '0 Bytes';
//         const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
//         const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
//         return parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
//     };

//     const handleFileUpload = (selectedFile: File) => {
//         const fileSize = selectedFile?.size;

//         // Check if the file is an Excel file
//         if (!selectedFile.name.endsWith('.xlsx') && !selectedFile.name.endsWith('.xls')) {
//             // Show alert for unsupported file type
//             alert('Please select an Excel file (.xlsx or .xls)');
//             return; // Abort upload process
//         }

//         setFile(selectedFile);

//         const fileSizeInMB = fileSize / (1024 * 1024);
//         // Simulated upload progress, you may replace it with actual file upload progress logic
//         let progress = 0;
//         const interval = setInterval(() => {
//             progress += Math.random() * 10;
//             setUploadPercentage(Math.min(Math.ceil((progress / fileSizeInMB) * 100), 100));

//             if (progress >= fileSizeInMB) {
//                 clearInterval(interval);
//                 setUploadComplete(true);
//             }
//         }, 500);
//     };

//     const handleAcceptFile = () => {
//         if (file) {
//             onFileAccepted(file);
//             setFile(null);
//         }
//         onClose();
//     };

//     const handleRemoveFile = () => {
//         setFile(null);
//         setUploadPercentage(0);
//         setUploadComplete(false);
//     };

//     const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
//         event.preventDefault();
//     };

//     const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
//         event.preventDefault();
//         const droppedFiles = event.dataTransfer.files;
//         if (droppedFiles.length > 0) {
//             handleFileUpload(droppedFiles[0]);
//         }
//     };

//     return (
//         <Dialog
//             sx={{
//                 "& .MuiDialog-container": {
//                     "& .MuiPaper-root": {
//                         width: "100%",
//                         maxWidth: "700px",
//                         borderRadius: "10px"
//                     },
//                 },
//             }}
//             fullWidth={fullWidth}
//             maxWidth={maxWidth}
//             open={open}
//             onClose={onClose}
//         >
//             <DialogTitle className="DialogTitle1" sx={{ color: "var(--redprimary)" }}>Upload Files</DialogTitle>
//             <DialogTitle className="DialogTitle2" sx={{ color: "rgba(125, 125, 125, 1)" }}>Upload the document to add the multiple user</DialogTitle>
//             <Grid
//                 sx={{ flexGrow: 1 }}
//                 container
//             >
//                 <Grid item xs={12} md={6}>
//                     <DialogContent>
//                         <Box className="dialogBox" onDragOver={handleDragOver} onDrop={handleDrop}>
//                             <img src={UpArraw} alt="Vector Icon" className="vector-icon" style={{ height: '60px', width: '60px' }} />
//                             <Typography variant="body1" color="rgba(125, 125, 125, 1)" sx={{ marginTop: '16px', fontSize: "13px !important" }}>
//                                 Drag and drop here
//                             </Typography>
//                             <hr style={{ width: '100%', margin: '16px 0', border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
//                             <Button variant="contained" color="primary" component="label" className="Browse-btn"
//                                 sx={{
//                                     backgroundColor: 'var(--blackprimary)',
//                                     '&:hover': {
//                                         backgroundColor: 'var(--blackprimary)',
//                                     }
//                                 }}>
//                                 Browse
//                                 <input type="file" hidden onChange={(e) => handleFileUpload(e.target.files![0])} />
//                             </Button>
//                         </Box>
//                     </DialogContent>
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <DialogContent>
//                         <Box>
//                             <div style={{ color: "#e0001b", fontSize: "18px" }}>Uploaded Files</div>
//                             <div>
//                                 {file && (
//                                     <Box className="DialogContentBox"
//                                         sx={{
//                                             marginTop: '16px',
//                                             display: 'flex',
//                                             flexDirection: 'column',
//                                             alignItems: 'flex-start',
//                                         }}
//                                         onMouseEnter={() => setHovering(true)} // Set hovering to true on mouse enter
//                                         onMouseLeave={() => setHovering(false)} // Set hovering to false on mouse leave
//                                     >
//                                         <div>
//                                             <Stack direction='column' spacing={0}>
//                                                 <div style={{
//                                                     alignItems: 'center',
//                                                     borderRadius: '8px',
//                                                     height: '58px',
//                                                     width: '278px',
//                                                     backgroundColor: "rgba(246, 246, 246, 1)",
//                                                     padding: '0 16px',
//                                                     position: 'relative',
//                                                     bottom: '0px !important',
//                                                 }}>
//                                                     {file && (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) && (
//                                                         <img src={excelIcon} alt="Excel Icon" className="excelIcon" style={{ marginRight: '16px' }} />
//                                                     )}
//                                                     <div style={{
//                                                         flex: 1,
//                                                         overflow: 'hidden',
//                                                         textOverflow: 'ellipsis',
//                                                         whiteSpace: 'nowrap',
//                                                         fontSize: '13px',
//                                                         color: 'rgba(0, 0, 0, 1)',
//                                                         height: '40px',
//                                                         paddingLeft: '45px',
//                                                         lineHeight: '2.5',
//                                                         position: 'relative',
//                                                         bottom: '48px',
//                                                     }}>{file ? file.name : 'No file selected'}</div>
//                                                     {file && (
//                                                         <Typography variant="body2" className='file-Size'>{formatFileSize(file.size)}</Typography>
//                                                     )}
//                                                 </div>
//                                                 <Typography variant="body2" className='uploadPercentage' style={{
//                                                     marginTop: '4px',
//                                                     marginLeft: '244px',
//                                                     position: 'relative',
//                                                     bottom: '42px',
//                                                     color: "rgba(26, 101, 0, 1)",
//                                                     // Show the CloseIcon only if hovering is true
//                                                 }}>
//                                                     {uploadPercentage}%
//                                                 </Typography>
//                                                 <div className='closeIcon'>
//                                                     <CloseIcon
//                                                         onClick={handleRemoveFile}
//                                                         sx={{
//                                                             visibility: hovering ? 'visible' : 'hidden',
//                                                             opacity: "0.6",
//                                                             cursor: 'pointer', // Change cursor to pointer on hover
//                                                             position: 'relative',
//                                                             bottom: '2px',
//                                                         }}
//                                                     />
//                                                 </div>
//                                             </Stack>
//                                         </div>
//                                     </Box>
//                                 )}
//                             </div>
//                         </Box>
//                     </DialogContent>
//                     {file && (
//                         <DialogActions sx={{}}>
//                             <Button onClick={onClose} color="primary"
//                                 style={{ backgroundColor: 'rgba(230, 230, 230, 1)', color: 'rgba(125, 125, 125, 1)' }}>Cancel</Button>
//                             <Button onClick={handleAcceptFile} color="primary" disabled={!file}
//                                 style={{ backgroundColor: 'var(--blackprimary)', color: 'rgba(255, 255, 255, 1)' }}
//                             >Accept</Button>
//                         </DialogActions>
//                     )}
//                 </Grid>
//             </Grid>
//         </Dialog>
//     );
// };

// export default FileDialog;


import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import excelIcon from "../../Assets/Images/vscode-icons_file-type-excel.svg";
import AlertOk from "../../hooks/AlertOk";
import UpArraw from "../../Assets/Images/Vector (5).svg";
import { generateExcelTemplate } from "./xlsx"; // Import the function
import templateIcon from "../../Assets/Images/templateIcon4.png";
import CheckIcon from '@mui/icons-material/Check';
import { Snackbar, Tooltip } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface FileDialogProps {
  open: boolean;
  onClose: () => void;
  onFileAccepted: (file: File) => void;
}

const FileDialog: React.FC<FileDialogProps> = ({
  open,
  onClose,
  onFileAccepted,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [alertActivation, setAlertActivation] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>("");
  const [alertActivationForCancel, setAlertActivationForCancel] = useState<boolean>(false);
  const [browseDisabled, setBrowseDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDownloaded, setIsDownloaded] = useState(false);

  const formatFileSize = (sizeInBytes: number) => {
    if (sizeInBytes === 0) return "0 Bytes";
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return (
      parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i]
    );
  };

  const handleFileUpload = (selectedFile: File) => {
    const fileSize = selectedFile?.size;

    if (
      !selectedFile.name.endsWith(".xlsx") &&
      !selectedFile.name.endsWith(".xls")
    ) {
      setAlertContent("Please select an Excel file (.xlsx or .xls)");
      setAlertActivationForCancel(true); // Activate alert
      return;
    }

    setFile(selectedFile);
    setBrowseDisabled(true);
    setUploadComplete(false);

    const fileSizeInMB = fileSize / (1024 * 1024);
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * 10;
      setUploadPercentage(
        Math.min(Math.ceil((progress / fileSizeInMB) * 100), 100)
      );

      if (progress >= fileSizeInMB) {
        clearInterval(interval);
        setUploadComplete(true);
      }
    }, 500);
  };

  const handleDownload = () => {
    generateExcelTemplate();
    setIsDownloaded(true);
    setSnackbarMessage("Template Downloaded successfully");
    setSnackbarOpen(true);
    // onClose(); // Close the dialog after download
  };

  const handleAcceptFile = () => {
    if (file && uploadComplete) {
      onFileAccepted(file);
      setFile(null);
      setBrowseDisabled(false);
      setUploadComplete(false);
    }
    onClose();
  };

  const handleRemoveFile = () => {
    setFile(null);
    setUploadPercentage(0);
    setUploadComplete(false);
    setBrowseDisabled(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setAlertContent("Dragging files here is not supported. Please use the Browse button.");
    setAlertActivation(true); // Activate alert
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setAlertContent("Dragging files here is not supported. Please use the Browse button.");
    setAlertActivation(true); // Activate alert
  };

  const handleAlertYesForCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };

  const handleAlertNoForCancel = () => {
    setAlertActivation(false);
    setAlertContent("");
  };

  const handleBrowseClick = () => {
    // Programmatically trigger the file input
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileUpload(e.target.files[0]);
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "700px",
            borderRadius: "10px",
          },
        },
      }}
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <Stack direction="row">
        <DialogTitle className="DialogTitle1 PrimaryColormediumFontSize">
          Upload File
        </DialogTitle>
      <Stack direction="row" spacing={0}>
        <Tooltip title={isDownloaded ? "Template Downloaded" : "Download Template"}>
          <Button
            startIcon={
              isDownloaded ? (
                <CheckIcon style={{ color: "#009BAD", width: "26px", height: "26px" }} />
              ) : (
                <img src={templateIcon} alt="Template Icon" style={{ width: "26px", height: "26px" }} />
              )
            }
            onClick={handleDownload}
            sx={{ marginTop: "16px" }}
            className="DownloadTemplate"
          >
            {/* {isDownloaded ? "Downloaded" : "Download Template"} */}
          </Button>
        </Tooltip>
        <div>
        <Tooltip title="Close">
          <CloseRoundedIcon
            onClick={onClose}
            style={{
              cursor: "pointer",
              // color: "whitesmoke",
              // padding: "5px",
              borderRadius: "50%",
              transition: "background-color 0.3s",
              position: "relative",
              left: "438px",
              top: "15px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          />
        </Tooltip>
        </div>
        </Stack>
      </Stack>
      <DialogContent>
        <DialogContentText className="DialogTitle2 SubHeader">
          Upload the document to add multiple users
        </DialogContentText>
      </DialogContent>
      <Grid container>
        <Grid item xs={12} md={6}>
          <DialogContent>
            <Box
              className="dialogBox"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <img
                src={UpArraw}
                alt="Vector Icon"
                className="vector-icon"
                style={{ height: "50px", width: "50px" }}
              />
              <Typography
                variant="body1"
                color="rgba(125, 125, 125, 1)"
                sx={{ marginTop: "16px", fontSize: "13px !important" }}
              >
                Drag and drop here
              </Typography>
              <hr
                style={{
                  width: "100%",
                  margin: "16px 0",
                  border: "none",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              />
              <Button
              style={{backgroundColor:"var(--redprimary)"}}
                className="PrimaryBuleButton"
                variant="contained"
                onClick={handleBrowseClick}
                disabled={browseDisabled} // Disable browse button when a file is uploaded
              >
                Browse
              </Button>
              <input
                id="fileInput"
                type="file"
                hidden
                onChange={handleFileInputChange}
              />
            </Box>
          </DialogContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <DialogContent>
            <Box>
              <div className="DateandYear">Uploaded File</div>
              <div>
                {file ? (
                  <Box
                    className="DialogContentBox"
                    sx={{
                      marginTop: "16px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                  >
                    <Stack direction="column" spacing={0}>
                      <div
                        style={{
                          alignItems: "center",
                          borderRadius: "8px",
                          height: "58px",
                          width: "278px",
                          backgroundColor: "rgba(246, 246, 246, 1)",
                          padding: "0 16px",
                          position: "relative",
                          bottom: "0px !important",
                        }}
                      >
                        {file &&
                          (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) && (
                            <img
                              src={excelIcon}
                              alt="Excel Icon"
                              className="excelIcon"
                              style={{ marginRight: "16px" }}
                            />
                          )}
                        <div
                          style={{
                            flex: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: "13px",
                            color: "rgba(0, 0, 0, 1)",
                            height: "40px",
                            paddingLeft: "45px",
                            lineHeight: "2.5",
                            position: "relative",
                            bottom: "48px",
                          }}
                        >
                          {file ? file.name : "No file selected"}
                        </div>
                        {file && (
                          <Typography variant="body2" className="file-Size">
                            {formatFileSize(file.size)}
                          </Typography>
                        )}
                      </div>
                      <Typography
                        variant="body2"
                        style={{
                          marginTop: "4px",
                          marginLeft: "244px",
                          position: "relative",
                          bottom: "42px",
                          color: "rgba(26, 101, 0, 1)",
                        }}
                      >
                        {uploadPercentage}%
                      </Typography>
                      <div className="closeIcon">
                        <CloseIcon
                          onClick={handleRemoveFile}
                          sx={{
                            visibility: hovering ? "visible" : "hidden",
                            opacity: "0.6",
                            cursor: "pointer",
                            position: "relative",
                            bottom: "2px",
                          }}
                        />
                      </div>
                    </Stack>
                  </Box>
                ) : (
                  <Typography
                    variant="body1"
                    color="rgba(125, 125, 125, 1)"
                    sx={{ marginTop: "16px", fontSize: "13px !important" }}
                  >
                    No uploaded file yet
                  </Typography>
                )}
              </div>
            </Box>
          </DialogContent>

          {file && (
            <DialogActions>
              <Button className="SecondaryGrayButton" onClick={handleRemoveFile}>
                Cancel
              </Button>
              <Button
                className="PrimaryBuleButton"
                onClick={handleAcceptFile}
                disabled={!uploadComplete} // Disable until upload is complete
              >
                Accept
              </Button>
            </DialogActions>
          )}
        </Grid>
      </Grid>
      <AlertOk
        isAlertOpen={alertActivationForCancel}
        handleAlertYes={handleAlertYesForCancel}
        handleAlertClose={handleAlertNoForCancel}
      >
        {alertContent}
      </AlertOk>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={3000} // Adjust the duration as needed (in milliseconds)
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          style={{
            backgroundColor: "#009BAD",
            fontWeight: "600",
            color: "white",
            padding: "10px",
            borderRadius: "4px",
            marginBottom: "73px",
            marginRight: "45px",
            fontSize: "14px !important",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
      {/* <AlertOk
        isAlertOpen={alertActivation}
        handleAlertYes={handleAlertYes}
        handleAlertClose={handleAlertNo}
      >
        {alertContent}
      </AlertOk> */}
    </Dialog>
  );
};

export default FileDialog;