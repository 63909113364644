import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Stack } from "@mui/material";

const AlertOk: React.FC<any> = (props: any) => {
  const { isAlertOpen, handleAlertClose, children } = props;

  return (
    <Dialog
      open={isAlertOpen}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "6px",
          minWidth: "26%",
          margin: "0px",
          padding: "30px",
        },
      }}
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            color: "#333333",
            fontSize: "14px",
            fontFamily: "Arial",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            wordBreak: "break-word",
            overflowY: "hidden",
          }}
        >
          {children}
        </DialogContentText>
      </DialogContent>

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
        <Button
          className="PrimaryBuleButton"
          sx={{ textTransform: "none" }}
          variant="outlined"
          onClick={handleAlertClose}
          autoFocus
        >
          OK
        </Button>
      </Stack>
    </Dialog>
  );
};

export default AlertOk;